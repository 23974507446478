<!--<head>
    <link href="~/Content/Styles/font-awesome.min.css" rel="stylesheet">
</head>-->
<div class="main">
  <div>

    <div class="row pb-2 mt-4 mb-2 border-bottom">
      <div class="col-12 col-md-6 col-lg-10"><h1>Important Links</h1></div>
      <div class="col-6 col-lg-2"><h1><button *ngIf="this.isAdmin==true" type="button" (click)="open(modalForm);" class="btn btn-primary"><span class="fa fa-plus"></span>Add New</button></h1></div>
    </div>
  </div>

  <!--<div id="dvalert" class="alert alert-danger" ng-show="false">
      {{errorMessage}}
  </div>-->

  <div id="divSuccess" class="row" ng-show="show" ng-controller="UserGuideController">
    <div class="card-group" style="width:100%; padding-bottom:2.5px" id="accordion">
      <div class="card" style="border:none">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let guide of UserGuides;" (opened)="panelOpenState = true"
                               (closed)="panelOpenState = false" style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;">
            <mat-expansion-panel-header (click)="FetchUserGuideDetailsByAppId(guide);">
              <mat-panel-title>
                <h4 class="card-title">
                  <a href="javascript:;" data-toggle="collapse" data-target="#collapse{{guide.AppId}}" ng-click="FetchUserGuideDetailsByAppId(guide.AppId);"><i id="expandCollapseBtn{{guide.AppId}}" class=" fa fa-plus-circle" aria-hidden="true"></i></a>
                  <strong>{{guide.ApplicationName}}</strong>
                </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!--<div id="collapse{{guide.AppId}}" class="panel-collapse collapse">-->
            <div class="card-body" style="overflow-x:scroll; white-space: nowrap;">
              <table class="table table-bordered">
                <thead>
                  <tr style="background-color: #f5f5f5;">
                    <th *ngIf="guide.ApplicationName == '0 - Others'">Application</th>
                    <th>Description</th>
                    <th>Url</th>
                    <th *ngIf="this.isAdmin==true">Edit</th>
                    <th *ngIf="this.isAdmin==true">Delete</th>
                  </tr>
                </thead>
                <tbody id="divbody">
                  <tr *ngFor="let uguide of UserGuide">
                    <td *ngIf="guide.ApplicationName == '0 - Others'">{{uguide.OtherAppName}}</td>
                    <td>{{uguide.Description}}</td>
                    <td><button type="button" class="btn btn-link" ng-click="redirectToUrl(uguide.Url);">{{uguide.Url}}</button></td>
                    <td *ngIf="this.isAdmin==true"><button type="button" class="btn btn-primary" (click)="openEdit(modalForm, uguide);"><span class="fa fa-edit"></span>Edit</button></td>
                    <td *ngIf="this.isAdmin==true"><button type="button" class="btn btn-primary" (click)="confirm(uguide);"><span class="fa fa-trash"></span>Delete</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--</div>-->
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <ng-template #modalForm let-modal id="myModal">
    <form name='modalform' [formGroup]="addForm">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">{{formTitle}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Close click'); addForm.reset();"><span aria-hidden="true">&times;</span></button>

      </div>

      <div id="modbody" class="modal-body" style="margin-left: 70px;">
        <div class="row form-group">
          <label class="col-form-label col-md-3" for="text" style="text-align: left;">Application<em style="color:red;">*</em> :</label>
          <div class="col-sm-5" ng-show="showticket">
            <div *ngIf="formTitle=='Edit'">
              <input type="text" id="txtservice" class="form-control" [value]="appName" readonly />
            </div>
            <fieldset [hidden]="applicationBlock">
              <div class="ng-autocomplete" id="ServiceAutocomplete">
                <ng-autocomplete [data]="applications"
                                 [searchKeyword]="keyword"
                                 placeholder="Enter Application"
                                 (click)='autocompleteclick($event)'
                                 (selected)='selectEventAdd($event)'
                                 (inputChanged)='LoadApplicationAdd($event)'
                                 historyIdentifier="applications"
                                 [itemTemplate]="itemTemplate"
                                 [notFoundTemplate]="notFoundTemplate">
                </ng-autocomplete>

                <ng-template #itemTemplate let-option>
                  <a [innerHTML]="option.Name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
              <div class="col-sm-5" *ngIf="addForm.value.ApplicationName == '0 - Others'" style="width: 100%">
                <div class="row form-group otherService">
                  <input type="text" id="txtservice" class="form-control" name="OtherAppName" formControlName="OtherAppName" #OtherAppName placeholder="Other Service" />
                </div>
              </div>
            </fieldset>
          </div>
          <!--<div class="col-md-5" ng-show="showticket">
              <input type="text" id="txtapp" ng-model="selectedapplication" class="form-control" placeholder="Select Application" />
              <br />
              <div id="dvothers" style="display:none;">
                <input type="text" id="txtservice" ng-model="service" class="form-control" placeholder="Other Service" />
              </div>
            </div>
            <div class="col-md-5" ng-show="showticketName">
              <input type="text" id="txtapp" ng-model="selectedapplication" class="form-control" disabled />
              <br />
              <div ng-show="selectedapplication == '0 - Others'">
                <input type="text" id="txtservice" ng-model="service" class="form-control" disabled />
              </div>
            </div>
          </div>-->

          <div class="row form-group imp-addF">
            <label class="col-form-label col-md-3" for="text" style="text-align: left;">Description<em style="color:red;">*</em> :</label>
            <div class="col-sm-5 imp-textarea">
              <textarea class="form-control" rows="5" id="txtDescription" name="Description" formControlName="Description" #Description placeholder="Enter description"></textarea>
            </div>
          </div>

          <div class="row form-group imp-addF">
            <label class="col-form-label col-md-3" for="text" style="text-align: left;">URL<em style="color:red;">*</em> :</label>
            <div class="col-md-5 imp-textarea">
              <textarea class="form-control" rows="5" id="txtUrl" name="Url" formControlName="Url" #Url placeholder="Enter Url"></textarea>
            </div>
          </div>

        </div>
        <br />
        <br />
      </div>
      <div class="alert alert-danger" *ngIf="errorMessage" id="divError">
        {{errorMessage}}
      </div>
      <div class="modal-footer">
        <div class="pull-right">
          <button type="submit" id="btnSave" class="btn btn-secondary" (click)="SaveRecord();">Save changes</button>
          <button id="formclosebtn" type="button" class="btn btn-primary" (click)="modal.dismiss('Close click'); addForm.reset();">Close</button>
        </div>
      </div>
    </form>
  </ng-template>
  <input type="hidden" id="hdnUserGuideId" />
</div>
