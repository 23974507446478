<div class="main" [hidden]="nonApproverMessage">
  <div class="noRequest">
    <p>Hello User,</p>
    <p>This page is exclusively for Approvers and permission based.</p>
  </div>
</div>
<div class="main" [hidden]="approverPageContent">
  <div>
    <h1 class="pb-2 mt-4 mb-2 border-bottom">
      Onboarding Requests
    </h1>
  </div>

  <br />
  <div class="row searchAreaRow" *ngIf="pendingRequest != null">
    <form class="searchForm" [formGroup]="searchForm">
      <div class="searchBox col-lg-3">
        <select class="form-control" name="status" formControlName="status" #status>
          <option [ngValue]="null" selected>--Status--</option>
          <option *ngFor="let status of asStatus; let i = index" [value]="asStatus[i].value" style="color:grey;">{{asStatus[i].value}}</option>
        </select>
      </div>
      <div class="searchBox col-lg-3">
        <select class="form-control" name="service" formControlName="service" #service>
          <option [ngValue]="null" selected>--Service Tier--</option>
          <option *ngFor="let tier of asTier; let i = index" [value]="asTier[i].value" style="color:grey;">{{asTier[i].value}}</option>
        </select>
      </div>
      <div class="searchBoxButton col-lg-3">
        <button type="submit" id="btnSrSearch" class="btn btn-primary" (click)="FilterRequests();"><span class="fa fa-search"></span> Search </button>
      </div>

      <!--<div class="searchBoxButton col-lg-3">
        <button id="btnSrSearch" class="btn btn-primary"><span class="fa fa-refresh" (click)="FetchOnboardingDetails();"></span> Refresh </button>
      </div>-->
    </form>

  </div>

  <br />
  <br />

  <div class="alert alert-danger" *ngIf="errorMessage1" id="divError">
    {{errorMessage1}}
  </div>
  <!--<div id="dvalert" class="alert alert-danger" ng-show="false">-->
  <!--{{errorMessage}}-->
  <!--</div>-->
  <!--DELETE CONFIRM MODAL-->

  <ng-template #openDeleteConfirmModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete</h4>
    </div>
    <div id="mymodbody" class="modal-body" style="height: 100px;">
      <p>Do you want to continue to delete this record ?</p>
    </div>
    <div class="alert alert-danger" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
    <div class="modal-footer">
      <div class="pull-right">
        <button id="DelBtn" type="button" class="btn btn-default" style="color: #333; background-color: #fff; border-radius: 4px; border: 1px solid transparent;border-color: #ccc">Delete</button>
        <button id="cancelDelBtn" type="button" class="btn btn-primary" (click)="modal.dismiss('Close click')">Cancel</button>

      </div>
    </div>
  </ng-template>

  <form class="flex-row form-inline" [formGroup]="hiddenform" style="display:none;">
    <div class="row form-group">
      <div class="row form-group">
        <input type="hidden" id="loggeduser" class="form-control" name="LoggedInUser" formControlName="LoggedInUser" #LoggedInUser />
      </div>
    </div>
  </form>
  <div id="divSuccess" class="row" ng-show="show">

    <div class="card-group" style="width:100%" id="accordion">

      <div class="card" style="border:none">
        <mat-accordion>
          <ng-container *ngFor="let data of pendingRequest">
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false" *ngIf="data.Status=='Requested'" style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;" (click)="FetchOnboardingDetailsTable(data);">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h4 class="card-title">
                    <strong>{{data.ApplicationName}}</strong>
                  </h4>
                </mat-panel-title>
                <div class="col-md-4">
                  <div style="float:left"><strong>Over All Status :</strong></div>
                  <div id="light" style="padding-left: 120px">
                    <span id="OverAllStatus" [class]="data.Status"></span> <!--need to add variable fetched from database instead of Red-->
                  </div>
                </div>

                <div class="col-md-3">
                  Total request # 1
                </div>

              </mat-expansion-panel-header>
              <div class="approverWrap">
                <table class="table table-bordered approverTable" id="collapse{{data.Id}}">
                  <thead>
                    <tr style="background-color: #f5f5f5;">
                      <td><strong>Air Id</strong></td>
                      <td><strong>Application Name</strong></td>
                      <td><strong>SO Lead</strong></td>
                      <td><strong>Application Lead</strong></td>
                      <td><strong>Status</strong></td>
                      <td><strong>Request Date</strong></td>
                      <td><strong>Action</strong></td>
                      <td><strong>Delete</strong></td>
                    </tr>
                  </thead>
                  <tbody id="divbody">
                    <tr class="requestTable" *ngFor="let req of pendingRequestForApplication">
                      <td>{{req.AirId}}</td>
                      <td>{{req.ApplicationName}}</td>
                      <td>{{req.ServiceLead}}</td>
                      <td>{{req.ApplicationPointofContact}}</td>
                      <td>{{req.Status}}</td>
                      <td>{{req.CreateDateTime|date:'dd/MM/yyyy'}}</td>
                      <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="viewRequest(modalForm, req)"><span class="fa fa-edit"></span>View</button></td>
                      <td><button type="button" class="btn btn-primary" (click)="delete(openDeleteConfirmModal, data)"><span class="fa fa-trash"></span>Delete</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </mat-expansion-panel>
          </ng-container>
          <ng-container *ngFor="let data of pendingRequest">
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false" *ngIf="data.Status=='Approved'" style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;" (click)="FetchOnboardingDetailsTable(data);">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h4 class="card-title">
                    <strong>{{data.ApplicationName}}</strong>
                  </h4>
                </mat-panel-title>
                <div class="col-md-4">
                  <div style="float:left"><strong>Over All Status :</strong></div>
                  <div id="light" style="padding-left: 120px">
                    <span id="OverAllStatus" [class]="data.Status"></span> <!--need to add variable fetched from database instead of Red-->
                  </div>
                </div>

                <div class="col-md-3">
                  Total request # 1
                </div>

              </mat-expansion-panel-header>
              <div class="approverWrap">
                <table class="table table-bordered approverTable" id="collapse{{data.Id}}">
                  <thead>
                    <tr style="background-color: #f5f5f5;">
                      <td><strong>Air Id</strong></td>
                      <td><strong>Application Name</strong></td>
                      <td><strong>SO Lead</strong></td>
                      <td><strong>Application Lead</strong></td>
                      <td><strong>Status</strong></td>
                      <td><strong>Request Date</strong></td>
                      <td><strong>Action</strong></td>
                      <td><strong>Delete</strong></td>
                    </tr>
                  </thead>
                  <tbody id="divbody">
                    <tr class="requestTable" *ngFor="let req of pendingRequestForApplication">
                      <td>{{req.AirId}}</td>
                      <td>{{req.ApplicationName}}</td>
                      <td>{{req.ServiceLead}}</td>
                      <td>{{req.ApplicationPointofContact}}</td>
                      <td>{{req.Status}}</td>
                      <td>{{req.CreateDateTime|date:'dd/MM/yyyy'}}</td>
                      <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="viewRequest(modalForm, req)"><span class="fa fa-edit"></span>View</button></td>
                      <td><button type="button" class="btn btn-primary" (click)="delete(openDeleteConfirmModal, data)"><span class="fa fa-trash"></span>Delete</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </mat-expansion-panel>
          </ng-container>
          <ng-container *ngFor="let data of pendingRequest">
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false" *ngIf="data.Status=='Review'" style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;" (click)="FetchOnboardingDetailsTable(data);">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h4 class="card-title">
                    <strong>{{data.ApplicationName}}</strong>
                  </h4>
                </mat-panel-title>
                <div class="col-md-4">
                  <div style="float:left"><strong>Over All Status :</strong></div>
                  <div id="light" style="padding-left: 120px">
                    <span id="OverAllStatus" [class]="data.Status"></span> <!--need to add variable fetched from database instead of Red-->
                  </div>
                </div>

                <div class="col-md-3">
                  Total request # 1
                </div>

              </mat-expansion-panel-header>
              <div class="approverWrap">
                <table class="table table-bordered approverTable" id="collapse{{data.Id}}">
                  <thead>
                    <tr style="background-color: #f5f5f5;">
                      <td><strong>Air Id</strong></td>
                      <td><strong>Application Name</strong></td>
                      <td><strong>SO Lead</strong></td>
                      <td><strong>Application Lead</strong></td>
                      <td><strong>Status</strong></td>
                      <td><strong>Request Date</strong></td>
                      <td><strong>Action</strong></td>
                      <td><strong>Delete</strong></td>
                    </tr>
                  </thead>
                  <tbody id="divbody">
                    <tr class="requestTable" *ngFor="let req of pendingRequestForApplication">
                      <td>{{req.AirId}}</td>
                      <td>{{req.ApplicationName}}</td>
                      <td>{{req.ServiceLead}}</td>
                      <td>{{req.ApplicationPointofContact}}</td>
                      <td>{{req.Status}}</td>
                      <td>{{req.CreateDateTime|date:'dd/MM/yyyy'}}</td>
                      <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="viewRequest(modalForm, req)"><span class="fa fa-edit"></span>View</button></td>
                      <td><button type="button" class="btn btn-primary" (click)="delete(openDeleteConfirmModal, data)"><span class="fa fa-trash"></span>Delete</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </mat-expansion-panel>
          </ng-container>
          <ng-container *ngFor="let data of pendingRequest">
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false" *ngIf="data.Status=='Onboarded'" style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;" (click)="FetchOnboardingDetailsTable(data);">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h4 class="card-title">
                    <strong>{{data.ApplicationName}}</strong>
                  </h4>
                </mat-panel-title>
                <div class="col-md-4">
                  <div style="float:left"><strong>Over All Status :</strong></div>
                  <div id="light" style="padding-left: 120px">
                    <span id="OverAllStatus" [class]="data.Status"></span> <!--need to add variable fetched from database instead of Red-->
                  </div>
                </div>

                <div class="col-md-3">
                  Total request # 1
                </div>

              </mat-expansion-panel-header>
              <div class="approverWrap">
                <table class="table table-bordered approverTable" id="collapse{{data.Id}}">
                  <thead>
                    <tr style="background-color: #f5f5f5;">
                      <td><strong>Air Id</strong></td>
                      <td><strong>Application Name</strong></td>
                      <td><strong>SO Lead</strong></td>
                      <td><strong>Application Lead</strong></td>
                      <td><strong>Status</strong></td>
                      <td><strong>Request Date</strong></td>
                      <td><strong>Action</strong></td>
                      <td><strong>Delete</strong></td>
                    </tr>
                  </thead>
                  <tbody id="divbody">
                    <tr class="requestTable" *ngFor="let req of pendingRequestForApplication">
                      <td>{{req.AirId}}</td>
                      <td>{{req.ApplicationName}}</td>
                      <td>{{req.ServiceLead}}</td>
                      <td>{{req.ApplicationPointofContact}}</td>
                      <td>{{req.Status}}</td>
                      <td>{{req.CreateDateTime|date:'dd/MM/yyyy'}}</td>
                      <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="viewRequest(modalForm, req)"><span class="fa fa-edit"></span>View</button></td>
                      <td><button type="button" class="btn btn-primary" (click)="delete(openDeleteConfirmModal, data)"><span class="fa fa-trash"></span>Delete</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
    </div>
  </div>
  <ng-template #modalForm let-modal id="myModal">
    <div class="modal-header">
      <h4 class="modal-title">Onboarding Request</h4>
      <button id="PPSClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
    </div>
    <form name='modalForm' [formGroup]="requestForm">
      <div id="modbody" class="modal-body">
        <fieldset [disabled]="formDisabled">
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text">1.Application AIR ID<em style="color:red; margin-left: 5px;">*</em> :</label>
            <div class="col-md-6">
              <input id="appAIRid" class="form-control" name="AirId" formControlName="AirId" #AirId>
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text">2.Application Name :</label>
            <div class="col-md-6">
              <input id="appname" class="form-control" name="ApplicationName" formControlName="ApplicationName" #ApplicationName>
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">3.Service Operation MD:</label>
            <div class="col-md-6">
              <input id="serviceOpsMD" class="form-control" name="ServiceOperationMD" formControlName="ServiceOperationMD" ServiceOperationMD>
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text">4.Service Operation Lead:</label>
            <div class="col-md-6">
              <input id="serviceOpsLead" class="form-control" name="ServiceLead" formControlName="ServiceLead" #ServiceLead>
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text">5.Service Operations Manager :</label>
            <div class="col-md-6">
              <input id="serviceOpsLead" class="form-control" name="ServiceOperationsManager" formControlName="ServiceOperationsManager" #ServiceOperationsManager>
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text">6.Application Point of Contact (Onboarding) :</label>
            <div class="col-md-6">
              <input id="appLead" class="form-control" name="ApplicationPointofContact" formControlName="ApplicationPointofContact" #ApplicationPointofContact>
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;"> 7.Application Service Tier : </label>
            <div class="col-sm-5">
              <input id="ApplicationServiceTier" class="form-control input-width" name="ApplicationServiceTier" formControlName="ApplicationServiceTier" #ApplicationServiceTier>
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;"> 8.GPC Onboarding Type : </label>
            <div class="col-sm-5">
              <input id="GPCOnboardingType" class="form-control input-width" name="GPCOnboardingType" formControlName="GPCOnboardingType" #GPCOnboardingType>
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;"> 9.Monitoring Alert Source : </label>
            <div class="col-sm-5">
              <input id="MonitoringAlertSource" class="form-control input-width" name="MonitoringAlertSource" formControlName="MonitoringAlertSource" #MonitoringAlertSource>
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text">
              10.Total amount of P1/P2 incidents in previous Fiscal Year :
            </label>
            <div class="col-md-6">
              <input type="text" id="txtTotalamountofpreviousFiscalYear" class="form-control" name="TotalAmountP1P2IncidentsFiscalYear" formControlName="TotalAmountP1P2IncidentsFiscalYear" #TotalAmountP1P2IncidentsFiscalYear />
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text">
              11.Application Distribution List (Support):
            </label>
            <div class="col-md-6">
              <input type="text" id="txtApplicationDistributionList" class="form-control" name="ApplicationDistributionList" formControlName="ApplicationDistributionList" #ApplicationDistributionList />
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text"> 12.High Availability Enabled : </label>
            <div class="col-sm-5">
              <input id="HighAvailabilityEnabled" class="form-control input-width" name="HighAvailabilityEnabled" formControlName="HighAvailabilityEnabled" #HighAvailabilityEnabled>
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text">
              13.Number of Application Users :
            </label>
            <div class="col-md-6">
              <input type="text" id="txtNumberofApplicationUser" class="form-control" name="NumberofApplicationUsers" formControlName="NumberofApplicationUsers" #NumberofApplicationUsers />
            </div>
          </div>
        </fieldset>
        <!--<div class="row form-group mb-3">
    <label class="col-form-label col-md-4" for="text">12.Question :</label>
    <div class="col-md-6">
      <input class="form-control" type="text" name="Question" formControlName="Question" #Question />
    </div>
  </div>-->
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">14.Files Upload:</label>
          <div class="col-md-6">
            <input type="file" id="file" multiple
                   (change)="getFileDetails($event)" accept="all/*" name="file" #file [disabled]="ifApproved"/>
            <button (click)="uploadFiles()" class="btn btn-primary" style="width: 28%; height: 35px; text-align: center; margin-top: 12px; margin-left: 135px;" [disabled]="ifApproved">Upload File</button>
          </div>
        </div>
        <div class="row form-group mb-3" *ngIf="SelectedFiles.length > 0">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Attached Images:</label>
          <div class="col-md-6">
            <ul class="ft-file-list">
              <li *ngFor="let file of SelectedFiles">
                <div class="ft-modal-upload-filename">
                  <a type="button" style="color:#0000EE " (click)="previewAttachment(file)">{{file.fName}}</a>
                  <a class="text-danger ft-modal-upload-delete-file" (click)="removeFile(file)" *ngIf="requestForm.value.Status !='Onboarded'"> <fa-icon [icon]="['fas', 'trash']"></fa-icon></a>
                </div>

              </li>
            </ul>
          </div>
        </div>
      </div> <!--Modal body closing-->
    </form>
    <br />
    <div class="alert alert-danger" ng-controller="ServiceReviewController" *ngIf="errorMessage">
      {{errorMessage}}
    </div>

    <div class="modal-footer">
      <div class="float-right">

        <p class="approveMessage" *ngIf="approveMessage">{{approveMessage}}</p>

        <button id="btnApForForm" type="button" class="btn btn-primary  double_button" (click)="saveChanges();" [disabled]="approvedOnce">{{buttonName}}</button>
        <button id="Closebtn" type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Cancel</button>
      </div>
    </div>
  </ng-template>
</div> <!--Main closing-->
