import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { userGuideLinksModel } from '../models/user-guide-Links-model';
import { userGuideResponseModel } from '../models/user-guide-response-model';
@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  baseURLenv: string = environment.baseUrl;
  baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) { }


  saveRecord(info: any): Observable<userGuideResponseModel> {
    let addUrl = this.baseUrl + "api/UserGuide/SaveRecord";
    return this.http.post<userGuideLinksModel>(addUrl, info).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }
  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  }
}


