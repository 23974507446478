import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalGuard } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { throws } from 'assert';

@Component({
  selector: 'app-accessdenied',
  templateUrl: './accessdenied.component.html',
  styleUrls: ['./accessdenied.component.css']
})
export class AccessdeniedComponent implements OnInit {
  closeResult: string = '';
  private _router: Router;
  constructor(private authService: MsalService, private router: Router) {
    this._router = router;

  }

  ngOnInit(): void {
  }
  logout(popup?: boolean) {
    sessionStorage.clear();
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  RequestAccess() {
    this._router.navigateByUrl('/request-onboarding');
  }

} 


