import { Component, OnInit, ViewChild, Input, Output, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UploadSamplesService } from '../services/upload-sample-service.service';
import { UserModel } from '../models/user-model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, map, throwError } from 'rxjs';
//import { FileRestrictions } from "@progress/kendo-angular-upload";
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
    givenName: string,
    surname: string,
    userPrincipalName: string,
    id: string
}

@Component({
    selector: 'app-upload-samples',
    templateUrl: './upload-samples.component.html',
    styleUrls: ['./upload-samples.component.css']
})
export class UploadSamplesComponent implements OnInit {
    shortLink: string = "";
    baseURL:string = environment.baseUrl;
    //loading: boolean = false; // Flag variable
    file!: File; // Variable to store file
 // Variable to store file
    successMessage: string = "";
    errorMessage: string = "";
    success: string = "SUCCESSFULLY UPLOADED";
    error: string = "FAILED TO UPLOAD";
    user: UserModel[] = [];
    private _router: Router;

    successNotification() {
        Swal.fire({
            title: 'success',
            text: 'Saved Successfully !!',
            icon: 'success',
            confirmButtonText: 'OK'
        })
      }

    //errorNotification(reason:string) {
    //    Swal.fire({
    //                title: 'Error',
    //                text: reason,
    //                icon: 'warning',
    //                confirmButtonText: 'OK'
    //            })
    //}
    public loading = false;
    // Inject service
    constructor(private fileUploadService: UploadSamplesService, private http: HttpClient, _router: Router) {
        this._router = _router;
     }

    ngOnInit(): void {
        //this.fileUploadService.upload = this.arr;

    }

    // On file Select
    onChange(event: any) {
        this.file = event.target.files[0];
    }


  uploadFile() {
    this.errorMessage = '';
    this.successMessage = '';
       //this.loading = true;
    let url = this.baseURL+ "api/UploadSample/Upload";
    var fd = new FormData();
    fd.append('file', this.file);
        return this.http.post(url, fd).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        ).subscribe(response => {
            //this.loading = false;
            console.log("uploadSamples", response);
            //this.errorMessage = response.ErrorMessage;
            //this.loading = false;
            if(response.ErrorMessage != ''){
              // this.loading = false;
              this.errorMessage = response.ErrorMessage;
              //  this.errorNotification(response.ErrorMessage);
            }else {
                this.successMessage='Upload Success';
            }
        });
    }
    private extractData(res: any) {
        let body = res;
        return body;
    }
    private handleErrorObservable(error: any) {
        console.error(error.message || error);
        return throwError(error);
    }
}
