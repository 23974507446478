import { HttpClient } from '@angular/common/http';
import { UploadSOPService } from '../services/upload-sop.service';
import { Component, OnInit, ViewChild, Input, Output, ElementRef } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-upload-sop',
  templateUrl: './upload-sop.component.html',
  styleUrls: ['./upload-sop.component.css']
})
export class UploadSOPComponent implements OnInit {
  baseURL: string = environment.baseUrl;
  loading: boolean = false; // Flag variable
  file!: File; // Variable to store file
  successMessage = "";
  errormessage = '';
  alasql = require('alasql')
  alertsSheet = {
    headers: true,
    columns: [
      { columnid: 'Source', title: 'Source' },
      { columnid: 'AlertSubject', title: 'Alert Subject' },
      { columnid: 'PriorityLevel', title: 'Priority Level' },
      { columnid: 'AlertType', title: 'Alert Type' },
      { columnid: 'Relevant', title: 'Relevant' },
      { columnid: 'AlertDescription', title: 'Alert Description' },
      { columnid: 'ReferenceLink', title: 'Reference Link' },
      { columnid: 'AutoTicket', title: 'Auto Ticket' },
      { columnid: 'Comments', title: 'Comments' },
    ],
  };


  escalationProcessColumns = {
    headers: true,
    columns: [
      { columnid: 'SopNo', title: 'SOP Number' },
      { columnid: 'Step', title: 'Step' },
    ]
  };

  contactsSheet = {
    headers: true,
    columns: ['SopNo', 'Contact'],
  };
 

  constructor(private fileUploadService: UploadSOPService, private http: HttpClient, private authService: MsalService) {
  }
  ngOnInit(): void {
  }


  onChange(event: any) {
    this.file = event.target.files[0];
    console.log("onchnage", this.file);
  }

  onUpload() {
    this.successMessage = '';
    this.errormessage = '';
    let url = this.baseURL + "api/UploadSop/Upload";
    var fd = new FormData();
    console.log(this.file);
    fd.append('file', this.file);
    return this.http.post(url, fd).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      console.log("uploadSop", response);
      if (response.ErrorMessage != '') {
        this.loading = false;
        this.errormessage = response.ErrorMessage;
      } else {
        this.successMessage = 'Upload Success';
      }
    });
  }

  checkIfLoggedIn() {
    let isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    if (!isLoggedIn && sessionStorage.getItem('LoggedInUser')== '') {
      this.authService.loginRedirect();

    } else {
      var data1 = [{ AIRID: '', Name: '', SopNo: '', Source: '', AlertSubject: '', PriorityLevel: '', AlertType: '', AlertDescription: '', ReferenceLink: '', Comments: '' }];
      var data2 = [{ SopNo: '', Step: '' }];
      /*var data3 = [{ SopNo: '', Contact: '' }];*/
      var opts = [{ sheetid: 'Alerts', header: true}, { sheetid: 'EscalationProcess', header: true }, { sheetid: 'Contacts', header: true }];
      var res = this.alasql('SELECT INTO XLSX("Template.xlsx",?) FROM ?', [opts, [data1, data2]]);
    }
  }

  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  }


}




