import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { PublicClientApplication } from "@azure/msal-browser";

const GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0/me";
type ProfileType = {
  givenName: string,
  surname: string,
  userPrincipalName: string,
  id: string
}

@Injectable({
  providedIn: 'root'
})
export class AzureProfileDataService {
  isUserLoggedIn: Subject<boolean> = new Subject<boolean>();
  constructor(private httpClient: HttpClient) { }
   getUserProfile(){
      return this.httpClient.get<ProfileType>(GRAPH_ENDPOINT);
    }


    // This should be the same instance you pass to MsalProvider
   

}
