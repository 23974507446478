<div class="main">
  <h1 class="page-header">Upload Samples</h1>
  <div class="panel-group">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-title pull-left"><strong> Choose file to upload (Only .zip)</strong></div>
        <div class="panel-title pull-right">&nbsp;</div>
        <div class="clearfix"></div>
      </div>
      <ul class="list-group">
        <li class="list-group-item">
          <input type="file" file-model="ZipFile" (change)="onChange($event)">
        </li>
        <li class="list-group-item">
          <input type="submit" id="btnUploadSample" value="Upload" class="btn btn-primary" (click)="uploadFile();" />
        </li>
      </ul>
      <!--Loading screen-->

      <!--<ngx-loading [show]="loading"
                   [config]="{ backdropBorderRadius: '3px' }"
                   [template]="uploadSample"></ngx-loading>
      <ng-template #uploadSample></ng-template>-->
    </div>
  </div>
  <div class="alert alert-success" *ngIf="successMessage">
    {{successMessage}}
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">
    {{errorMessage}}
  </div>
</div>
