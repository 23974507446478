import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, Input, Output, ElementRef } from '@angular/core';
import { userGuideLinksModel } from '../models/user-guide-Links-model';
import { userGuideResponseModel } from '../models/user-guide-response-model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApplicationCommonModel } from '../models/application-common-model';
import { ApplicationCommonResponseModel } from '../models/application-common-response';
import { CommonService } from '../services/common.service';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  ValidatorFn,
  Validators,
  RequiredValidator
} from '@angular/forms';
import { ApplicationModel } from '../models/application-model';
import { UserInfoService } from '../services/user-info.service';
import Swal from 'sweetalert2';
import { ApplicationUserModel } from '../models/application-user-model';

type ProfileType = {
  givenName: string,
  surname: string,
  userPrincipalName: string,
  id: string
}
@Component({
  selector: 'app-important-links',
  templateUrl: './important-links.component.html',
  styleUrls: ['./important-links.component.css']
})
export class ImportantLinksComponent implements OnInit {
  baseURL: string = environment.baseUrl;
  profile!: ProfileType;
  panelOpenState = false;
  public username!: string;
  UserGuides: userGuideLinksModel[] = [];
  UserGuide: userGuideLinksModel[] = [];
  userGuideResponse: userGuideResponseModel | undefined;
  data = { LoggedInUser: sessionStorage.getItem('LoggedInUser') };
  dataDescriptive =
    {
      AppId: 0,
      LoggedInUser: sessionStorage.getItem('LoggedInUser')
    };
  user = sessionStorage.getItem('LoggedInUser');
  formTitle: string = '';
  closeResult: string = '';
  Applications: ApplicationCommonModel[] = [];
  options: ApplicationModel[] = [];
  AppResponse: ApplicationCommonResponseModel | undefined;
  public applications: any[] = [];
  keyword = 'Name';
  appName = '';
  addForm = new FormGroup({
    Id: new FormControl(), AppId: new FormControl(), AirId: new FormControl(), ApplicationName: new FormControl(),
    Url: new FormControl(), OtherAppName: new FormControl(), Description: new FormControl(),
    LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser'))
  });
  isAdmin: boolean = false;
  errorMessage = '';
  applicationBlock: boolean = false;

  successNotification(reason: string) {
    Swal.fire({
      title: 'Success',
      text: reason,
      icon: 'success',
      confirmButtonText: 'OK'
    });
  }
  confirm(data: any) {
    Swal.fire({
      title: 'Delete this recored ?',
      icon: 'warning',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result['isConfirmed']) {
        this.DeleteRecord(data);
      }
    })
  }
  constructor(private http: HttpClient, private modalService: NgbModal, private common: CommonService, private userGuide: UserInfoService) { }

  ngOnInit() {

    this.FetchUserGuideDetails();
  }

  FetchUserGuideDetails() {
    return this.http.post<userGuideLinksModel>(this.baseURL + 'api/UserGuide/FetchUserGuideDetails', this.data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.userGuideResponse = response;
      this.UserGuides = this.userGuideResponse!.Result;
      this.isAdmin = this.UserGuides[0].CreatedBy.IsAdmin;
      console.log(this.UserGuides);
    });
  }

  FetchUserGuideDetailsByAppId(guide: any) {

    this.dataDescriptive.AppId = guide.AppId;
    return this.http.post<userGuideLinksModel>(this.baseURL + 'api/UserGuide/FetchUserGuideDetailsByAppId', this.dataDescriptive).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.userGuideResponse = response;
      this.UserGuide = this.userGuideResponse!.Result;
      this.isAdmin = this.UserGuide[0].CreatedBy.IsAdmin;
      console.log(this.UserGuide);
    });
  }
  openEdit(content: any, data: any) {
    this.formTitle = "Edit";
    $("#myModalTitle").text("Edit");
    let dataApp = { Id: data.Id, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<userGuideLinksModel>(this.baseURL + 'api/UserGuide/FetchUserGuideDetailsById', dataApp).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      console.log(response.Result);
      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
      } else {
        this.addForm.patchValue({
          Id: response.Result[0].Id, AppId: response.Result[0].AppId, AirId: response.Result[0].AirId, ApplicationName: response.Result[0].ApplicationName,
          Url: response.Result[0].Url, OtherAppName: response.Result[0].OtherAppName, Description: response.Result[0].Description, LoggedInUser: sessionStorage.getItem('LoggedInUser')
        });
        this.appName = response.Result[0].ApplicationName;
        this.applicationBlock = true;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `closed with: ${result}`;
        }, (reason: any) => {
          this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
        });
      }
    });
  }
  SaveRecord() {
    $('#btnSave').prop('disabled', true);
    console.log(this.addForm.value);
    if (this.addForm.value.AppId == '' || this.addForm.value.AppId == null || this.addForm.value.AppId == undefined) {
      this.errorMessage = "Please select an application";
      $('#btnSave').prop('disabled', false);
    } else if (this.addForm.value.Description == '' || this.addForm.value.Description == null || this.addForm.value.Description == undefined) {
      this.errorMessage = "Description cannot be empty";
      $('#btnSave').prop('disabled', false);
    } else if (this.addForm.value.Url == '' || this.addForm.value.Url == null || this.addForm.value.Url == undefined) {
      this.errorMessage = "Please provide an URL";
      $('#btnSave').prop('disabled', false);
    } else {
      this.addForm.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));
      this.userGuide.saveRecord(this.addForm.value)
        .subscribe(response => {
          if (response.ErrorMessage != '') {
            $('#btnSave').prop('disabled', false);
            this.errorMessage = response.ErrorMessage;
          } else {

            this.addForm.reset();
            this.FetchUserGuideDetails();
            var message = "Saved Successfully !!";
            this.successNotification(message);
            $('#btnSrSaveIssue').prop('disabled', false);
            $("#formclosebtn").trigger('click');
            this.applicationBlock = false;
            this.errorMessage = '';
          }
        });
    }
  }
  DeleteRecord(data: any) {
    let delData = { Id: data.Id, IsActivated: false, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    return this.http.post<userGuideLinksModel>(this.baseURL + 'api/UserGuide/DeleteRecord', delData).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
      } else {
        this.FetchUserGuideDetails();
        var success = "Record has been deleted successfully.";
        this.successNotification(success);
      }
    });
  }
  autocompleteclick(e: any) {
    //$('#ServiceAutocomplete').find('input:text').val('');
  }
  selectEventAdd(e: any) {
    this.addForm.get("AppId")?.setValue(e.Id);
    this.addForm.get("AirId")?.setValue(e.AirId);
    this.addForm.get("ApplicationName")?.setValue(e.Name);
  }
  LoadApplicationAdd(Search: string) {
    this.common.FindApplicationName("=" + Search + "=" + this.user)
      .subscribe(response => {
        this.AppResponse = response;
        this.options = this.AppResponse!.Result;
        this.applications = this.options;
        this.applications.map(app => app.Name = `${app.AirId} - ${app.Name}`);
      });
  }
  open(content: any) {
    this.formTitle = "Add New";
    this.applicationBlock = false;
    this.errorMessage = '';
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
    });
  }
  private getdismissreason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing esc';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  }
}
