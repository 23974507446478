import { Component, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalGuard } from '@azure/msal-angular';
@Component({
  selector: 'app-critical-error',
  templateUrl: './critical-error.component.html',
  styleUrls: ['./critical-error.component.css']
})
export class CriticalErrorComponent implements OnInit {

  constructor(private authService: MsalService) { }

  ngOnInit(): void {
  }
  logout(popup?: boolean) {
    sessionStorage.clear();
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

}
