import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { IssueInterface } from '../models/issue-interface';
import { ApplicationModel } from '../models/application-model';
import { ApplicationCommonResponseModel } from '../models/application-common-response';
import { ApplicationResponseModel } from '../models/application-response-model';
import { UserResponseModel } from '../models/user-model-response';
import { UserPeopleResponse } from '../models/user-people-response';
import { environment } from '../../environments/environment';
import { ServiceReviewModel } from '../models/service-review-model';
import { ServiceReviewResponseModel } from '../models/service-review-response-model';
import { OnboardingInterface } from '../models/onboarding-interface';
let headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
});
let options = {
    headers: headers
}
@Injectable({
    providedIn: 'root'
})
export class CommonService {
    baseURL:string = environment.baseUrl;
    baseurl = this.baseURL+"api/Application/FindApplication";
    urlLeads = this.baseURL+"api/IssueTracker/FetchPeopleByName";
    urlShift = this.baseURL+"/api/ShiftTurnOver/FetchLeadsbyFilter";
  urlCserviceveManager = this.baseURL + "api/ShiftTurnOver/FetchLeadsbyFilter";
  urlFindPeopleOnboarding = this.baseURL + "api/OnboardingForm/FetchPeopleByName";
    //headers = {
    //    'contentType' : "application/x-www-form-urlencoded; charset=utf-8"
    //};

    constructor(private http: HttpClient) { }
    FindApplicationName(filter: string): Observable<ApplicationCommonResponseModel> {
        return this.http.post(this.baseurl, filter, options).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    FindSRFapplicationName(filter: string): Observable<ApplicationCommonResponseModel> {
      return this.http.post(this.baseURL+'api/Application/SRFindApplication', filter, options).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      );
    }
    FetchPeopleByName(people: string): Observable<UserPeopleResponse> {
        return this.http.post(this.urlLeads, people, options).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    FetchLeadsbyFilter(leads: string): Observable<UserResponseModel> {
        return this.http.post(this.urlShift, leads, options).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    FetchCurrentServiceManager(currentManager: string): Observable<UserResponseModel> {
        return this.http.post(this.urlCserviceveManager, currentManager, options).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    FetchNewServiceManager(newManager: string): Observable<UserResponseModel> {
        return this.http.post(this.urlCserviceveManager, newManager, options).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    GetClusterParentDetails(data: any): Observable<ServiceReviewResponseModel> {
      return this.http.post<ServiceReviewModel>(this.baseURL+'api/ServiceReview/GetClusterParentDetails', data, options).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable));
    }
    FetchClusteredChildApps(data: any): Observable<ApplicationCommonResponseModel> {
        return this.http.post(this.baseURL+'api/ServiceReview/SRFindChildApplication', data, options).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable));
  }
  FetchPeopleForOnboardingForm(people: string): Observable<UserPeopleResponse> {
    return this.http.post(this.urlFindPeopleOnboarding, people, options).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }
    private extractData(res: any) {
        let body = res;
        return body;
    }
    private handleErrorObservable(error: any) {
        console.error(error.message || error);
        return throwError(error);
    }
}
