import { Pipe, PipeTransform } from '@angular/core';
import { IssueInterface } from './models/issue-interface';

@Pipe({ name: 'appFilter' })
export class SearchfilterPipe implements PipeTransform {

    transform(items: any[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLocaleLowerCase();

        return items.filter(it => {
            return it.toLocaleLowerCase().includes(searchText);
        });
    }

}


//transform(issue: IssueInterface[], startDate: string, endDate: string, appName: string, StatusFilter: string, PriorityFilter: string, OfferingFilter: string): IssueInterface[] {
//    if (!issue || !startDate || !endDate || !appName || !StatusFilter || !PriorityFilter || OfferingFilter) {
//        return issue;
//    }
//    return issue.filter(issue =>
//        issue.StartDate.toString().toLocaleLowerCase().includes(startDate.toLocaleLowerCase()) ||
//        issue.EndDate.toString().toLocaleLowerCase().includes(endDate.toLocaleLowerCase()) ||
//        issue.ApplicationName.toLocaleLowerCase().includes(appName.toLocaleLowerCase()) ||
//        issue.Status.toLocaleLowerCase().includes(StatusFilter.toLocaleLowerCase()) ||
//        issue.Priority.toLocaleLowerCase().includes(PriorityFilter.toLocaleLowerCase()) ||
//        issue.ServicePriority.toLocaleLowerCase().includes(OfferingFilter.toLocaleLowerCase())
//    )
//}