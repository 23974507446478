import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MonitoringInterface } from '../models/monitoring-tracker-model';
import { CommentsModel } from '../models/comments-model';
import { environment } from '../../environments/environment';
import { MonitoringTrackerResponseInterface } from '../models/monitoring-tracker-response-model';
import { CommentsResponseModel } from '../models/comments-response-model';
@Injectable({
  providedIn: 'root'
})
export class MonitoringTrackerServiceService {
    baseUrl:string = environment.baseUrl;
    constructor(private http: HttpClient) { }
    addIssueToBeMonitored(module: any): Observable<MonitoringTrackerResponseInterface> {
        return this.http.post<MonitoringTrackerResponseInterface>(this.baseUrl+"api/MonitoringTracker/SaveUpdate", module).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    SaveComments(tracker: any): Observable<CommentsResponseModel> {
        return this.http.post<CommentsResponseModel>(this.baseUrl+"api/MonitoringTracker/SaveIssueComments", tracker).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    private extractData(res: any) {
        let body = res;
        return body;
    }
    private handleErrorObservable(error: any) {
        console.error(error.message || error);
        return throwError(error);
    }
}
