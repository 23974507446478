<div *ngIf="userLoggedIn">
  <mat-toolbar color="primary" *ngIf="userLoggedIn">
  <img class="logo" src="/assets/Images/gpcLogo.png" alt="GPC logo">
  <a class="title" href="/">{{ title }}</a>

  <div class="toolbar-spacer"></div>

  <!--<a mat-button [routerLink]="['profile']">Profile</a>-->
  <div class="navbar-collapse">
    <ul class="top-nav navbar-nav ml-auto">
      <li><img src="/assets/Images/accLogo.png" style="padding-top: 5px;" alt="Accenture logo"></li>
      <li style="padding-top: 7px; padding-left: 15px;"><button class="btn btn-info btn-xs refresh" style="border-radius: 20px;height:86%; width:100%" (click)="GetNotificationCountbyUser();" [hidden]="ifOnboardRequester"><fa-icon [icon]="['fas', 'arrows-rotate']"></fa-icon>Refresh</button></li>
      <li style="padding-top: 7px;padding-left: 15px;"><button class="btn btn-info btn-xs notification" style="border-radius: 20px; height:86%; width:100%" (click)="FetchNotificationSummary(modalNotificationform);" [hidden]="ifOnboardRequester">Notification(s)<!-- ngIf: Count --><span style="background: red;color: white;display: inline-block;width: 20px;line-height: 20px;border-radius: 10px;text-align: center;" *ngIf="Count" class="ng-binding ng-scope">{{Count}}</span></button></li>
      <li style="padding-top: 10px;padding-left: 10px;padding-right: 10px;" class="user"><a style="color:#eee"><fa-icon [icon]="['fas', 'user']"></fa-icon><span id="spanWelcome" class="ng-binding">Welcome {{username}}</span></a></li>

    </ul>
  </div>
  <button class="logoutButton" mat-raised-button (click)="logout()"><fa-icon [icon]="['fas', 'right-from-bracket']"></fa-icon>Logout</button>
  <!-- <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button> -->
  <!-- <button class="logoutButton" mat-raised-button *ngIf="loginDisplay" (click)="logout()"><mat-icon>logout</mat-icon>Logout</button> -->

</mat-toolbar>




<div class="container">
  <!-- <app-navbar *ngIf="loginDisplay"></app-navbar>
  <router-outlet *ngIf="!isIframe"></router-outlet> -->
<app-navbar *ngIf="userLoggedIn"></app-navbar>
<router-outlet *ngIf="userLoggedIn"></router-outlet>

  <ng-template #modalNotificationform let-modal>
    <form name='modalNotificationform'>
      <div class="modal-header">
        <h4 class="modal-title" id="patchActivityModalLabel">Notification(s)</h4>
        <!-- <input type="text" id="txtSearchapplicationName" class="form-control" placeholder="Application" style="width:40%;" /> -->
        <div class="ng-autocomplete" style="width:40%;">
          <!--<input type="text" ng-model="selectedApplication" id="appMonitoringTracker" class="form-control" style="width:123%;" name="ApplicationId" formControlName="ApplicationId" #ApplicationId placeholder="Application" ng-blur="CheckContent();" />-->
          <ng-autocomplete [data]="applications"
                           [searchKeyword]="keyword"
                           placeholder="Enter Application Name"
                           (inputChanged)='LoadApplication($event)'
                           historyIdentifier="applications"
                           [itemTemplate]="itemTemplate"
                           [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>

          <ng-template #itemTemplate let-option>
            <a [innerHTML]="option.Name" (click)="getSelectedAirIds(option)"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>

        <div class="pull-right">
          <button style="margin-left:160px" type="button" value="Search" id="btnSearchNotification" class="btn btn-primary" (click)="FindNotificationByFilter();"><span class="fa fa-search"></span> Search </button>

          <button style="margin: 0 10px" type="button" value="Add New" id="btnSearchNotification" class="btn btn-primary" (click)="AddNew(notificationAddNewForm); dataReset();">Add New</button>

          <button type="button" class="close btn btn-danger" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Button click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body" style="height:80%">
        <!--<div id="addpatchActivityAlert" ng-controller="ServiceReviewController" class="alert alert-danger" ng-show="error">
            <strong>Error!</strong> {{errorMessage}}
        </div>-->
        <!-- <div class="alert alert-danger" *ngIf="errorMessage" id="addpatchActivityAlert">
            {{errorMessage}}
        </div> -->
        <div style="overflow: auto; width:100%;height:400px;">
          <table class=" table table-bordered table-striped">
            <thead>
              <tr style="background-color: #f5f5f5;">
                <th>Application Name</th>
                <th>Notification</th>
                <th>Comments</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody id="divbody">
              <tr *ngFor="let notification of Notifications" class="inbx_unread" (click)="SaveReadStatusbyUser(notification.Id);" style="cursor:pointer;background-color: #CCC !important;border: 1px solid #CCC;color: #333;padding: 6px;font-weight: bold">
                <ng-container *ngIf="notification.IsRead==false">
                  <td width="30%">{{notification.ApplicationName}}</td>
                  <td style="width:25%;white-space:normal; word-wrap:break-word;" [innerHTML]="notification.NotificationDesc"></td>
                  <td style="width:35%;white-space:normal; word-wrap:break-word;" [innerHTML]="notification.Comments"></td>
                  <td width="5%"><button type="button" class="btn btn-primary" style="width:69px!important" (click)="OpenEditForm(notificationAddNewForm, notification)"><span class="fa fa-edit"></span>Edit</button></td>
                  <td width="5%"><button type="button" class="btn btn-primary" style="width:85px!important" (click)="DeleteRecord(notification)"><span class="fa fa-trash"></span>Delete</button></td>
                </ng-container>
              </tr>
              <tr *ngFor="let notification of Notifications" style="background-color:white !important">
                <ng-container *ngIf="notification.IsRead==true">
                  <td width="30%">{{notification.ApplicationName}}</td>
                  <td style="width:25%;white-space:normal; word-wrap:break-word;" [innerHTML]="notification.NotificationDesc"></td>
                  <td style="width:35%;white-space:normal; word-wrap:break-word;" [innerHTML]="notification.Comments"></td>
                  <td width="5%"><button type="button" class="btn btn-primary" style="width:69px!important" (click)="OpenEditForm(notificationAddNewForm, notification)"><span class="fa fa-edit"></span>Edit</button></td>
                  <td width="5%"><button type="button" class="btn btn-primary" style="width:85px!important" (click)="DeleteRecord(notification)"><span class="fa fa-trash"></span>Delete</button></td>
                </ng-container>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #notificationAddNewForm let-modal>
    <form name='modalNotificationNewform' [formGroup]="addNewNotification" (ngSubmit)="SaveData()">
      <div class="modal-header">
        <h4 class="modal-title" id="notiModalLabel">Add New Notification</h4>
        <button type="button" class="close btn btn-danger" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Button click'); dataReset();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="layoutbody" class="modal-body" style="height:80%">
        <div class="form-group row">
          <label class="control-label col-sm-3" for="text" style="text-align: left;">Application<em style="color:red;margin-left:-2px">*</em> :</label>
          <div class="col-sm-6" ng-show="showticket">
            <div class="ng-autocomplete" id="appNotification" style="width:100%; border:solid 1px #d3d3d3">
              <!--<input type="text" ng-model="selectedApplication" id="appMonitoringTracker" class="form-control" style="width:123%;" name="ApplicationId" formControlName="ApplicationId" #ApplicationId placeholder="Application" ng-blur="CheckContent();" />-->
              <ng-autocomplete [data]="applications"
                               [searchKeyword]="keyword"
                               [placeholder]="appNotification"
                               (inputChanged)='LoadApplication($event)'
                               (selected)='onselectAppName($event)'
                               historyIdentifier="applications"
                               [itemTemplate]="itemTemplate"
                               [notFoundTemplate]="notFoundTemplate"
                               [disabled]="makeDisable">
              </ng-autocomplete>

              <ng-template #itemTemplate let-option>
                <a [innerHTML]="option.Name" (click)="getSelectedAirIds(option)"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
            <br />

          </div>
          <div class="col-sm-6" *ngIf="addNewNotification.value.ApplicationName == '0 - Others'" style="margin: 0 0 2% 25%;">
            <div id="divothers">
              <input type="text" id="txtAppServiceOther" ng-model="selectedotherservice" class="form-control" name="OtherAppName" formControlName="OtherAppName" #OtherAppName placeholder="Other Service" />
            </div>
          </div>
          <!--<div class="col-sm-6" ng-show="showticketName"  style="margin: 0 0 2% 25%;">
              <input type="text" id="txtapplicationNameOther" class="form-control" name="selectedappname" #selectedappname hidden />
              <br />
              <div *ngIf="selectedAirIds[0] == 38">
                  <input type="text" id="txtAppServiceOther" class="form-control" name="OtherAppName" formControlName="OtherAppName" #OtherAppName placeholder="Other application name"/>
              </div>
          </div>-->
        </div>
        <div class="form-group row" style="margin-bottom: 20px;">
          <label class="col-form-label col-md-3" for="text" style="text-align: left;">Notification<em style="color:red;">*</em> :</label>
          <div class="col-md-6">
            <ckeditor class="tool-guidance" maxCharacters=1000 [editor]="Editor" ng-Model="NotificationDesc" name="NotificationDesc" formControlName="NotificationDesc" #NotificationDesc (change)="changeNotificationUpdates($event)" placeholder="Enter Notification Here"></ckeditor>
            <!-- <textarea ck-editor class="form-control ng-untouched ng-valid ng-dirty ng-valid-parse ng-empty" rows="2" name="txtNotificationUpdates" formControlName="txtNotificationUpdates" #txtNotificationUpdates id="txtNotificationUpdates" ng-model="NotificationUpdate" placeholder="Enter Notification Here"></textarea> -->
          </div>
          <!-- <a href="javascript:;" id="hrfNotificationUpdates" style="color:blue;" ng-click="ShowNotificationUpdatesPopup();">Edit</a> -->
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-3" for="text" style="text-align: left;">Comments<em style="color:red;">*</em> :</label>
          <div class="col-md-6">
            <ckeditor class="tool-guidance" [editor]="Editor" ng-Model="Comments" name="Comments" formControlName="Comments" #Comments (change)="changeCommentUpdates($event)" placeholder="Enter Comments Here"></ckeditor>
            <!-- <textarea ck-editor class="form-control" rows="2" name="txtCommentUpdates"  formControlName="txtCommentUpdates" #txtCommentUpdates id="txtCommentUpdates" ng-model="CommentUpdate" placeholder="Enter Comments Here"></textarea> -->
          </div>
          <!-- <a href="javascript:;" id="hrfCommentUpdates" style="color:blue;" ng-click="ShowCommentUpdatesPopup();">Edit</a> -->
        </div>
        <br />
        <!-- <div class="alert alert-danger" ng-show="error" id="divModalError">
            {{errorMessage}}
        </div> -->
        <div class="modal-footer row" ng-controller="NotificationController">
          <div class="">
            <!--<div class="col-md-6 float-left" id="CreatedByDiv" style="margin-left:150px;margin-top:-20px;">-->
            <div class="col-md-6" id="CreatedByDiv" style="display: inline-block;" *ngIf="addNewNotification.value.Id != null">
              <!--<label class="col-form-label col-md-4"></label>-->
              <div class="col-form-label">
                Created at <strong>{{CreatedDate | date:'MM/dd/yyyy HH:mm:ss a'}} </strong>by <strong>{{CreatedByDisplay}} </strong><br />
                Last Modified at <strong>{{LastModifiedDate | date:'MM/dd/yyyy HH:mm:ss a'}}</strong> by <strong>{{LastModifiedBy}}</strong> <br />
              </div>

            </div>
            <div class="col-md-6" style="display: inline-block; float: right;">
              <button type="submit" id="btnSaveRecord" class="btn btn-default" style="border:1px solid #eee" (click)="modal.dismiss('Button click')">Save changes</button>
              <button id="notificationFormClose" type="button" class="btn btn-primary" (click)="modal.dismiss('Button click'); dataReset();">Close</button>
            </div>

          </div>
        </div>
      </div>
    </form>
  </ng-template>
</div>
  <!--terms and conditions starts-->
  <div id="terms">
  <!--terms and conditions starts-->
  <ngb-modal-window role="dialog" tabindex="-1" aria-modal="true" class="d-block let-modal modal fade show" *ngIf="isVisible">
    <div role="document" class="modal-dialog modal-dialog-centered modal-xl" *ngIf="isVisible">
      <div class="modal-content">

        <div id="termOfUseModalWindow" class="row terms-of-use-modal-window noMarginLeft noMarginRight">
          <div class="col-sm-12 nopaddings">
            <div class="row nomargins">
              <h3 class="title-skill">Terms of Use </h3>

              <p> Your access to and use of this site is subject to the following terms and conditions and all applicable laws. By accessing and using this site, you accept the following terms and conditions, without limitation or qualification. </p>
              <p> The information provided on this site is free of charge and for informational purposes and internal use only. Unless otherwise stated, the contents of this site including, but not limited to, the text and images contained herein and their arrangement are the property of Accenture. All trademarks used or referred to in this website are the property of their respective owners. </p>
              <p> Nothing contained in this site shall be construed as conferring by implication, estoppel, or otherwise, any license or right to any copyright, patent, trademark or other proprietary interest of Accenture or any third party. This site and the content provided in this site, including, but not limited to, graphic images, audio, video, html code, buttons, and text, may not be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, without the prior written consent of Accenture, except that you may download, display, and print one copy of the materials on any single computer solely for your personal, non-commercial use, provided that you do not modify the material in any way and you keep intact all copyright, trademark, and other proprietary notices.Links on this site may lead to services or sites not operated by Accenture. No judgment or warranty is made with respect to such other services or sites and Accenture takes no responsibility for such other sites or services. A link to another site or service is not an endorsement of that site or service. Any use you make of the information provided on this site, or any site or service linked to by this site, is at your own risk. </p>
              <p> This site and its contents are provided “as is” and Accenture makes no representation or warranty of any kind with respect to this site or any site or service accessible through this site. Accenture expressly disclaims all express and implied warranties including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. In no event will Accenture be liable to any party for any direct, indirect, incidental, special, exemplary, consequential, or other damages (including, but not limited to, lost profits, business interruption, loss of programs or data) without regard to the form of action and whether in contract, tort, negligence, strict liability, or otherwise, arising out of or in connection with this site, any content on or accessed through this site or any site service linked to, or any copying, displaying, or use thereof. </p>
              <p> Accenture maintains this site in Illinois, U.S.A. and you agree that these terms of use and any legal action or proceeding relating to this site shall be governed by the laws of the State of Illinois without reference to its choice of law rules. If you attempt to bring any legal proceedings against Accenture you specifically acknowledge that Accenture is free to choose the jurisdiction of our preference as to where such action against us may be held. As you have agreed by using this site to choose the laws of the State of Illinois to govern any such proceedings, we will probably choose to defend any such action in Illinois and we can make this decision entirely as it suits us, without regard to where in the world you are located, or from where in the world you visited this site. </p>
              <p> You are responsible for complying with the laws of the jurisdiction from which you are accessing this site and you agree that you will not access or use the information on this site in violation of such laws. Unless expressly stated otherwise herein, any information submitted by you through this site shall be deemed non-confidential and non-proprietary. You represent that you have the lawful right to submit such information and agree that you will not submit any information unless you are legally entitled to do so. Because of the open nature of the Internet, we recommend that you not submit information you consider confidential. </p>


            </div>
            <div class="modal-footer" id="saveOnboardingFormData">
              <div>
                <div class="float-right">
                  <button id="btnOrSaveIssue" type="button" class="btn btn-secondary" (click)="AcceptTerms('terms');">Accept</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ngb-modal-window>
  <!--terms and conditions closed-->
</div>
</div>
