import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShiftturnoverModel } from '../models/shiftturnover-model';
import { ShiftturnoverResponse } from '../models/shiftturnover-response';
import { map, catchError, delay } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
    givenName: string,
    surname: string,
    userPrincipalName: string,
    id: string
}
@Component({
  selector: 'app-shift-turn-over',
  templateUrl: './shift-turn-over.component.html',
  styleUrls: ['./shift-turn-over.component.css']
})
export class ShiftTurnOverComponent implements OnInit {
    baseUrl:string = environment.baseUrl;
    profile!: ProfileType;
    username!: string; 
    control = new UntypedFormControl(''); 
    ShiftTurnOverModel: ShiftturnoverModel[] = [];
    ShiftTurnOverModelDetails: ShiftturnoverModel[] = [];
    ShiftTurnOverResponse: ShiftturnoverResponse | undefined;

    hiddenform = new UntypedFormGroup({
         LoggedInUser: new UntypedFormControl()
    });

    fetchTurnOverD = {
        Id: 0, TurnOverDate:'', LoggedInUser: ''
    }
    panelOpenState = false;
    @ViewChild('LoggedInUser') LoggedInUser!: ElementRef;
    @ViewChild('StoId') StoId!: ElementRef;
    @ViewChild('TurnOverDate') TurnOverDate!: ElementRef;



    successNotification(reason:string) {
        Swal.fire({
            title: 'Success',
            text: reason,
            icon: 'success',
            confirmButtonText: 'OK'
        });
      }

    errorNotification(reason:string) {
        Swal.fire({
                    title: 'Error',
                    text: reason,
                    icon: 'warning',
                    confirmButtonText: 'OK'
                })
    }
    constructor(private http: HttpClient) { }

    async ngOnInit(): Promise<void>{
        function delay(ms: number) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        this.getProfile();
        await delay(2000);
        this.FetchTurnOverSummary();
    }
   
    //ngAfterViewInit() {
    //    this.FetchTurnOverSummary();
    //}
    getProfile(): string {
        this.http.get<ProfileType>(GRAPH_ENDPOINT)
            .subscribe(profile => {
                this.profile = profile;
                this.username = profile.userPrincipalName.substring(0, profile.userPrincipalName.indexOf("@"));
                this.hiddenform.get("LoggedInUser")?.setValue(this.username);
            });
        
        return this.username; 
        
    }

    FetchTurnOverSummary() {
        let LoggedInUser = this.username;
        let data: any = { LoggedInUser: LoggedInUser };
        return this.http.post<ShiftturnoverResponse>(this.baseUrl+"api/ShiftTurnOver/FetchTurnOverSummary", data).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        ).subscribe(response => {
            //console.log("from fetchsummery"  + response);
            this.ShiftTurnOverResponse = response;
            this.ShiftTurnOverModel = this.ShiftTurnOverResponse!.Result;
            if(response.ErrorMessage != ''){
                   
                this.errorNotification(response.ErrorMessage);
            }else if(response.Result == null){
                var message = "No data found";
                this.errorNotification(message);
            }
        });
        //this.errorMessage = this.SearchSopResponse?.ErrorMessage;
    }


    FetchTurnOverDetail(sto: any) {
        this.fetchTurnOverD.LoggedInUser = sto.ModifiedBy.EnterpriseId;
        this.fetchTurnOverD.TurnOverDate = sto.TurnOverDate;
        this.fetchTurnOverD.Id = sto.Id;
        return this.http.post<ShiftturnoverResponse>(this.baseUrl+"api/ShiftTurnOver/FetchTurnOverDetail", this.fetchTurnOverD).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        ).subscribe(response => {
            //console.log(response);
            this.ShiftTurnOverResponse = response;
            this.ShiftTurnOverModelDetails = this.ShiftTurnOverResponse!.Result;
            //console.log(this.Applications);
        });
    }

    private extractData(res: any) {
        let body = res;
        return body;
    }
    private handleErrorObservable(error: any) {
        console.error(error.message || error);
        return throwError(error);
    }
}

