import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class UploadSOPService {
    baseURLenv : string = environment.baseUrl;
    baseApiUrl = this.baseURLenv+"api/UploadSop/Upload";

    constructor(private http: HttpClient) { }

    // Returns an observable
    Upload(file: any): Observable<any> {
        //if(wrong Enterprise Id -- You do not have access to upload SOP)
        if (file['FILE'][0] != null) {
            var ext = file['FILE'][0].split('.').pop();
            if (ext == "xlsx") {
                const formData = new FormData();
                formData.append("file", file, file['FILE'][0]);
                return this.http.post(this.baseApiUrl, formData);
            }
            else {
                var ErrorMessage = "Not a .xlsx file"
                alert("Not a excel File");
                var Result = "Upload Failed";
                return this.http.post(Result, ErrorMessage);
            }
        }
        Result = "";
        var ErrorMessage = "No file selected";
        return this.http.post(Result, ErrorMessage);
    }
}