import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalGuard } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-access-denied-vpn',
  templateUrl: './access-denied-vpn.component.html',
  styleUrls: ['./access-denied-vpn.component.css']
})
export class AccessDeniedVpnComponent {
  private _router: Router;
  constructor(private authService: MsalService, private router: Router) {
    this._router = router;

  }
  logout(popup?: boolean) {
    sessionStorage.clear();
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

}

