import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { ShiftturnoverModel } from '../models/shiftturnover-model';
import { ShiftturnoverResponse } from '../models/shiftturnover-response';
import { map, catchError, delay } from 'rxjs/operators';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import * as $ from 'jquery';
import { environment } from '../../environments/environment';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RequestOnboardingModel } from '../request-onboarding-model';
import { CommonService } from '../services/common.service';
import { RequestOnboardingService } from '../services/request-onboarding.service';
import Swal from 'sweetalert2';
import { NextGenApplications } from '../models/next-gen-applications';
import { OnboardingInterface } from '../models/onboarding-interface';
import { NextGenP1Incidents } from '../models/next-gen-P1-incidents';
import { NextGenP2Incidents } from '../models/next-gen-P2-incident';
import { NextGenApplication } from '../models/next-gen-application';
import { NextGenPreventions } from '../models/next-gen-preventions';
import { Console } from 'console';
import { ThemePalette } from '@angular/material/core';
import { PeopleModel } from '../models/people-model';
@Component({
  selector: 'app-nxt-gen-service-pulse',
  templateUrl: './nxt-gen-service-pulse.component.html',
  styleUrls: ['./nxt-gen-service-pulse.component.css']
})
export class NxtGenServicePulseComponent {
  baseURL: string = environment.baseUrl;
  username!: string;
  control = new UntypedFormControl('');
  errorMessage = '';
  errorMessage1: string = "";
  successMessage = "";
  incidentsApps: NextGenApplications[] = [];
  //holdsPendingRequest: RequestOnboardingModel[] = [];
  incidentsAppsDetails: NextGenApplications[] = [];
  nextGenP1Incidents: NextGenP1Incidents[] = [];
  loadednextGenP1Incidents: NextGenP1Incidents[] = [];
  pageIndex = 1;
  pageSize = 15;
  nextGenP2Incidents: NextGenP2Incidents[] = [];
  nextGenApplications: NextGenApplication[] = [];
  nextGenPreventions: NextGenPreventions[] = [];
  loadednextGenPreventions: NextGenPreventions[] = [];
  preventionPageIndex = 1;
  preventionPageSize = 15;
  closeResult: string = '';
  incidentNumber: string = '';
  editFormContent: any;
  minDate: Date = new Date(2010, 0, 1); // ASA Business Bypass Logic
  status: any[] = [];
  SelectedFiles: any[] = [];
  asStatus: any[] = [];
  asRootCauseCategory: any[] = [];
  asSubCategory: any[] = [];
  asEndUsers: any[] = [];
  asResponse: any[] = [];
  asRestore: any[] = [];
  asLeadership: any[] = [];
  asPlaybook: any[] = [];

  asImpactedEntity: any[] = [];
  asDay: any[] = [];
  asChangeInduced: any[] = [];
  asTier: any[] = [];
  asSLOMet: any[] = [];
  asGFPSLO: any[] = [];
  asInAppSLO: any[] = [];
  asGSMEngagementSLO: any[] = [];
  asMDJoined: any[] = [];
  asPlaybookUsage: any[] = [];
  asTechTeamsSLO: any[] = [];
  asEndUserSLO: any[] = [];
  asAlertsReceived: any[] = [];
  asRecurringIssue: any[] = [];
  asTicketPriorityMismatch: any[] = [];
  asIssueReportedthroughGPCBot: any[] = [];
  asReasonforMissedSLOs: any[] = [];
  
  public applications: any[] = [];
  public names: any;
  airIds: string[] = [];
  applicationNames: string[] = [];
  mdNames: string[] = [];
  applicationNameAddForm: any = [];
  airIDAddForm: any = [];
  selectedAirIds: any[] = [];
  request: any;
  navAdminHide: boolean = true;
  hiddenform = new UntypedFormGroup({
    LoggedInUser: new UntypedFormControl()
  });
  buttonName: string = "Approve";
  //changed
  formDisabled: boolean = false;
  approvedOnce: boolean = false;
  nonApproverMessage: boolean = false;
  approverPageContent: boolean = true;
  hideIncidentTable: boolean = true;
  showIncidentTable: boolean = true;
  nextGenRole: any;
  currentForm: any;
  priority: string = "";
  enableExportToExcel: boolean = true;

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    ariaLabelledBy: 'modal-basic-title'
  };

  fetchTurnOverD = {
    Id: 0, TurnOverDate: '', LoggedInUser: ''
  }
  loading: boolean | undefined;
    modalTitle: string = '';
  RootOBJ: any;
  lotOfPeople: PeopleModel[] = [];
  public SearchPeople: any[] = [];
    errorMessage2: string = '';

  //date picker
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = true;
  public stepMinutes = [0o0, 15, 30, 45];
  public stepHour = 1;
  public stepMinute = 15;
  public stepSecond = 1;
  public defaultTime = [new Date().getHours(), 0, 0]
  public color: ThemePalette = 'primary';
  
  successNotification(reason: string) {
    Swal.fire({
      title: 'Success',
      text: reason,
      icon: 'success',
      confirmButtonText: 'OK'
    });
  }
  alasql = require('alasql')
  //Export to excel template
  

  panelOpenState = false;

  IncidentForm = new FormGroup({
    IncidentNumber: new FormControl(),
    AirId: new FormControl(),
    ApplicationName: new FormControl(),
    ServiceTier: new FormControl('', Validators.required),
    RootCauseCategory: new FormControl('', Validators.required),
    SubCategory: new FormControl(),
    Duration: new FormControl('', Validators.required),
    UserImpacted: new FormControl('', Validators.required),
    Description: new FormControl('', Validators.required),
    EndUsers: new FormControl('', Validators.required),
    Response: new FormControl('', Validators.required),
    Restore: new FormControl('', Validators.required),
    Leadership: new FormControl('', Validators.required),
    Playbook: new FormControl('', Validators.required),
    CreatedBy: new FormControl(),
    EnterpriseId: new FormControl(sessionStorage.getItem('LoggedInUser'))
  });

  P1IncidentForm = new FormGroup({
    EnterpriseId: new FormControl(),
    FY: new FormControl(),
    Quarter: new FormControl(),
    Month: new FormControl(),
    Incident_ID: new FormControl('', Validators.required),
    Users_Impacted: new FormControl('', Validators.required),
    Description: new FormControl('', Validators.required),
    Impacted_Entity: new FormControl(),
    AIR_ID: new FormControl('', Validators.required),
    ApplicationName: new FormControl('', Validators.required),
    Application_Name_AIR: new FormControl(),
    MD_Name: new FormControl('', Validators.required),
    Day: new FormControl(),
    Incident_Start_Time: new FormControl(null as FormControl | null, Validators.required),
    Root_cause_Categorization: new FormControl('', Validators.required),
    Pulse_Root_Cause: new FormControl(),
    Sub_Category: new FormControl(),
    RCA_Category: new FormControl(),
    Change_Induced: new FormControl(),
    Change_Ticket: new FormControl(),
    Outage_Duration: new FormControl(),
    Service_Tier: new FormControl(null, Validators.required),
    SLO_Met: new FormControl(),
    GFP_SLO: new FormControl(),
    In_app_SLO: new FormControl(),
    GSM_Engagement_SLO: new FormControl(),
    MD_joined: new FormControl(null, Validators.required),
    Playbook_Usage: new FormControl(),
    Tech_Teams_SLO: new FormControl(),
    End_User_SLO: new FormControl(),
    Alerts_Received: new FormControl(),
    Alerts_Remarks: new FormControl(),
    Alerts_Justification: new FormControl(),
    Recurring_Issue: new FormControl(),
    App_Count: new FormControl(),
    Ticket_Priority_Mismatch: new FormControl(),
    Issue_Reported_through_GPC_Bot: new FormControl(null, Validators.required),
    RCA_Description: new FormControl(),
    Problem_Ticket: new FormControl(),
    Vendor_Name: new FormControl(),
    Reasons_for_missed_SLOs: new FormControl(),
    Week_Numbers: new FormControl(),
    priorityChanged: new FormControl(),
    sp: new FormControl()
  });

  PtwoIncidentForm = new FormGroup({
    EnterpriseId: new FormControl(),
    FY: new FormControl(),
    Quarter: new FormControl(),
    Month: new FormControl(),
    Incident_ID: new FormControl('', Validators.required),
    Users_Impacted: new FormControl(),
    Description: new FormControl(),
    Impacted_Entity: new FormControl(),
    AIR_ID: new FormControl('', Validators.required),
    ApplicationName: new FormControl('', Validators.required),
    Application_Name_AIR: new FormControl(),
    MD_Name: new FormControl('', Validators.required),
    Day: new FormControl(),
    Incident_Start_Time: new FormControl(null as FormControl | null, Validators.required),
    Root_cause_Categorization: new FormControl('', Validators.required),
    Pulse_Root_Cause: new FormControl(),
    Sub_Category: new FormControl(),
    RCA_Category: new FormControl(),
    Change_Induced: new FormControl(),
    Change_Ticket: new FormControl(),
    Outage_Duration: new FormControl(),
    Service_Tier: new FormControl(null, Validators.required),
    SLO_Met: new FormControl(),
    GFP_SLO: new FormControl(),
    In_app_SLO: new FormControl(),
    GSM_Engagement_SLO: new FormControl(),
    MD_joined: new FormControl(),
    Playbook_Usage: new FormControl(),
    Tech_Teams_SLO: new FormControl(),
    End_User_SLO: new FormControl(),
    Alerts_Received: new FormControl(),
    Alerts_Remarks: new FormControl(),
    Alerts_Justification: new FormControl(),
    Recurring_Issue: new FormControl(),
    App_Count: new FormControl(),
    Ticket_Priority_Mismatch: new FormControl(),
    Issue_Reported_through_GPC_Bot: new FormControl(null, Validators.required),
    RCA_Description: new FormControl(),
    Problem_Ticket: new FormControl(),
    Vendor_Name: new FormControl(),
    Reasons_for_missed_SLOs: new FormControl(),
    Week_Numbers: new FormControl(),
    priorityChanged: new FormControl(),
    sp: new FormControl()
  });

  NextGenApplicationsForm = new FormGroup({
    EnterpriseId: new FormControl(),
    AirId: new FormControl('', Validators.required),
    ApplicationName: new FormControl('', Validators.required),
    ServiceTier: new FormControl('', Validators.required),
    MD: new FormControl('', Validators.required),
    ServiceOperationLead: new FormControl(),
    ReliabilityDirector: new FormControl(),
    GPCManaged: new FormControl(),
    ServiceArea: new FormControl(),
    ProjectAIM: new FormControl()
  });

  NextGenPreventionsForm = new FormGroup({
    ID: new FormControl(),
    EnterpriseId: new FormControl(),
    FY: new FormControl('', Validators.required),
    Month: new FormControl('', Validators.required),
    Incident_Ticket: new FormControl('', Validators.required),
    Date_Identified: new FormControl(null as FormControl | null, Validators.required),
    Duration: new FormControl('', Validators.required),
    Start_Date: new FormControl(),
    Prevented_By: new FormControl('', Validators.required),
    AIR_ID: new FormControl('', Validators.required),
    Application_Name: new FormControl('', Validators.required),
    MD: new FormControl('', Validators.required),
    Category: new FormControl('', Validators.required),
    Refined_Category: new FormControl('', Validators.required),
    TAO_Comments: new FormControl('', Validators.required)
  });

  searchForm = new FormGroup({
    ApplicationIds: new FormControl(),
    IncidentNo: new FormControl(),
    status: new FormControl(), service: new FormControl()
  });

  p1ExcelTemplate = {
    headers: true,
    columns: [
      { columnid: 'FY', title: 'FY' },
      { columnid: 'Quarter', title: 'Quarter' },
      { columnid: 'Month', title: 'Month' },
      { columnid: 'Incident_ID', title: 'Incident ID' },
      { columnid: 'Users_Impacted', title: 'Users Impacted' },
      { columnid: 'Description', title: 'Description' },
      { columnid: 'Impacted_Entity', title: 'Impacted Entity' },
      { columnid: 'AIR_ID', title: 'AIR ID' },
      { columnid: 'ApplicationName', title: 'Application Name' },
      { columnid: 'Application_Name_AIR', title: 'Application Name AIR' },
      { columnid: 'MD_Name', title: 'MD Name' },
      { columnid: 'Day', title: 'Day' },
      { columnid: 'Incident_Start_Time', title: 'Incident Start Time' },
      { columnid: 'Root_cause_Categorization', title: 'Root Cause Categorization' },
      { columnid: 'Pulse_Root_Cause', title: 'Pulse Root Cause' },
      { columnid: 'Sub_Category', title: 'Sub Category' },
      { columnid: 'RCA_Category', title: 'RCA Category' },
      { columnid: 'Change_Induced', title: 'Change Induced' },
      { columnid: 'Change_Ticket', title: 'Change Ticket' },
      { columnid: 'Outage_Duration', title: 'Outage Duration' },
      { columnid: 'Service_Tier', title: 'Service Tier' },
      { columnid: 'SLO_Met', title: 'SLO Met' },
      { columnid: 'GFP_SLO', title: 'GFP SLO' },
      { columnid: 'In_app_SLO', title: 'In-app SLO' },
      { columnid: 'GSM_Engagement_SLO', title: 'GSM Engagement SLO' },
      { columnid: 'MD_joined', title: 'MD Joined' },
      { columnid: 'Playbook_Usage', title: 'Playbook Usage' },
      { columnid: 'Tech_Teams_SLO', title: 'Tech Teams SLO' },
      { columnid: 'End_User_SLO', title: 'End User SLO' },
      { columnid: 'Alerts_Received', title: 'Alerts Received' },
      { columnid: 'Alerts_Remarks', title: 'Alerts Remarks' },
      { columnid: 'Alerts_Justification', title: 'Alerts Justification' },
      { columnid: 'Recurring_Issue', title: 'Recurring Issue' },
      { columnid: 'App_Count', title: 'P1 Ranking' },
      { columnid: 'Ticket_Priority_Mismatch', title: 'Ticket Priority Mismatch' },
      { columnid: 'Issue_Reported_through_GPC_Bot', title: 'Issue Reported through GPC Bot' },
      { columnid: 'RCA_Description', title: 'RCA Description' },
      { columnid: 'Problem_Ticket', title: 'Problem Ticket' },
      { columnid: 'Vendor_Name', title: 'Vendor Name' },
      { columnid: 'Reasons_for_missed_SLOs', title: 'Reasons for Missed SLOs' },
      { columnid: 'Week_Numbers', title: 'Occurrence in last 12 months' }
    ]
  };

  p2ExcelTemplate = {
    headers: true,
    columns: [
      { columnid: 'FY', title: 'FY' },
      { columnid: 'Quarter', title: 'Quarter' },
      { columnid: 'Month', title: 'Month' },
      { columnid: 'Incident_ID', title: 'Incident ID' },
      { columnid: 'AIR_ID', title: 'AIR ID' },
      { columnid: 'ApplicationName', title: 'Application Name' },
      { columnid: 'MD_Name', title: 'MD Name' },
      { columnid: 'Day', title: 'Day' },
      { columnid: 'Incident_Start_Time', title: 'Incident Start Time' },
      { columnid: 'Root_cause_Categorization', title: 'Root Cause Categorization' },
      { columnid: 'Sub_Category', title: 'Sub Category' },
      { columnid: 'Change_Induced', title: 'Change Induced' },
      { columnid: 'Change_Ticket', title: 'Change Ticket' },
      { columnid: 'Outage_Duration', title: 'Outage Duration (mins)' },
      { columnid: 'SLO_Met', title: 'SLO Met' },
      { columnid: 'Service_Tier', title: 'Service Tier' },
      { columnid: 'Playbook_Usage', title: 'Playbook Usage' },
      { columnid: 'Ticket_Priority_Mismatch', title: 'Ticket Priority Mismatch' },
      { columnid: 'MD_joined', title: 'MD MOD Joined' },
      { columnid: 'GSM_Engagement_SLO', title: 'GSM Engagement Delays' },
      { columnid: 'End_User_SLO', title: 'End User SLO' },
      { columnid: 'Recurring_Issue', title: 'Recurring Issue' },
      { columnid: 'Issue_Reported_through_GPC_Bot', title: 'Issue Reported through GPC Bot' },
      { columnid: 'Alerts_Received', title: 'Alerts Received' },
      { columnid: 'Alerts_Remarks', title: 'Alerts Remarks' },
      { columnid: 'Alerts_Justification', title: 'Alerts Justification' },
      { columnid: 'Problem_Ticket', title: 'Problem Ticket' },
      { columnid: 'Vendor_Name', title: 'Vendor Name' },
      { columnid: 'RCA_Description', title: 'RCA Statement' },
      { columnid: 'Reasons_for_missed_SLOs', title: 'Reasons for missed SLOs' },
    ]
  };

  applicationsExcelTemplate = {
    headers: true,
    columns: [
      { columnid: 'AirId', title: 'AirId' },
      { columnid: 'ApplicationName', title: 'Application Name' },
      { columnid: 'ServiceArea', title: 'Service Area' },
      { columnid: 'ServiceTier', title: 'Service Tier' },
      { columnid: 'MD', title: 'MD' },
      { columnid: 'ServiceOperationLead', title: 'Service Operation Lead' },
      { columnid: 'ReliabilityDirector', title: 'Reliability Director' },
      { columnid: 'PrjectAIM', title: 'Project AIM' },
      { columnid: 'GPCManaged', title: 'GPC Managed' }
    ]
  };

  preventionsExcelTemplate = {
    headers: true,
    columns: [
      { columnid: 'FY', title: 'FY' },
      { columnid: 'Month', title: 'Month' },
      { columnid: 'Incident_Ticket', title: 'Incident Ticket' },
      { columnid: 'Date_Identified', title: 'Date Identified' },
      { columnid: 'Duration', title: 'Duration' },
      { columnid: 'Start_Date', title: 'Start Date' },
      { columnid: 'Prevented_By', title: 'Prevented By' },
      { columnid: 'AIR_ID', title: 'AirId' },
      { columnid: 'Application_Name', title: 'Application Name' },
      { columnid: 'MD', title: 'MD' },
      { columnid: 'Category', title: 'Category' },
      { columnid: 'Refined_Category', title: 'Refined Category' },
      { columnid: 'TAO_Comments', title: 'TAO Comments' }
    ]
  };

  monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  message: string = "";
  approveMessage: string = "";
  constructor(private http: HttpClient, private modalService: NgbModal, private datePipe: DatePipe, private common: CommonService, private Approvingservice: RequestOnboardingService) {

    
    
    this.asRootCauseCategory = this.getAsRootCauseCategory();
    this.asSubCategory = this.getAsSubCategory();
    this.asEndUsers = this.getAsEndUsers();
    this.asResponse = this.getAsResponse();
    this.asRestore = this.getAsRestore();
    this.asLeadership = this.getAsLeadership();
    this.asPlaybook = this.getAsPlaybook();

    this.asImpactedEntity = this.getAsImpactedEntity();
    this.asDay = this.getAsDay();
    this.asChangeInduced = this.getAsChangeInduced();
    this.asTier = this.getAsTier();
    this.asSLOMet = this.getAsSLOMet();
    this.asGFPSLO = this.getAsGFPSLO();
    this.asInAppSLO = this.getAsInAppSLO();
    this.asGSMEngagementSLO = this.getAsGSMEngagementSLO();
    this.asMDJoined = this.getAsMDJoined();
    this.asPlaybookUsage = this.getAsplaybookUsage();
    this.asTechTeamsSLO = this.getAsTechTeamsSLO();
    this.asEndUserSLO = this.getAsEndUserSLO();
    this.asAlertsReceived = this.getAsAlertsReceived();
    this.asRecurringIssue = this.getAsRecurringIssue();
    this.asTicketPriorityMismatch = this.getAsTicketPriorityMismatch();
    this.asIssueReportedthroughGPCBot = this.getAsIssueReportedthroughGPCBot();
    this.asReasonforMissedSLOs = this.getAsReasonforMissedSLOs();
  }

  ngOnInit() {
    this.checkIfNextGenAdmin();
    this.FetchNextGenIncDetailsTable();
    this.fetchPoneData();
    this.fetchPtwoData();
    this.fetchApplicationsData();
    this.fetchPreventionData();
    this.findapps();
    console.log(this.IncidentForm.controls.ServiceTier.value);

    this.P1IncidentForm.get('Incident_Start_Time')?.valueChanges.subscribe((date: Date) => {
      if (date) {
        this.updateP1Fields(date);
      }
    });

    this.PtwoIncidentForm.get('Incident_Start_Time')?.valueChanges.subscribe((date: Date) => {
      if (date) {
        this.updateP2Fields(date);
      }
    });

    this.NextGenPreventionsForm.get('Date_Identified')?.valueChanges.subscribe((date: Date) => {
      if (date) {
        this.updatePreventionFields(date);
      }
    });
  }

  selectedForm = 'p1';

  getAsImpactedEntity() {
    return [
      { Id: "1", value: "Users" },
      { Id: "2", value: "Apps" },
      { Id: "3", value: "Jobs" },
      { Id: "4", value: "Emails" },
      { Id: "5", value: "Global" }
    ]
  }

  getAsDay() {
    return [
      { Id: "1", value: "Monday" },
      { Id: "2", value: "Tuesday" },
      { Id: "3", value: "Wednesday" },
      { Id: "4", value: "Thursday" },
      { Id: "5", value: "Friday" },
      { Id: "6", value: "Saturday" },
      { Id: "7", value: "Sunday" }
    ]
  }

  getAsChangeInduced() {
    return [
      { Id: "1", value: "Yes" },
      { Id: "2", value: "No"}
    ]
  }

  getAsTier() {
    return [
      { Id: "1", value: "AO" },
      { Id: "2", value: "NAO" }
    ]
  }

  getAsSLOMet() {
    return [
      { Id: "1", value: "Yes" },
      { Id: "2", value: "No" },
      { Id: "3", value: "NA" }
    ]
  }

  getAsGFPSLO() {
    return [
      { Id: "1", value: "Met" },
      { Id: "2", value: "Missed" },
      { Id: "3", value: "NA" }
    ]
  }

  getAsInAppSLO() {
    return [
      { Id: "1", value: "Met" },
      { Id: "2", value: "Missed" },
      { Id: "3", value: "NA" }
    ]
  }

  getAsGSMEngagementSLO() {
    return [
      { Id: "1", value: "Met" },
      { Id: "2", value: "Missed" },
      { Id: "3", value: "NA" }
    ]
  }

  getAsMDJoined() {
    return [
      { Id: "1", value: "Yes" },
      { Id: "2", value: "No" },
      { Id: "3", value: "NA" }
    ]
  }

  getAsplaybookUsage() {
    return [
      { Id: "1", value: "Yes" },
      { Id: "2", value: "No" },
      { Id: "3", value: "NA" }
    ]
  }

  getAsTechTeamsSLO() {
    return [
      { Id: "1", value: "Yes" },
      { Id: "2", value: "No" }
    ]
  }

  getAsEndUserSLO() {
    return [
      { Id: "1", value: "Met" },
      { Id: "2", value: "Missed" },
      { Id: "3", value: "NA" }
    ]
  }

  getAsAlertsReceived() {
    return [
      { Id: "1", value: "Yes" },
      { Id: "2", value: "No" },
      { Id: "3", value: "NA" }
    ]
  }

  getAsRecurringIssue() {
    return [
      { Id: "1", value: "Yes" },
      { Id: "2", value: "No" }
    ]
  }

  getAsTicketPriorityMismatch() {
    return [
      { Id: "1", value: 0 },
      { Id: "2", value: 1 }
    ]
  }

  getAsIssueReportedthroughGPCBot() {
    return [
      { Id: "1", value: "Yes" },
      { Id: "2", value: "No" },
      { Id: "3", value: "NA" }
    ]
  }

  getAsReasonforMissedSLOs() {
    return [
      { Id: "1", value: "Vendor Dependency/Delay" },
      { Id: "2", value: "Met SLA" },
      { Id: "3", value: "Issue Detection" },
      { Id: "4", value: "Complexity of the Issue" },
      { Id: "5", value: "Troubleshooting and Fix" }
    ]
  }

  getAsRootCauseCategory() {
    return [
      { Id: "1", value: "Configuration Issue" },
      { Id: "2", value: "Negligence" },
      { Id: "3", value: "Software Issue" },
      { Id: "4", value: "Defect" },
      { Id: "5", value: "Capacity Issue" },
      { Id: "6", value: "Hardware Failure" },
      { Id: "7", value: "Change" },
      { Id: "8", value: "Unknown" },
      { Id: "9", value: "Vendor Issue" }
    ]
  }

  getAsSubCategory() {
    return [
      { Id: "1", value: "Service" },
      { Id: "2", value: "Hardware" },
      { Id: "3", value: "Infrastructure" },
      { Id: "4", value: "Capacity" },
      { Id: "5", value: "Negligence" },
      { Id: "6", value: "Change" },
      { Id: "7", value: "Configuration" },
      { Id: "8", value: "Network" },
      { Id: "9", value: "Software" }
    ]
  }

  getAsEndUsers() {
    return [
      { Id: "1", value: "Met" },
      { Id: "2", value: "Missed" },
      { Id: "3", value: "NA" }
    ]
  }

  getAsResponse() {
    return [
      { Id: "1", value: "Met" },
      { Id: "2", value: "Missed" }
    ]
  }

  getAsRestore() {
    return [
      { Id: "1", value: "Yes" },
      { Id: "2", value: "No" }
    ]
  }

  getAsLeadership() {
    return [
      { Id: "1", value: "Yes" },
      { Id: "2", value: "No" }
    ]
  }

  getAsPlaybook() {
    return [
      { Id: "1", value: "Yes" },
      { Id: "2", value: "No" },
      { Id: "3", value: "NA" }
    ]
  }

 checkIfNextGenAdmin() {
     let user_info = { enterpriseId: sessionStorage.getItem('LoggedInUser'), givenname: sessionStorage.getItem('LoggedInUser') };
     return this.http.post<OnboardingInterface>(this.baseURL + 'api/NextGen/IsNextGenAdmin', user_info).pipe(
       map(this.extractData),
       catchError(this.handleErrorObservable)
     ).subscribe(response => {
       this.nextGenRole = response.Result;
       console.log(this.nextGenRole);
       if (response.Result == true) {
          this.nonApproverMessage = true;
        this.approverPageContent = false;
       }
     });
    
   }
  
  // fetching details on expansion of a panel
  FetchNextGenIncDetailsTable() {
    var log = { EnterpriseId: sessionStorage.getItem('LoggedInUser') };
    this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenIncidents', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.Result.length > 0) {
        this.hideIncidentTable = true;
        this.showIncidentTable = false;
      }
      else {
        this.showIncidentTable = true;
        this.hideIncidentTable = false;
      }
      console.log(response);
      this.incidentsAppsDetails = response.Result;

    });

  }


  
  viewIncident(content: any, data: any) {
    this.incidentNumber = data.IncidentNumber;
    var fetchData = { IncidentNumber: data.IncidentNumber, EnterpriseId: sessionStorage.getItem('LoggedInUser') };
    return this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenIncidents', fetchData).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {

      if (response.ErrorMessage != '') {

        this.errorMessage = response.ErrorMessage;
      } else {
        this.IncidentForm.patchValue({
          IncidentNumber: response.Result[0].IncidentNumber, AirId: response.Result[0].AirId, ApplicationName: response.Result[0].ApplicationName,
          ServiceTier: response.Result[0].ServiceTier, Duration: response.Result[0].Duration, UserImpacted: response.Result[0].UserImpacted,
          Description: response.Result[0].Description, CreatedBy: response.Result[0].CreatedBy,
          RootCauseCategory: response.Result[0].RootCauseCategory, SubCategory: response.Result[0].SubCategory,
          EndUsers: response.Result[0].EndUsers, Response: response.Result[0].Response,
          Restore: response.Result[0].Restore, Leadership: response.Result[0].Leadership,
          Playbook: response.Result[0].Playbook
        });
        console.log(this.IncidentForm.controls.ServiceTier.value);
      }
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result: any) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason: any) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });
    
  }
 
  saveChanges() {
    if (this.IncidentForm.controls.RootCauseCategory.value == 'Vendor Issue') {
      this.IncidentForm.controls['SubCategory'].setValidators([Validators.required]);
    }
    else {
      this.IncidentForm.controls['SubCategory'].clearValidators();
    }
    //if (this.IncidentForm.value.ServiceTier == null) {
    if (!this.IncidentForm.valid) { 
      this.errorMessage = "Kindly fill all the fields before clicking on update";
      return null;
    }
    // Rest of the add functinality goes here
    //console.log(this.requestForm.value);
    //this.Approvingservice.approveForms(this.requestForm.value)
    else {

      return this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/UpdateNextGenIncidents', this.IncidentForm.value).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      )
        .subscribe(response => {
          if (response.ErrorMessage == '') {
            $("#btnApForForm").prop("disabled", false);
            var saveMessage = "Saved successfully!!";
            this.successNotification(saveMessage);
            $('#Closebtn').trigger('click');
            //this.FetchNextGenAppPanels();
            this.IncidentForm.reset();

            // this.ServiceTierrequestForm = [];
            this.FetchNextGenIncDetailsTable();

          } else {
            this.errorMessage = response.ErrorMessage;
            $("#btnApForForm").prop("disabled", false);
          }

        });
    }
  }

  saveEditForm() {
    if (this.currentForm == 'P1') {
      this.saveP1Form();
    }
    else if (this.currentForm == 'P2') {
      this.saveP2Form();
    }
    else if (this.currentForm == 'application') {
      this.saveApplicationForm();
    }
    else if (this.currentForm == 'prevention') {
      this.savePreventionForm();
    }
  }

  saveAddForm() {
    if (this.selectedForm == 'p1') {
      this.addP1Form();
    }
    else if (this.selectedForm == 'p2') {
      this.addP2Form();
    }
    else if (this.selectedForm == 'applications') {
      this.addApplicationForm();
    }
    else if (this.selectedForm == 'preventions') {
      this.addPreventionForm();
    }
  }

  addP1Form() {
    this.P1IncidentForm.get('EnterpriseId')?.setValue(sessionStorage.getItem('LoggedInUser'));
    if (!this.P1IncidentForm.valid) {

      this.errorMessage = "Kindly fill all the mandatatory fields before clicking on Save";
      return null;
    }
    else {
      //var date = this.datePipe.transform(this.P1IncidentForm.value.Incident_Start_Time, 'dd/MM/yyyy HH:mm');
      var date = new Date(this.P1IncidentForm.get('Incident_Start_Time')?.value.getTime() - this.P1IncidentForm.get('Incident_Start_Time')?.value.getTimezoneOffset() * 60000);
      this.P1IncidentForm.get('Incident_Start_Time')?.setValue(date);
      var incidentNumber = this.P1IncidentForm.get('Incident_ID')?.value?.trim() ?? null;
      this.P1IncidentForm.get('Incident_ID')?.setValue(incidentNumber);
        return this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/AddNextGenP1Incidents', this.P1IncidentForm.value).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    )
      .subscribe(response => {
        if (response.ErrorMessage == '') {
          $("#updateAddForm").prop("disabled", false);
          var saveMessage = "Saved successfully!!";
          this.successNotification(saveMessage);
          $('#closeAddForm').trigger('click');
          //this.FetchNextGenAppPanels();
          this.P1IncidentForm.reset();

          // this.ServiceTierrequestForm = [];
          this.fetchPoneData();

        } else {
          this.errorMessage = response.ErrorMessage;
          $("#updateAddForm").prop("disabled", false);
        }

      });
  }
  }

  addP2Form() {
    this.PtwoIncidentForm.get('EnterpriseId')?.setValue(sessionStorage.getItem('LoggedInUser'));
    if (!this.PtwoIncidentForm.valid) {

      this.errorMessage = "Kindly fill all the mandatatory fields before clicking on Save";
      return null;
    }
    else {
      var date = new Date(this.PtwoIncidentForm.get('Incident_Start_Time')?.value.getTime() - this.PtwoIncidentForm.get('Incident_Start_Time')?.value.getTimezoneOffset() * 60000);
      this.PtwoIncidentForm.get('Incident_Start_Time')?.setValue(date);
      var incidentNumber = this.PtwoIncidentForm.get('Incident_ID')?.value?.trim() ?? null;
      this.PtwoIncidentForm.get('Incident_ID')?.setValue(incidentNumber);
      return this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/AddNextGenP2Incidents', this.PtwoIncidentForm.value).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      )
        .subscribe(response => {
          if (response.ErrorMessage == '') {
            $("#updateAddForm").prop("disabled", false);
            var saveMessage = "Saved successfully!!";
            this.successNotification(saveMessage);
            $('#closeAddForm').trigger('click');
            //this.FetchNextGenAppPanels();
            this.PtwoIncidentForm.reset();

            // this.ServiceTierrequestForm = [];
            this.fetchPtwoData();

          } else {
            this.errorMessage = response.ErrorMessage;
            $("#updateAddForm").prop("disabled", false);
          }

        });
    }
  }

  addApplicationForm() {
    this.NextGenApplicationsForm.get('EnterpriseId')?.setValue(sessionStorage.getItem('LoggedInUser'));

    if (!this.NextGenApplicationsForm.valid) {

      this.errorMessage = "Kindly fill all the mandatatory fields before clicking on Save";
      return null;
    }
    else {
      
      return this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/AddNextGenApplications', this.NextGenApplicationsForm.value).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      )
        .subscribe(response => {
          if (response.ErrorMessage == '') {
            $("#updateAddForm").prop("disabled", false);
            var saveMessage = "Saved successfully!!";
            this.successNotification(saveMessage);
            $('#closeAddForm').trigger('click');
            //this.FetchNextGenAppPanels();
            this.NextGenApplicationsForm.reset();

            // this.ServiceTierrequestForm = [];
            this.fetchApplicationsData();

          } else {
            this.errorMessage = response.ErrorMessage;
            $("#updateAddForm").prop("disabled", false);
          }

        });
    }
  }

  addPreventionForm() {
    this.NextGenPreventionsForm.get('EnterpriseId')?.setValue(sessionStorage.getItem('LoggedInUser'));

    if (!this.NextGenPreventionsForm.valid) {

      this.errorMessage = "Kindly fill all the mandatatory fields before clicking on Save";
      return null;
    }
    else {
      var date = new Date(this.NextGenPreventionsForm.get('Date_Identified')?.value.getTime() - this.NextGenPreventionsForm.get('Date_Identified')?.value.getTimezoneOffset() * 60000);
      this.NextGenPreventionsForm.get('Date_Identified')?.setValue(date);

      var incidentNumber = this.NextGenPreventionsForm.get('Incident_Ticket')?.value?.trim() ?? null;
      this.NextGenPreventionsForm.get('Incident_Ticket')?.setValue(incidentNumber);

      if (this.NextGenPreventionsForm.get('Start_Date')?.value) {
        var startDate = new Date(this.NextGenPreventionsForm.get('Start_Date')?.value.getTime() - this.NextGenPreventionsForm.get('Start_Date')?.value.getTimezoneOffset() * 60000);
        this.NextGenPreventionsForm.get('Start_Date')?.setValue(startDate);
      }
      return this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/AddNextGenPreventions', this.NextGenPreventionsForm.value).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      )
        .subscribe(response => {
          if (response.ErrorMessage == '') {
            $("#updateAddForm").prop("disabled", false);
            var saveMessage = "Saved successfully!!";
            this.successNotification(saveMessage);
            $('#closeAddForm').trigger('click');
            //this.FetchNextGenAppPanels();
            this.NextGenPreventionsForm.reset();

            // this.ServiceTierrequestForm = [];
            this.fetchPreventionData();

          } else {
            this.errorMessage = response.ErrorMessage;
            $("#updateAddForm").prop("disabled", false);
          }

        });
    }
  }

  saveP1Form() {
    this.P1IncidentForm.get('EnterpriseId')?.setValue(sessionStorage.getItem('LoggedInUser'));
    if (this.priority == 'p2') {
      this.P1IncidentForm.get('priorityChanged')?.setValue('changed');
    }
    this.P1IncidentForm.get('sp')?.setValue('P2');

    if (!this.P1IncidentForm.valid) {

      this.errorMessage = "Kindly fill all the mandatatory fields before clicking on Save";
      return null;
    }
    else {
      var date = new Date(this.P1IncidentForm.get('Incident_Start_Time')?.value.getTime() - this.P1IncidentForm.get('Incident_Start_Time')?.value.getTimezoneOffset() * 60000);
      this.P1IncidentForm.get('Incident_Start_Time')?.setValue(date);
      return this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/EditNextGenIncidents', this.P1IncidentForm.value).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      )
        .subscribe(response => {
          if (response.ErrorMessage == '') {
            $("#updateEditForm").prop("disabled", false);
            var saveMessage = "Saved successfully!!";
            this.successNotification(saveMessage);
            $('#closeEditForm').trigger('click');
            //this.FetchNextGenAppPanels();
            this.P1IncidentForm.reset();

            // this.ServiceTierrequestForm = [];
            this.fetchPoneData();
            this.fetchPtwoData();
          } else {
            this.errorMessage = response.ErrorMessage;
            $("#updateEditForm").prop("disabled", false);
          }

        });
    }
  }

  saveP2Form() {
    this.PtwoIncidentForm.get('EnterpriseId')?.setValue(sessionStorage.getItem('LoggedInUser'));
    if (this.priority == 'p1') {
      this.PtwoIncidentForm.get('priorityChanged')?.setValue('changed');
    }
    this.PtwoIncidentForm.get('sp')?.setValue('P1');

    if (!this.PtwoIncidentForm.valid) {

      this.errorMessage = "Kindly fill all the mandatatory fields before clicking on Save";
      return null;
    }
    else {
      var date = new Date(this.PtwoIncidentForm.get('Incident_Start_Time')?.value.getTime() - this.PtwoIncidentForm.get('Incident_Start_Time')?.value.getTimezoneOffset() * 60000);
      this.PtwoIncidentForm.get('Incident_Start_Time')?.setValue(date);
      return this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/EditNextGenIncidents', this.PtwoIncidentForm.value).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      )
        .subscribe(response => {
          if (response.ErrorMessage == '') {
            $("#updateEditForm").prop("disabled", false);
            var saveMessage = "Saved successfully!!";
            this.successNotification(saveMessage);
            $('#closeEditForm').trigger('click');
            //this.FetchNextGenAppPanels();
            this.PtwoIncidentForm.reset();

            // this.ServiceTierrequestForm = [];
            this.fetchPoneData();
            this.fetchPtwoData();

          } else {
            this.errorMessage = response.ErrorMessage;
            $("#updateEditForm").prop("disabled", false);
          }

        });
    }
  }

  saveApplicationForm() {
    this.NextGenApplicationsForm.get('EnterpriseId')?.setValue(sessionStorage.getItem('LoggedInUser'));

    if (!this.NextGenApplicationsForm.valid) {

      this.errorMessage = "Kindly fill all the mandatatory fields before clicking on Save";
      return null;
    }
    else {
      return this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/EditNextGenApplications', this.NextGenApplicationsForm.value).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      )
        .subscribe(response => {
          if (response.ErrorMessage == '') {
            $("#updateEditForm").prop("disabled", false);
            var saveMessage = "Saved successfully!!";
            this.successNotification(saveMessage);
            $('#closeEditForm').trigger('click');
            //this.FetchNextGenAppPanels();
            this.NextGenApplicationsForm.reset();

            // this.ServiceTierrequestForm = [];
            this.fetchApplicationsData();

          } else {
            this.errorMessage = response.ErrorMessage;
            $("#updateEditForm").prop("disabled", false); 
          }

        }); 
    }
  }

  savePreventionForm() {
    this.NextGenPreventionsForm.get('EnterpriseId')?.setValue(sessionStorage.getItem('LoggedInUser'));

    if (!this.NextGenPreventionsForm.valid) {

      this.errorMessage = "Kindly fill all the mandatatory fields before clicking on Save";
      return null;
    }
    else {
      var date = new Date(this.NextGenPreventionsForm.get('Date_Identified')?.value.getTime() - this.NextGenPreventionsForm.get('Date_Identified')?.value.getTimezoneOffset() * 60000);
      this.NextGenPreventionsForm.get('Date_Identified')?.setValue(date);

      if (this.NextGenPreventionsForm.get('Start_Date')?.value) {
        var startDate = new Date(this.NextGenPreventionsForm.get('Start_Date')?.value.getTime() - this.NextGenPreventionsForm.get('Start_Date')?.value.getTimezoneOffset() * 60000);
        this.NextGenPreventionsForm.get('Start_Date')?.setValue(startDate);
      }
    

      return this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/EditNextGenPreventions', this.NextGenPreventionsForm.value).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      )
        .subscribe(response => {
          if (response.ErrorMessage == '') {
            $("#updateEditForm").prop("disabled", false);
            var saveMessage = "Saved successfully!!";
            this.successNotification(saveMessage);
            $('#closeEditForm').trigger('click');
            //this.FetchNextGenAppPanels();
            this.NextGenPreventionsForm.reset();

            // this.ServiceTierrequestForm = [];
            this.fetchPreventionData();

          } else {
            this.errorMessage = response.ErrorMessage;
            $("#updateEditForm").prop("disabled", false);
          }

        });
    }
  }

  clearErrors() {
    this.errorMessage = "";
    this.IncidentForm.reset();
    this.P1IncidentForm.reset();
    this.PtwoIncidentForm.reset();
    this.NextGenApplicationsForm.reset();
    this.NextGenPreventionsForm.reset();
  }
  tabClick(tab: any) {
    this.applicationNameAddForm = [];
    this.airIDAddForm = [];
    $('#IncidentNo').find('input:text').val('');

    this.searchForm.get('IncidentNo')?.setValue('');
    if (tab.tab.textLabel == "P1") {
      this.selectedForm = "p1";
      this.fetchPoneData();
      console.log(this.selectedForm);
    }
    else if (tab.tab.textLabel == "P2") {
      this.selectedForm = "p2";
      this.fetchPtwoData();
      console.log(this.selectedForm);
    }
    else if (tab.tab.textLabel == "Applications") {
      this.selectedForm = "applications";
      this.fetchApplicationsData();
      console.log(this.selectedForm);
    }
    else if (tab.tab.textLabel == "Preventions") {
      this.selectedForm = "preventions";
      this.fetchPreventionData();
      console.log(this.selectedForm);
    }
  }
  fetchPoneData() {
    
    var log = { EnterpriseId: sessionStorage.getItem('LoggedInUser') };
    this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenP1Incidents', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      
      this.nextGenP1Incidents = response.Result;
      this.loadednextGenP1Incidents = this.nextGenP1Incidents.slice(0, 15);
      this.pageIndex = 1;
      this.checkExportData();
    });
  }
  fetchPtwoData() {
    var log = { EnterpriseId: sessionStorage.getItem('LoggedInUser') };
    this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenP2Incidents', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      
      this.nextGenP2Incidents = response.Result;
      this.checkExportData();
    });
  }
  fetchApplicationsData() {
    var log = { EnterpriseId: sessionStorage.getItem('LoggedInUser') };
    this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenApplications', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      
      this.nextGenApplications = response.Result;
      
      this.applicationNames = this.nextGenApplications.map((nextGenApplication) => nextGenApplication.ApplicationName);
      this.checkExportData();
    });
  }
  fetchPreventionData() {
    var log = { EnterpriseId: sessionStorage.getItem('LoggedInUser') };
    this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenPreventions', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      
      this.nextGenPreventions = response.Result;
      this.loadednextGenPreventions = this.nextGenPreventions.slice(0, 15);
      this.preventionPageIndex = 1;
      this.checkExportData();
    });
  }

  checkExportData() {
    if (this.nextGenP1Incidents != null && this.nextGenP2Incidents != null && this.nextGenApplications != null && this.nextGenPreventions != null) {
      this.enableExportToExcel = false;
    }
  }
  exportData() {
    let filter = { LoggedInUser: sessionStorage.getItem('LoggedInUser')}
    let date = new Date();
    let day = date.getDate();     // Get current date
    let month = date.getMonth() + 1; // current month
    let year = date.getFullYear();
    let CurrentDate = year + "/" + month + "/" + day;


    if (this.selectedForm == "p1") {
      this.alasql('SELECT * INTO XLS("NextGen P1 Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.p1ExcelTemplate, this.nextGenP1Incidents]);
    }

    else if (this.selectedForm == "p2") {
      this.alasql('SELECT * INTO XLS("NextGen P2 Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.p2ExcelTemplate, this.nextGenP2Incidents]);
    }

    else if (this.selectedForm == "applications") {
      this.alasql('SELECT * INTO XLS("NextGen Applications Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.applicationsExcelTemplate, this.nextGenApplications]);
    }

    else if (this.selectedForm == "preventions") {
      this.alasql('SELECT * INTO XLS("NextGen Preventions Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.preventionsExcelTemplate, this.nextGenPreventions]);
    }
 
  }
  open(content: any) {
    this.modalTitle = "Add New";

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result: any) => {
      this.closeResult = `closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
    });
  }

  edit(content: any, form: any, formName: string, data: any) {
    this.modalTitle = "Edit";
    this.editFormContent = form;

    if (formName == 'P1') {
        //this.P1IncidentForm.controls['Incident_ID'].disable();
        this.currentForm = 'P1';
        this.p1Edit(content, data);
    }
    else if (formName == 'P2') {
        //this.PtwoIncidentForm.controls['Incident_ID'].disable();
        this.currentForm = 'P2';
        this.p2Edit(content, data);
    }
    else if (formName == 'application') {
      //this.NextGenApplicationsForm.controls['AirId'].disable();
      this.currentForm = 'application';
      this.applicationsEdit(content, data);
    }
    else if (formName == 'prevention') {
      //this.NextGenPreventionsForm.controls['Incident_Ticket'].disable();
      this.currentForm = 'prevention';
      this.preventionsEdit(content, data);
    }
  }

  p1Edit(content: any, data: any) {
    this.incidentNumber = data.IncidentNumber;
    var fetchData = { IncidentNumber: data.Incident_ID, EnterpriseId: sessionStorage.getItem('LoggedInUser') };
    this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenP1Incidents', fetchData).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {

      if (response.ErrorMessage != '') {

        this.errorMessage = response.ErrorMessage;
      } else {
        this.priority = "p1";
        const IncidentStartTime = response.Result[0].Incident_Start_Time ? new Date(response.Result[0].Incident_Start_Time) : null;
        this.P1IncidentForm.patchValue({
          FY: response.Result[0].FY,
          Quarter: response.Result[0].Quarter,
          Month: response.Result[0].Month,
          Incident_ID: response.Result[0].Incident_ID,
          Users_Impacted: response.Result[0].Users_Impacted,
          Description: response.Result[0].Description,
          AIR_ID: response.Result[0].AIR_ID,
          ApplicationName: response.Result[0].ApplicationName,
          Application_Name_AIR: response.Result[0].Application_Name_AIR,
          MD_Name: response.Result[0].MD_Name,
          Incident_Start_Time: IncidentStartTime,
          Root_cause_Categorization: response.Result[0].Root_cause_Categorization,
          Pulse_Root_Cause: response.Result[0].Pulse_Root_Cause,
          Sub_Category: response.Result[0].Sub_Category,
          RCA_Category: response.Result[0].RCA_Category,
          Change_Induced: response.Result[0].Change_Induced,
          Outage_Duration: response.Result[0].Outage_Duration,
          Service_Tier: response.Result[0].Service_Tier,
          SLO_Met: response.Result[0].SLO_Met,
          GFP_SLO: response.Result[0].GFP_SLO,
          In_app_SLO: response.Result[0].In_app_SLO,
          GSM_Engagement_SLO: response.Result[0].GSM_Engagement_SLO,
          MD_joined: response.Result[0].MD_joined,
          Playbook_Usage: response.Result[0].Playbook_Usage,
          Tech_Teams_SLO: response.Result[0].Tech_Teams_SLO,
          End_User_SLO: response.Result[0].End_User_SLO,
          Alerts_Received: response.Result[0].Alerts_Received,
          Recurring_Issue: response.Result[0].Recurring_Issue,
          App_Count: response.Result[0].App_Count,
          Ticket_Priority_Mismatch: response.Result[0].Ticket_Priority_Mismatch,
          Issue_Reported_through_GPC_Bot: response.Result[0].Issue_Reported_through_GPC_Bot,
          RCA_Description: response.Result[0].RCA_Description,
          Vendor_Name: response.Result[0].Vendor_Name,
          Reasons_for_missed_SLOs: response.Result[0].Reasons_for_missed_SLOs,
          Week_Numbers: response.Result[0].Week_Numbers,

          Impacted_Entity: response.Result[0].Impacted_Entity,
          Day: response.Result[0].Day,
          Change_Ticket: response.Result[0].Change_Ticket,
          Alerts_Remarks: response.Result[0].Alerts_Remarks,
          Alerts_Justification: response.Result[0].Alerts_Justification,
          Problem_Ticket: response.Result[0].Problem_Ticket
        });
        //console.log(this.IncidentForm.controls.ServiceTier.value);
      }
    });
    this.modalService.open(content, this.ngbModalOptions).result.then((result: any) => {
      this.closeResult = `closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
    });
  }
  p2Edit(content: any, data: any) {
    this.incidentNumber = data.IncidentNumber;
    var fetchData = { IncidentNumber: data.Incident_ID, EnterpriseId: sessionStorage.getItem('LoggedInUser') };
    this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenP2Incidents', fetchData).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {

      if (response.ErrorMessage != '') {

        this.errorMessage = response.ErrorMessage;
      } else {
        this.priority = "p2";
        const IncidentStartTime = response.Result[0].Incident_Start_Time ? new Date(response.Result[0].Incident_Start_Time) : null;
        this.PtwoIncidentForm.patchValue({
          FY: response.Result[0].FY,
          Quarter: response.Result[0].Quarter,
          Month: response.Result[0].Month,
          Incident_ID: response.Result[0].Incident_ID,
          AIR_ID: response.Result[0].AIR_ID,
          ApplicationName: response.Result[0].ApplicationName,
          MD_Name: response.Result[0].MD_Name,
          Incident_Start_Time: IncidentStartTime,
          Root_cause_Categorization: response.Result[0].Root_cause_Categorization,
          Change_Induced: response.Result[0].Change_Induced,
          Outage_Duration: response.Result[0].Outage_Duration,
          SLO_Met: response.Result[0].SLO_Met,
          Service_Tier: response.Result[0].Service_Tier,
          Playbook_Usage: response.Result[0].Playbook_Usage,
          Ticket_Priority_Mismatch: response.Result[0].Ticket_Priority_Mismatch,
          MD_joined: response.Result[0].MD_joined,
          GSM_Engagement_SLO: response.Result[0].GSM_Engagement_SLO,
          Recurring_Issue: response.Result[0].Recurring_Issue,
          Issue_Reported_through_GPC_Bot: response.Result[0].Issue_Reported_through_GPC_Bot,
          Alerts_Received: response.Result[0].Alerts_Received,

          Day: response.Result[0].Day,
          Sub_Category: response.Result[0].Sub_Category,
          Change_Ticket: response.Result[0].Change_Ticket,
          End_User_SLO: response.Result[0].End_User_SLO,
          Alerts_Remarks: response.Result[0].Alerts_Remarks,
          Alerts_Justification: response.Result[0].Alerts_Justification,
          Problem_Ticket: response.Result[0].Problem_Ticket,
          Vendor_Name: response.Result[0].Vendor_Name,
          RCA_Description: response.Result[0].RCA_Description,
          Reasons_for_missed_SLOs: response.Result[0].Reasons_for_missed_SLOs,
        });
        
      }
    });
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result: any) => {
      this.closeResult = `closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
    });
  }
  applicationsEdit(content: any, data: any) {
    
    var fetchData = { AirId: data.AirId, EnterpriseId: sessionStorage.getItem('LoggedInUser') };
    this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenApplications', fetchData).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {

      if (response.ErrorMessage != '') {

        this.errorMessage = response.ErrorMessage;
      } else {
        this.NextGenApplicationsForm.patchValue({
          AirId: response.Result[0].AirId,
          ApplicationName: response.Result[0].ApplicationName,
          ServiceTier: response.Result[0].ServiceTier,
          MD: response.Result[0].MD,
          ServiceOperationLead: response.Result[0].ServiceOperationLead,
          ReliabilityDirector: response.Result[0].ReliabilityDirector,
          GPCManaged: response.Result[0].GPCManaged,
          ServiceArea: response.Result[0].ServiceArea,
          ProjectAIM: response.Result[0].ProjectAIM
        });

      }
    });
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result: any) => {
      this.closeResult = `closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
    });
  }
  preventionsEdit(content: any, data: any) {
    
    var fetchData = { ID: data.ID, EnterpriseId: sessionStorage.getItem('LoggedInUser') };
    this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenPreventions', fetchData).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {

      if (response.ErrorMessage != '') {

        this.errorMessage = response.ErrorMessage;
      } else {
        const DateIdentified = response.Result[0].Date_Identified ? new Date(response.Result[0].Date_Identified) : null;
        const StartDate = response.Result[0].Start_Date ? new Date(response.Result[0].Start_Date) : null;
        this.NextGenPreventionsForm.patchValue({
          ID: response.Result[0].ID,
          FY: response.Result[0].FY,
          Month: response.Result[0].Month,
          Incident_Ticket: response.Result[0].Incident_Ticket,
          Date_Identified: DateIdentified,
          Start_Date: StartDate,
          Duration: response.Result[0].Duration,
          Prevented_By: response.Result[0].Prevented_By,
          AIR_ID: response.Result[0].AIR_ID,
          Application_Name: response.Result[0].Application_Name,
          MD: response.Result[0].MD,
          Category: response.Result[0].Category,
          Refined_Category: response.Result[0].Refined_Category,
          TAO_Comments: response.Result[0].TAO_Comments
        });

      }
    });
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result: any) => {
      this.closeResult = `closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
    });
  }

  findapps() {
    sessionStorage.getItem('LoggedInUser')
    this.common.FindApplicationName("==" + sessionStorage.getItem('LoggedInUser'))
      .subscribe(response => {
       
        console.log(response!.Result);
        this.applications = response!.Result;
        console.log(this.applications.map(app => app.AirId));
        if (response.ErrorMessage != '') {
          this.errorMessage = response.ErrorMessage;
        }
      });
  }

  LoadMdNames(Search: string) {
    this.common.FetchPeopleByName("=" + Search + "=" + sessionStorage.getItem('LoggedInUser'))
      .subscribe(response => {

        
        this.RootOBJ = response.Result;

        this.lotOfPeople = this.RootOBJ.Value;
        this.SearchPeople = this.lotOfPeople;
        this.SearchPeople = this.SearchPeople.map(people => people.EnterpriseId);
        console.log(this.SearchPeople);
        if (response.ErrorMessage != '') {
          this.errorMessage = response.ErrorMessage;
        }
      });
  }


  LoadAirIDs(searchText: String) {
    
    //this.airIds = this.nextGenApplications.map((nextGenApplication) => "AIR: " + nextGenApplication.AirId);
    this.airIds = this.applications.map(app => app.AirId.toString());
  }

  LoadApplicationNames(searchText: String) {

    //this.applicationNames = this.nextGenApplications.map((nextGenApplication) => nextGenApplication.ApplicationName);
    this.airIds = this.applications.map(app => app.Name);
  }

  LoadMDNames(searchText: String) {

    this.mdNames = this.nextGenApplications.map((nextGenApplication) => nextGenApplication.MD);
  }

  selectP1AirIDAdd(e: string) {
    this.P1IncidentForm.get('AIR_ID')?.setValue(e);
  }

  selectP1ApplicationNameAdd(e: any) {
    this.P1IncidentForm.get('ApplicationName')?.setValue(e);
  }

  selectP1MDNameAdd(e: any) {
    this.P1IncidentForm.get('MD_Name')?.setValue(e);
  }

  selectP2AirIDAdd(e: string) {
    this.PtwoIncidentForm.get('AIR_ID')?.setValue(e);
  }

  selectP2ApplicationNameAdd(e: any) {
    this.PtwoIncidentForm.get('ApplicationName')?.setValue(e);
  }

  selectP2MDNameAdd(e: any) {
    this.PtwoIncidentForm.get('MD_Name')?.setValue(e);
  }

  selectApplicationAirIDAdd(e: string) {
    this.NextGenApplicationsForm.get('AirId')?.setValue(e);
  }

  selectAppApplicationNameAdd(e: any) {
    this.NextGenApplicationsForm.get('ApplicationName')?.setValue(e);
  }

  selectApplicationMDNameAdd(e: any) {
    this.NextGenApplicationsForm.get('MD')?.setValue(e);
  }

  selectApplicationLeadNameAdd(e: any) {
    this.NextGenApplicationsForm.get('ServiceOperationLead')?.setValue(e);
  }

  selectApplicationManagerNameAdd(e: any) {
    this.NextGenApplicationsForm.get('ReliabilityDirector')?.setValue(e);
  }

  selectPreventionAirIDAdd(e: string) {
    this.NextGenPreventionsForm.get('AIR_ID')?.setValue(e);
  }

  selectPreventionApplicationNameAdd(e: any) {
    this.NextGenPreventionsForm.get('Application_Name')?.setValue(e);
  }

  selectPreventionMDNameAdd(e: any) {
    this.NextGenPreventionsForm.get('MD')?.setValue(e);
  }

  selectApplicationSearch(e: any) {
    this.errorMessage1 = '';
    if (this.applicationNameAddForm.indexOf(e) === -1) {
      this.applicationNameAddForm.push(e);
      this.errorMessage2 = "";
    }
    else {
      // this.errorNotification("Application Name already exists");
      var message = "Application Name already exists";
      this.errorMessage2 = message;
    }
    //console.log(this.applicationNameAddForm);
  }

  toggleSelectedApplication(app: any): void {
    this.errorMessage1 = '';
    this.errorMessage = '';
    this.errorMessage2 = '';
    const index = this.applicationNameAddForm.indexOf(app);
    // const indexS = this.selectedAirIds.indexOf(app);

    if (index >= 0) {
     
      this.applicationNameAddForm.splice(index, 1);
      this.selectedAirIds.splice(index, 1);

    }
    var tempIds = this.selectedAirIds.join(',');
    this.searchForm.get("ApplicationIds")?.setValue(tempIds);
    if (this.applicationNameAddForm == '') {
      this.searchForm.get("ApplicationIds")?.setValue(null);
      this.fetchPoneData();
      this.fetchPtwoData();
      this.fetchApplicationsData();
      this.fetchPreventionData();
    }
  }

  selectAirIDSearch(e: any) {
    this.errorMessage1 = '';
    if (this.airIDAddForm.indexOf(e) === -1) {
      this.airIDAddForm.push(e);
      this.errorMessage2 = "";
    }
    else {
      // this.errorNotification("Application Name already exists");
      var message = "Air ID already selected";
      this.errorMessage2 = message;
    }
  }

  toggleSelectedAirIDs(app: any): void {
    this.errorMessage1 = '';
    this.errorMessage = '';
    this.errorMessage2 = '';
    const index = this.airIDAddForm.indexOf(app);
    // const indexS = this.selectedAirIds.indexOf(app);

    if (index >= 0) {
      this.airIDAddForm.splice(index, 1);
    
      this.selectedAirIds.splice(index, 1);

    }
    var tempIds = this.selectedAirIds.join(',');
    this.searchForm.get("ApplicationIds")?.setValue(tempIds);
    if (this.applicationNameAddForm == '') {
      this.searchForm.get("ApplicationIds")?.setValue(null);
      this.fetchPoneData();
      this.fetchPtwoData();
      this.fetchApplicationsData();
      this.fetchPreventionData();
    }
  }

  SearchNextGen() {
    this.errorMessage1 = '';
    var app = this.applicationNameAddForm.toString();
    var appId = this.airIDAddForm.toString();
    var incident = this.searchForm.value.IncidentNo;
    let filter = {
      EnterpriseId: sessionStorage.getItem('LoggedInUser'),
      AirId: appId,
      ApplicationName: app,
      IncidentNumber: incident
    }
    console.log(filter);

    if (this.selectedForm == "p1") {

      this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenP1Incidents', filter).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      ).subscribe(response => {

        this.nextGenP1Incidents = response.Result;
        this.loadednextGenP1Incidents = this.nextGenP1Incidents.slice(0, 15);
        this.checkExportData();
        if (this.nextGenP1Incidents.length != 0) {
          this.errorMessage1 = '';
        }
        else {
          var message = "No Data found for the selected search filters";
          
          this.errorMessage1 = message;
        }
        
      });
    }
    else if (this.selectedForm == "p2") {

      this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenP2Incidents', filter).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      ).subscribe(response => {

        this.nextGenP2Incidents = response.Result;
        this.checkExportData();
        if (this.nextGenP2Incidents.length != 0) {
          this.errorMessage1 = '';
        }
        else {
          var message = "No Data found for the selected search filters";

          this.errorMessage1 = message;
        }
      });
    }
    else if (this.selectedForm == "applications") {

      this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenApplications', filter).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      ).subscribe(response => {

        this.nextGenApplications = response.Result;
        this.checkExportData();
        if (this.nextGenApplications.length != 0) {
          this.errorMessage1 = '';
        }
        else {
          var message = "No Data found for the selected search filters";

          this.errorMessage1 = message;
        }
      });
    }
    else if (this.selectedForm == "preventions") {

      this.http.post<NextGenApplications>(this.baseURL + 'api/NextGen/FetchNextGenPreventions', filter).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable)
      ).subscribe(response => {

        this.nextGenPreventions = response.Result;
        this.loadednextGenPreventions = this.nextGenPreventions.slice(0, 15);
        this.checkExportData();
        if (this.nextGenPreventions.length != 0) {
          this.errorMessage1 = '';
        }
        else {
          var message = "No Data found for the selected search filters";

          this.errorMessage1 = message;
        }
      });
    }
  }

  autocompleteclickNG(e: any) {
    $('#applicationFilter').find('input:text').val('');
    $('#airIDFilter').find('input:text').val('');
    
  }

  autocompleteINCclickNG(e: any) {
    $('#IncidentNo').find('input:text').val('');

    this.searchForm.get('IncidentNo')?.setValue('');
    this.SearchNextGen();
  }

  onScroll() {
    if (this.nextGenP1Incidents.length >= this.loadednextGenP1Incidents.length) { 
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    // Simulate fetching data from your data source
    const dataToLoad = this.nextGenP1Incidents.slice(startIndex, endIndex);

    // Append the data to the loadedData array
    this.loadednextGenP1Incidents = this.loadednextGenP1Incidents.concat(dataToLoad);

    // Increment the page index for the next load
      this.pageIndex++;
      console.log(startIndex);
      console.log(endIndex);
      console.log("scrolled");
      console.log(this.loadednextGenP1Incidents.length);
  }
  }

  
  onPreventionScroll() {
    
    if (this.nextGenPreventions.length >= this.loadednextGenPreventions.length) {
      const startIndex = this.preventionPageIndex * this.preventionPageSize;
      const endIndex = startIndex + this.preventionPageSize;

      // Simulate fetching data from your data source
      const dataToLoad = this.nextGenPreventions.slice(startIndex, endIndex);

      // Append the data to the loadedData array
      this.loadednextGenPreventions = this.loadednextGenPreventions.concat(dataToLoad);

      // Increment the page index for the next load
      this.preventionPageIndex++;
      console.log(startIndex);
      console.log(endIndex);
      console.log("scrolled");
      console.log(this.loadednextGenPreventions.length);
    }
  }

  deleteNextGenP1(data: any) {
    let user_info = { EnterpriseId: sessionStorage.getItem('LoggedInUser'), IncidentNumber: data.Incident_ID };
    return this.http.post<OnboardingInterface>(this.baseURL + 'api/NextGen/DeleteNextGenP1Incident', user_info).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage != '') {
        var deleteMessage = "There was a error while deleting!";
        this.successNotification(deleteMessage);
      }
      else {
        var deleteMessage = "Deleted successfully!!";
        this.successNotification(deleteMessage);
        this.fetchPoneData();
      }
    });
  }

  deleteNextGenP2(data: any) {
    let user_info = { EnterpriseId: sessionStorage.getItem('LoggedInUser'), IncidentNumber: data.Incident_ID };
    return this.http.post<OnboardingInterface>(this.baseURL + 'api/NextGen/DeleteNextGenP2Incident', user_info).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage != '') {
        var deleteMessage = "There was a error while deleting!";
        this.successNotification(deleteMessage);
      }
      else {
        var deleteMessage = "Deleted successfully!!";
        this.successNotification(deleteMessage);
        this.fetchPtwoData();
      }
    });
  }

  deleteNextGenApplication(data: any) {
    let user_info = { EnterpriseId: sessionStorage.getItem('LoggedInUser'), AirId: data.AirId };
    return this.http.post<OnboardingInterface>(this.baseURL + 'api/NextGen/DeleteNextGenApplications', user_info).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage != '') {
        var deleteMessage = "There was a error while deleting!";
        this.successNotification(deleteMessage);
      }
      else {
        var deleteMessage = "Deleted successfully!!";
        this.successNotification(deleteMessage);
        this.fetchApplicationsData();
      }
    });
  }

  deleteNextGenPrevention(data: any) {
    let user_info = { EnterpriseId: sessionStorage.getItem('LoggedInUser'), IncidentNumber: data.ID };
    return this.http.post<OnboardingInterface>(this.baseURL + 'api/NextGen/DeleteNextGenPreventions', user_info).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage != '') {
        var deleteMessage = "There was a error while deleting!";
        this.successNotification(deleteMessage);
      }
      else {
        var deleteMessage = "Deleted successfully!!";
        this.successNotification(deleteMessage);
        this.fetchPreventionData();
      }
    });
  }

  updateP1Fields(date: Date) {

    this.P1IncidentForm.patchValue({
      Month: this.monthNames[date.getMonth()], 
      FY: this.calculateFiscalYear(date),
      Quarter: this.calculateQuarter(date),
      Day: this.dayNames[date.getDay()],
    });
    console.log(date.getDay());
  }

  updateP2Fields(date: Date) {

    this.PtwoIncidentForm.patchValue({
      Month: this.monthNames[date.getMonth()],
      FY: this.calculateFiscalYear(date),
      Quarter: this.calculateQuarter(date),
      Day: this.dayNames[date.getDay()],
    });
    console.log(date.getDay());
  }

  updatePreventionFields(date: Date) {

    this.NextGenPreventionsForm.patchValue({
      Month: this.monthNames[date.getMonth()],
      FY: this.calculateFiscalYear(date)
    });
    console.log(date.getDay());
  }

  calculateFiscalYear(date: Date): string {
    // Fiscal year starts from September 1st
    const fiscalYear = date.getMonth() >= 8
      ? `FY${((date.getFullYear() + 1) % 100).toString().padStart(2, '0')}`
      : `FY${(date.getFullYear() % 100).toString().padStart(2, '0')}`;
    return fiscalYear;
  }

  calculateQuarter(date: Date): string {
    const month = date.getMonth();
    if (month >= 8 && month <= 10) {
      return 'Q1';
    } else if (month >= 11 || (month >= 0 && month <= 1)) {
      return 'Q2';
    } else if (month >= 2 && month <= 4) {
      return 'Q3';
    } else {
      return 'Q4';
    }
  }

  checkEditForm(): boolean {
    if (this.modalTitle == "Edit") {
      return true;
    }
    return false;
  }

  getdismissreason(reason: any) {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing esc';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.formDisabled = false;
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  }
}
