import { HttpClient } from '@angular/common/http';
import { DatePipe } from "@angular/common";
import { Component, ElementRef, Input, OnInit, ViewChild, SecurityContext } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { async, catchError, map, throwError } from 'rxjs';
import { ApplicationCommonModel } from '../models/application-common-model';
import { ApplicationCommonResponseModel } from '../models/application-common-response';
import { ApplicationModel } from '../models/application-model';
import { MonitoringInterface } from "../models/monitoring-tracker-model";
import { MonitoringTrackerResponseInterface } from "../models/monitoring-tracker-response-model";
import { UserPeopleResponse } from '../models/user-people-response';

import { MonitoringTrackerServiceService } from '../services/monitoring-tracker-service.service';
import { CommentsModel } from '../models/comments-model';
import { CommentsResponseModel } from '../models/comments-response-model';
import { environment } from '../../environments/environment';
import * as $ from 'jquery';
import { CommonService } from '../services/common.service';
import Swal from 'sweetalert2';
import { AzureProfileDataService } from '../services/azure-profile-data.service';
import { UserResponseModel } from '../models/user-model-response';
import { RootObject } from '../models/root-object';
import { PeopleModel } from '../models/people-model';
import { ThemePalette } from '@angular/material/core';
import { ApplicationUserModel } from '../models/application-user-model';
import { SupportContactModel } from '../models/support-contact-model';
//import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName: string,
  surname: string,
  userPrincipalName: string,
  id: string
}
@Component({
  selector: 'app-monitoring-tracker',
  templateUrl: './monitoring-tracker.component.html',
  styleUrls: ['./monitoring-tracker.component.css']
})
export class MonitoringTrackerComponent implements OnInit {
  baseURL: string = environment.baseUrl;
  profile!: ProfileType;
  username!: string;
  status: any[] = [];
  area: any[] = [];
  issues: any[] = [];
  shift: any[] = [];
  trigger: any[] = [];
  escaltedthrough: any[] = [];
  priority: any[] = [];
  incidentreportedby: any[] = [];
  incidentpriority: any[] = [];
  statusF: any[] = [];
  typeAlert: any[] = [];
  issueCategory: any[] = [];
  alertReceived: any[] = [];
  serviceTier: any[] = [];
  statusP: any[] = [];
  keywordP = 'EnterpriseId';
  errorMessage: string = "";
  //for alasql excel export
  alasql = require('alasql')
  keyword = 'Name';
  @ViewChild('LoggedInUser') LoggedInUser!: ElementRef;
  @ViewChild('txtSearch') txtSearch!: ElementRef;
  Applications: ApplicationCommonModel[] = [];
  AppResponse: ApplicationCommonResponseModel | undefined;
  options: ApplicationModel[] = [];
  myControl = new FormControl();
  public applications: any[] = [];
  public valueOfEdit: string = '';
  public unchangedE: boolean = true;
  public unchanged: boolean = false;
  public editServiceTier: boolean = false;
  public ControlEditAppName = new FormControl();
  Issues: MonitoringInterface[] = [];
  IssuesForPanel: MonitoringInterface[] = [];
  MonitoringTracker: MonitoringInterface[] = [];
  MonitoringTrackerResponse: MonitoringTrackerResponseInterface | undefined;
  Comments: CommentsModel[] = [];
  CommentsResponse: CommentsResponseModel | undefined;
  public SearchPeople: any[] = [];
  public loadedIssues: any[] = [];
  public applicationNameSearchForm: any = [];
  UserResponse: UserResponseModel | undefined;
  UserPeopleResponse: UserPeopleResponse | undefined;
  RootOBJ: any;
  RootObjV: RootObject | undefined;
  lotOfPeople: PeopleModel[] = [];
  public checkStatusComplete: boolean = false;
  public saveButtonStatusCompltete: boolean = false;
  public closeButtonStatusCompltete: boolean = false;
  public ControlItemStartDate = new FormControl();
  public ControlItemEndDate = new FormControl();
  public numberofrca!: number;
  public formTitle: string = '';
  @Input()
  selectedDateTime!: Date;
  @Input() itemStartDate!: Date;
  @Input() itemEndDate!: Date;
  minDate: Date = new Date(2010, 0, 1); // ASA Business Bypass Logic
  dateError: boolean = false;
  txtValue: any;
  errorMessageFilter: string = '';
  ApplicationId: any;
  //date picker
  public ServiceTierdisabled = false;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = true;
  public stepMinutes = [0o0, 15, 30, 45];
  public stepHour = 1;
  public stepMinute = 15;
  public stepSecond = 1;
  public defaultTime = [new Date().getHours(), 0, 0]
  public color: ThemePalette = 'primary';
  onresponse: boolean = true;
  applicationContactResponse: SupportContactModel[] = [];
  onEdit: boolean = false;

  successNotification(reason: string) {
    Swal.fire({
      title: 'Success',
      text: reason,
      icon: 'success',
      confirmButtonText: 'OK'
    });
  }

  //errorNotification(reason:string) {
  //    Swal.fire({
  //                title: 'Error',
  //                text: reason,
  //                icon: 'warning',
  //                confirmButtonText: 'OK'
  //            })
  //}
  public loading = false;

  public panelOpenState: boolean = false;
  closeResult: string = '';
  selectedAirIds: any[] = [];
  public bindMDname = new FormControl();

  searchForm = new FormGroup({
    ApplicationId: new FormControl(), dtStartDate: new FormControl(), dtEndDate: new FormControl(),
    Status: new FormControl(), FocusArea: new FormControl(), ApplicationIds: new FormControl(), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser'))
  });

  commentForm = new FormGroup({
    Id: new FormControl(), Comment: new FormControl(), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser'))
  });
  data = { ApplicationId: 0, Status: 'Active', LoggedInUser: sessionStorage.getItem('LoggedInUser') };

  searchFormData = {
    ApplicationId: 0, dtStartDate: Date, dtEndDate: Date,
    Status: '', FocusArea: '', ApplicationIds: '', LoggedInUser: sessionStorage.getItem('LoggedInUser')
  }
  dataTodelete = { Id: '', IsActivated: false, LoggedInUser: sessionStorage.getItem('LoggedInUser') }

  filter = new FormGroup({
    ApplicationId: new FormControl(), dtStartDate: new FormControl(), dtEndDate: new FormControl(),
    Status: new FormControl(), FocusArea: new FormControl(), ApplicationIds: new FormControl(), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser'))
  });

  addForm = new FormGroup({
    Id: new FormControl(0), ApplicationId: new FormControl(), Issue: new FormControl(),
    InvestigationTrigger: new FormControl(), Focusarea: new FormControl(),
    IssueCategory: new FormControl(), OtherCategory: new FormControl(),
    AlertReceived: new FormControl(), AlertType: new FormControl(), OtherAlert: new FormControl(),
    ServiceTier: new FormControl(), MD: new FormControl('', Validators.required),
    Alertpriority: new FormControl(),
    IncidentPriority: new FormControl(), Status: new FormControl(),
    IncidentReportedBy: new FormControl(),
    EscalationPoint: new FormControl(), Remedyincident: new FormControl(),
    Incidentname: new FormControl(), Name: new FormControl(),
    FocusAreaComment: new FormControl(), Incidentdescription: new FormControl(),
    Shift: new FormControl(), Preventionactivity: new FormControl(),
    Openactionitem: new FormControl(),
    RootCauseOpened: new FormControl(), RootCauseNumber: new FormControl(),
    Comment: new FormControl(), CreatedBy: new FormControl(sessionStorage.getItem('LoggedInUser')), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser')),
    ItemStartDate: new FormControl(), ItemEndDate: new FormControl(), Duration: new FormControl(),
    ModifiedBy: new FormControl, ModifiedDateTime: new FormControl, LastModifiedBy: new FormControl()
  });
  user = sessionStorage.getItem('LoggedInUser');
  commentData = { Id: '', LoggedInUser: sessionStorage.getItem('LoggedInUser') };

  dataDBstatus = { LoggedInUser: sessionStorage.getItem('LoggedInUser') };
  dataGetAppType = { ApplicationId: '', LoggedInUser: sessionStorage.getItem('LoggedInUser') };
  public selectedtypeAlert: string = '';
  public selectedAlertReceived: string = '';
  //for export to excel
  template = {
    headers: true,
    columns: [
      { columnid: 'AirId', title: 'AIRID' },
      { columnid: 'Name', title: 'Application' },
      { columnid: 'Issue', title: 'Issue' },
      { columnid: 'InvestigationTrigger', title: 'InvestigationTrigger' },
      { columnid: 'FocusArea', title: 'FocusArea' },

      { columnid: 'IssueCategory', title: 'PreventionCategory ' },    //edited by Meena:)
      { columnid: 'OtherCategory', title: 'OtherPreventionCategory' },  //edited by Meena:)

      { columnid: 'AlertReceived', title: 'Alert/Episode Received?' },
      { columnid: 'AlertType', title: 'AlertType' },
      { columnid: 'OtherAlert', title: 'OtherAlert' },
      { columnid: 'ServiceTier', title: 'ServiceTier' },
      { columnid: 'MD', title: 'MD' },
      { columnid: 'AlertPriority', title: 'AlertPriority' },
      { columnid: 'EscalationPoint', title: 'Escalation Source' },
      { columnid: 'RemedyIncident', title: 'RemedyIncident' },
      { columnid: 'IncidentName', title: 'IncidentName' },
      { columnid: 'IncidentDescription', title: 'IncidentDescription' },
      { columnid: 'PreventionActivity', title: 'PreventionActivity' },
      { columnid: 'IncidentPriority', title: 'IncidentPriority' },
      { columnid: 'Status', title: 'Status' },
      { columnid: 'IncidentReportedBy', title: 'IncidentReportedBy' },
      { columnid: 'OpenActionItem', title: 'Open Action Item' },
      { columnid: 'RcaOpenedForReport', title: 'RootCauseOpened' },
      { columnid: 'RootCauseNumber', title: 'Number of RCA' },
      { columnid: 'Shift', title: 'Shift' },
      { columnid: 'ItemStartDateForReport', title: 'Start Date (GMT)' },
      { columnid: 'ItemEndDateForReport', title: 'End Date (GMT)' },
      { columnid: 'Duration', title: 'Duration' },
      { columnid: 'OpenedBy', title: 'CreatedBy' },
      { columnid: 'CreateDatetimeForReport', title: 'CreateDateTime' },
      { columnid: 'LastModifiedBy', title: 'Last ModifiedBy' },
      { columnid: 'ModifiedDatetimeForReport', title: 'Last ModifiedDateTime' },
      { columnid: 'Comment', title: 'Comment' }

    ]
  };

  preventivetemplate = {
    headers: true,
    columns: [
      { columnid: 'AirId', title: 'AIRID' },
      { columnid: 'Name', title: 'Application Name' },
      { columnid: 'ItemStartDateForReport', title: 'Start Date (GMT)' },
      { columnid: 'RemedyIncident', title: 'Incident Ticket' },
      { columnid: 'IncidentDescription', title: 'Incident Description' },
      { columnid: 'PreventionActivity', title: 'Prevention Activity' },
      { columnid: 'MD', title: 'MD' },
      { columnid: 'ItemEndDateForReport', title: 'End Date (GMT)' },
      { columnid: 'Duration', title: 'Duration' },

      { columnid: 'IssueCategory', title: 'Prevention Category ' },  //edited by Meena :)

      { columnid: 'AlertReceived', title: 'Alert/Episode Received?' },
      { columnid: 'AlertType', title: 'Type of Alert' },
      { columnid: 'IncidentReportedBy', title: 'Incident Reported by?' },
      { columnid: 'ServiceTier', title: 'Service Tier' }
    ]
  };

  constructor(private http: HttpClient, private modalService: NgbModal, private common: CommonService, private monitoringService: MonitoringTrackerServiceService, private formBuilder: FormBuilder, private get_profile_service: AzureProfileDataService, private datePipe: DatePipe) {
    this.status = this.LoadStatus();
    this.area = this.LoadFocusArea();
    this.issues = this.LoadIssue();
    this.shift = this.LoadShift();
    this.trigger = this.LoadTrigger();
    this.area = this.LoadArea();
    this.escaltedthrough = this.LoadEscalatedthrough();
    this.incidentpriority = this.LoadIncidentpriority();
    this.incidentreportedby = this.LoadIncidentreportedby();
    this.statusF = this.LoadStatusForm(); //for add form status dropdown
    //this.typeAlert = this.LoadTypeAlert();
    this.issueCategory = this.LoadIssueCategory();
    this.alertReceived = this.LoadAlertReceived();
    this.serviceTier = this.LoadServiceTier();
    this.priority = this.LoadPriority();
    this.statusP = this.LoadifStatus(); //if focus area is "Prevent"
    this.monitoringService.addIssueToBeMonitored(this.addForm.value);
    this.monitoringService.SaveComments(this.commentForm.value);

  }

  async ngOnInit(): Promise<void> {
    function delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    this.getProfile();
    await delay(2000);
    this.FilterTracker();
  }

  nullcheckApp(e: any) {
    //if (e.target.value === '') {
    //  this.addForm.get("ApplicationId")?.setValue(e.Id);
    //}
  }

  onTextChange(value: any) {
    this.ComputeDuration(value);
    //this.txtValue = value;
    //if(this.txtValue == '')
    //{
    ////this.message="Textbox is empty !!!";
    //this.addForm.get('ItemStartDate')?.setValue('');
    //this.addForm.get('ItemEndDate')?.setValue('');

    //}

  }
  //StatusChange(){
    //if (this.addForm.value.Status=="complete" && this.addForm.value.Focusarea =="Prevent" ){
      //this.addForm.controls['ServiceTier'].disable();
   // }
  //}
  getProfile() {
    this.get_profile_service.getUserProfile().subscribe(profileInfo => {
      this.profile = profileInfo;
      this.profile = this.profile;
      this.username = this.profile.userPrincipalName.substring(0, this.profile.userPrincipalName.indexOf("@"));
      //this.searchForm.get("LoggedInUser")?.setValue(this.username);
      //this.addForm.get("LoggedInUser")?.setValue(this.username);
      //this.data.LoggedInUser = this.username;
      //this.searchFormData.LoggedInUser = this.username;
      //this.commentData.LoggedInUser = this.username;
      //this.commentForm.get("LoggedInUser")?.setValue(this.username);
      //this.dataDBstatus.LoggedInUser = this.username;
      //this.dataTodelete.LoggedInUser = this.username;
      //this.user = sessionStorage.getItem('LoggedInUser');
    });

    return this.username;

  }

  LoadStatus() {
    return [
      { statusId: "1", value: "Planned" },
      { statusId: "2", value: "In Progress" },
      { statusId: "3", value: "Completed" },
      { statusId: "4", value: "Cancelled/Invalid" },
      { statusId: "5", value: "Active" }];
  }
  //----------------  DSR017 changes -------------------
  //  { statusId: "7", value: "Failed to Prevent" },
  //{ statusId: "2", value: "Delayed" },
  //{ statusId: "3", value: "At Risk" },

  LoadFocusArea() {
    return [

      { areaId: "1", value: "Prevent" },
      { areaId: "2", value: "Resolve" },
      { areaId: "3", value: "Govern" }];
  }
  //----------------  DSR017 changes -------------------
  //{ areaId: "1", value: "Monitor" },
  //{ areaId: "4", value: "Improve" },
  LoadIssue() {
    return [
      { issueId: "1", value: "Imminent Issue" },
      { issueId: "2", value: "On going issue" },
      { issueId: "3", value: "Watch Item" }];
  }
  //----------------  DSR017 chnages ------------------->
  LoadShift() {
    return [
      { issueId: "1", value: "ATCP" },
      { issueId: "2", value: "ATCI" },
      { issueId: "3", value: "ATCA" }];
  }

  LoadTrigger() {
    return [

      { triggerId: "1", value: "Just in Time/Monitoring" },
      { triggerId: "2", value: "Continual Service Improvement" }];
  }
  //----------------  DSR017 chnages -------------------
  //{ triggerId: "1", value: "Hardware/Software Upgrades" },
  //{ triggerId: "2", value: "Compliance(Patching & Configuration)" },

  LoadArea() {
    return [
      { areaId: "1", value: "Prevent" },
      { areaId: "2", value: "Resolve" },
      { areaId: "3", value: "Govern" }];
  }
  LoadEscalatedthrough() {
    return [{
      choiceid: 1,
      value: "Service Now"
    }, {
      choiceid: 2,
      value: "Email"
    }, {
      choiceid: 3,
      value: "MS Teams"
    }]
  }

  LoadPriority() {
    return [
      { priorityId: "1", value: "P1" },
      { priorityId: "2", value: "P2" },
      { priorityId: "3", value: "P3" },
      { priorityId: "4", value: "P4" },
      { priorityId: "5", value: "NA" }];
  }
  //----------------DSR017 changes--------------------->
  LoadIncidentreportedby() {
    return [
      { reportedbyId: "1", value: "Application Team" },
      { reportedbyId: "2", value: "GSM" },
      { reportedbyId: "3", value: "Users" },
      { reportedbyId: "4", value: "GPC Custom Alerts Monitoring" },
      { reportedbyId: "5", value: "Datadog Alert Monitoring" },
      { reportedbyId: "6", value: "Infrastructure Team/Other ACC team" },
      { reportedbyId: "7", value: "GPC Splunk Dashboard" },
      { reportedbyId: "8", value: "GPC Datadog Dashboard" },
      { reportedbyId: "9", value: "Other Custom Dashboards" },
      { reportedbyId: "10", value: "Others" }];
      
  }
  // { reportedbyId: "7", value: "Other Analytics Tool" },Other Custom Dashboards

  //----------------DSR017 changes--------------------->
  LoadIncidentpriority() {
    return [
      { incidentpriorityId: "1", value: "1-Critical" },
      { incidentpriorityId: "2", value: "2-High" },
      { incidentpriorityId: "3", value: "3-Medium" },
      { incidentpriorityd: "4", value: "NA" }];
  }
  // { incidentpriorityId: "4", value: "4-Low" },
  LoadStatusForm() {
    return [
      { statusId: "1", value: "Planned" },
      { statusId: "2", value: "In Progress" },
      { statusId: "3", value: "Completed" },
      { statusId: "4", value: "Cancelled/Invalid" },
      ];
  }

  LoadTypeAlert() {
    //----------------DSR017 changes--------------------->
    return [
      { typeAlertId: "1", value: "Datadog - Synthetic" },
      { typeAlertId: "2", value: "Datadog - Infrastructure" },
      { typeAlertId: "3", value: "Datadog - Application Performance" },
      { typeAlertId: "4", value: "Splunk - xMatter / vMware" },
      { typeAlertId: "5", value: "Splunk Observability-Infrastructure" },
      { typeAlertId: "6", value: "Datadog-Custom" },
      { typeAlertId: "7", value: "Custom alerts / Others" }];
  }
  //----------------DSR017 changes--------------------->
  LoadIssueCategory() {
    return [
      { issueCategoryId: "1", value: "Disk Space Usage" },
      { issueCategoryId: "2", value: "Cluster Failure" },
      { issueCategoryId: "3", value: "Memory Usage" },
      { issueCategoryId: "4", value: "CPU Usage" },
      { issueCategoryId: "5", value: "Job Failure" },
      { issueCategoryId: "6", value: "IIS/APP Pool" },
      { issueCategoryId: "8", value: "Infrastructure Issue" },
      { issueCategoryId: "9", value: "Performance/Service Issue" },
      { issueCategoryId: "11", value: "Identity and Access Management" },
      { issueCategoryId: "13", value: "Change" },
      { issueCategoryId: "14", value: "Data Issue" },
      { issueCategoryId: "15", value: "Others" }
    ];
  }

  LoadAlertReceived() {
    return [
      { alertReceivedId: "1", value: "Yes" },
      { alertReceivedId: "2", value: "No" }];
  }

  LoadServiceTier() {
    return [
      { serviceTierId: "1", value: "AO" },
      { serviceTierId: "2", value: "NAO" },
      { serviceTierId: "3", value: "Standard" }];
  }

  LoadifStatus() {
    return [
      { statusId: "1", value: "In Progress" },
      { statusId: "2", value: "Completed" },
      { statusId: "3", value: "Cancelled/Invalid" }];
  }
  //----------------DSR017 changes--------------------->
  //{ statusId: "4", value: "Failed to Prevent" }

  LoadAlertReceivedChanged() {
    var val = $("#selectedAlertReceived").val();
    if (val == "No") {
      this.typeAlert = [
        { typeAlertId: "1", value: "N/A" }];
      this.selectedtypeAlert = "N/A";
      this.addForm.get('AlertType')?.setValue('N/A');
    } else {
      this.typeAlert = [
        { typeAlertId: " ", value: "" },
        { typeAlertId: "1", value: "Datadog - Synthetic" },
        { typeAlertId: "2", value: "Datadog - Infrastructure" },
        { typeAlertId: "3", value: "Datadog - Application Performance" },
        { typeAlertId: "4", value: "Splunk - xMatter / vMware" },
        { typeAlertId: "5", value: "Splunk Observability-Infrastructure" },
        { typeAlertId: "6", value: "Datadog-Custom" },
        { typeAlertId: "7", value: "Custom alerts / Others" }];
      this.selectedtypeAlert = " ";
    }
  }
  LoadPreventYes() {
    this.selectedAlertReceived = "Yes";
    this.addForm.get('AlertReceived')?.setValue('Yes');
    this.typeAlert = [
      { typeAlertId: "1", value: "Datadog - Synthetic" },
      { typeAlertId: "2", value: "Datadog - Infrastructure" },
      { typeAlertId: "3", value: "Datadog - Application Performance" },
      { typeAlertId: "4", value: "Splunk - xMatter / vMware" },
      { typeAlertId: "5", value: "Splunk Observability-Infrastructure" },
      { typeAlertId: "6", value: "Datadog-Custom" },
      { typeAlertId: "7", value: "Custom alerts / Others" }];
    this.selectedtypeAlert = " ";
  }

  ComputeDuration($event: any) {

    let startDate = new Date(this.addForm.get('ItemStartDate')?.value);
    if (startDate < this.minDate) {
      this.dateError = true;
    } else {
      this.dateError = false;
    }
    let endDate = new Date(this.addForm.get('ItemEndDate')?.value);
     if (endDate < this.minDate) {
      this.dateError = true;
    } else {
      this.dateError = false;
    }
    if (startDate == null || endDate == null) {
      this.addForm.get('Duration')?.setValue('00:00:00');
    } else {
      let diffMs = ((+endDate) - (+startDate)); // milliseconds
      let diffDays = Math.floor(diffMs / 86400000); // days
      let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      //var mystring = "-16:-1:-1";
      //let days = diffDays.toString().replace('-','0');
      //let mins:string = diffHrs.toString().replace('-','0');
      //let hrs:string = diffMins.toString().replace('-','0');

      let days: any = (diffDays < 10) ? "0" + diffDays : diffDays;
      let hours: any = (diffHrs < 10) ? "0" + diffHrs : diffHrs;
      let mins: any = (diffMins < 10) ? "0" + diffMins : diffMins;


      let duration: string = days + ":" + hours + ":" + mins;
      this.addForm.get('Duration')?.setValue(duration);
    }


  }
  dateReset() {
    this.ControlItemStartDate = new FormControl();
    this.ControlItemEndDate = new FormControl();
    this.errorMessage = '';
    this.errorMessageFilter = '';
  }
  FilterTracker() {
    this.dateReset();
    this.searchForm.get('Status')?.setValue('Active');
    return this.http.post<MonitoringInterface>(this.baseURL + 'api/MonitoringTracker/FilterTracker', this.searchForm.value).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      //this.loading = false;
      this.MonitoringTrackerResponse = response;
      this.MonitoringTracker = this.MonitoringTrackerResponse!.Result;

      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
        // this.errorNotification(response.ErrorMessage);
      } else if (this.MonitoringTracker == null) {
        var message = "No data found";
        this.errorMessage = message;
        // this.errorNotification(message);
      }
    });
  }

  LoadApplications() {
    //let user = this.username;
    //if(this.applicationNameSearchForm == []){
    //    this.selectedAirIds = [];
    //    this.searchForm.get("ApplicationId")?.setValue(null);
    //    this.searchForm.get("ApplicationIds")?.setValue(null);
    //}
    this.common.FindApplicationName("=" + this.txtSearch?.nativeElement.value + "=" + sessionStorage.getItem('LoggedInUser'))
      .subscribe(response => {
        this.AppResponse = response;
        this.options = this.AppResponse!.Result;
        if (response.ErrorMessage != '') {
          this.errorMessage = response.ErrorMessage;
          // this.errorNotification(response.ErrorMessage);
        }
      });
  }
  autocompleteclickMT(e: any) {
    $('#applicationFilter').find('input:text').val('');
  }
  LoadApplication(Search: string) {
    this.loading = true;
    this.common.FindApplicationName("=" + Search + "=" + sessionStorage.getItem('LoggedInUser'))
      .subscribe(response => {
        this.loading = false;
        this.AppResponse = response;
        this.options = this.AppResponse!.Result;
        this.applications = this.options;
        this.applications.map(app => app.Name = `${app.AirId} -- ${app.Name}`);
        if (response.ErrorMessage != '') {
          this.errorMessage = response.ErrorMessage;
          //  this.errorNotification(response.ErrorMessage);
        }
      });
    //return this.options;
  }

  LoadPeopleByName(Search: string) {
    if (Search == "") {
      this.addForm.get("MD")?.setValue('');
    } else {
      this.common.FetchPeopleByName("=" + Search + "=" + sessionStorage.getItem('LoggedInUser'))
        .subscribe(response => {
          this.UserPeopleResponse = response;
          this.RootOBJ = this.UserPeopleResponse!.Result;
          this.lotOfPeople = this.RootOBJ!.Value;
          this.SearchPeople = this.lotOfPeople;
          this.SearchPeople.map(people => people.Name = `${people.EnterpriseId} -- ${people?.StandardJobDescription}`);
        });
    }
  }
  selectPeopleEvent(p: any) {
    this.addForm.get("MD")?.setValue(p.EnterpriseId);
  }

  getSelectedAirIds(e: any) {
    this.selectedAirIds.push(e.Id);
    this.addForm.get("ApplicationId")?.setValue(e.Id);
    this.findAIRdetails(e.AirId);
    return this.selectedAirIds;
  }
  selectEventSearchMT(e: any) {
    this.selectedAirIds.indexOf(e.Id) === -1 ? this.selectedAirIds.push(e.Id) : alert("This item already selected");
    //this.selectedAirIds.push(e.Id);
    this.applicationNameSearchForm.indexOf(e.Name) === -1 ? this.applicationNameSearchForm.push(e.Name) : alert("This item already exists");
    var tempIds = this.selectedAirIds.join(',');
    this.searchForm.get("ApplicationIds")?.setValue(tempIds);
    return this.selectedAirIds;
  }
  //to remove the selected app name from the array : applicationName
  toggleSelectedAirIds(app: any): void {
    const indexName = this.applicationNameSearchForm.indexOf(app);

    if (indexName >= 0) {
      this.applicationNameSearchForm.splice(indexName, 1);
      this.selectedAirIds.splice(indexName, 1);
    }
    var tempIds = this.selectedAirIds.join(',');
    this.searchForm.get("ApplicationIds")?.setValue(tempIds);
  }
  FilterMTracker() {
    this.dateReset();
    this.loading = true;
    this.checkStatusComplete = false;
    this.saveButtonStatusCompltete = false;
    this.closeButtonStatusCompltete = false;
    if (this.applicationNameSearchForm.length < 1) {
      this.selectedAirIds = [];
      this.searchForm.get("ApplicationId")?.setValue(null);
      this.searchForm.get("ApplicationIds")?.setValue(null);
    }
    return this.http.post<MonitoringInterface>(this.baseURL + 'api/MonitoringTracker/FilterTracker', this.searchForm.value).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.MonitoringTrackerResponse = response;
      this.MonitoringTracker = this.MonitoringTrackerResponse!.Result;

      //var isAdmin: ApplicationUserModel = this.MonitoringTracker[0].ModifiedBy;
      if (response.ErrorMessage != '') {
        this.errorMessageFilter = response.ErrorMessage;
        // this.errorNotification(response.ErrorMessage);
      } else if (this.MonitoringTracker == null) {
        var message = "No Data found for the selected search filters";
        this.errorMessageFilter = message;
        // this.errorNotification(message);
      }
    });
  }

  LoadIssues(app: any) {
    this.panelOpenState = false;

    $('#' + app.AirId).attr('expanded', 'true');
    this.loading = true;
    //let startdate = null;
    //if (this.searchForm.value.dtStartDate != null) {
    //  startdate = new Date(this.searchForm.get('dtStartDate')?.value.getTime() - this.searchForm.get('dtStartDate')?.value.getTimezoneOffset() * 60000);
    //}
    //let enddate = null;
    //if (this.searchForm.value.dtEndDate != null) {
    //  enddate = new Date(this.searchForm.get('dtEndDate')?.value.getTime() - this.searchForm.get('dtEndDate')?.value.getTimezoneOffset() * 60000);
    //}
    var searchFormDataLoad = {
      ApplicationId: app.ApplicationId, dtStartDate: this.searchForm.value.dtStartDate, dtEndDate: this.searchForm.value.dtEndDate,
      Status: $('#statusFilter option:selected').text(), FocusArea: $('#focusareaFilter option:selected').text(), ApplicationIds: app.ApplicationIds, LoggedInUser: sessionStorage.getItem('LoggedInUser')
    }

    //    this.searchFormData.ApplicationId = app.ApplicationId;
    //    this.searchFormData.ApplicationIds = app.ApplicationIds;
    //this.searchFormData.dtStartDate = startdate;
    //this.searchFormData.dtEndDate = enddate;
    //this.searchFormData.FocusArea = $('#focusareaFilter option:selected').text();
    //this.searchFormData.Status = $('#statusFilter option:selected').text();
    //this.loadIssueData.Status = $('#statusFilter option:selected').text();
    //console.log("load",this.searchFormData);
    return this.http.post<MonitoringInterface>(this.baseURL + 'api/MonitoringTracker/FetchTrackerDetails', searchFormDataLoad).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.MonitoringTrackerResponse = response;
      this.ApplicationId = app.ApplicationId;
      this.IssuesForPanel = this.MonitoringTrackerResponse!.Result;
      this.IssuesForPanel.forEach((element) => {
        //return this._sanitizer.sanitize(SecurityContext.HTML, element.IncidentDescription);
      });
      //----------------DSR017 changes--------------------->
      this.IssuesForPanel.sort((a, b) => {
        return +new Date(b.ModifiedDateTime) - +new Date(a.ModifiedDateTime);
      });

      if (response.ErrorMessage != '') {
        //No items exists currently.
        this.errorMessage = response.ErrorMessage;
        // this.errorNotification(response.ErrorMessage);
      }
    });
  }


  openAddForm(content: any) {
    this.formTitle = "Add New";
    this.addForm.reset();
    this.dateReset();
    this.unchanged = false;
    this.unchangedE = true;
    this.checkStatusComplete = false;
    this.saveButtonStatusCompltete = false;
    //this.addForm.get("MD")?.setValue('');
    //$('#myModalLabel').text('Add New');
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

   
  }

  OnIssue() {
    if (this.addForm.value.Issue == 'Watch Item') {
      this.addForm.get('InvestigationTrigger')?.setValue("Continual Service Improvement");
    }
    else if (this.addForm.value.Issue == 'On going issue' || this.addForm.value.Issue == 'Imminent Issue') {
      this.addForm.get('InvestigationTrigger')?.setValue("Just in Time/Monitoring");
    }
  }

  Edit(content: any, issue: any) {
    this.onEdit = true;
    this.errorMessage= '';
    //console.log(issue);
    this.formTitle = "Edit";
    this.checkStatusComplete = false;
    this.saveButtonStatusCompltete = false;
    this.closeButtonStatusCompltete = false;
    $('#myModalLabel').text('Edit');
    var LoggedInUserID = sessionStorage.getItem('LoggedInUser');
    var data = { Id: issue.Id, LoggedInUser: LoggedInUserID };

    return this.http.post<MonitoringInterface>(this.baseURL + 'api/MonitoringTracker/FetchTrackerDetails', data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.MonitoringTrackerResponse = response;
      this.Issues = this.MonitoringTrackerResponse!.Result;
      //$('#selectEventAdd').find('input').val(this.Issues[0].Name).attr('readonly', 'true').off('click');
      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
        //this.errorNotification(response.ErrorMessage);
      } else {

        //this.editServiceTier = true;
        var LastModifiedDate = this.datePipe.transform(this.Issues[0].ModifiedDateTime, "dd MMM yyyy");
        (this.Issues[0].ItemStartDate != null) ? this.ControlItemStartDate = new FormControl(new Date(this.Issues[0].ItemStartDate)) : this.addForm.get('ItemStartDate')?.setValue(this.Issues[0].ItemStartDate);
        (this.Issues[0].ItemEndDate != null) ? this.ControlItemEndDate = new FormControl(new Date(this.Issues[0].ItemEndDate)) : this.addForm.get('ItemEndDate')?.setValue(this.Issues[0].ItemEndDate);
        $('#appMonitoringTracker').find('input').val(this.Issues[0].Name).prop('disabled', true);
        this.bindMDname = new FormControl(this.Issues[0].MD);
        //this.ControlEditAppName = new FormControl(new Date(this.Issues[0].Name))
        this.unchanged = true;
        this.unchangedE = false;

        if (this.Issues[0].AlertReceived == "No") {
          this.typeAlert = [
            { typeAlertId: "1", value: "N/A" }];
          this.selectedtypeAlert = "N/A";
          this.addForm.get('AlertType')?.setValue('N/A');
        } else {
          this.typeAlert = [
            { typeAlertId: "1", value: "Datadog - Synthetic" },
            { typeAlertId: "2", value: "Datadog - Infrastructure" },
            { typeAlertId: "3", value: "Datadog - Application Performance" },
            { typeAlertId: "4", value: "Splunk - xMatter / vMware" },
            { typeAlertId: "5", value: "Custom alerts / Others" }];
          // -------------DSR017----------------------
          // { typeAlertId: "5", value: "Custom alerts / Others - Please specify" }];
          this.addForm.get('AlertType')?.setValue(this.Issues[0].AlertType);
        }

        //Fix for filter out HTML tags from the description input field
        var incDes = this.Issues[0].IncidentDescription;
        function stripHTML(incDes: any) {
          var regex = /(<([^>]+)>)/ig;
          return incDes.replace(regex, "");
        }
        var incDESfiltered = stripHTML(incDes);
        //if (incDes != null) {
        //var div = document.createElement("div");
        //div.innerHTML = incDes;
        //var incDes = div.textContent || div.innerText || "";
        //}
        this.addForm.patchValue(
          {
            Id: this.Issues[0].Id, ApplicationId: this.Issues[0].ApplicationId, Issue: this.Issues[0].Issue,
            InvestigationTrigger: this.Issues[0].InvestigationTrigger, Focusarea: this.Issues[0].FocusArea,
            IssueCategory: this.Issues[0].IssueCategory, OtherCategory: this.Issues[0].OtherCategory,
            AlertReceived: this.Issues[0].AlertReceived, AlertType: this.Issues[0].AlertType, OtherAlert: this.Issues[0].OtherAlert,
            ServiceTier: this.Issues[0].ServiceTier, MD: this.Issues[0].MD,
            Alertpriority: this.Issues[0].AlertPriority,
            IncidentPriority: this.Issues[0].IncidentPriority, Status: this.Issues[0].Status,
            IncidentReportedBy: this.Issues[0].IncidentReportedBy,
            EscalationPoint: this.Issues[0].EscalationPoint, Remedyincident: this.Issues[0].RemedyIncident,
            Incidentname: this.Issues[0].IncidentName, Name: this.Issues[0].Name,
            FocusAreaComment: this.Issues[0].FocusAreaComment, Incidentdescription: incDESfiltered,
            Shift: this.Issues[0].Shift, Preventionactivity: this.Issues[0].PreventionActivity,
            Openactionitem: this.Issues[0].OpenActionItem,
            RootCauseOpened: this.Issues[0].RootCauseOpened, RootCauseNumber: this.Issues[0].RootCauseNumber,
            Comment: this.Issues[0].Comment, CreatedBy: this.Issues[0].OpenedBy,
            Duration: this.Issues[0].Duration, ItemStartDate: new Date(this.Issues[0].ItemStartDate), ItemEndDate: new Date(this.Issues[0].ItemEndDate),
            ModifiedBy: sessionStorage.getItem('LoggedInUser'), LastModifiedBy: this.Issues[0].LastModifiedBy,
            ModifiedDateTime: LastModifiedDate, LoggedInUser: sessionStorage.getItem('LoggedInUser')
          });
        //if (this.Issues[0].FocusArea == "Prevent") {
        //  this.addForm.controls['ServiceTier'].disable();
        //} else {
        //  this.addForm.controls['ServiceTier'].enable();
        //}
        if (issue.Status == 'Completed' && issue.MTAppUser?.IsSpecialUser == false) {
          this.checkStatusComplete = true;
          this.saveButtonStatusCompltete = true;
          this.closeButtonStatusCompltete = false;
          //$("#formclosebtn").prop("disabled", false);
          $("#formcrossbtn").prop("disabled", false); //commented
        }
        else if (issue.Status == 'Completed' && issue.MTAppUser?.IsSpecialUser == true && issue.FocusArea == "Prevent") {
          //$("#AddNewForm :input").prop("disabled", false);
          this.checkStatusComplete = false;
          $("#appMonitoringTracker").prop("disabled", true);
          $("#ddlSerTier").prop("disabled", true);
          $('#txtDuration').prop('disabled', true);
          //$("#formclosebtn").prop("disabled", false);
          //$("#formcrossbtn").prop("disabled", false);
          this.saveButtonStatusCompltete = false; //true
          this.closeButtonStatusCompltete = false;
        } else if (issue.Status == 'Completed' && issue.MTAppUser?.IsSpecialUser == true && issue.FocusArea != "Prevent") {
          //$("#AddNewForm :input").prop("disabled", true);
          this.checkStatusComplete = true;
          this.saveButtonStatusCompltete = true;
          this.closeButtonStatusCompltete = false;
          $("#formcrossbtn").prop("disabled", false); //not here
        }
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    });
  }
  //------- To check HTML injection -------------------
  onDescriptionChange(e: any) {
    var description = this.addForm.get('Incidentdescription')?.value.toLowerCase();
    var position = description.indexOf("document.write");
    var positionJquery = description.search("document.prompt");
    var positionJquery_1 = description.search("jquery");
    var positionJquery_2 = description.search("prompt");
    var positionJquery_3 = description.search("href");
    var positionJquery_4 = description.search("onerror");
     var positionJquery_5 = description.search("src");

    if (position > -1 || positionJquery > -1 || positionJquery_1 > -1 || positionJquery_2 > -1 || positionJquery_3 > -1 || positionJquery_4 > -1 || positionJquery_5 > -1) {

      this.errorMessage = 'Scripting keywords like "document. / onerror / onclick / prompt / href / src" are restricted.';

      this.addForm.get('Incidentdescription')?.value.includes('document.write') ? this.addForm.get('Incidentdescription')?.setValue(description.replace('document.write', '')) : setTimeout(() => { this.errorMessage = ''; }, 5000);
      this.addForm.get('Incidentdescription')?.value.includes('document.prompt') ? this.addForm.get('Incidentdescription')?.setValue(description.replace('document.prompt', '')) : setTimeout(() => { this.errorMessage = ''; }, 5000);
      this.addForm.get('Incidentdescription')?.value.includes('jquery') ? this.addForm.get('Incidentdescription')?.setValue(description.replace('jquery', '')) : setTimeout(() => { this.errorMessage = ''; }, 5000);
      this.addForm.get('Incidentdescription')?.value.includes('prompt') ? this.addForm.get('Incidentdescription')?.setValue(description.replace('prompt', '')) : setTimeout(() => { this.errorMessage = ''; }, 5000);
      this.addForm.get('Incidentdescription')?.value.includes('href') ? this.addForm.get('Incidentdescription')?.setValue(description.replace('href', '')) : setTimeout(() => { this.errorMessage = ''; }, 5000);
      this.addForm.get('Incidentdescription')?.value.includes('onerror') ? this.addForm.get('Incidentdescription')?.setValue(description.replace('onerror', '')) : setTimeout(() => { this.errorMessage = ''; }, 5000);
      this.addForm.get('Incidentdescription')?.value.includes('src') ? this.addForm.get('Incidentdescription')?.setValue(description.replace('src', '')) : setTimeout(() => { this.errorMessage = ''; }, 5000);
    }
  }
  SubmitForm() {
    this.loading = true;
    //----------------  DSR017 chnages -------------------
    //this.numberofrca = this.addForm.value.RootCauseNumber;
    //if (isNaN(this.numberofrca)) {
    //  var errorMessage = "Only Integer Values allowed for Number of RCA";
    //  this.errorMessage = errorMessage;
    //}
    //else {
    //  if (((this.numberofrca) % 1) != 0) {
    //    var errorMessage = "Decimal Values not allowed for Number of RCA";
    //    this.errorMessage = errorMessage;
    //  }
    //  else {
    var issuCate = "";
    var issuCateOth = "";
    var alrtRecevd = "";
    var typAlrt = "";
    var typAlrtOth = "";
    var serTier = "";
    var itemStartDate = null;
    var itemEndDate = null;
    var duration = null;

    var description = this.addForm.get('Incidentdescription')?.value;

    //var position = description.search("document.write");
    //var positionJquery = description.search("document.prompt");
    //var positionJquery_1 = description.search("jQuery");
    //var positionJquery_2 = description.search("prompt");
    //var positionJquery_3 = description.search("href");
    //var positionJquery_4 = description.search("onerror");

    //var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/;
    //format.test(description) == true ||
    //if (format.test(description) == true || position > -1 || positionJquery > -1 || positionJquery_1 > -1 || positionJquery_2 > -1 || positionJquery_3 > -1 || positionJquery_4 > -1) {
    // console.log(position);
    // this.errorMessage = "Entered description is not allowed, please remove special chareceters and links from the description.";

    // } else {

    if (this.addForm.value.Focusarea == "Prevent") {
      issuCate = this.addForm.get('IssueCategory')?.value;
      if (issuCate == "Others") {
        issuCateOth = this.addForm.get('OtherCategory')?.value;
      }
      else {
        issuCateOth = this.addForm.get('OtherCategory')?.value;
      }
      alrtRecevd = this.addForm.get('AlertReceived')?.value;
      if (this.addForm.value.AlertReceived == 'No') {
        this.addForm.get('AlertType')?.setValue("N/A");
      }
      typAlrt = this.addForm.get('AlertType')?.value;
      if (typAlrt == "Custom alerts / Others")//to show other category textbox
      {
        typAlrtOth = this.addForm.get('OtherAlert')?.value;
      }
      else {
        typAlrtOth = this.addForm.get('OtherAlert')?.value;
      }
      //enabling it here to avoid edit data while focus area is prevent
      this.addForm.controls['ServiceTier'].enable();
      serTier = this.addForm.get('ServiceTier')?.value;
      // ASA scan html inject check




      // Prevention Fields for Item Start and End Date with duration
      if (this.addForm.get('ItemStartDate')?.value == "" || this.addForm.get('ItemStartDate')?.value == null) {
        itemStartDate = null;
      }
      else {
        if (this.addForm.get('ItemStartDate')?.value != null)
          itemStartDate = new Date(this.addForm.get('ItemStartDate')?.value.getTime() - this.addForm.get('ItemStartDate')?.value.getTimezoneOffset() * 60000);
      }


      if (this.addForm.get('ItemEndDate')?.value == "" || this.addForm.get('ItemEndDate')?.value == null || this.addForm.get('Status')?.value != "Completed") {
        itemEndDate = null;
      }
      else {
        if (this.addForm.get('ItemEndDate')?.value != null)
          itemEndDate = new Date(this.addForm.get('ItemEndDate')?.value.getTime() - this.addForm.get('ItemEndDate')?.value.getTimezoneOffset() * 60000);
      }


      if (this.addForm.get('Status')?.value != "Completed") {
        duration = null;
      } else {
        duration = this.addForm.value.Duration;
      }

      //----------------  DSR017 chnages -------------------
      //var rcaopened = this.addForm.value.RootCauseOpened;

    } else {
      alrtRecevd = '';
      typAlrt = '';
      typAlrtOth = '';
      issuCate = '';
    }

    //----------------  DSR017 chnages -------------------
    this.addForm.patchValue({

      IssueCategory: issuCate, OtherCategory: issuCateOth, AlertReceived: alrtRecevd, AlertType: typAlrt, OtherAlert: typAlrtOth, RootCauseOpened: null,
      RootCauseNumber: null, LoggedInUser: sessionStorage.getItem('LoggedInUser'), ServiceTier: serTier,
      Duration: duration, ItemStartDate: itemStartDate, ItemEndDate: itemEndDate
    });
    //----------------  DSR017 chnages -------------------
    //RootCauseOpened: ~~(rcaopened)
    //}
    //}
    this.addForm.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));
    var dataF = { Module: this.addForm.value };
    this.monitoringService.addIssueToBeMonitored(this.addForm.value)
      .subscribe(response => {
        this.loading = false;
        if (response.ErrorMessage != '') {
          this.errorMessage = response.ErrorMessage;
        } else {
          let doneMessage = "Saved successfully!!"
          this.successNotification(doneMessage);
          $('#formclosebtn').trigger('click');
          this.editServiceTier = false;
          this.addForm.reset();
          this.selectedAirIds = [];
          //this.ngOnInit();
          this.FilterMTracker();
        }

      });
    //} description check if
  }
  resetData() {
    this.addForm.controls['ServiceTier'].enable();
    //public bindMDname = new FormControl();
    this.bindMDname = new FormControl();
    this.addForm.reset();
    this.errorMessage= '';
  }
  ShowComments(content: any, issue: any) {
    this.commentForm.get('Comment')?.setValue('');
    this.errorMessage = '';
    this.loading = true;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.commentData.Id = issue.Id;
    this.commentForm.get("Id")?.setValue(issue.Id);
    //this.commentForm.get("ApplicationId")?.setValue(issue.ApplicationId);
    return this.http.post<CommentsModel>(this.baseURL + 'api/MonitoringTracker/FetchIssueComments', this.commentData).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.CommentsResponse = response;
      this.Comments = this.CommentsResponse!.Result;
      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
        // this.errorNotification(response.ErrorMessage);
      }
    });
  }

  SaveComments() {
    this.loading = true;
    this.errorMessage = '';
    this.commentForm.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));
    this.monitoringService.SaveComments(this.commentForm.value)
      .subscribe(response => {
        this.loading = false;
        if (response.ErrorMessage != '') {
          this.errorMessage = response.ErrorMessage;
          // this.errorNotification(response.ErrorMessage);
        } else {
          let doneMessage = "Saved successfully!!"
          this.successNotification(doneMessage);
          $('#commentcrossbtn').trigger('click');
          this.commentForm.reset();
        }
      });
  }
  exportData() {

    $('#btnMtExportExcel').prop('disabled', true);
    var appIds: any;
    if (this.selectedAirIds.length > 0) {
      var obj = this.selectedAirIds;
      for (var prop in obj) {
        if (appIds == undefined || appIds == null) {
          appIds = obj[prop].appId;
        }
        else {
          appIds = appIds + ',' + obj[prop].appId;
        }
      }
    }
    return this.http.post<MonitoringInterface>(this.baseURL + 'api/MonitoringTracker/ExportToExcel', this.searchForm.value).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.MonitoringTrackerResponse = response;
      var date = new Date();
      var day = date.getDate();     // Get current date
      var month = date.getMonth() + 1; // current month
      var year = date.getFullYear();
      var CurrentDate = year + "/" + month + "/" + day;

      this.alasql('SELECT * INTO XLS("Monitoring Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.template, this.MonitoringTrackerResponse!.Result]);
      $('#btnMtExportExcel').prop('disabled', false);
    });
  }
  ExportToExcelPreventionReport() {
    $('#btnMtExportExcel').prop('disabled', true);
    var appIds: any;
    if (this.selectedAirIds.length > 0) {
      var obj = this.selectedAirIds;
      for (var prop in obj) {
        if (appIds == undefined || appIds == null) {
          appIds = obj[prop].appId;
        }
        else {
          appIds = appIds + ',' + obj[prop].appId;
        }
      }
    }
    return this.http.post<MonitoringInterface>(this.baseURL + 'api/MonitoringTracker/ExportToExcelPreventionReport', this.searchForm.value).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.MonitoringTrackerResponse = response;
      var date = new Date();
      var day = date.getDate();     // Get current date
      var month = date.getMonth() + 1; // current month
      var year = date.getFullYear();
      var CurrentDate = year + "/" + month + "/" + day;

      this.alasql('SELECT * INTO XLS("Preventive Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.preventivetemplate, this.MonitoringTrackerResponse!.Result]);
      $('#btnMtExportExcel').prop('disabled', false);
    });

  }
  //this function does not exists on cs controller 
  FetchDBStatus() {

    return this.http.post<MonitoringInterface>(this.baseURL + 'api/MonitoringTracker/FetchDBStatus', this.dataDBstatus).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.MonitoringTrackerResponse = response;
      if (response.ErrorMessage != '') {
        this.loading = false;
        this.errorMessage = response.ErrorMessage;
        // this.errorNotification(response.ErrorMessage);
      }
      //this.Issues = this.MonitoringTrackerResponse!.Result;
    });
  }
  //this function does not exists on cs html file 
  GetAppType() {
    return this.http.post<MonitoringInterface>(this.baseURL + 'api/MonitoringTracker/GetAppType', this.dataGetAppType).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.MonitoringTrackerResponse = response;
      if (response.ErrorMessage != '') {
        this.loading = false;
        this.errorMessage = response.ErrorMessage;
        // this.errorNotification(response.ErrorMessage);
      }
    });
  }
  Deactivate(issue: any) {
    this.loading = true;
    this.dataTodelete.Id = issue.Id;

    return this.http.post<MonitoringInterface>(this.baseURL + 'api/MonitoringTracker/DeleteIssue', this.dataTodelete).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
        // this.errorNotification(response.ErrorMessage);
      } else {
        let delMessage = "Deleted successfully!!"
        this.successNotification(delMessage);
      }
      this.FilterMTracker();
    });
  }

  findAIRdetails(air: any) {
  var enteredAirId = { airId: air,LoggedInUser: sessionStorage.getItem('LoggedInUser') };
  this.http.post<MonitoringInterface>(this.baseURL + 'api/MonitoringTracker/FindServiceTierDetailsByAirId', enteredAirId).pipe(
    map(this.extractData),
    catchError(this.handleErrorObservable)
  ).subscribe(response => {
    //this.addForm.get('Name')?.setValue(this.applicationContactResponse[0].ApplicationNm);
    this.addForm.get('ServiceTier')?.setValue(response.Result);
  });
}
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  }
}
