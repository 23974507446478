import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalGuard } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Observable, Subject, throwError } from 'rxjs';
import { DatePipe } from "@angular/common";
import { catchError, delay, filter, map, takeUntil } from 'rxjs/operators';
import { OnboardingInterface } from '../models/onboarding-interface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as $ from 'jquery';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { EventMessage, EventType } from '@azure/msal-browser';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { Router } from '@angular/router';
//import { faFileExcel} from '@fortawesome/free-regular-svg-icons';
import { faL, fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Swal from 'sweetalert2';
import { RequestOnboardingModel } from '../request-onboarding-model';
import { RequestOnboardingService } from '../services/request-onboarding.service';
import { RequestOnboardingResponseInterface } from '../models/RequestOnboardingResponse';
import { UserPeopleResponse } from '../models/user-people-response';
import { PeopleModel } from '../models/people-model';
import { RootObject } from '../models/root-object';
import { UserResponseModel } from '../models/user-model-response';
import { UserModel } from '../models/user-model';
import { CommonService } from '../services/common.service';
import { data } from 'jquery';
import { ApplicationResponseModel } from '../models/application-response-model';
import { SupportContactModel } from '../models/support-contact-model';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
type ProfileType = {
  givenName: string,
  surname: string,
  userPrincipalName: string,
  id: string
}
@Component({
  selector: 'app-request-onboarding',
  templateUrl: './request-onboarding.component.html',
  styleUrls: ['./request-onboarding.component.css']
})
export class RequestOnboardingComponent implements OnInit {
  baseURL: string = environment.baseUrl;
  closeResult: string = '';
  errorMessage: string = '';
  errorMessage1: string = '';
  successMessage = "";
  requestData: any;
  public CreatedBy: string = '';

  //getusername: any[] = [];
  //public username!: string;
  title = 'Global Prevention Center';
  pendingRequest: RequestOnboardingModel[] = [];
  holdsPendingRequest: RequestOnboardingModel[] = [];
  pendingRequestForApplication: RequestOnboardingModel[] = [];
  applicationContactResponse: SupportContactModel[] = [];
  // baseURL: string = environment.baseUrl;
  env: string = environment.name;
  loading: boolean | undefined;
  get_profile_Data: any;
  forms: OnboardingInterface[] = [];
  formsfilter: OnboardingInterface[] = [];
  applicationNamerequestForm: any = [];
  ServiceTierrequestForm: any = [];
  asTier: any[] = [];
  asStatus: any[] = [];
  maSource: any[] = [];
  haEnabled: any[] = [];
  gpcType: any[] = [];
  public SearchPeople: any[] = [];
  public SearchAir: any[] = [];
  UserPeopleResponse: UserPeopleResponse | undefined;
  lotOfPeople: PeopleModel[] = [];
  RootOBJ: any;
  RootObjV: RootObject | undefined;
  leadsNamerequestForm: any = [];
  keywordP = 'EnterpriseId';
  keywordAir = 'AirId';
  files?: FileList;
  SelectedFiles: any[] = [];
  sendImagesFile: any[] = [];
  filename!: string;
  sendImagesDB: string[] = [];
  public ImageAttachmentPath: any[] = [];
  UserResponse: UserResponseModel | undefined;
  leads: UserModel[] = [];
  public SearchLeads: any[] = [];
  public ControlStartDate = new FormControl();
  formTitle: string | undefined;
  profile: any;
  panelOpenState = false;
  username = sessionStorage.getItem('LoggedInUser');
  selectedMD: string = "";
  selectedLead: string = "";
  selectedServiceM: string = "";
  selectedPOC: string = "";
  modalTitle: string = "";
  user: string | undefined;
  arrStatus: any[] = [];
  arrAirId: any[] = [];
  dependsOnStatus: boolean = true;
  ifApproved: boolean = true;
  formDisabled: boolean = false;
  onresponse: boolean = true;
  enableAfterResponse: boolean = false;
  onEdit: boolean = false;
    request: any;
    appName: string="";
  appTier: string = "";
  serviceOpsMD: string = "";
  serviceOpsLead: string = "";
  serviceOpsMan: string = "";
  clickedsampleFileplaybook: boolean = false;
  clickedsampleFileXmatters: boolean = false;
  clickedsampleFileMock1: boolean = false;
  clickedsampleFileMock2: boolean = false;
  clickedsampleFileMock3: boolean = false;
  clickedsampleFileMonitorSOP: boolean = false;

  constructor(private authService: MsalService, private modalService: NgbModal, library: FaIconLibrary, private http: HttpClient, private Onboardingservice: RequestOnboardingService, private common: CommonService, private datePipe: DatePipe) {
    library.addIconPacks(fas, far);
    this.asTier = this.getAsTier();
    this.maSource = this.getMaSource();
    this.haEnabled = this.getHaEnabled();
    this.gpcType = this.getGPCType();
    this.asStatus = this.getAsStatus();
  }

  ngOnInit(): void {
    this.getProfile();
    this.FetchOnboardingDetails();
  }
  getAsStatus() {
    return [{ Id: "1", value: "Requested" },
      { Id: "2", value: "Approved" },
      { Id: "3", value: "Review" },
      { Id: "4", value: "Onboarded" }]
  }
  getAsTier() {
    return [{ Id: "1", value: "Always On" },
    { Id: "2", value: "Nearly Always On" }]
  }
  getMaSource() {
    return [{ Id: "1", value: "Datadog" },
    { Id: "2", value: "Cloud Vendor" },
    { Id: "3", value: "Other" }]
  }

  getHaEnabled() {
    return [{ Id: "1", value: "Yes" },
    { Id: "2", value: "No" }]
  }

  getGPCType() {
    return [{ Id: "1", value: "Onboarding + Monitoring" },
    { Id: "2", value: "Service Management" }]
  }


  requestForm = new FormGroup({
    Id: new FormControl(), AirId: new FormControl(), ApplicationName: new FormControl(), ServiceOperationMD: new FormControl(),
    ServiceLead: new FormControl(), ServiceOperationsManager: new FormControl(), ApplicationPointofContact: new FormControl(),
    ApplicationServiceTier: new FormControl(), GPCOnboardingType: new FormControl(), MonitoringAlertSource: new FormControl(),
    TotalAmountP1P2IncidentsFiscalYear: new FormControl(),
    ApplicationDistributionList: new FormControl(),
    HighAvailabilityEnabled: new FormControl(), NumberofApplicationUsers: new FormControl(),
    CreatedBy: new FormControl(), ImagesPath: new FormControl(this.sendImagesDB), CreateDateTime: new FormControl(),
    ModifiedBy: new FormControl(), ModifiedDateTime: new FormControl(), OpenedBy: new FormControl(),
    LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser')), Status: new FormControl()
  });

  searchForm = new FormGroup({
    status: new FormControl(), service: new FormControl()
  });

  successNotification(reason: string) {
    Swal.fire({
      title: 'Success',
      text: reason,
      icon: 'success',
      confirmButtonText: 'OK'
    });
  }
  errorNotification(reason: string) {
    Swal.fire({
      title: 'Error',
      text: reason,
      icon: 'warning',
      confirmButtonText: 'OK'
    })
  }

  public getProfile(): any {
    this.http.get<ProfileType>(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;

        this.username = profile.userPrincipalName.substring(0, profile.userPrincipalName.indexOf("@"));

        this.requestForm.get("LoggedInUser")?.setValue(sessionStorage.getItem('LoggedInUser'));

        this.user = this.username;


      });
    return this.username;
  }

  

  findAIRdetails(air: any) {
    this.onresponse = false;
    var enteredAirId = { airId: air.target.value,LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<OnboardingInterface>(this.baseURL + 'api/OnboardingForm/FindApplicationDetailsByAirId', enteredAirId).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.onresponse = true;
      this.applicationContactResponse = response.Result.Result;
      this.requestForm.get('ApplicationName')?.setValue(this.applicationContactResponse[0].ApplicationNm);
      this.requestForm.get('ServiceOperationMD')?.setValue(this.applicationContactResponse[0].ServiceOperationsMD);
      this.requestForm.get('ServiceLead')?.setValue(this.applicationContactResponse[0].ServiceOperationsLead);
      this.requestForm.get('ServiceOperationsManager')?.setValue(this.applicationContactResponse[0].ServiceOperationsManager);
      this.requestForm.get('ApplicationPointofContact')?.setValue(this.applicationContactResponse[0].PrimarySupport);
      this.requestForm.get('ApplicationServiceTier')?.setValue(this.applicationContactResponse[0].ServiceLevelOfferingNm);

    });
  }
  FilterRequests() {
    this.errorMessage1 = '';
    this.pendingRequest = this.holdsPendingRequest;
    if (this.searchForm.value.status != null && this.searchForm.value.service == null) {
      this.pendingRequest = this.pendingRequest.filter(
        request => request.Status === this.searchForm.value.status);
    } else if (this.searchForm.value.service != null && this.searchForm.value.status == null) {
      this.pendingRequest = this.pendingRequest.filter(
        request => request.ApplicationServiceTier === this.searchForm.value.service);
    } else if (this.searchForm.value.service == null && this.searchForm.value.status == null) {
      this.FetchOnboardingDetails();
    } else {
      let pendingRequestService: RequestOnboardingModel[] = [];
      pendingRequestService = this.pendingRequest.filter(
        request => request.Status === this.searchForm.value.status);
      this.pendingRequest = pendingRequestService.filter(
        request => request.ApplicationServiceTier === this.searchForm.value.service);
    }
    if (this.pendingRequest.length == 0) {
      var message = "No Data found for the selected search filters";
      
      this.errorMessage1 = message;
    }
    else {
      this.errorMessage1 = '';
    }
  }
  FilterRequestsService() {

    this.pendingRequest = this.pendingRequest.filter(
      request => request.ApplicationServiceTier === this.searchForm.value.service);
  }

  LoadPeopleByName(Search: string) {

    this.common.FetchPeopleForOnboardingForm("=" + Search + "=" + this.user)
      .subscribe(response => {
        this.loading = false;
        this.UserPeopleResponse = response;
        this.RootOBJ = this.UserPeopleResponse!.Result;

        this.lotOfPeople = this.RootOBJ.Value;
        this.SearchPeople = this.lotOfPeople;
        this.SearchPeople.map(people => people.Name = `${people.EnterpriseId} -- ${people.StandardJobDescription}`);
      });
  }
  selectEventAddMD(md: any) {
    this.requestForm.get('ServiceOperationMD')?.setValue(md.EnterpriseId);
    //this.selectedMD = l.EnterpriseId;
  }
  selectEventAddLead(lead: any) {
    this.requestForm.get('ServiceLead')?.setValue(lead.EnterpriseId);
    //this.selectedLead = l.EnterpriseId;
  }
  selectEventAddSerManager(serMan: any) {
    this.requestForm.get('ServiceOperationsManager')?.setValue(serMan.EnterpriseId);
    //this.selectedServiceM = l.EnterpriseId;
  }
  selectEventAddPOC(poc: any) {
    this.requestForm.get('ApplicationPointofContact')?.setValue(poc.EnterpriseId);
    //this.selectedPOC = l.EnterpriseId;
  }
  // Creating the panels
  FetchOnboardingDetails() {
    var log = { LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<OnboardingInterface>(this.baseURL + 'api/OnboardingForm/FetchOnboardingDetails', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.pendingRequest = response.Result;
      this.holdsPendingRequest = response.Result;
      this.pendingRequest.forEach((data) => { this.arrStatus.push(data.Status) });
      if (this.arrStatus.indexOf('Approved') > -1) {
        this.dependsOnStatus = false;
      }
      this.pendingRequest.forEach((data) => { this.arrAirId.push(data.AirId) });
    });

  }
  // For fetching each panel details post expanding
  FetchOnboardingDetailsForApplication(data: any) {
    this.enableAfterResponse = true;
    var log = { Id: data.Id, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<OnboardingInterface>(this.baseURL + 'api/OnboardingForm/FetchOnboardingDetails', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      
      this.pendingRequestForApplication = response.Result;
      this.enableAfterResponse = false;

    });

  }

  //Downloading Onboarding Sample
  sampleDownload(file: any) {
    this.errorMessage = '';
    var fileToDownload = file.attributes['name'].value;
    let previewAttachmentData = { ImagesName: fileToDownload, FileName: fileToDownload, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    const headers = { Accept: "application/octet-stream" };
    return this.http.post(this.baseURL + "api/OnboardingForm/downloadOnboardingSample", previewAttachmentData, { responseType: 'arraybuffer', headers: headers })
      .subscribe(response => {

        var blob = new Blob([response], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = previewAttachmentData.FileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url); //to release memory when not in use

      });
  }


  openEdit(content: any, data: OnboardingInterface) {
    $("#myModalTitle").text("Edit");
    //this.formDisabled = false;
    this.onEdit = true;
    var log = { Id: data.Id, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<OnboardingInterface>(this.baseURL + 'api/OnboardingForm/FetchOnboardingDetails', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
      } else {

        this.requestForm.patchValue({
          Id: response.Result[0].Id, AirId: response.Result[0].AirId, ApplicationName: response.Result[0].ApplicationName,
          ServiceOperationMD: response.Result[0].ServiceOperationMD, ServiceLead: response.Result[0].ServiceLead,
          ServiceOperationsManager: response.Result[0].ServiceOperationsManager, ApplicationPointofContact: response.Result[0].ApplicationPointofContact,
          ApplicationServiceTier: response.Result[0].ApplicationServiceTier, GPCOnboardingType: response.Result[0].GPCOnboardingType, MonitoringAlertSource: response.Result[0].MonitoringAlertSource,
          TotalAmountP1P2IncidentsFiscalYear: response.Result[0].TotalAmountP1P2IncidentsFiscalYear,
          ApplicationDistributionList: response.Result[0].ApplicationDistributionList, Status: response.Result[0].Status,
          HighAvailabilityEnabled: response.Result[0].HighAvailabilityEnabled, NumberofApplicationUsers: response.Result[0].NumberofApplicationUsers,
          ImagesPath: this.sendImagesDB, CreatedBy: response.Result[0].OpenedBy, CreateDateTime: response.Result[0].CreateDateTime,
          ModifiedBy: response.Result[0].ModifiedBy, ModifiedDateTime: response.Result[0].ModifiedDateTime
        });

        this.FetchAttachmentImages(response.Result[0].Id);
        this.requestForm.get("Id")?.setValue(data.Id);
        this.applicationNamerequestForm = response.Result.ApplicationName?.split(';');

        setTimeout(() => {
          $('#MDAutocomplete').find('input').val(response.Result[0].ServiceOperationMD);
          $('#ServiceAutocomplete').find('input').val(response.Result[0].ServiceLead);
          $('#ServiceManager').find('input').val(response.Result[0].ServiceOperationsManager);
          $('#ApplicationPointofContact').find('input').val(response.Result[0].ApplicationPointofContact);
        }, 500);

        if (this.requestForm.value.Status != 'Requested') {
          this.ifApproved = false;
        }
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result: any) => {
          this.closeResult = `closed with: ${result}`;
        }, (reason: any) => {
          this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
        });

      }
    });
  }
  getdismissreason(reason: any) {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing esc';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.formDisabled = false;
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  makeDisable() {
    this.formDisabled = true;
  }
  dateReset() {
    this.ControlStartDate = new FormControl();
    this.requestForm.reset();
    this.sendImagesDB = [];
    this.applicationNamerequestForm = [];
    this.ServiceTierrequestForm = [];
    this.errorMessage = "";
    this.onEdit = false;
  }
  SaveFormRecord() {
    $('#btnOrSaveIssue').prop('disabled', true);
    //if ((this.arrAirId.indexOf(parseInt(this.requestForm.value.AirId)) > -1) && this.requestForm.value.Id == null ) {
     // this.errorMessage = "An onboarding request with this AirID is already Exists";
     // $('#btnOrSaveIssue').prop('disabled', false);
    //} else {
      this.requestForm.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));
      this.requestForm.get('CreatedBy')?.setValue(sessionStorage.getItem('LoggedInUser'));
      this.requestForm.get('ModifiedBy')?.setValue(sessionStorage.getItem('LoggedInUser'));

      if (this.requestForm.value.Id == 0 || this.requestForm.value.Id == null) { //request raised
        this.requestForm.get('Status')?.setValue("Requested");
      } else if (this.requestForm.value.Id != 0 && this.requestForm.value.Status == 'Requested' && this.SelectedFiles.length < 1) { //requester edit the request when status: Requested
        this.requestForm.get('Status')?.setValue("Requested");
      } else if (this.requestForm.value.Id != 0 && this.requestForm.value.Status == 'Approved' && this.SelectedFiles.length < 1) { //requester edit the request when status: Approved
        this.requestForm.get('Status')?.setValue("Approved");
      } else if (this.requestForm.value.Id != 0 && this.requestForm.value.Status == 'Approved' && this.SelectedFiles.length > 0) { //requester uploads files when status: Approved
        this.requestForm.get('Status')?.setValue("Review");
      } else { //requester edits when status: Review
        this.requestForm.get('Status')?.setValue("Review");
      }

      if (this.requestForm.value.AirId == null || this.requestForm.value.AirId == "") {
        this.errorMessage = "AirId field cannot be empty.";
        $("#btnOrSaveIssue").prop("disabled", false);
      }
      else if (this.requestForm.value.ApplicationName == null || this.requestForm.value.ApplicationName == "") {
        this.errorMessage = "Application Name field cannot be empty.";
        $("#btnOrSaveIssue").prop("disabled", false);
      }
      else if (this.requestForm.value.ApplicationServiceTier == null || this.requestForm.value.ApplicationServiceTier == "") {
        this.errorMessage = "Application Service Tier field cannot be empty.";
        $("#btnOrSaveIssue").prop("disabled", false);
      }

      else {
        console.log(this.requestForm.value);
        this.Onboardingservice.addForms(this.requestForm.value)
          .subscribe(response => {
            if (response.ErrorMessage == '') {
              $("#btnOrSaveIssue").prop("disabled", false);
              var saveMessage = "Saved successfully!!";
              this.successNotification(saveMessage);
              $('#formclosebtn').trigger('click');
              this.FetchOnboardingDetails();
              this.requestForm.reset();
              this.applicationNamerequestForm = [];
              this.selectedMD = "";
              this.selectedLead = "";
              this.selectedServiceM = "";
              this.selectedPOC = "";
              this.dateReset();
            } else {
              this.errorMessage = response.ErrorMessage;
              $("#btnOrSaveIssue").prop("disabled", false);
              this.sendImagesFile = [];
              this.SelectedFiles = [];
            }

          });
      }
    //}

  }

  open(content: any) {
    this.modalTitle = "Add New";
    this.SelectedFiles = [];
    this.sendImagesFile = [];

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result: any) => {
      this.closeResult = `closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
    });
  }

  getFileDetails(e: any) {
    this.files = e.target.files;
  }

  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  uploadFiles(): Observable<any> {
    this.loading = true;
    this.sendImagesFile = [];
    if (this.files != undefined) {
      this.loading = false;
      for (let k = 0; k < this.files.length; k++) {
        let filename = this.files[k].name;
        this.filename = filename;
        let index = filename.lastIndexOf(".");
        let strsubstring = filename.substring(index, filename.length);
        if (strsubstring.toUpperCase() == '.JPG' || strsubstring.toUpperCase() == '.JPEG' || strsubstring.toUpperCase() == '.PNG' || strsubstring.toUpperCase() == '.GIF' || strsubstring.toUpperCase() == '.MSG' || strsubstring.toUpperCase() == '.XLSX' || strsubstring.toUpperCase() == '.XLSM' || strsubstring.toUpperCase() == '.DOCX' || strsubstring.toUpperCase() == '.ZIP' || strsubstring.toUpperCase() == '.TXT' || strsubstring.toUpperCase() == '.PDF' || strsubstring.toUpperCase() == '.PPTX') {
          this.sendImagesFile.push(this.files[k]);
          // if (!this.sendImagesFile.includes(this.files[k].name)) this.sendImagesFile.push(this.files[k])

        }
        else {
          alert("File Extension Is InValid - Please Upload valid Extension Files");

        }
      }

      if (this.sendImagesFile) {
        if (this.sendImagesFile.length > 0) {
          let fd = new FormData();
          for (let k = 0; k < this.sendImagesFile.length; k++) {
            fd.append("uploadedFile", this.sendImagesFile[k]);
            this.S4();
            // then to call it, plus stitch in '4' in the third group
            let guid = (this.S4() + this.S4() + "-" + this.S4() + "-4" + this.S4().substr(0, 3) + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4()).toLowerCase();
            let uploadedFile = this.sendImagesFile[k].name;
            let index = uploadedFile.lastIndexOf(".");
            let ext = uploadedFile.substring(index, this.filename.length);
            let imgN = uploadedFile.replace(ext, "");
            let imageName = imgN + "_" + guid.toString() + ext;
            // if (!this.sendImagesDB.includes(imageName)) this.sendImagesDB.push(imageName)
            this.sendImagesDB.push(imageName);
            this.SelectedFiles.push(({ name: imageName, fName: this.sendImagesFile[k].name }));
            fd.append("UniqVal", guid.toString());

          }

          this.http.post(this.baseURL + "api/OnboardingForm/UploadOnboardingTemplate", fd).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
          ).subscribe(response => {
            this.loading = false;
            if (response.ErrorMessage != '') {

              // this.errorNotification(response.ErrorMessage);
              this.errorMessage = response.ErrorMessage;
            } else {
              this.ImageAttachmentPath = response.Result;
              console.log(this.sendImagesDB);
            }
          });
        }

      }

      $('#file').val('');

    }
    else {
      this.loading = false;
      alert("Please select at least one valid file.");
    }
    return <any>this.SelectedFiles;
  }

  previewAttachment(file: any) {
    let previewAttachmentData = { ImagesName: file.name, FileName: file.fName, LoggedInUser: sessionStorage.getItem('LoggedInUser') };

    const headers = { Accept: "application/octet-stream" };
    return this.http.post(this.baseURL + "api/OnboardingForm/PreviewOnboardingTemplate", previewAttachmentData, { responseType: 'arraybuffer', headers: headers })
      .subscribe(response => {

        var blob = new Blob([response], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = previewAttachmentData.FileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url); //to release memory when not in use

      });
  }

  removeFile(file: any) {
    let data = { Id: file.Id, FileName: file.fName, ImagesName: file.name, LoggedInUser: sessionStorage.getItem("LoggedInUser") };

    return this.http.post<RequestOnboardingModel>(this.baseURL + "api/OnboardingForm/RemoveOnboardingTemplate", data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.SelectedFiles.splice(0, 1);
      //this.FetchAttachmentImages(file.SId);
      if (response.ErrorMessage != '') {

        // this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      } else {
        var message = "Removed";
        this.successNotification(message);

      }
    });

  }

  FetchAttachmentImages(IssueId: any) {
    this.errorMessage = '';

    this.SelectedFiles = [];
    //this.sendImagesDB = [];
    var data = { Id: IssueId, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.loading = true;
    return this.http.post<RequestOnboardingModel>(this.baseURL + "api/OnboardingForm/FetchOnboardingTemplate", data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      for (var k = 0; k < response.Result.length; k++) {
        var resultSplit = response.Result[k].ImagesName;
        this.SelectedFiles.push({ name: resultSplit, Id: response.Result[k].ImageId, SId: IssueId, ImageName: response.Result[k].ImagesName, fName: response.Result[k].FileName });

      }
      if (response.ErrorMessage != '') {

        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      }
      return <any>this.SelectedFiles;
    });
  }
  formReset() {
    this.selectedMD = "";
    this.selectedLead = "";
    this.selectedServiceM = "";
    this.selectedPOC = "";
    this.sendImagesDB = [];
    this.formDisabled = false;
    this.errorMessage = "";
    this.onEdit = false;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  }
  private extractData(res: any) {
    let body = res;
    return body;
  }

}

