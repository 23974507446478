<div class="main">

  <h1 class="pb-2 mt-4 mb-2 border-bottom" style=" border-bottom: 1px solid #eee;padding-bottom: 9px;margin: 0px -20px 20px;">Standard Operating Procedures</h1>

  <form class="d-flex flex-row align-items-center flex-wrap" [formGroup]="searchForm" (ngSubmit)="fetchApplications()">
    <div class="row" style="margin-left: -32px; width: 28%;">
      <div class="ng-autocomplete" id="alertApps">
        <ng-autocomplete [data]="applications"
                         [searchKeyword]="keyword"
                         placeholder="Enter Application"
                         (inputChanged)='findapps($event)'
                         historyIdentifier="applications"
                         [itemTemplate]="itemTemplate"
                         [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-option>
          <a [innerHTML]="option.Name" (click)="getSelectedAirIds(option)"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
      <input type="hidden" formControlName="AirId" id="airId" value="{{searchForm.value.ApplicationId}}" />

    </div>
    <div class="card-group" style="display:none;" *ngFor="let app of searchResult;">
      <textarea name="AirId" formControlName="AirId" id="airid" #airid>{{app.AirId}}</textarea>
    </div>

    <div class="row" style="margin-left: 10px;">
      <input type="text" id="txtSubject" class="form-control" name="Subject" formControlName="AlertSubject" placeholder="Subject" value="">
    </div>

    <div class="row" style="margin-left: 23px;">
      <input type="text" id="txtKeyWord" class="form-control" name="Keyword" formControlName="AlertDescription" placeholder="Keyword" value="">
    </div>
    <div class="row" style="margin-left: 23px; width:150px">
      <input type="text" id="txtSopNo" class="form-control" name="sop" formControlName="SopNumber" style="width:150px" placeholder="SOP No" maxlength="4" />
    </div>
    <div class="row form-group">
      <input type="hidden" id="loggeduser" class="form-control" name="LoggedInUser" formControlName="LoggedInUser" #LoggedInUser />
    </div>
    <div class="row">
      <button type="submit" value="Search" id="btnSearchSop" class="btn btn-primary" style="margin-left:60px"><span class="fa fa-search"></span> Search </button>
    </div>
  </form>

  <br />
  <br />

  <div class="alert alert-danger" *ngIf="errorMessage" id="divError">
    {{errorMessage}}
  </div>



  <ng-template #openDeleteConfirmModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete</h4>
    </div>
    <div id="mymodbody" class="modal-body" style="height: 100px;">
      <p>Do you want to continue to delete this record ?</p>
    </div>
    <div class="alert alert-danger" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
    <div class="modal-footer">
      <div class="pull-right">
        <button id="DelBtn" type="button" class="btn btn-default" style="color: #333; background-color: #fff; border-radius: 4px; border: 1px solid transparent;border-color: #ccc">Delete</button>
        <button id="cancelDelBtn" type="button" class="btn btn-primary" (click)="modal.dismiss('Close click')">Cancel</button>

      </div>
    </div>
  </ng-template>




  <!--Add new form starts-->
  <ng-template #modalForm let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="myModalTitle">{{formTitle}}</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click'); addForm.reset();"><span aria-hidden="true">&times;</span></button>
    </div>
    <form name='modalForm' [formGroup]="addForm">
      <div class="icon-container">
        <i class="loader"></i>
      </div>

      <div id="modbody" class="modal-body" style="margin-left: 70px;">

        <fieldset [disabled]="onEdit">
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">AIRID - ApplicationName <em style="color:red; margin-left: 5px;">*</em> :</label>
            <div class="col-md-5">
              <input id="AirId_AppName" class="form-control" name="AirId_AppName" placeholder="5317 - CIO GPC" formControlName="AirId_AppName" #AirId_AppName>

            </div>
          </div>
        </fieldset>

        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">ServerList Link<em style="color:red; margin-left: 5px;">*</em> :</label>
          <div class="col-md-5">
            <input id="Link" class="form-control" name="Link" formControlName="Link" #Link>

          </div>
        </div>

        <fieldset [disabled]="onEdit">
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Application AIR ID<em style="color:red; margin-left: 5px;">*</em> :</label>
            <div class="col-md-5">
              <input id="AirId" type="number" class="form-control" name="AirId" placeholder="5317" formControlName="AirId" #AirId>

            </div>
          </div>
        </fieldset>


        <div class="row form-group" style="display: none">
          <input class="col-sm-5" name="LoggedInUser" formControlName="LoggedInUser" value="{{username}}" #LoggedInUser />
        </div>

        <fieldset [hidden]="onNew">
          <div class="row form-group">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Created By :</label>
            <div class="col-sm-5" id="createdBy" name="CreatedBy" #CreatedBy>
              {{username}}
            </div>
          </div>
        </fieldset>

        <div class="alert alert-danger" *ngIf="errorMessage">
          {{errorMessage}}
        </div>

        <div class="modal-footer" id="saveServerlistFormData">
          <div>
            <div class="float-right">
              <button id="btnOrSaveIssue" type="submit" class="btn btn-primary" (click)="SaveData();">Save changes</button>
              <button id="formclosebtn" type="button" class="btn btn-primary" data-dismiss="modal" (click)="modal.dismiss('Close click'); formReset();">Close</button>
              <input type="hidden" id="hdnAppId" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <!--Add new Control-M Jobs form starts-->
  <ng-template #controllistForm let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="myModalTitle">{{formTitle}}</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click'); controlForm.reset();"><span aria-hidden="true">&times;</span></button>
    </div>
    <form name='controllistForm' [formGroup]="controlForm">
      <div class="icon-container">
        <i class="loader"></i>
      </div>

      <div id="modbody" class="modal-body" style="margin-left: 70px;">

        <fieldset [disabled]="onEdit">
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">AIRID - ApplicationName <em style="color:red; margin-left: 5px;">*</em> :</label>
            <div class="col-md-5">
              <input id="AirId_AppName" class="form-control" name="AirId_AppName" placeholder="5317 - CIO GPC" formControlName="AirId_AppName" #AirId_AppName>

            </div>
          </div>
        </fieldset>

        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Control-MJobs Link<em style="color:red; margin-left: 5px;">*</em> :</label>
          <div class="col-md-5">
            <input id="Link" class="form-control" name="Link" formControlName="Link" #Link>

          </div>
        </div>

        <fieldset [disabled]="onEdit">
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Application AIR ID<em style="color:red; margin-left: 5px;">*</em> :</label>
            <div class="col-md-5">
              <input id="AirId" type="number" class="form-control" name="AirId" placeholder="5317" formControlName="AirId" #AirId>

            </div>
          </div>
        </fieldset>


        <div class="row form-group" style="display: none">
          <input class="col-sm-5" name="LoggedInUser" formControlName="LoggedInUser" value="{{username}}" #LoggedInUser />
        </div>

        <fieldset [hidden]="onNew">
          <div class="row form-group">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Created By :</label>
            <div class="col-sm-5" id="createdBy" name="CreatedBy" #CreatedBy>
              {{username}}
            </div>
          </div>
        </fieldset>

        <div class="alert alert-danger" *ngIf="errorMessage">
          {{errorMessage}}
        </div>

        <div class="modal-footer" id="saveControlMJobslistFormData">
          <div>
            <div class="float-right">
              <button id="btnOrSaveForm" type="submit" class="btn btn-primary" (click)="SaveControlMJobsData();">Save changes</button>
              <button id="formclosebtn" type="button" class="btn btn-primary" data-dismiss="modal" (click)="modal.dismiss('Close click'); formReset();">Close</button>
              <input type="hidden" id="hdnAppId" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <!--<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px' }"
  [template]="sample"></ngx-loading>
  <ng-template #sample></ng-template>-->
  <div class="row" ng-show="success" id="divSuccess">

    <div id="accordion" class="card-group" style="width:100%">
      <div class="card" style="border:none">
        <mat-accordion>
          <mat-expansion-panel style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;" (opened)="panelOpenState = true"
                               (closed)="panelOpenState = false" *ngFor="let app of searchResult;">
            <mat-expansion-panel-header>
              <h4 class="card-title">
                <a class="navbar-toggler" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation"><i class="fa fa-plus-circle" id="expandable{{app.Id}}" style="color:#333" aria-hidden="true"></i></a>
                <strong>{{app.Name}}</strong> - {{app.AirId}}
                <!--<a href={{app.ReferenceLink}} target="_blank" style="color:#333"></a>-->
              </h4>
            </mat-expansion-panel-header>
            <mat-expansion-panel style="width: 96%;margin: 0 auto;">
              <mat-expansion-panel-header style="cursor: pointer">
                <mat-panel-title>
                  <strong>Alerts</strong>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="card" *ngFor="let alert of app.Alerts">
                <div class="card-header">
                  <div class="card-title float-left">Alert Subject - {{alert.AlertSubject}}</div>
                  <div class="card-title float-right">SOP No# - {{alert.SopNumber}}</div>
                  <div class="clearfix"></div>
                </div>
                <div class="card-body">
                  <table class="table table-bordered">
                    <thead>
                      <tr style="background-color: #f5f5f5;">
                        <th>Escalation Process</th>
                        <td style="text-align: right;"><a (click)="ContactService(myContactmodal,alert);" style="color:blue; cursor:pointer;">Click here to view contacts.</a></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let es of alert.EscalationProcess">
                        <td colspan="2">{{es.Step}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered">
                    <thead>
                      <tr style="background-color: #f5f5f5;">
                        <th colspan="2">Alert Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><strong>Description</strong></td>
                        <td>{{alert.AlertDescription}}</td>
                      </tr>
                      <tr>
                        <td><strong>Priority</strong></td>
                        <td>{{alert.PriorityLevel}}</td>
                      </tr>
                      <tr>
                        <td><strong>Alert/Notification</strong></td>
                        <td>{{alert.AlertType}}</td>
                      </tr>
                      <!--<tr>
                        <td><strong>ServiceNow Ticket</strong></td>
                        <td *ngIf="alert.AutoTicket == 'y'">yes</td>
                        <td *ngIf="alert.AutoTicket !== 'y'">no</td>
                      </tr>-->
                      <tr *ngIf="alert.SplunkProcessFlowLink == ''">
                        <td><strong>Sample</strong></td>
                        <td style="display:none;"><a id="alertsample" style="color:blue" #AlertSample>{{alert.AlertSample}}</a></td>
                        <td><a (click)="downloadsop(alert.AlertSample);" style="color:blue; cursor: pointer;">Alert Sample</a></td>
                      </tr><!--downloadsop(alert.AlertSample);-->
                      <tr *ngIf="alert.SplunkProcessFlowLink != '' ">
                        <td><strong>Process Flow</strong></td>
                        <td>na</td>
                      </tr>
                      <tr>
                        <td><strong>Reference Link</strong></td>
                        <td *ngIf="alert.ReferenceLink != ''"><a href={{alert.ReferenceLink}} target="_blank" style="color:blue">reference link</a></td>
                      </tr>
                      <tr>
                        <td><strong>Comments</strong></td>
                        <td><p>{{alert.Comments}}</p></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mat-expansion-panel><!--inner panel for alert-->
            <!--<div id="navbarSupportedContent">
    <div class="card-body">
      <div class="col-xl-12">
        <ng-template matExpansionPanelContent>
          <mat-accordion class="example-headers-align" multi="false">-->
            <fieldset [hidden]="onOpen">
              <mat-expansion-panel style="width: 96%;margin: 0 auto;">
                <mat-expansion-panel-header style="cursor: pointer">
                  <mat-panel-title>
                    <strong>Server List</strong>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="card" *ngIf="app.ServerList.length!=0">
                  <div class="card-header">
                    <div class="card-title float-left" style="display: inline;">Server List</div>
                    <div class="card-title float-right" style="display: inline;">
                      <button *ngIf="this.isAdmin==true" type="button" id="btnItAddNew" style="float: right;margin-right: 30px; margin-top:5px" class="btn btn-primary" (click)="open(modalForm); dateReset();"><span class="fa fa-plus"></span>Add New</button>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="card-body">
                    <table class="table table-bordered">
                      <thead>
                        <tr style="background-color: #f5f5f5;">
                          <th style="border:none;">Air Id-Application Name</th>
                          <th style="border:none;">Link</th>
                          <th *ngIf="this.isAdmin==true" style="border:none;">Edit</th>
                          <th *ngIf="this.isAdmin==true" style="border:none;">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let slists of app.ServerList">
                          <td>{{slists.AppName}}</td>
                          <td><a href='{{slists.Url}}' target="_blank">Link</a></td>
                          <td *ngIf="this.isAdmin==true"><button type="button" id="btnEdit" class="btn btn-primary" (click)="openEdit(modalForm, slists);"><span class="fa fa-edit"></span>Edit</button></td>
                          <td *ngIf="this.isAdmin==true"><button type="button" class="btn btn-primary" (click)="confirm(slists)"><span class="fa fa-trash"></span>Delete</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </mat-expansion-panel><!--inner panel for serverlist-->
              <!--</mat-accordion>--><!--inner panel for serverlist-->
              <!--</ng-template>--><!--inner panel for serverlist-->
              <!--</div>
    </div>
  </div>-->
              <!--Navbad supported content is closing-->
              <mat-expansion-panel style="width: 96%;margin: 0 auto;">
                <mat-expansion-panel-header style="cursor: pointer">
                  <mat-panel-title>
                    <strong>Control M List</strong>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="card" *ngIf="app.ControlMJobsList.length!=0">
                  <div class="card-header">
                    <div class="card-title float-left" style="display: inline;">Control-M Jobs List</div>
                    <div class="card-title float-right" style="display: inline;">
                      <button *ngIf="this.isAdmin==true" type="button" id="btnAddNew" style="float: right;margin-right: 30px; margin-top:5px" class="btn btn-primary" (click)="open(controllistForm); dateReset();"><span class="fa fa-plus"></span>Add New</button>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="card-body">
                    <table class="table table-bordered">
                      <thead>
                        <tr style="background-color: #f5f5f5;">
                          <th style="border:none;">Air Id-Application Name</th>
                          <th style="border:none;">Link</th>
                          <th *ngIf="this.isAdmin==true" style="border:none;">Edit</th>
                          <th *ngIf="this.isAdmin==true" style="border:none;">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let clists of app.ControlMJobsList">
                          <td>{{clists.AppName}}</td>
                          <td><a href='{{clists.Url}}' target="_blank">Link</a></td>
                          <td *ngIf="this.isAdmin==true"><button type="button" id="btnEdit" class="btn btn-primary" (click)="controlMEdit(controllistForm, clists);"><span class="fa fa-edit"></span>Edit</button></td>
                          <td *ngIf="this.isAdmin==true"><button type="button" class="btn btn-primary" (click)="confirmDelete(clists)"><span class="fa fa-trash"></span>Delete</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </mat-expansion-panel><!--inner panel for alert-->
              </fieldset>






                <ng-template #myContactmodal let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" id="myModalTitle">{{service.ApplicationNm}}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
                  </div>
                  <!--<form name='modalformContact'>-->
                  <div id="modbody modalContactTable" class="modal-body">
                    <h4 class="modal-title" id="CommentModalLabel">Support Escalation</h4>

                    <br />

                    <table class="table table-bordered">

                      <tr>
                        <td><strong>Primary Support</strong></td>
                        <td>{{service.PrimarySupport}}</td>







                        <!--<td>{{val.SupportEscalation.PrimarySupport}}</td>-->
                      </tr>
                      <tr>
                        <td><strong>Secondary Support</strong></td>
                        <td>{{service.SecondarySupport}}</td>







                        <!--<td>{{val.SupportEscalation.SecondarySupport}}</td>-->
                      </tr>
                      <tr>
                        <td><strong>Tertiary Support</strong></td>
                        <td>{{service.TertiarySupport}}</td>







                        <!--<td>{{val.SupportEscalation.TertiarySupport}}</td>-->
                      </tr>

                    </table>

                  </div><!--modal body closing-->
                  <div class="form-group modal-footer">
                    <div class="pull-right">
                      <button id="contactModalClose" type="button" class="btn btn-primary" data-dismiss="modal" (click)="modal.dismiss('Close click')">Close</button>
                      <input type="hidden" id="hdnAppId" />

                    </div>

                  </div>
                  <!--</form>-->
                </ng-template>


