import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { NotificationResponse } from '../models/notification-response';
import { NotificationModel } from '../models/notification-model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  baseUrl:string = environment.baseUrl;
  constructor(private http: HttpClient) { }

  SaveNotification(addNewNotification: any): Observable<NotificationResponse> {
    return this.http.post<NotificationResponse>(this.baseUrl+'/api/Notification/SaveRecord', addNewNotification).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable));
  }

  FetchNotificationById(editNotificationData: any): Observable<NotificationResponse>{
    return this.http.post<NotificationResponse>(this.baseUrl+'/api/Notification/FetchNotificationById', editNotificationData).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable));
  }

private extractData(res: any) {
  let body = res;
  return body;
}
private handleErrorObservable(error: any) {
  console.error(error.message || error);
  return throwError(error);
}
}
