<div class="main" *ngIf="nextGenRole==2">
  <!--<div class="noRequest">
    <p>Hello User,</p>
    <p>You do not have permission to access this page.</p>
  </div>-->

  <div>
    <div class="row">
      <h1 class="pb-2 mt-4 mb-2 border-bottom" style="margin-left: -13px;">
        <span>NextGen Reporting</span>
        <button type="button" id="btnExportExcel" class="btn btn-primary" style="margin-left: 43%; margin-top: 5px;" [disabled]="enableExportToExcel" (click)="exportData();"><span class="fa fa-download"></span>Export to Excel</button>
        <button type="button" id="btnItAddNew" style="float: right;margin-right: 30px; margin-top:5px" class="btn btn-primary" (click)="open(formModal);"><span class="fa fa-plus"></span>Add New</button>
      </h1>

      <div class="row searchAreaRow">
        <form class="searchForm" [formGroup]="searchForm" (ngSubmit)="SearchNextGen();">
          <!--(ngSubmit)="FilterServiceReview();"-->


          <div class="searchBox col-lg-3" id="airIDFilter" ng-controller="NextGenController" #airIDFilter>

            <ng-autocomplete [data]="airIds"
                             placeholder="Enter AIR ID"
                             (inputChanged)='LoadAirIDs($event)'
                             (click)='autocompleteclickNG($event)'
                             (selected)='selectAirIDSearch($event)'
                             historyIdentifier="airIds"
                             [itemTemplate]="itemTemplate"
                             [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-option>
              <a [innerHTML]="option"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>

          </div>

          <div class="searchBox col-lg-3" id="applicationFilter" ng-controller="NextGenController" #applicationFilter>

            <ng-autocomplete [data]="applicationNames"
                             placeholder="Enter Application"
                             (inputChanged)='LoadApplicationNames($event)'
                             (click)='autocompleteclickNG($event)'
                             (selected)='selectApplicationSearch($event)'
                             historyIdentifier="applicationNames"
                             [itemTemplate]="itemTemplate"
                             [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-option>
              <a [innerHTML]="option"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>

          </div>

          <div id="IncidentNo" class="searchBox col-lg-3">
            <input type="text" id="txtIncidentNo" class="form-control" placeholder="Incident No" (click)='autocompleteINCclickNG($event)' name="IncidentNo" formControlName="IncidentNo" #IncidentNo />
          </div>

          <div class="searchBoxButton col-lg-3" style="width:auto;">
            <button type="submit" id="btnSrSearch" class="btn btn-primary"><span class="fa fa-search"></span> Search </button>
          </div>
        </form>
        <div class="row form-group" style="width: 100%;">
          <div class="col-lg-12">
            <mat-chip-list aria-label="Application selection">
              <mat-chip color="primary" *ngFor="let app of applicationNameAddForm;" (removed)="toggleSelectedApplication(app)">
                {{app}}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div class="row form-group suggesstion" style="width: 100%;">
          <div class="col-lg-12">
            <mat-chip-list aria-label="Application selection">
              <mat-chip color="primary" *ngFor="let airID of airIDAddForm;" (removed)="toggleSelectedAirIDs(airID)">
                {{airID}}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>

        <div id="dvalert" ng-controller="NextGenController" class="alert alert-danger selectError" *ngIf="errorMessage2">
          {{errorMessage2}}
        </div>
        
      </div>


      <mat-tab-group (selectedTabChange)="tabClick($event)">
        <mat-tab label="P1" (click)="fetchPoneData();" onclick="fetchPoneData();">
          <div class="p1"
               infinite-scroll
               [infiniteScrollDistance]=3
               [infiniteScrollThrottle]=50
               (scrolled)="onScroll()"
               [scrollWindow]="false">
            <table class="table table-bordered incidentTable" id="collapse">
              <thead>
                <tr style="background-color: #f5f5f5;">
                  <td><strong>Incident Number</strong></td>
                  <td><strong>Application Name</strong></td>
                  <td><strong>Service Tier</strong></td>
                  <td><strong>Description</strong></td>
                  <td><strong>Action</strong></td>
                  <td><strong>Action</strong></td>
                </tr>
              </thead>
              <tbody id="divbody">
                <tr class="incidentTable" *ngFor="let inc of loadednextGenP1Incidents">
                  <td>{{inc.Incident_ID}}</td>
                  <td>{{inc.ApplicationName}}</td>
                  <td>{{inc.Service_Tier}}</td>
                  <td>{{inc.Description}}</td>
                  <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="edit(editForm,p1IncidentForm, 'P1', inc);"><span class="fa fa-edit"></span>Edit</button></td>
                  <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="deleteNextGenP1(inc);"><span class="fa fa-edit"></span>Delete</button></td>
                </tr>
              </tbody>
            </table>
            </div>
        </mat-tab>
        <mat-tab label="P2" (click)="fetchPtwoData();">
          <table class="table table-bordered incidentTable" id="collapse">
            <thead>
              <tr style="background-color: #f5f5f5;">
                <td><strong>Incident Number</strong></td>
                <td><strong>Application Name</strong></td>
                <td><strong>Service Tier</strong></td>
                <td><strong>Root Cause</strong></td>
                <td><strong>Action</strong></td>
                <td><strong>Action</strong></td>
              </tr>
            </thead>
            <tbody id="divbody">
              <tr class="incidentTable" *ngFor="let inc of nextGenP2Incidents">
                <td>{{inc.Incident_ID}}</td>
                <td>{{inc.ApplicationName}}</td>
                <td>{{inc.Service_Tier}}</td>
                <td>{{inc.Root_cause_Categorization}}</td>
                <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="edit(editForm,p2IncidentForm, 'P2', inc);"><span class="fa fa-edit"></span>Edit</button></td>
                <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="deleteNextGenP2(inc);"><span class="fa fa-edit"></span>Delete</button></td>
              </tr>
            </tbody>
          </table>
        </mat-tab>
        <mat-tab label="Applications" (click)="fetchApplicationsData();">
          <table class="table table-bordered incidentTable" id="collapse">
            <thead>
              <tr style="background-color: #f5f5f5;">
                <td><strong>AIR ID</strong></td>
                <td><strong>Application Name</strong></td>
                <td><strong>Service Tier</strong></td>
                <td><strong>Service Area</strong></td>
                <td><strong>MD</strong></td>
                <td><strong>Service Operation Lead</strong></td>
                <td><strong>Reliability Director</strong></td>
                <td><strong>Project AIM</strong></td>
                <td><strong>GPC Managed</strong></td>
                <td><strong>Action</strong></td>
                <td><strong>Action</strong></td>
              </tr>
            </thead>
            <tbody id="divbody">
              <tr class="incidentTable" *ngFor="let application of nextGenApplications">
                <td>{{application.AirId}}</td>
                <td>{{application.ApplicationName}}</td>
                <td>{{application.ServiceTier}}</td>
                <td>{{application.ServiceArea}}</td>
                <td>{{application.MD}}</td>
                <td>{{application.ServiceOperationLead}}</td>
                <td>{{application.ReliabilityDirector}}</td>
                <td>{{application.ProjectAIM}}</td>
                <td>{{application.GPCManaged}}</td>
                <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="edit(editForm,nextGenApplicationsForm,'application', application);"><span class="fa fa-edit"></span>Edit</button></td>
                <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="deleteNextGenApplication(application);"><span class="fa fa-edit"></span>Delete</button></td>
              </tr>
            </tbody>
          </table>
        </mat-tab>
        <mat-tab label="Preventions" (click)="fetchPreventionData();">
          <div class="p1"
               infinite-scroll
               [infiniteScrollDistance]=3
               [infiniteScrollThrottle]=50
               (scrolled)="onPreventionScroll()"
               [scrollWindow]="false">
            <table class="table table-bordered incidentTable" id="collapse">
              <thead>
                <tr style="background-color: #f5f5f5;">
                  <td><strong>FY</strong></td>
                  <td><strong>Month</strong></td>
                  <td><strong>Incident Ticket</strong></td>
                  <td><strong>Date Identified</strong></td>
                  <td><strong>Duration</strong></td>
                  <td><strong>Prevented By</strong></td>
                  <td><strong>AIR ID</strong></td>
                  <td><strong>Application Name</strong></td>
                  <td><strong>MD</strong></td>
                  <td><strong>Refined Category</strong></td>
                  <td><strong>TAO Comments</strong></td>
                  <td><strong>Action</strong></td>
                  <td><strong>Action</strong></td>
                </tr>
              </thead>
              <tbody id="divbody">
                <tr class="incidentTable" *ngFor="let prevention of loadednextGenPreventions">
                  <td>{{prevention.FY}}</td>
                  <td>{{prevention.Month}}</td>
                  <td>{{prevention.Incident_Ticket}}</td>
                  <td>{{prevention.Date_Identified | date:'M/dd/yyyy hh:mm a'}}</td>
                  <td>{{prevention.Duration}}</td>
                  <td>{{prevention.Prevented_By}}</td>
                  <td>{{prevention.AIR_ID}}</td>
                  <td>{{prevention.Application_Name}}</td>
                  <td>{{prevention.MD}}</td>
                  <td>{{prevention.Refined_Category}}</td>
                  <td>{{prevention.TAO_Comments}}</td>
                  <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="edit(editForm,nextGenPreventionsForm, 'prevention', prevention);"><span class="fa fa-edit"></span>Edit</button></td>
                  <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="deleteNextGenPrevention(prevention);"><span class="fa fa-edit"></span>Delete</button></td>
                </tr>
              </tbody>
            </table>
            </div>
        </mat-tab>
      </mat-tab-group>
      <div class="alert alert-danger" *ngIf="errorMessage1" id="dvError">
        {{errorMessage1}}
      </div>
    </div>
  </div>

  <ng-template #formModal let-modal>

    <div class="modal-header">
      <h2 class="modal-title">{{modalTitle}}</h2>
      <button id="PPSClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click');clearErrors()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div id="modbody" class="modal-body">
      <div class="row form-group mb-3">
        <label class="col-form-label col-md-4" for="text" style="text-align: left;">Select Form<em style="color:red; margin-left: 5px;">*</em> :</label>
        <div class="col-md-6">
          <select class="form-control" [(ngModel)]="selectedForm">
            <option value="p1">P1</option>
            <option value="p2">P2</option>
            <option value="applications">Applications</option>
            <option value="preventions">Preventions</option>
          </select>
        </div>
      </div>

      <div *ngIf="selectedForm === 'p1'">
        <ng-template *ngTemplateOutlet="p1IncidentForm"></ng-template>
      </div>

      <div *ngIf="selectedForm === 'p2'">
        <ng-template *ngTemplateOutlet="p2IncidentForm"></ng-template>
      </div>

      <div *ngIf="selectedForm === 'applications'">
        <ng-template *ngTemplateOutlet="nextGenApplicationsForm"></ng-template>
      </div>

      <div *ngIf="selectedForm === 'preventions'">
        <ng-template *ngTemplateOutlet="nextGenPreventionsForm"></ng-template>
      </div>
    </div>
    <div class="alert alert-danger" ng-controller="NextGenController" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
    <div class="modal-footer">
      <div class="float-right">
        <button id="updateAddForm" type="button" class="btn btn-primary  double_button" (click)="saveAddForm();">Save</button>
        <button id="closeAddForm" type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click');clearErrors()">Close</button>
      </div>
    </div>
  </ng-template>

  <ng-template #editForm let-modal>

    <div class="modal-header">
      <h2 class="modal-title">{{modalTitle}}</h2>
      <button id="PPSClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click');clearErrors()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div id="modbody" class="modal-body">
      <div class="row form-group mb-3" *ngIf="currentForm === 'P1' || currentForm === 'P2'">
        <label class="col-form-label col-md-4" for="text" style="text-align: left;">Priority<em style="color:red; margin-left: 5px;">*</em> :</label>
        <div class="col-md-6">
          <select class="form-control" [(ngModel)]="priority">
            <option value="p1">P1</option>
            <option value="p2">P2</option>
          </select>
        </div>
      </div>
          <ng-template *ngTemplateOutlet="editFormContent"></ng-template>
    </div>
    <div class="alert alert-danger" ng-controller="NextGenController" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
        <div class="modal-footer">
          <div class="float-right">
            <button id="updateEditForm" type="button" class="btn btn-primary  double_button" (click)="saveEditForm();">Update</button>
            <button id="closeEditForm" type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click');clearErrors()">Close</button>
        </div>
     </div>
</ng-template>

    <ng-template #p1IncidentForm id="p1IncidentForm">
      <form name='modalForm' [formGroup]="P1IncidentForm">
        <div class="p1Incident">
          <fieldset [disabled]="formDisabled">
            <fieldset [disabled]="checkEditForm()">
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Incident ID<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <input id="incident_ID" class="form-control" name="Incident_ID" formControlName="Incident_ID" #Incident_ID>

                  <span class="text-danger"
                        *ngIf="(P1IncidentForm.controls.Incident_ID.touched) && P1IncidentForm.controls.Incident_ID.errors?.['required']">
                    Incident ID is required
                  </span>
                </div>
              </div>
            </fieldset>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">AIR ID<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <!--<input id="airId" class="form-control" name="AirId" #AirId>-->

                  <ng-autocomplete [data]="airIds"
                                   placeholder="Enter Air ID"
                                   (selected)='selectP1AirIDAdd($event)'
                                   (inputChanged)='LoadAirIDs($event)'
                                   historyIdentifier="airIds"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="AIR_ID">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>

                  <span class="text-danger"
                        *ngIf="(P1IncidentForm.controls.AIR_ID.touched) && P1IncidentForm.controls.AIR_ID.errors?.['required']">
                    Air ID is required
                  </span>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Application Name<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <ng-autocomplete [data]="applicationNames"
                                   placeholder="Enter Application Name"
                                   (selected)='selectP1ApplicationNameAdd($event)'
                                   (inputChanged)='LoadApplicationNames($event)'
                                   historyIdentifier="applicationNames"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="ApplicationName">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>

                  <span class="text-danger"
                        *ngIf="(P1IncidentForm.controls.ApplicationName.touched) && P1IncidentForm.controls.ApplicationName.errors?.['required']">
                    Application Name is required
                  </span>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Application Name AIR :</label>
                <div class="col-md-6">
                  <input id="application_Name_AIR" class="form-control" name="Application_Name_AIR" formControlName="Application_Name_AIR" #Application_Name_AIR>

                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">FY :</label>
                <div class="col-md-6">
                  <input id="FY" class="form-control" name="FY" formControlName="FY" #FY>

                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Quarter :</label>
                <div class="col-md-6">
                  <input id="quarter" class="form-control" name="Quarter" formControlName="Quarter" #Quarter>

                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Month :</label>
                <div class="col-md-6">
                  <input id="month" class="form-control" name="Month" formControlName="Month" #Month>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Users Impacted<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <input id="users_Impacted" class="form-control" name="Users_Impacted" formControlName="Users_Impacted" #Users_Impacted>

                  <span class="text-danger"
                        *ngIf="(P1IncidentForm.controls.Users_Impacted.touched) && P1IncidentForm.controls.Users_Impacted.errors?.['required']">
                    Users Impacted is required
                  </span>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Impacted Entity :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Impacted_Entity" formControlName="Impacted_Entity" #Impacted_Entity>
                    <option [ngValue]="null" selected>--Select Impacted Entity--</option>
                    <option *ngFor="let impactedEntity of asImpactedEntity; let i = index" [value]="asImpactedEntity[i].value" style="color:grey;">{{asImpactedEntity[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Description<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <input id="description" class="form-control" name="Description" formControlName="Description" #Description>

                  <span class="text-danger"
                        *ngIf="(P1IncidentForm.controls.Description.touched) && P1IncidentForm.controls.Description.errors?.['required']">
                    Description is required
                  </span>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">MD Name<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <ng-autocomplete [data]="SearchPeople"
                                   placeholder="Enter MD Name"
                                   (selected)='selectP1MDNameAdd($event)'
                                   (inputChanged)='LoadMdNames($event)'
                                   historyIdentifier="SearchPeople"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="MD_Name">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-people>
                    <a [innerHTML]="people"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>

                  <span class="text-danger"
                        *ngIf="(P1IncidentForm.controls.MD_Name.touched) && P1IncidentForm.controls.MD_Name.errors?.['required']">
                    MD Name is required
                  </span>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Day :</label>
                <div class="col-md-6">
                  <select class="form-control" name="day" formControlName="Day" #Day>
                    <option [ngValue]="null" selected>--Select Day--</option>
                    <option *ngFor="let day of asDay; let i = index" [value]="asDay[i].value" style="color:grey;">{{asDay[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Incident Start Time<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6 datePicker">
                  <!--<ngx-datetime-picker id="incident_Start_Time" class="form-control dateTimePicker" name="Incident_Start_Time" formControlName="Incident_Start_Time" #Incident_Start_Time />-->
                  <mat-form-field>
                    <input matInput [ngxMatDatetimePicker]="picker" placeholder="Incident start time" [min]="minDate" formControlName="Incident_Start_Time"
                           [disabled]="disabled">
                    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                             [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                             [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
                    </ngx-mat-datetime-picker>
                  </mat-form-field>
                  <span class="text-danger"
                        *ngIf="(P1IncidentForm.controls.Incident_Start_Time.errors?.['required'])">
                    Incident Start Time is required
                  </span>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Root Cause Categorization<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <input id="root_cause_Categorization" class="form-control" name="Root_cause_Categorization" formControlName="Root_cause_Categorization" #Root_cause_Categorization>

                  <span class="text-danger"
                        *ngIf="(P1IncidentForm.controls.Root_cause_Categorization.touched) && P1IncidentForm.controls.Root_cause_Categorization.errors?.['required']">
                    Root Cause Categorization is required
                  </span>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Pulse Root Cause :</label>
                <div class="col-md-6">
                  <input id="pulse_Root_Cause" class="form-control" name="Pulse_Root_Cause" formControlName="Pulse_Root_Cause" #Pulse_Root_Cause>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Sub Category :</label>
                <div class="col-md-6">
                  <input id="sub_Category" class="form-control" name="Sub_Category" formControlName="Sub_Category" #Sub_Category>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">RCA Category :</label>
                <div class="col-md-6">
                  <input id="rca_Category" class="form-control" name="RCA_Category" formControlName="RCA_Category" #RCA_Category>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Change Induced :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Change_Induced" formControlName="Change_Induced" #Change_Induced>
                    <option [ngValue]="null" selected>--Select Change Induced--</option>
                    <option *ngFor="let changeInduced of asChangeInduced; let i = index" [value]="asChangeInduced[i].value" style="color:grey;">{{asChangeInduced[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Change Ticket if any? :</label>
                <div class="col-md-6">
                  <input id="change_Ticket" class="form-control" name="Change_Ticket" formControlName="Change_Ticket" #Change_Ticket>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Outage Duration :</label>
                <div class="col-md-6">
                  <input id="outage_Duration" class="form-control" name="Outage_Duration" formControlName="Outage_Duration" type="number" #Outage_Duration>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Service Tier<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Service_Tier" formControlName="Service_Tier" #Service_Tier>
                    <option [ngValue]="null" selected>--Select Service Tier--</option>
                    <option *ngFor="let tier of asTier; let i = index" [value]="asTier[i].value" style="color:grey;">{{asTier[i].value}}</option>
                  </select>
                  <span class="text-danger"
                        *ngIf="(P1IncidentForm.controls.Service_Tier.touched) && P1IncidentForm.controls.Service_Tier.errors?.['required']">
                    Service Tier is required
                  </span>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">SLO Met :</label>
                <div class="col-md-6">
                  <select class="form-control" name="SLO_Met" formControlName="SLO_Met" #SLO_Met>
                    <option [ngValue]="null" selected>--Select SLO Met--</option>
                    <option *ngFor="let sloMet of asSLOMet; let i = index" [value]="asSLOMet[i].value" style="color:grey;">{{asSLOMet[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">GFP SLO :</label>
                <div class="col-md-6">
                  <select class="form-control" name="GFP_SLO" formControlName="GFP_SLO" #GFP_SLO>
                    <option [ngValue]="null" selected>--Select GFP SLO--</option>
                    <option *ngFor="let gfpSLO of asGFPSLO; let i = index" [value]="asGFPSLO[i].value" style="color:grey;">{{asGFPSLO[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">In App SLO :</label>
                <div class="col-md-6">
                  <select class="form-control" name="In_app_SLO" formControlName="In_app_SLO" #In_app_SLO>
                    <option [ngValue]="null" selected>--Select In App SLO--</option>
                    <option *ngFor="let inAppSLO of asInAppSLO; let i = index" [value]="asInAppSLO[i].value" style="color:grey;">{{asInAppSLO[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">GSM Engagement SLO :</label>
                <div class="col-md-6">
                  <select class="form-control" name="GSM_Engagement_SLO" formControlName="GSM_Engagement_SLO" #GSM_Engagement_SLO>
                    <option [ngValue]="null" selected>--Select GSM Engagement SLO--</option>
                    <option *ngFor="let gsmEngagementSLO of asGSMEngagementSLO; let i = index" [value]="asGSMEngagementSLO[i].value" style="color:grey;">{{asGSMEngagementSLO[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">MD Joined<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <select class="form-control" name="MD_joined" formControlName="MD_joined" #MD_joined>
                    <option [ngValue]="null" selected>--Select MD Joined--</option>
                    <option *ngFor="let mdJoined of asMDJoined; let i = index" [value]="asMDJoined[i].value" style="color:grey;">{{asMDJoined[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Playbook Usage :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Playbook_Usage" formControlName="Playbook_Usage" #Playbook_Usage>
                    <option [ngValue]="null" selected>--Select Playbook Usage--</option>
                    <option *ngFor="let playbookusage of asPlaybookUsage; let i = index" [value]="asPlaybookUsage[i].value" style="color:grey;">{{asPlaybookUsage[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Tech Teams SLO :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Tech_Teams_SLO" formControlName="Tech_Teams_SLO" #Tech_Teams_SLO>
                    <option [ngValue]="null" selected>--Select Tech Teams SLO--</option>
                    <option *ngFor="let techTeamsSLO of asTechTeamsSLO; let i = index" [value]="asTechTeamsSLO[i].value" style="color:grey;">{{asTechTeamsSLO[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">End User SLO :</label>
                <div class="col-md-6">
                  <select class="form-control" name="End_User_SLO" formControlName="End_User_SLO" #End_User_SLO>
                    <option [ngValue]="null" selected>--Select End User SLO--</option>
                    <option *ngFor="let endUserSLO of asEndUserSLO; let i = index" [value]="asEndUserSLO[i].value" style="color:grey;">{{asEndUserSLO[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Alerts Received :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Alerts_Received" formControlName="Alerts_Received" #Alerts_Received>
                    <option [ngValue]="null" selected>--Select Alerts Received--</option>
                    <option *ngFor="let alertsReceived of asAlertsReceived; let i = index" [value]="asAlertsReceived[i].value" style="color:grey;">{{asAlertsReceived[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Alert Remarks :</label>
                <div class="col-md-6">
                  <input id="alert_Remarks" class="form-control" name="Alerts_Remarks" formControlName="Alerts_Remarks" #Alerts_Remarks>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Alerts Justification :</label>
                <div class="col-md-6">
                  <input id="alerts_Justification" class="form-control" name="Alerts_Justification" formControlName="Alerts_Justification" #Alerts_Justification>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Recurring Issue :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Recurring_Issue" formControlName="Recurring_Issue" #Recurring_Issue>
                    <option [ngValue]="null" selected>--Select Recurring Issue--</option>
                    <option *ngFor="let recurringIssue of asRecurringIssue; let i = index" [value]="asRecurringIssue[i].value" style="color:grey;">{{asRecurringIssue[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">P1 ranking :</label>
                <div class="col-md-6">
                  <input id="app_Count" class="form-control" name="App_Count" formControlName="App_Count" type="number" #App_Count>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Ticket Priority Mismatch :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Ticket_Priority_Mismatch" formControlName="Ticket_Priority_Mismatch" #Ticket_Priority_Mismatch>
                    <option [ngValue]="null" selected>--Select Ticket Priority Mismatch--</option>
                    <option [ngValue]="0" selected>0</option>
                    <option [ngValue]="1" selected>1</option>
                    <!--<option *ngFor="let ticketPriorityMismatch of asTicketPriorityMismatch; let i = index" [value]="i" style="color:grey;">{{ticketPriorityMismatch.value}}</option>-->
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Issue Reported through GPC Bot<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Issue_Reported_through_GPC_Bot" formControlName="Issue_Reported_through_GPC_Bot" #Issue_Reported_through_GPC_Bot>
                    <option [ngValue]="null" selected>--Select Issue Reported through GPC Bot--</option>
                    <option *ngFor="let issueReportedthroughGPCBot of asIssueReportedthroughGPCBot; let i = index" [value]="asIssueReportedthroughGPCBot[i].value" style="color:grey;">{{asIssueReportedthroughGPCBot[i].value}}</option>
                  </select>
                  <span class="text-danger"
                        *ngIf="(P1IncidentForm.controls.Issue_Reported_through_GPC_Bot.touched) && P1IncidentForm.controls.Issue_Reported_through_GPC_Bot.errors?.['required']">
                    Issue Reported through GPC Bot is required
                  </span>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">RCA Description :</label>
                <div class="col-md-6">
                  <input id="rca_Description" class="form-control" name="RCA_Description" formControlName="RCA_Description" #RCA_Description>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Problem Ticket :</label>
                <div class="col-md-6">
                  <input id="problem_Ticket" class="form-control" name="Problem_Ticket" formControlName="Problem_Ticket" #Problem_Ticket>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Vendor Name :</label>
                <div class="col-md-6">
                  <input id="vendor_Name" class="form-control" name="Vendor_Name" formControlName="Vendor_Name" #Vendor_Name>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Reasons for missed SLOs :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Reasons_for_missed_SLOs" formControlName="Reasons_for_missed_SLOs" #Reasons_for_missed_SLOs>
                    <option [ngValue]="null" selected>--Select Reason for missed SLO--</option>
                    <option *ngFor="let reasonforMissedSLOs of asReasonforMissedSLOs; let i = index" [value]="asReasonforMissedSLOs[i].value" style="color:grey;">{{asReasonforMissedSLOs[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Occurrence in last 12 months :</label>
                <div class="col-md-6">
                  <input id="week_Numbers" class="form-control" name="Week_Numbers" formControlName="Week_Numbers" type="number" #Week_Numbers>
                </div>
              </div>
            </fieldset>
</div>
      </form>
    </ng-template>

    <ng-template #p2IncidentForm id="p2IncidentForm">
      <form name='modalForm' [formGroup]="PtwoIncidentForm">
        <div class="p2Incident">
          <fieldset [disabled]="formDisabled">
            <div class="row form-group mb-3">
              <label class="col-form-label col-md-4" for="text">FY :</label>
              <div class="col-md-6">
                <input id="fY" class="form-control" name="FY" formControlName="FY" #FY>
              </div>
            </div>


            <div class="row form-group mb-3">
              <label class="col-form-label col-md-4" for="text">Quarter :</label>
              <div class="col-md-6">
                <input id="quarter" class="form-control" name="Quarter" formControlName="Quarter" #Quarter>
              </div>
            </div>


            <div class="row form-group mb-3">
              <label class="col-form-label col-md-4" for="text">Month :</label>
              <div class="col-md-6">
                <input id="month" class="form-control" name="Month" formControlName="Month" #Month>
              </div>
            </div>

            <fieldset [disabled]="checkEditForm()">
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Incident ID<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <input id="incident_ID" class="form-control" name="Incident_ID" formControlName="Incident_ID" #Incident_ID>

                  <span class="text-danger"
                        *ngIf="(PtwoIncidentForm.controls.Incident_ID.touched) && PtwoIncidentForm.controls.Incident_ID.errors?.['required']">
                    Incident ID is required
                  </span>
                </div>
              </div>
            </fieldset>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">AIR ID<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">

                  <ng-autocomplete [data]="airIds"
                                   placeholder="Enter Air ID"
                                   (selected)='selectP2AirIDAdd($event)'
                                   (inputChanged)='LoadAirIDs($event)'
                                   historyIdentifier="airIds"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="AIR_ID">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>

                  <span class="text-danger"
                        *ngIf="(PtwoIncidentForm.controls.AIR_ID.touched) && PtwoIncidentForm.controls.AIR_ID.errors?.['required']">
                    AIR ID is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Application Name<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">

                  <ng-autocomplete [data]="applicationNames"
                                   placeholder="Enter Application Name"
                                   (selected)='selectP2ApplicationNameAdd($event)'
                                   (inputChanged)='LoadApplicationNames($event)'
                                   historyIdentifier="applicationNames"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="ApplicationName">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>

                  <span class="text-danger"
                        *ngIf="(PtwoIncidentForm.controls.ApplicationName.touched) && PtwoIncidentForm.controls.ApplicationName.errors?.['required']">
                    Application Name is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">MD Name<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">

                  <ng-autocomplete [data]="SearchPeople"
                                   placeholder="Enter MD Name"
                                   (selected)='selectP2MDNameAdd($event)'
                                   (inputChanged)='LoadMdNames($event)'
                                   historyIdentifier="SearchPeople"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="MD_Name">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>

                  <span class="text-danger"
                        *ngIf="(PtwoIncidentForm.controls.MD_Name.touched) && PtwoIncidentForm.controls.MD_Name.errors?.['required']">
                    MD Name is required
                  </span>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Day :</label>
                <div class="col-md-6">
                  <select class="form-control" name="day" formControlName="Day" #Day>
                    <option [ngValue]="null" selected>--Select Day--</option>
                    <option *ngFor="let day of asDay; let i = index" [value]="asDay[i].value" style="color:grey;">{{asDay[i].value}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Incident Start Time<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <!--<ngx-datetime-picker id="incident_Start_Time" class="form-control dateTimePicker" name="Incident_Start_Time" formControlName="Incident_Start_Time" #Incident_Start_Time />-->
                  <mat-form-field>
                    <input matInput [ngxMatDatetimePicker]="picker" placeholder="Incident start time" [min]="minDate" formControlName="Incident_Start_Time"
                           [disabled]="disabled">
                    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                             [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                             [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
                    </ngx-mat-datetime-picker>
                  </mat-form-field>
                  <span class="text-danger"
                        *ngIf=" PtwoIncidentForm.controls.Incident_Start_Time.errors?.['required']">
                    Incident Start Time is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Root cause Categorization<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <input id="root_cause_Categorization" class="form-control" name="Root_cause_Categorization" formControlName="Root_cause_Categorization" #Root_cause_Categorization>

                  <span class="text-danger"
                        *ngIf="(PtwoIncidentForm.controls.Root_cause_Categorization.touched) && PtwoIncidentForm.controls.Root_cause_Categorization.errors?.['required']">
                    Root cause Categorization is required
                  </span>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Sub Category :</label>
                <div class="col-md-6">
                  <input id="sub_Category" class="form-control" name="Sub_Category" formControlName="Sub_Category" #Sub_Category>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Change Induced :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Change_Induced" formControlName="Change_Induced" #Change_Induced>
                    <option [ngValue]="null" selected>--Select Change Induced--</option>
                    <option *ngFor="let changeInduced of asChangeInduced; let i = index" [value]="asChangeInduced[i].value" style="color:grey;">{{asChangeInduced[i].value}}</option>
                  </select>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Change Ticket if any? :</label>
                <div class="col-md-6">
                  <input id="change_Ticket" class="form-control" name="Change_Ticket" formControlName="Change_Ticket" #Change_Ticket>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Outage Duration Mins :</label>
                <div class="col-md-6">
                  <input id="outage_Duration_Mins" class="form-control" name="Outage_Duration_Mins" formControlName="Outage_Duration" type="number" #Outage_Duration_Mins>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">SLO Met :</label>
                <div class="col-md-6">
                  <select class="form-control" name="SLO_Met" formControlName="SLO_Met" #SLO_Met>
                    <option [ngValue]="null" selected>--Select SLO Met--</option>
                    <option *ngFor="let sloMet of asSLOMet; let i = index" [value]="asSLOMet[i].value" style="color:grey;">{{asSLOMet[i].value}}</option>
                  </select>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Service Tier<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <select class="form-control" name="Service_Tier" formControlName="Service_Tier" #Service_Tier>
                    <option [ngValue]="null" selected>--Select Service Tier--</option>
                    <option *ngFor="let tier of asTier; let i = index" [value]="asTier[i].value" style="color:grey;">{{asTier[i].value}}</option>
                  </select>
                  <span class="text-danger"
                        *ngIf="(PtwoIncidentForm.controls.Service_Tier.touched) && PtwoIncidentForm.controls.Service_Tier.errors?.['required']">
                    Service Tier is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Playbook Usage :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Playbook_Usage" formControlName="Playbook_Usage" #Playbook_Usage>
                    <option [ngValue]="null" selected>--Select Playbook Usage--</option>
                    <option *ngFor="let playbookusage of asPlaybookUsage; let i = index" [value]="asPlaybookUsage[i].value" style="color:grey;">{{asPlaybookUsage[i].value}}</option>
                  </select>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Ticket Priority Mismatch :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Ticket_Priority_Mismatch" formControlName="Ticket_Priority_Mismatch" #Ticket_Priority_Mismatch>
                    <option [ngValue]="null" selected>--Select Ticket Priority Mismatch--</option>
                    <option [ngValue]="0" selected>0</option>
                    <option [ngValue]="1" selected>1</option>
                    <!--<option *ngFor="let ticketPriorityMismatch of asTicketPriorityMismatch; let i = index" [value]="asTicketPriorityMismatch[i].value" style="color:grey;">{{asTicketPriorityMismatch[i].value}}</option>-->
                  </select>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">MD MOD Joined :</label>
                <div class="col-md-6">
                  <select class="form-control" name="MD_joined" formControlName="MD_joined" #MD_joined>
                    <option [ngValue]="null" selected>--Select MD MOD Joined--</option>
                    <option *ngFor="let mdJoined of asMDJoined; let i = index" [value]="asMDJoined[i].value" style="color:grey;">{{asMDJoined[i].value}}</option>
                  </select>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">GSM Engagement Delays :</label>
                <div class="col-md-6">
                  <select class="form-control" name="GSM_Engagement_SLO" formControlName="GSM_Engagement_SLO" #GSM_Engagement_SLO>
                    <option [ngValue]="null" selected>--Select GSM Engagement Delays--</option>
                    <option *ngFor="let gsmEngagementSLO of asGSMEngagementSLO; let i = index" [value]="asGSMEngagementSLO[i].value" style="color:grey;">{{asGSMEngagementSLO[i].value}}</option>
                  </select>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">End User SLO :</label>
                <div class="col-md-6">
                  <select class="form-control" name="End_User_SLO" formControlName="End_User_SLO" #End_User_SLO>
                    <option [ngValue]="null" selected>--Select End User SLO--</option>
                    <option *ngFor="let endUserSLO of asEndUserSLO; let i = index" [value]="asEndUserSLO[i].value" style="color:grey;">{{asEndUserSLO[i].value}}</option>
                  </select>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Recurring Issue :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Recurring_Issue" formControlName="Recurring_Issue" #Recurring_Issue>
                    <option [ngValue]="null" selected>--Select Recurring Issue--</option>
                    <option *ngFor="let recurringIssue of asRecurringIssue; let i = index" [value]="asRecurringIssue[i].value" style="color:grey;">{{asRecurringIssue[i].value}}</option>
                  </select>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Issue Reported through GPC Bot<em style="color:red; margin-left: 5px;">*</em> :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Issue_Reported_through_GPC_Bot" formControlName="Issue_Reported_through_GPC_Bot" #Issue_Reported_through_GPC_Bot>
                    <option [ngValue]="null" selected>--Select Issue Reported through GPC Bot--</option>
                    <option *ngFor="let issueReportedthroughGPCBot of asIssueReportedthroughGPCBot; let i = index" [value]="asIssueReportedthroughGPCBot[i].value" style="color:grey;">{{asIssueReportedthroughGPCBot[i].value}}</option>
                  </select>
                  <span class="text-danger"
                        *ngIf="(PtwoIncidentForm.controls.Issue_Reported_through_GPC_Bot.touched) && PtwoIncidentForm.controls.Issue_Reported_through_GPC_Bot.errors?.['required']">
                    Issue Reported through GPC Bot is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Alerts Received :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Alerts_Received" formControlName="Alerts_Received" #Alerts_Received>
                    <option [ngValue]="null" selected>--Select Alerts Received--</option>
                    <option *ngFor="let alertsReceived of asAlertsReceived; let i = index" [value]="asAlertsReceived[i].value" style="color:grey;">{{asAlertsReceived[i].value}}</option>
                  </select>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Alert Remarks :</label>
                <div class="col-md-6">
                  <input id="alert_Remarks" class="form-control" name="Alerts_Remarks" formControlName="Alerts_Remarks" #Alerts_Remarks>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Alerts Justification :</label>
                <div class="col-md-6">
                  <input id="alerts_Justification" class="form-control" name="Alerts_Justification" formControlName="Alerts_Justification" #Alerts_Justification>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Problem Ticket :</label>
                <div class="col-md-6">
                  <input id="problem_Ticket" class="form-control" name="Problem_Ticket" formControlName="Problem_Ticket" #Problem_Ticket>
                </div>
              </div>
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Vendor Name :</label>
                <div class="col-md-6">
                  <input id="vendor_Name" class="form-control" name="Vendor_Name" formControlName="Vendor_Name" #Vendor_Name>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">RCA Statement :</label>
                <div class="col-md-6">
                  <input id="rca_Description" class="form-control" name="RCA_Description" formControlName="RCA_Description" #RCA_Description>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Reasons for missed SLOs :</label>
                <div class="col-md-6">
                  <select class="form-control" name="Reasons_for_missed_SLOs" formControlName="Reasons_for_missed_SLOs" #Reasons_for_missed_SLOs>
                    <option [ngValue]="null" selected>--Select Reason for missed SLO--</option>
                    <option *ngFor="let reasonforMissedSLOs of asReasonforMissedSLOs; let i = index" [value]="asReasonforMissedSLOs[i].value" style="color:grey;">{{asReasonforMissedSLOs[i].value}}</option>
                  </select>
                </div>
              </div>

            </fieldset>
</div>
      </form>
    </ng-template>

    <ng-template #nextGenApplicationsForm id="nextGenApplicationsForm">
      <form name='modalForm' [formGroup]="NextGenApplicationsForm">
        <div class="nextGenApplications">
          <fieldset [disabled]="formDisabled">
            <fieldset [disabled]="checkEditForm()">
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Application ID<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <ng-autocomplete [data]="airIds"
                                   placeholder="Enter Air ID"
                                   (selected)='selectApplicationAirIDAdd($event)'
                                   (inputChanged)='LoadAirIDs($event)'
                                   historyIdentifier="airIds"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="AirId">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                  <span class="text-danger"
                        *ngIf="(NextGenApplicationsForm.controls.AirId.touched) && NextGenApplicationsForm.controls.AirId.errors?.['required']">
                    Application ID is required
                  </span>
                </div>
              </div>
            </fieldset>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Name<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <ng-autocomplete [data]="applicationNames"
                                   placeholder="Enter Application Name"
                                   (selected)='selectAppApplicationNameAdd($event)'
                                   (inputChanged)='LoadApplicationNames($event)'
                                   historyIdentifier="applicationNames"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="ApplicationName">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>

                  <span class="text-danger"
                        *ngIf="(NextGenApplicationsForm.controls.ApplicationName.touched) && NextGenApplicationsForm.controls.ApplicationName.errors?.['required']">
                    Name is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Service Tier<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <input id="service_Tier" class="form-control" name="Service_Tier" formControlName="ServiceTier" #Service_Tier>

                  <span class="text-danger"
                        *ngIf="(NextGenApplicationsForm.controls.ServiceTier.touched) && NextGenApplicationsForm.controls.ServiceTier.errors?.['required']">
                    Service Tier is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">MD<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <ng-autocomplete [data]="SearchPeople"
                                   placeholder="Enter MD Name"
                                   (selected)='selectApplicationMDNameAdd($event)'
                                   (inputChanged)='LoadMdNames($event)'
                                   historyIdentifier="SearchPeople"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="MD">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>

                  <span class="text-danger"
                        *ngIf="(NextGenApplicationsForm.controls.MD.touched) && NextGenApplicationsForm.controls.MD.errors?.['required']">
                    MD is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Lead:</label>
                <div class="col-md-6">
                  <ng-autocomplete [data]="SearchPeople"
                                   placeholder="Enter Lead Name"
                                   (selected)='selectApplicationLeadNameAdd($event)'
                                   (inputChanged)='LoadMdNames($event)'
                                   historyIdentifier="SearchPeople"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="ServiceOperationLead">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>


                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Reliability Director:</label>
                <div class="col-md-6">

                  <ng-autocomplete [data]="SearchPeople"
                                   placeholder="Enter Manager Name"
                                   (selected)='selectApplicationManagerNameAdd($event)'
                                   (inputChanged)='LoadMdNames($event)'
                                   historyIdentifier="SearchPeople"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="ReliabilityDirector">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>


                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">GPC Managed Application:</label>
                <div class="col-md-6">
                  <input id="gPC_Managed_Application" class="form-control" name="GPC_Managed_Application" formControlName="GPCManaged" #GPC_Managed_Application>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Service Area:</label>
                <div class="col-md-6">
                  <input id="service_Area" class="form-control" name="Service_Area" formControlName="ServiceArea" #Service_Area>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Project AIM:</label>
                <div class="col-md-6">
                  <input id="project_AIM" class="form-control" name="Project_AIM" formControlName="ProjectAIM" #Project_AIM>
                </div>
              </div>
            </fieldset>
</div>
      </form>
    </ng-template>

    <ng-template #nextGenPreventionsForm id="nextGenPreventionsForm">
      <form name='modalForm' [formGroup]="NextGenPreventionsForm">
        <div class="nextGenPreventions">
          <fieldset [disabled]="formDisabled">
            <div class="row form-group mb-3">
              <label class="col-form-label col-md-4" for="text">FY<em style="color:red; margin-left: 5px;">*</em>:</label>
              <div class="col-md-6">
                <input id="fY" class="form-control" name="FY" formControlName="FY" #FY>

                <span class="text-danger"
                      *ngIf="(NextGenPreventionsForm.controls.FY.touched) && NextGenPreventionsForm.controls.FY.errors?.['required']">
                  FY is required
                </span>
              </div>
            </div>


            <div class="row form-group mb-3">
              <label class="col-form-label col-md-4" for="text">Month<em style="color:red; margin-left: 5px;">*</em>:</label>
              <div class="col-md-6">
                <input id="month" class="form-control" name="Month" formControlName="Month" #Month>

                <span class="text-danger"
                      *ngIf="(NextGenPreventionsForm.controls.Month.touched) && NextGenPreventionsForm.controls.Month.errors?.['required']">
                  Month is required
                </span>
              </div>
            </div>

            <fieldset [disabled]="checkEditForm()">
              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Incident Ticket<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <input id="incident_Ticket" class="form-control" name="Incident_Ticket" formControlName="Incident_Ticket" #Incident_Ticket>

                  <span class="text-danger"
                        *ngIf="(NextGenPreventionsForm.controls.Incident_Ticket.touched) && NextGenPreventionsForm.controls.Incident_Ticket.errors?.['required']">
                    Incident Ticket is required
                  </span>
                </div>
              </div>
            </fieldset>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Date Identified<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <!--<ngx-datetime-picker id="date_Identified" class="form-control dateTimePicker" name="Date_Identified" formControlName="Date_Identified" #Date_Identified />-->
                  <mat-form-field>
                    <input matInput [ngxMatDatetimePicker]="picker" placeholder="Date Identified" [min]="minDate" formControlName="Date_Identified"
                           [disabled]="disabled">
                    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                             [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                             [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
                    </ngx-mat-datetime-picker>
                  </mat-form-field>
                  <span class="text-danger"
                        *ngIf="(NextGenPreventionsForm.controls.Date_Identified.touched) && NextGenPreventionsForm.controls.Date_Identified.errors?.['required']">
                    Date Identified is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Duration<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <input id="duration" class="form-control" name="Duration" formControlName="Duration" #Duration>

                  <span class="text-danger"
                        *ngIf="(NextGenPreventionsForm.controls.Duration.touched) && NextGenPreventionsForm.controls.Duration.errors?.['required']">
                    Duration is required
                  </span>
                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Start Date :</label>
                <div class="col-md-6">
                  <mat-form-field>
                    <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Start Date"  [min]="minDate" formControlName="Start_Date"
                           [disabled]="disabled">
                    <mat-datepicker-toggle matSuffix [for]="$any(picker1)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker1 [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                             [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                             [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
                    </ngx-mat-datetime-picker>
                  </mat-form-field>

                </div>
              </div>

              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Prevented By<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <input id="prevented_By" class="form-control" name="Prevented_By" formControlName="Prevented_By" #Prevented_By>

                  <span class="text-danger"
                        *ngIf="(NextGenPreventionsForm.controls.Prevented_By.touched) && NextGenPreventionsForm.controls.Prevented_By.errors?.['required']">
                    Prevented By is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">AIR ID<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">

                  <ng-autocomplete [data]="airIds"
                                   placeholder="Enter Air ID"
                                   (selected)='selectPreventionAirIDAdd($event)'
                                   (inputChanged)='LoadAirIDs($event)'
                                   historyIdentifier="airIds"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="AIR_ID">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>

                  <span class="text-danger"
                        *ngIf="(NextGenPreventionsForm.controls.AIR_ID.touched) && NextGenPreventionsForm.controls.AIR_ID.errors?.['required']">
                    AIR ID is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Application Name<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">

                  <ng-autocomplete [data]="applicationNames"
                                   placeholder="Enter Application Name"
                                   (selected)='selectPreventionApplicationNameAdd($event)'
                                   (inputChanged)='LoadApplicationNames($event)'
                                   historyIdentifier="applicationNames"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="Application_Name">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>

                  <span class="text-danger"
                        *ngIf="(NextGenPreventionsForm.controls.Application_Name.touched) && NextGenPreventionsForm.controls.Application_Name.errors?.['required']">
                    Application Name is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">MD<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">

                  <ng-autocomplete [data]="SearchPeople"
                                   placeholder="Enter MD Name"
                                   (selected)='selectPreventionMDNameAdd($event)'
                                   (inputChanged)='LoadMdNames($event)'
                                   historyIdentifier="SearchPeople"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate"
                                   formControlName="MD">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-option>
                    <a [innerHTML]="option"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>

                  <span class="text-danger"
                        *ngIf="(NextGenPreventionsForm.controls.MD.touched) && NextGenPreventionsForm.controls.MD.errors?.['required']">
                    MD is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Category<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <input id="category" class="form-control" name="Category" formControlName="Category" #Category>

                  <span class="text-danger"
                        *ngIf="(NextGenPreventionsForm.controls.Category.touched) && NextGenPreventionsForm.controls.Category.errors?.['required']">
                    Category is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">Refined Category<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <input id="refined_Category" class="form-control" name="Refined_Category" formControlName="Refined_Category" #Refined_Category>

                  <span class="text-danger"
                        *ngIf="(NextGenPreventionsForm.controls.Refined_Category.touched) && NextGenPreventionsForm.controls.Refined_Category.errors?.['required']">
                    Refined Category is required
                  </span>
                </div>
              </div>


              <div class="row form-group mb-3">
                <label class="col-form-label col-md-4" for="text">TAO Comments<em style="color:red; margin-left: 5px;">*</em>:</label>
                <div class="col-md-6">
                  <input id="tAO_Comments" class="form-control" name="TAO_Comments" formControlName="TAO_Comments" #TAO_Comments>

                  <span class="text-danger"
                        *ngIf="(NextGenPreventionsForm.controls.TAO_Comments.touched) && NextGenPreventionsForm.controls.TAO_Comments.errors?.['required']">
                    TAO Comments is required
                  </span>
                </div>
              </div>
            </fieldset>
</div>
      </form>
    </ng-template>

</div>
      <div class="main" *ngIf="nextGenRole == 1">
        <div>
          <h1 class="pb-2 mt-4 mb-2 border-bottom">
            NextGen Service Pulse
          </h1>

        </div>

        <br />
        <div class="row searchAreaRow" hidden>
          <form class="searchForm" [formGroup]="searchForm">
            <div class="searchBox col-lg-3">

              <select class="form-control" name="status" formControlName="status" #status>
                <option [ngValue]="null" selected>Application Name</option>
                <option *ngFor="let status of asStatus; let i = index" [value]="asStatus[i].value" style="color:grey;">{{asStatus[i].value}}</option>
              </select>
            </div>
            <div class="searchBox col-lg-3">
              <select class="form-control" name="service" formControlName="service" #service>
                <option [ngValue]="null" selected>Incident Number</option>
                <option *ngFor="let tier of asTier; let i = index" [value]="asTier[i].value" style="color:grey;">{{asTier[i].value}}</option>
              </select>
            </div>
            <div class="searchBoxButton col-lg-3">
              <button type="submit" id="btnSrSearch" class="btn btn-primary"><span class="fa fa-search"></span> Search </button>
            </div>

          </form>

        </div>
        <!--<div id="dvalert" class="alert alert-danger" ng-show="false">-->
        <!--{{errorMessage}}-->
        <!--</div>-->
        <form class="flex-row form-inline" [formGroup]="hiddenform" style="display:none;">
          <div class="row form-group">
            <div class="row form-group">
              <input type="hidden" id="loggeduser" class="form-control" name="LoggedInUser" formControlName="LoggedInUser" #LoggedInUser />
            </div>
          </div>
        </form>
        <div class="noIncidentData" [hidden]="hideIncidentTable">
          <p>Hello User,</p>
          <p>There are no P1 incidents currently.</p>
        </div>
        <div id="divSuccess" class="row" ng-show="show" [hidden]="showIncidentTable">

          <table class="table table-bordered incidentTable" id="collapse">
            <thead>
              <tr style="background-color: #f5f5f5;">
                <td><strong>Incident Number</strong></td>
                <td><strong>Application Name</strong></td>
                <td><strong>Service Tier</strong></td>
                <td><strong>Description</strong></td>
                <td><strong>Action</strong></td>
              </tr>
            </thead>
            <tbody id="divbody">
              <tr class="incidentTable" *ngFor="let inc of incidentsAppsDetails">
                <td>{{inc.IncidentNumber}}</td>
                <td>{{inc.ApplicationName}}</td>
                <td>{{inc.ServiceTier}}</td>
                <td>{{inc.Description}}</td>
                <td><button type="button" id="btnEdit" class="btn btn-primary" (click)="viewIncident(modalForm, inc)"><span class="fa fa-edit"></span>Edit</button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--</mat-expansion-panel>
                </ng-container>
              </mat-accordion>
            </div>
          </div>
        </div>-->
        <ng-template #modalForm let-modal id="myModal">
          <div class="modal-header">
            <h2 class="modal-title">{{incidentNumber}}</h2>
            <button id="PPSClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click');clearErrors()"><span aria-hidden="true">&times;</span></button>
          </div>
          <form name='modalForm' [formGroup]="IncidentForm">
            <div id="modbody" class="modal-body">
              <fieldset [disabled]="formDisabled">
                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text">Application AIR ID<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <input id="appAIRid" class="form-control" name="AirId" formControlName="AirId" readonly #AirId>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text">Application Name<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <input id="appname" class="form-control" name="ApplicationName" formControlName="ApplicationName" readonly #ApplicationName>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">Service Tier<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <select class="form-control" name="serviceTier" id="serviceTier" formControlName="ServiceTier" #ServiceTier required>
                      <option [ngValue]="null" selected>Select Service Tier</option>
                      <option *ngFor="let tier of asTier; let i = index" [value]="asTier[i].value" style="color:grey;">{{asTier[i].value}}</option>
                    </select>
                    <span class="text-danger"
                          *ngIf="(IncidentForm.controls.ServiceTier.touched) && IncidentForm.controls.ServiceTier.errors?.['required']">
                      Service Tier is required
                    </span>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">Root Cause Category<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <select class="form-control" name="rootCauseCategory" id="rootCauseCategory" formControlName="RootCauseCategory" #RootCauseCategory required>
                      <option [ngValue]="null" selected>Select Root Cause Category</option>
                      <option *ngFor="let rootCauseCategory of asRootCauseCategory; let i = index" [value]="asRootCauseCategory[i].value" style="color:grey;">{{asRootCauseCategory[i].value}}</option>
                    </select>
                    <span class="text-danger"
                          *ngIf="(IncidentForm.controls.RootCauseCategory.touched) && IncidentForm.controls.RootCauseCategory.errors?.['required']">
                      Root Cause Category is required
                    </span>
                  </div>
                </div>

                <div class="row form-group mb-3" *ngIf="IncidentForm.controls.RootCauseCategory.value == 'Vendor Issue'">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">  Sub-Category<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <select class="form-control" name="subCategory" id="subCategory" formControlName="SubCategory" #Subcategory required>
                      <option [ngValue]="null" selected>Select Sub-Category</option>
                      <option *ngFor="let subCategory of asSubCategory; let i = index" [value]="asSubCategory[i].value" style="color:grey;">Vendor-{{asSubCategory[i].value}}</option>
                    </select>
                    <span class="text-danger"
                          *ngIf="(IncidentForm.controls.SubCategory.touched) && IncidentForm.controls.SubCategory.errors?.['required']">
                      Sub-Category is required
                    </span>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text">Description<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <input id="Description" class="form-control" name="Description" formControlName="Description" #Description>
                    <span class="text-danger"
                          *ngIf="(IncidentForm.controls.Description.touched) && IncidentForm.controls.Description.errors?.['required']">
                      Description is required
                    </span>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text">Duration<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <input id="Duration" class="form-control" name="Duration" formControlName="Duration" type="number" #Duration>
                    <span class="text-danger"
                          *ngIf="(IncidentForm.controls.Duration.touched) && IncidentForm.controls.Duration.errors?.['required']">
                      Duration is required
                    </span>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text">User Impacted<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <input id="UserImpacted" class="form-control" name="UserImpacted" formControlName="UserImpacted" #UserImpacted>
                    <span class="text-danger"
                          *ngIf="(IncidentForm.controls.UserImpacted.touched) && IncidentForm.controls.UserImpacted.errors?.['required']">
                      Users Impacted is required
                    </span>
                  </div>
                </div>



                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">End Users<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <select class="form-control" name="endUsers" id="endUsers" formControlName="EndUsers" #EndUsers required>
                      <option [ngValue]="null" selected>Select End User SLO</option>
                      <option *ngFor="let endUsers of asEndUsers; let i = index" [value]="asEndUsers[i].value" style="color:grey;">{{asEndUsers[i].value}}</option>
                    </select>
                    <span class="text-danger"
                          *ngIf="(IncidentForm.controls.EndUsers.touched) && IncidentForm.controls.EndUsers.errors?.['required']">
                      End User is required
                    </span>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">Response<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <select class="form-control" name="response" id="response" formControlName="Response" #Response required>
                      <option [ngValue]="null" selected>Select Response</option>
                      <option *ngFor="let response of asResponse; let i = index" [value]="asResponse[i].value" style="color:grey;">{{asResponse[i].value}}</option>
                    </select>
                    <span class="text-danger"
                          *ngIf="(IncidentForm.controls.Response.touched) && IncidentForm.controls.Response.errors?.['required']">
                      Response is required
                    </span>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">Restore<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <select class="form-control" name="restore" id="restore" formControlName="Restore" #Restore required>
                      <option [ngValue]="null" selected>Select Restore</option>
                      <option *ngFor="let restore of asRestore; let i = index" [value]="asRestore[i].value" style="color:grey;">{{asRestore[i].value}}</option>
                    </select>
                    <span class="text-danger"
                          *ngIf="(IncidentForm.controls.Restore.touched) && IncidentForm.controls.Restore.errors?.['required']">
                      Restore Category is required
                    </span>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">Leadership<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <select class="form-control" name="leadership" id="leadership" formControlName="Leadership" #Leadership required>
                      <option [ngValue]="null" selected>Select Leadership</option>
                      <option *ngFor="let leadership of asLeadership; let i = index" [value]="asLeadership[i].value" style="color:grey;">{{asLeadership[i].value}}</option>
                    </select>
                    <span class="text-danger"
                          *ngIf="(IncidentForm.controls.Leadership.touched) && IncidentForm.controls.Leadership.errors?.['required']">
                      Leadership is required
                    </span>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">Playbook<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-6">
                    <select class="form-control" name="playbook" id="playbook" formControlName="Playbook" #Playbook required>
                      <option [ngValue]="null" selected>Select Root Cause Category</option>
                      <option *ngFor="let playbook of asPlaybook; let i = index" [value]="asPlaybook[i].value" style="color:grey;">{{asPlaybook[i].value}}</option>
                    </select>
                    <span class="text-danger"
                          *ngIf="(IncidentForm.controls.Playbook.touched) && IncidentForm.controls.Playbook.errors?.['required']">
                      Playbook Category is required
                    </span>
                  </div>
                </div>

              </fieldset>

            </div> <!--Modal body closing-->
          </form>
          <br />
          <div class="alert alert-danger" ng-controller="NextGenController" *ngIf="errorMessage">
            {{errorMessage}}
          </div>

          <div class="modal-footer">
            <div class="float-right">

              <p class="approveMessage" *ngIf="approveMessage">{{approveMessage}}</p>

              <button id="btnApForForm" type="button" class="btn btn-primary  double_button" (click)="saveChanges();">Update</button>
              <button id="Closebtn" type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click');clearErrors()">Cancel</button>
            </div>
          </div>
        </ng-template>

      </div> <!--Main closing-->
