export const environment = {
  production: false,
  apiKey: 'devKey',
  name: 'development',
  baseUrl: "https://gpcdigitalapi.ciodev.accenture.com/",
  scopeUri: ['api://30125ec2-e13d-4814-800f-374325573b12/user_impersonation'],
  tenantId: 'f3211d0e-125b-42c3-86db-322b19a65a22',
  uiClienId: '812817d1-88c6-4383-856b-c076f0852c6b',
  redirectUrl: 'https://gpcdigital.ciodev.accenture.com/',
  resource: 'api://30125ec2-e13d-4814-800f-374325573b12',
  authority: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22'
};
