import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class UploadSamplesService {
    // API url
    baseURLenv : string = environment.baseUrl;
    baseApiUrl = this.baseURLenv+"api/UploadSample/Upload"
    constructor(private http: HttpClient) { }

    // Returns an observable
    //upload(file: any): Observable<any> {
    //    // Create form data
    //    const formData = new FormData();
    //    // Store form name as "file" with file data
    //    formData.append("file", file, file.name);
    //    // Make http post request over api
    //    // with formData as req
    //    return this.http.post(this.baseApiUrl, formData)
    //}

    Upload(file: any): Observable<any> {
        //if(wrong Enterprise Id -- You do not have access to upload SOP)
        if (file['FILE'][0] != null) {
            var ext = file['FILE'][0].split('.').pop();
            if (ext == "zip") {
                const formData = new FormData();
                formData.append("file", file, file['FILE'][0]);
                return this.http.post(this.baseApiUrl, formData);
            }
            else {
                var ErrorMessage = "Not a .zip file"
                alert("Not a zip File");
                var Result = "Upload Failed";
                return this.http.post(Result, ErrorMessage);
            }
        }
        Result = "";
        var ErrorMessage = "No file selected";
        return this.http.post(Result, ErrorMessage);
    }
}