<!--<head>
    <link href="~/Content/Styles/font-awesome.min.css" rel="stylesheet">
</head>-->
<div class="main">
  <div class="row" ng-controller="MonitoringTrackerController">
    <h1 class="pb-2 mt-4 mb-2 border-bottom" style="margin-left: -13px;">
      Monitoring Tracker
      <button type="button" id="btnMtExportExcel" style="float: right; margin-top:5px" class=" btn btn-primary" (click)="exportData();"><span class="fa fa-download"></span>Export to Excel</button>
      <button type="button" id="btnMtAddNew" style="float: right;margin-right: 30px;margin-left:377px; margin-top:5px" (click)="openAddForm(modaladdForm);" ng-click=" OnLoad();" class="btn btn-primary"><span class="fa fa-plus"></span>Add New</button>
    </h1>
  </div>
  


  <div class="row searchFormWrap" style="margin-left: -38px;">
    <form class="d-flex flex-row align-items-center flex-wrap" [formGroup]="searchForm" (ngSubmit)="FilterMTracker()">
      <div class="row col-md-2" style="margin-left: 0px;">
        <input id="dtStart" placeholder="Start Date" class="form-control" name="dtStartDate" formControlName="dtStartDate" #dtStartDate onfocus="(this.type='date')">
      </div>
      <div class="col-md-2" style="margin-left: -2px;">
        <input id="dtend" placeholder="End Date" class="form-control" name="dtEndDate" formControlName="dtEndDate" #dtEndDate onfocus="(this.type='date')" style="margin-left: -5px;">
      </div>
      <div class="col-md-2" id="statusFilter" ng-controller="MonitoringTrackerController" ng-init="LoadStatus();" style="margin-left:-14px">
        <select id="Status" ng-model="selectedStatus" ng-options="st.value as st.value for st in status" class="form-control" name="Status" formControlName="Status" #Status>
          <!-- <option value="">--Select Status--</option> -->
          <option [ngValue]="null" selected>--Select Status--</option>
          <option *ngFor="let st of status; let i = index" [value]="status[i].value">{{status[i].value}}</option>
        </select>
      </div>
      <div class="col-md-2" id="applicationFilter" #applicationFilter>
        <!-- <mat-form-field>
            <input id="txtSearch" type="text" placeholder="Search Application" matInput [formControl]="myControl" [matAutocomplete]="auto" (keyup)="LoadApplications();" name="txtSearch" formControlName="txtSearch" #txtSearch multiple>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of options" class="form-control" [value]="option.AirId +' - '+ option.Name" (click)="getSelectedAirIds(option)">
                    {{option.AirId}} - {{option.Name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field> -->
        <!--<input type="text" ng-model="selectedApplication" id="appMonitoringTracker" class="form-control" style="width:123%;" name="ApplicationId" formControlName="ApplicationId" #ApplicationId placeholder="Application" ng-blur="CheckContent();" />-->
        <ng-autocomplete [data]="applications"
                         [searchKeyword]="keyword"
                         placeholder="Enter Application"
                         (click)='autocompleteclickMT($event)'
                         (selected)='selectEventSearchMT($event)'
                         (inputChanged)='LoadApplication($event)'
                         historyIdentifier="applications"
                         [itemTemplate]="itemTemplate"
                         [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-option>
          <a [innerHTML]="option.Name"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
      <div class="col-md-2" id="focusareaFilter" ng-controller="MonitoringTrackerController" ng-init="LoadFocusArea();">
        <select ng-model="selectedArea" ng-options="fa.value as fa.value for fa in area" class="form-control" name="FocusArea" formControlName="FocusArea" #FocusArea>
          <option [ngValue]="null" selected>--Select Focus Area--</option>
          <option *ngFor="let fa of area; let i = index" [value]="area[i].value">{{area[i].value}}</option>
        </select>
      </div>
      <div class="row form-group mb-3" style="display: none">
        <input name="LoggedInUser" formControlName="LoggedInUser" value="{{username}}" #LoggedInUser />
      </div>
      <div class="col-md-2" style="margin-left: 0px;">
        <button type="submit" id="btnMtSearch" class="btn btn-primary"><span class="fa fa-search"></span> Search </button>
      </div>
    </form>
  </div>
  <br />

  <div class="row form-group" style="width: 100%;">
    <div class="col-lg-12">
      <mat-chip-list aria-label="Application selection">
        <mat-chip color="primary" *ngFor="let app of applicationNameSearchForm;" (removed)="toggleSelectedAirIds(app)">
          {{app}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <br />
  <br />
  <div id="dvalert" ng-controller="MonitoringTrackerController" class="alert alert-danger" *ngIf="errorMessageFilter">
    {{errorMessageFilter}}
  </div>

  <div id="dvtracker" class="row" ng-show="show">
    <div class="card-group" style="width:100%; padding:2.5px">
      <div class="card" style="border:none">
        <mat-accordion [multi]="false">
          <mat-expansion-panel style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;" id={{app.AirId}} *ngFor="let app of MonitoringTracker" [expanded]="panelOpenState" (click)="LoadIssues(app);">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4 class="card-title">
                  <strong>{{app.Name}}</strong> - {{app.AirId}}
                </h4>
              </mat-panel-title>
              <div class="col-md-4">
                <div style="float:left"><strong>Over All Status :</strong></div>
                <div id="light" style="padding-left: 120px">
                  <span id="OverAllStatus" [class]="app.Overallstatus"></span>
                </div>
              </div>

              <div class="col-md-3">
                Total Issues # {{app.RecordCount}}
              </div>

            </mat-expansion-panel-header>
            <!--<div id="collapse{{app.ApplicationId}}" class="panel-collapse collapse">
            <div class="card-body" id="dvbody" ng-controller="MonitoringTrackerController">-->
            <div class="MonitoringTrackerWrap">
              <table class="table table-bordered" style="width:100%" id="monitoringtrackerTable">
                <thead>
                  <tr style="background-color: #f5f5f5;" *ngIf="ApplicationId == app.ApplicationId">
                    <td style="border:solid; border-width:1px; text-align: center;" class="col-xs-2"><strong>Status</strong></td>
                    <td style="border: solid; border-width: 1px; text-align: center"><strong>Type of Issue</strong></td>
                    <td style="border: solid; border-width: 1px; text-align: center"><strong>Incident Description</strong></td>
                    <td style="border: solid; border-width: 1px; text-align: center"><strong>Incident Priority</strong></td>
                    <td style="border: solid; border-width: 1px; text-align: center"><strong>Edit/View</strong></td>
                    <td style="border: solid; border-width: 1px; text-align: center; width:110px" *ngIf="app.ModifiedBy?.IsAdmin == true"><strong>Delete</strong></td>

                    <td style="border: solid; border-width: 1px; text-align: center"><strong>Comments</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let issue of IssuesForPanel" ng-if="ApplicationId == app.ApplicationId" class="custom-column">
                    <td style="border: solid; border-width: 1px;" class="col-xs-2">
                      <div id="light" style="float:left;">
                        <span id="Status" [class]="issue.Status" *ngIf="issue.Status != 'Cancelled/Invalid'"></span>
                        <span id="Status" class="cancelledinvalid" *ngIf="issue.Status == 'Cancelled/Invalid'"></span>
                      </div>
                      <div style="padding-left: 40px;">
                        {{issue.Status}}
                      </div>
                    </td>
                    <td style="border:solid; border-width:1px; text-align: center">{{issue.Issue}}</td>
                    <td id='permalink_section' style="border:solid; border-width:1px; text-align: center; white-space: normal;" [innerHTML]="issue.IncidentDescription"></td>
                    <td style="border:solid; border-width:1px; text-align: center">{{issue.IncidentPriority}}</td>
                    <td style="border:solid; border-width:1px; text-align: center">

                      <button type="button" id="btnEdit" class="btn btn-primary" (click)="Edit(modaladdForm,issue);" *ngIf="(issue.Status != 'Completed') || (issue.Status == 'Completed' && issue.MTAppUser?.IsSpecialUser == true && issue.FocusArea == 'Prevent')"><span class="fa fa-edit"></span>Edit</button>


                      <button type="button" id="btnView" class="btn btn-primary" style="width:104% !important" (click)="Edit(modaladdForm,issue);" *ngIf="(issue.Status == 'Completed' && issue.MTAppUser?.IsSpecialUser == false) || (issue.Status == 'Completed' && issue.FocusArea != 'Prevent' && issue.MTAppUser?.IsSpecialUser == true)"><span class="fa fa-edit"></span>View</button>

                    </td>
                    <td *ngIf="app.ModifiedBy?.IsAdmin == true" style="border:solid; border-width:1px; text-align: center">
                      <button type="button" id="btnMtIssueDelete" class="btn btn-primary" (click)="Deactivate(issue);"><span class="fa fa-trash"></span>Delete</button>
                    </td>


                    <td style="border:solid; border-width:1px; text-align: center">

                      <a href="javascript:;" id="lkComments" style="color:blue;" (click)="ShowComments(modalformComment,issue);">Click here to view comments</a>

                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>


        </mat-accordion>
      </div>
    </div>
  </div>
  <ng-template #modaladdForm let-modal id="myModal">
    <form id="AddNewForm" name='modaladdForm' [formGroup]="addForm" (ngSubmit)="SubmitForm();">

      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">{{formTitle}}</h4>
        <button id="formcrossbtn" type="button" class="close" data-dismiss="modal" (click)="modal.dismiss('Button click'); resetData();"><span>&times; </span></button>
      </div>
      <fieldset [disabled]="checkStatusComplete">
        <div id="modbody" class="modal-body" style="margin-left: 70px;">
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Application<em style="color:red;">*</em> :</label>
            <div class="col-md-5" id="appMonitoringTrackerEdit" [hidden]="unchangedE">
              <input type="text" ng-model="selectedApplication" id="appMonitoringTracker" class="form-control" style="width: 123%;" name="Name" formControlName="Name" #Name ng-blur="CheckContent();" readonly />
            </div>
            <div class="col-md-5" id="appMonitoringTracker" [hidden]="unchanged">
              <ng-autocomplete [data]="applications"
                               [searchKeyword]="keyword"
                               placeholder="Enter Application"
                               (inputChanged)='LoadApplication($event)'
                               [readonly]="onEdit"
                               historyIdentifier="applications"
                               [itemTemplate]="itemTemplate"
                               [notFoundTemplate]="notFoundTemplate">

              </ng-autocomplete>
              <!--(change) ="findAIRdetails($event)"-->

              <ng-template #itemTemplate let-option>
                <a [innerHTML]="option.Name" (click)="getSelectedAirIds(option)"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>

            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Type of Issue<em style="color:red;">*</em> :</label>
            <input id="LoggedInUser" type="hidden" class="form-control" [value]="username" name="LoggedInUser" formControlName="LoggedInUser" #LoggedInUser>
            <div class="col-md-5">
              <select ng-model="selectedIssue" ng-options="i.value as i.value for i in statusF" class="form-control" style="width:123%;" name="Issue" (click) = "OnIssue();" formControlName="Issue" #Issue>
                <option [ngValue]="null" selected>--Select Issue--</option>
                <option *ngFor="let iss of issues; let i = index" [value]="issues[i].value">{{issues[i].value}}</option>
              </select>
            </div>
          </div>
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Investigation Trigger<em style="color:red;">*</em> :</label>
            <div class="col-sm-5">
              <select ng-model="selectedTrigger" ng-options="t.value as t.value for t in trigger" class="form-control" style="width:123%;" name="InvestigationTrigger" formControlName="InvestigationTrigger" #InvestigationTrigger>
                <option [ngValue]="null" selected>--Select Investigation Trigger--</option>
                <option *ngFor="let status of trigger; let i = index" [value]="trigger[i].value">{{trigger[i].value}}</option>
              </select>
            </div>
          </div>

          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Focus Area<em style="color:red;">*</em> :</label>
            <div class="col-md-5">
              <select ng-model="selectedArea" ng-options="ar.value as ar.value for ar in area" class="form-control" style="width:123%;" (change)="LoadPreventYes();" name="Focusarea" formControlName="Focusarea" #Focusarea>
                <option [ngValue]="null" selected>--Select Focus Area--</option>
                <option *ngFor="let status of area; let i = index" [value]="area[i].value">{{area[i].value}}</option>
              </select>
            </div>
          </div>

          <div ng-show="showPrevent" *ngIf="(addForm.value.Focusarea == 'Prevent' && addForm.value.Status =='Completed')">
            <div class="row form-group mb-3">
              <label class="col-form-label col-md-4" for="text" style="text-align: left;">Alert/Episode Received?<em style="color:red;">*</em> :</label>
              <div class="col-md-5">
                <select [value]="selectedAlertReceived" class="form-control" style="width:123%;" id="selectedAlertReceived" (change)="LoadAlertReceivedChanged();" name="AlertReceived" formControlName="AlertReceived" #AlertReceived>
                  <!--<option [ngValue]="null" selected>--Select Focus Area--</option>-->
                  <option *ngFor="let alertRevcd of alertReceived; let i = index" [value]="alertReceived[i].value">{{alertReceived[i].value}}</option>
                </select>
              </div>
            </div>
            <div class="row form-group mb-3">
              <label class="col-form-label col-md-4" for="text" style="text-align: left;">Type of Alert<em style="color:red;">*</em> :</label>
              <div class="col-md-5">
                <select id="ddlTypeAlert" [value]="selectedtypeAlert" ng-options="typeAlrt.value as typeAlrt.value for typeAlrt in typeAlert" class="form-control" style="width:123%;" ng-change="loadtypeAleartOthBox();" name="AlertType" formControlName="AlertType" #AlertType>
                  <!--<option [value]="selectedtypeAlert">--Select Type of Alert--</option>-->
                  <option *ngFor="let typeAlrt of typeAlert; let i = index" [value]="typeAlert[i].value">{{typeAlert[i].value}}</option>
                </select>
              </div>
            </div>

            <div class="row form-group mb-3" ng-show="showtypealrtOth" *ngIf="(addForm.value.AlertType == 'Custom alerts / Others')">
              <label class="col-form-label col-md-4" for="text" style="text-align: left;">Other Alert<em style="color:red;">*</em> :</label>
              <div class="col-md-5">
                <input type="text" id="txttypAlrtCateOth" ng-model="selectedtypealrtOth" class="form-control" placeholder="Other" style="width:123%;" name="OtherAlert" formControlName="OtherAlert" #OtherAlert />
              </div>
            </div>

            <div class="row form-group mb-3" ng-show="showIssuCategory">
              <label class="col-form-label col-md-4" for="text" style="text-align: left;">Prevention Category<em style="color:red;">*</em> :</label>
              <div class="col-md-5">
                <select id="ddlIssuCategory" ng-model="selectedIssuCategory" ng-options="icategory.value as icategory.value for icategory in issueCategory" class="form-control" style="width:123%;" ng-click="ValidateAlert()" ng-change="loadIssueCateOthbox();" name="IssueCategory" formControlName="IssueCategory" #IssueCategory>
                  <option [ngValue]="null" selected>--Select Prevention Category--</option>
                  <option *ngFor="let icategory of issueCategory; let i = index" [value]="issueCategory[i].value">{{issueCategory[i].value}}</option>
                </select>
                <div id="AlertError" style='color:red' ng-show="showAlertError" *ngIf="(addForm.value.IssueCategory == '')"></div>

              </div>
            </div>
            <!------------------DSR017 changes--------------------->
            <!--<div class="row form-group mb-3" ng-show="showIssueCateOther" *ngIf="(addForm.value.IssueCategory == 'Others - Please specify')">
              <label class="col-form-label col-md-4" for="text" style="text-align: left;">Other Prevention Category<em style="color:red;">*</em> :</label>
              <div class="col-md-5">
                <input type="text" id="txtIssueCateOth" ng-model="selectedIssueCateother" class="form-control" placeholder="Other Prevention Category" style="width:123%;" name="OtherCategory" formControlName="OtherCategory" #OtherCategory />
              </div>
            </div>-->

            <div class="row form-group mb-3">
              <label class="col-form-label col-md-4" for="text" style="text-align: left;">Service Tier<em style="color:red;">*</em> :</label>
              <div class="col-md-5">
                <div> 
                  <input id="ddlSerTier" class="form-control" name="ServiceTier" formControlName="ServiceTier" #ServiceTier readonly/>
              <!-- <select id="ddlSerTier" ng-model="selectedServiceTier"  ng-options="stier.value as stier.value for stier in serviceTier" class="form-control" style="width:123%;" name="ServiceTier" formControlName="ServiceTier" #ServiceTier  [value]="ServiceTier" readonly="serviceTier"  [disabled]="ServiceTierdisabled" >
                  <option [ngValue]="null" selected>--Select Service Tier--</option>
                  <option *ngFor="let stier of serviceTier; let i = index" [value]="serviceTier[i].value">{{serviceTier[i].value}}</option>
                </select> -->
              </div>
            </div>
            </div>
            <div class="row form-group mb-3">
              <label class="col-form-label col-md-4" for="text" style="text-align: left;">MD<em style="color:red;">*</em> :</label>
              <div class="col-md-5">
                <!-- <input type="text" ng-model="selectedMd" class="form-control " id="txtMd" style="width:124%;" placeholder="Enter a  MD Name..." name="MD" formControlName="MD" #MD /> -->
                <div class="ng-autocomplete" id="mdName">
                  <ng-autocomplete [data]="SearchPeople"
                                   [searchKeyword]="keywordP"
                                   placeholder="Enter a MD Name..."
                                   (selected)='selectPeopleEvent($event)'
                                   (inputChanged)='LoadPeopleByName($event)'
                                   historyIdentifier="SearchPeople"
                                   [itemTemplate]="peopleTemplate"
                                   [notFoundTemplate]="notFoundTemplateP"
                                   [formControl]="bindMDname">
                  </ng-autocomplete>

                  <ng-template #peopleTemplate let-people>
                    <a [innerHTML]="people.Name"></a>
                    <!--<a [innerHTML]="people.EnterpriseId+' -- '+ people.StandardJobDescription"></a>-->

                  </ng-template>

                  <ng-template #notFoundTemplateP let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>

              </div>

            </div>
            <!--DSR017 changes-->
            <!--<div class="row form-group mb-3">
    <label class="col-form-label col-md-4" for="text" style="text-align: left;">Alert Priority as per GPC<em style="color:red;">*</em>:</label>
    <div class="col-md-5">
      <select ng-model="selectedPriority" ng-options="pr.value as pr.value for pr in priority" class="form-control" style="width:123%;" name="Alertpriority" formControlName="Alertpriority" #Alertpriority>
        <option [ngValue]="null" selected>--Select Priority--</option>
        <option *ngFor="let pr of priority; let i = index" [value]="priority[i].value">{{priority[i].value}}</option>
      </select>
    </div>
  </div>-->
            <div class="row form-group mb-3">
              <label class="col-form-label col-md-4" for="text" style="text-align: left;">Incident Priority<em style="color:red;">*</em> :</label>
              <div class="col-md-5">
                <select ng-model="selectedIncident" ng-options="inpr.value as inpr.value for inpr in incidentpriority" class="form-control" style="width:123%;" name="IncidentPriority" formControlName="IncidentPriority" #IncidentPriority>
                  <option [ngValue]="null" selected>--Select Incident Priority--</option>
                  <option *ngFor="let inpr of incidentpriority; let i = index" [value]="incidentpriority[i].value">{{incidentpriority[i].value}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row form-group mb-3" *ngIf="(addForm.value.Focusarea != 'Prevent' || addForm.value.Focusarea == '')">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Status<em style="color:red;">*</em> :</label>
            <div class="col-md-5">
              <select ng-model="selectedStatus" ng-options="st.value as st.value for st in status" class="form-control" style="width:123%;" ng-change="LoadStatusChange();" name="Status" formControlName="Status" #Status>
                <option [ngValue]="null" selected>--Select Status--</option>
                <option *ngFor="let status of statusF; let i = index" [value]="statusF[i].value">{{statusF[i].value}}</option>
              </select>
            </div>
          </div>
          <div class="row form-group mb-3" *ngIf="(addForm.value.Focusarea == 'Prevent')">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Status<em style="color:red;">*</em> :</label>
            <div class="col-md-5">
              <select ng-model="selectedStatus" ng-options="st.value as st.value for st in status" class="form-control" style="width:123%;" ng-change="LoadStatusChange();" name="Status" formControlName="Status" #Status>
                <option [ngValue]="null" selected>--Select Status--</option>
                <option *ngFor="let status of statusP; let i = index" [value]="statusP[i].value">{{statusP[i].value}}</option>
              </select>
            </div>
          </div>

          <div class="row form-group mb-3" ng-show="showDivStartDate" *ngIf="(addForm.value.Focusarea == 'Prevent')">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Start Date (GMT)<em style="color:red;">*</em> :</label>
            <div class="col-md-5 datePicker">
              <!-- <input id="dtStartDateAdd" type="date" placeholder="Select Start Date" style="width:123%" class="form-control" ng-model="selectedItemStartDate" ng-blur="ComputeDuration();"> -->
              <!--<ngx-datetime-picker [(selectedDateTime)]="itemStartDate" style="width:123%" [formControl]="ControlItemStartDate" class="form-control dateTimePicker" name="ItemStartDate" formControlName="ItemStartDate" #ItemStartDate (ngModelChange)="onTextChange($event); ComputeDuration($event)"></ngx-datetime-picker>-->
              <mat-form-field>
                <input matInput [ngxMatDatetimePicker]="picker" placeholder="Start Date" [min]="minDate" formControlName="ItemStartDate"
                       [disabled]="disabled" (ngModelChange)="ComputeDuration($event);">
                <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                         [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                         [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
                </ngx-mat-datetime-picker>
                <mat-error *ngIf="dateError">
                  Date cannot be in the past
                </mat-error>
              </mat-form-field>
             
            </div>
          </div>
          <div class="row form-group mb-3" ng-show="showDivEndDate" *ngIf="(addForm.value.Focusarea == 'Prevent' && addForm.value.Status =='Completed')">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">End Date (GMT)<em style="color:red;">*</em> :</label>
            <div class="col-md-5 datePicker">
              <!-- <input id="dtEndDateAdd" type="date" placeholder="Select End Date" style="width:123%" class="form-control" ng-blur="ComputeDuration();" ng-model="selectedItemEndDate"> -->
              <!--<ngx-datetime-picker [(selectedDateTime)]="itemEndDate" style="width:123%" [formControl]="ControlItemEndDate" class="form-control dateTimePicker" name="ItemEndDate" formControlName="ItemEndDate" #ItemEndDate (ngModelChange)="onTextChange($event); ComputeDuration($event)"></ngx-datetime-picker>-->
              <mat-form-field>
                <input matInput [ngxMatDatetimePicker]="picker" placeholder="End Date" [min]="minDate" formControlName="ItemEndDate"
                       [disabled]="disabled" (ngModelChange)="ComputeDuration($event);">
                <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                         [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                         [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
                </ngx-mat-datetime-picker>
                <mat-error *ngIf="dateError">
                  Date cannot be in the past
                </mat-error>
              </mat-form-field>
             
            </div>
          </div>
          <div class="row form-group mb-3" ng-show="showDivDuration" *ngIf="(addForm.value.Focusarea == 'Prevent' && addForm.value.Status =='Completed')">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Duration:</label>
            <div class="col-md-5">
              <input id="txtDuration" type="text" style="width:123%; background-color: #dcdcdc;" class="form-control" name="Duration" formControlName="Duration" #Duration readonly>
            </div>
          </div>
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Incident Reported by?<em style="color:red;">*</em> :</label>
            <div class="col-md-5">
              <select ng-model="selectedReporter" ng-options="irpb.value as irpb.value for irpb in Incidentreportedby" class="form-control" style="width:123%;" name="IncidentReportedBy" formControlName="IncidentReportedBy" #IncidentReportedBy>
                <option [ngValue]="null" selected>--Select Incident Reported--</option>
                <option *ngFor="let irpb of incidentreportedby; let i = index" [value]="incidentreportedby[i].value">{{incidentreportedby[i].value}}</option>
              </select>
            </div>
          </div>
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Incident Escalated through<em style="color:red;">*</em> :</label>
            <div class="col-md-5">
              <label data-ng-repeat="choice in escalatedthrough.choices" *ngFor="let escalate of escaltedthrough; let i = index" style="margin-left:1px;">
                <input type="radio" name="EscalationPoint" [value]="escaltedthrough[i].value" ng-model="$parent.selectedoption" formControlName="EscalationPoint" #EscalationPoint />{{escaltedthrough[i].value}}
              </label>
            </div>
          </div>
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Shift<em style="color:red;">*</em> :</label>
            <div class="col-md-5">
              <select ng-model="selectedShift" ng-options="sh.value as sh.value for sh in shift" class="form-control" style="width:123%;" name="Shift" formControlName="Shift" #Shift>
                <option [ngValue]="null" selected>--Select Shift--</option>
                <option *ngFor="let sh of shift; let i = index" [value]="shift[i].value">{{shift[i].value}}</option>
              </select>
            </div>
          </div>
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">ServiceNow Incident/CHG no. wherever applicable</label>
            <div class="col-md-6">
              <textarea class="form-control" rows="2" ng-model="remedyincident" name="Remedyincident" placeholder="INCXXXXXXXX/CHGXXXXXXX/NA" formControlName="Remedyincident" #Remedyincident></textarea>
            </div>
          </div>
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Incident name<em style="color:red;">*</em> :</label>
            <div class="col-md-6">
              <textarea class="form-control" rows="2" ng-model="incidentname" name="Incidentname" formControlName="Incidentname" #Incidentname></textarea>
            </div>
          </div>
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Incident description<em style="color:red;">*</em> :</label>
             <div class="col-md-6">
              <textarea id='incident_description_permalink' class="form-control" rows="2" (keyup)="onDescriptionChange($event);" ng-model="incidentdescription" name="Incidentdescription" formControlName="Incidentdescription" #Incidentdescription></textarea>
            </div>
          </div>
          <div class="row form-group mb-3" *ngIf="(addForm.value.Focusarea == 'Prevent' && addForm.value.Status =='Completed')">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Prevention Activity by GPC <em style="color:red;">*</em></label>
            <div class="col-md-6">
              <textarea class="form-control" rows="4" ng-model="preventionactivity" name="Preventionactivity" formControlName="Preventionactivity" #Preventionactivity></textarea>
            </div>
          </div>
          <!----------------  DSR017 chnages --------------------->
          <!--<div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Open Action Item<em style="color:red;">*</em> :</label>
            <div class="col-md-6">
              <textarea class="form-control" rows="2" ng-model="openaction" name="Openactionitem" formControlName="Openactionitem" #Openactionitem></textarea>
            </div>
          </div>-->
          <!----------------  DSR017 chnages --------------------->
          <!--<div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">RCA Opened</label>
            <div class="col-md-5">
              <input type="checkbox" style="vertical-align:top;" ng-model="rcaopened" name="RootCauseOpened" formControlName="RootCauseOpened" #RootCauseOpened />
            </div>
          </div>
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Number of RCA</label>
            <div class="col-md-5">
              <input type="number" id="txtRCA" class="form-control" [value]="numberofrca" style="width:123%;" maxlength="4" pattern="\d*" name="RootCauseNumber" formControlName="RootCauseNumber" #RootCauseNumber />
            </div>
          </div>-->
          <div class="row form-group mb-3" id="commentsDiv" *ngIf="(addForm.value.Id == null)">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Comments</label>
            <div class="col-md-6">
              <textarea id='permalink_section' class="form-control" rows="5" ng-model="comments" name="Comment" formControlName="Comment" #Comment></textarea>
            </div>
          </div>
          <div class="row form-group mb-3">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Created By</label>
            <div class="col-md-5">

              <!--{{username}}-->

              <input id='permalink_section' class="form-control" rows="5" ng-model="CreatedBy" name="CreatedBy" formControlName="CreatedBy" #CreatedBy readonly />
            </div>
          </div>
          <div class="row form-group mb-3" *ngIf="(addForm.value.Id != null)">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Last Modified By</label>
            <div class="col-md-5">

              <!-- {{LastModifiedBy}} -->

              <input id='permalink_section' class="form-control" rows="5" ng-model="ModifiedBy" name="LastModifiedBy" formControlName="LastModifiedBy" #LastModifiedBy readonly />
            </div>
          </div>
          <div class="row form-group mb-3" *ngIf="(addForm.value.Id != null)">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Last Modified Date</label>
            <div class="col-md-5">

              <!--{{LastModifiedDate | date : "MMM d, y"}}-->
              <input id='permalink_section' class="form-control" rows="5" name="ModifiedDateTime" formControlName="ModifiedDateTime" #ModifiedDateTime readonly />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div id="addIssueAlert" ng-controller="MonitoringTrackerController" class="alert alert-danger" *ngIf="errorMessage">
          <strong></strong> {{errorMessage}}
        </div>
      </fieldset>
      <div class="modal-footer" ng-controller="MonitoringTrackerController">
        <div class="pull-right">
          <button id="btnMtSaveIssue" type="submit" class="btn btn-secondary" [disabled]="saveButtonStatusCompltete">Save changes</button>
          <button id="formclosebtn" type="button" class="btn btn-primary" (click)="modal.dismiss('Button click'); resetData();" [disabled]="closeButtonStatusCompltete">Close</button>
        </div>
      </div>

    </form>
  </ng-template>

  <ng-template #modalformComment let-modal>
    <form name='modalformComment' [formGroup]="commentForm" (ngSubmit)="SaveComments();">
      <div class="modal-header">
        <h4 class="modal-title" id="CommentModalLabel">Comments</h4>
        <button id="commentcrossbtn" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Button click')"><span aria-hidden="true">&times;</span></button>
      </div>
      <div id="modCommentbody" class="modal-body">
        <div id="commentsform" ng-show="showcomm" style="height: 145px; overflow-y: auto; width: 100%; word-wrap: break-word;" *ngIf="Comments">
          <table style="table-layout: fixed;" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th style="table-layout:fixed;" class=" col-xs-2"><strong>Created By :</strong></th>
                <th style="table-layout:fixed;" class="col-xs-2"><strong>Created Date :</strong></th>
                <th style="table-layout:fixed;" class="col-xs-8"><strong style="padding-left:10px">Comments Added :</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let comm of Comments" class="custom-column">
                <td style="table-layout: fixed; word-wrap: break-word; word-break: break-all">{{comm.CreatedBy}}</td>
                <td style="table-layout: fixed; word-wrap: break-word; word-break: break-all">{{comm.CreateDatetime | date : "MMM d, y"}}</td>
                <td id='permalink_section' style="table-layout:fixed;  word-wrap :break-word ; word-break : break-all;" [innerHTML]="comm.Description" ></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf!="Comments">
          <br />
          <br />
        </div>
        <div ng-show="commhistory">No historical comments exists.</div>
        <div ng-show="commhistory">
          <br />
        </div>
        <table>
          <tr class="spaceUnder">
            <td><strong>Add New Coments</strong></td>
          </tr>
          <tr>
            <td><textarea id="txtComm" class="form-control" rows="4" cols="80" name="Comment" formControlName="Comment" #Comment required></textarea></td>
          </tr>
        </table>
      </div>
      <div ng-controller="MonitoringTrackerController" class="modal-footer">
        <div id="addCommentsAlert" ng-controller="MonitoringTrackerController" class="alert alert-danger" *ngIf="errorMessage">
          <strong>{{errorMessage}}</strong>
        </div>
        <div class="pull-right">
          <button type="submit" id="btnIssueCommentSave" class="btn btn-secondary">Save changes</button>
          <button id="commentclosebtn" type="button" class="btn btn-primary" (click)="modal.dismiss('Button click')">Close</button>
        </div>
      </div>
    </form>
  </ng-template>
  <!--<input type="hidden" id="IssueId" value="0">
  <input type="hidden" id="hdntxtMD" value="0">
  <input type="hidden" id="hdnIssuecateOther" value="0">
  <input type="hidden" id="hdntypealrtOther" value="0">
  <input type="hidden" id="hdnFocusArea" value="0">
  <input type="hidden" id="hdnIssueCate" value="0">
  <input type="hidden" id="hdntypeAlert" value="0">
  <input type="hidden" id="hdnalrtReceived" value="0">
  <input type="hidden" id="hdnServiceTier" value="0">
  <input type="hidden" id="hdnMD" value="0">
  <input type="hidden" id="hdnPreventActivity" value="0">
  <input type="hidden" id="hdnItemStartDate" value="">
  <input type="hidden" id="hdnItemEndDate" value="">
  <input type="hidden" id="hdnDuration" value="">
  <input type="hidden" id="hdnStatus" value="">-->
</div>
