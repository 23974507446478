<div class="navbar">
  <ul>
    <li [routerLink]="['search-sop']" [routerLinkActive]="['active']"><fa-icon [icon]="['fas','magnifying-glass']"></fa-icon><a> Search SOP</a></li>
    <li [routerLink]="['upload-sop']"><fa-icon [icon]="['far', 'file-excel']"></fa-icon><a> Upload SOP</a></li>
    <li [routerLink]="['upload-sample']"><fa-icon [icon]="['fas','upload']"></fa-icon><a> Upload Samples</a></li>
    <li [routerLink]="['monitoring-tracker']"><fa-icon [icon]="['fas','gauge-high']"></fa-icon><a> Monitoring Tracker</a></li>
    <li [routerLink]="['issue-tracker']"><fa-icon [icon]="['fas','gauge-high']"></fa-icon><a> Issue Tracker</a></li>
    <li [routerLink]="['shift-turn-over']"><fa-icon [icon]="['fas','gauge-high']"></fa-icon><a> Shift Turn Over</a></li>
    <li [routerLink]="['reports']"><fa-icon [icon]="['fas','gauge-high']"></fa-icon><a> Reports</a></li>
    <li [routerLink]="['important-links']"><fa-icon [icon]="['fas','gauge-high']"></fa-icon><a> Important Links</a></li>
    <li [routerLink]="['service-review']"><fa-icon [icon]="['fas','gauge-high']"></fa-icon><a> Service Review</a></li>
    <li [routerLink]="['onboarding-request-approver']"><fa-icon [icon]="['far', 'file-excel']"></fa-icon><a> Onboarding Request</a></li>
    <li [routerLink]="['nxt-gen-service-pulse']"><fa-icon [icon]="['far', 'file-excel']"></fa-icon><a> Next Gen Service Pulse</a></li>
  </ul>
</div>
