<mat-toolbar color="primary">
    <h3 class="accessDenied_title">Service Operation Hub</h3>
     <button class="logoutButton" mat-raised-button (click)="logout()" style="margin-left: 92%;"><fa-icon [icon]="['fas', 'right-from-bracket']"></fa-icon>Logout</button>
 </mat-toolbar>
 <div class="navbar" style="background-color: #fff; overflow: hidden;">
     <!-- <ul>
         <li><fa-icon [icon]="['fas','address-card']"></fa-icon><a> Onboarding Form</a></li>
     </ul> -->
 </div>
 <div class="main" style="margin: 10% 0;">
         <div class="container accessDenied_container">
             <div class="jumbotron">
                <h1 style="color:red">Page not Found</h1>
                <p>The page you are looking for is either removed or does not exists.</p>
             </div>
         </div>
     </div>