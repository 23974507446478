<!--<head>
    <link href="~/Content/Styles/font-awesome.min.css" rel="stylesheet">
</head>-->
<div class="main">
  <!--(document:mousemove)="onMouseMove()"-->
  <!--<div>
      <modaldirective modal-body='body' modal-footer='footer' modal-header='header'></modaldirective>
  </div>-->
  <div>
    <div class="row">
      <h1 class="pb-2 mt-4 mb-2 border-bottom" style="margin-left: -13px;">
        Issue Tracker
        <button type="button" id="btnItExportExcel" style="float: right; margin-top:5px" class=" btn btn-primary" (click)="ExportExcel();"><span class="fa fa-download"></span>Export to Excel</button>
        <button type="button" id="btnItAddNew" style="float: right;margin-right: 30px; margin-top:5px" class="btn btn-primary" (click)="open(modalForm); dateReset();"><span class="fa fa-plus"></span>Add New</button>
      </h1>
    </div>
  </div>

  <!-- Modal -->

  <ng-template #modalForm let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="myModalTitle">{{formTitle}}</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click'); addForm.reset();"><span aria-hidden="true">&times;</span></button>
    </div>
    <form name='modalform' [formGroup]="addForm" (ngSubmit)="onSubmit()">

      <div id="modbody" class="modal-body addform">
        <div class="row form-group" ng-show="showticket">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Incident Ticket<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" id="txtIncidentTicket" name="IncidentTicket" formControlName="IncidentTicket" placeholder="Enter Incident Ticket" #IncidentTicket />
          </div>
        </div>
        <div class="row form-group" ng-show="showticketName">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;display:none">Incident Ticket<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
          </div>
        </div>
        <!--For the Issue Tracker, enable multiselect when selecting service/s in the Service field.-->
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Service<em style="color:red;">*</em> :</label>
          <div class="col-sm-5" ng-show="showticket">
            <div class="ng-autocomplete" id="ServiceAutocomplete">
              <ng-autocomplete [data]="applications"
                               [searchKeyword]="keyword"
                               placeholder="Enter Application"
                               (click)='autocompleteclick($event)'
                               (selected)='selectEventAdd($event)'
                               (inputChanged)='LoadApplicationAdd($event)'
                               historyIdentifier="applications"
                               [itemTemplate]="itemTemplate"
                               [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>

              <ng-template #itemTemplate let-option>
                <a [innerHTML]="option.Name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
            <div class="row form-group" style="width: 100%;">
              <div class="col-lg-12">
                <mat-chip-list aria-label="Application selection">
                  <mat-chip color="primary" *ngFor="let app of applicationNameAddForm;" (removed)="remove(app)">
                    {{app}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>

            <br />

            <div class="col-sm-5" *ngIf="addForm.value.ApplicationName == '0 - Others'" style="width: 100%">
              <div class="row form-group">
                <input type="text" id="txtservice" ng-model="service" class="form-control" name="OtherApplication" formControlName="OtherApplication" #OtherApplication placeholder="Other Service" />
              </div>
            </div>

          </div>
          <!-- <div class="col-sm-5" ng-show="showticketName" style="display:none;">
        <input type="text" id="txtappIT" name="OtherImpactedServiceApplication" formControlName="OtherImpactedServiceApplication" class="form-control" placeholder="Service" #OtherImpactedServiceApplication />
        <br />
        <div ng-show="selectedapplication == '0 - Others'" style="display:none;">
            <input type="text" id="txtservice" name="ApplicationName" formControlName="ApplicationName" class="form-control" #ApplicationName />
        </div>
    </div> -->
        </div>

        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Leads<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <div class="ng-autocomplete" id="LeadsAutocomplete">
              <ng-autocomplete [data]="SearchPeople"
                               [searchKeyword]="keywordP"
                               placeholder="Enter name"
                               (click)='autocompleteclick($event)'
                               (selected)='selectLeadsEventADDLead($event)'
                               (inputChanged)='LoadPeopleByNameLeads($event)'
                               historyIdentifier="SearchPeople"
                               [itemTemplate]="leadsTemplate"
                               [notFoundTemplate]="notFoundTemplateL">
              </ng-autocomplete>

              <ng-template #leadsTemplate let-people>
                <a [innerHTML]="people.Name"></a>
              </ng-template>

              <ng-template #notFoundTemplateL let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
            <div class="row form-group">
              <div class="col-lg-12">
                <mat-chip-list aria-label="Application selection">
                  <mat-chip color="primary" *ngFor="let lead of leadsNameAddForm;" (removed)="removeL(lead)">
                    {{lead}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Business Impact Flag<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select name="BusinessImpactFlag" formControlName="BusinessImpactFlag" class="form-control" #BusinessImpactFlag>
              <option [ngValue]="null" selected>--select the impact flag--</option>
              <option *ngFor="let flag of biFlag; let i = index" [value]="biFlag[i].value" style="color:grey;">{{biFlag[i].value}}</option>
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Business Impact<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <textarea class="form-control" rows="5" id="txtarImpact" name="BusinessImpact" formControlName="BusinessImpact" placeholder="Enter Business Impact" #BusinessImpact></textarea>
          </div>
        </div>
        <!--Add new “Other Impacted Service/s” field in the Issue Tracker & Checkpoint email MEENA :)-->
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Impacted Service/s<em style="color:red;">*</em> :</label>

          <div class="col-sm-5" ng-show="showImpactedService">
            <div class="ng-autocomplete" id="ImpactedServiceAutocomplete">
              <ng-autocomplete [data]="applications"
                               [searchKeyword]="keyword"
                               placeholder="Enter Application"
                               (click)='autocompleteclick($event)'
                               (selected)='selectEventImpactAdd($event)'
                               (inputChanged)='LoadApplicationImpactAdd($event)'
                               historyIdentifier="applications"
                               [itemTemplate]="itemTemplate"
                               [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>

              <ng-template #itemTemplateImpactS let-option>
                <a [innerHTML]="option.Name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
            <br />
            <div id="dvImpactedServiceothers" style="display:none;">
              <input type="text" id="txtImpactedservice" name="OtherImpactedServiceApplication" formControlName="OtherImpactedServiceApplication" class="form-control" placeholder="Other Service" #OtherImpactedServiceApplication />
            </div>
            <div class="row form-group" style="width: 100%;">
              <div class="col-lg-12">
                <mat-chip-list aria-label="Application selection">
                  <mat-chip color="primary" *ngFor="let appI of applicationNameAddFormImpactedS;" (removed)="removeIS(appI)">
                    {{appI}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Start Date (GMT)<em style="color:red;">*</em> :</label>
          <div class="col-sm-5 datePicker" id="dtStartDate">
            <!-- <input id="dtStartDate" type="date" placeholder="Select Start Date" class="form-control" name="StartDate" formControlName="StartDate" #StartDate required> -->
            <!--<ngx-datetime-picker [(selectedDateTime)]="startDate" class="form-control dateTimePicker" name="StartDate" [formControl]="ControlStartDate" formControlName='StartDate' #StartDate (change)="onTextChangeStartDate($event)"></ngx-datetime-picker>-->
            <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="picker" placeholder="Start date" [min]="minDate" formControlName="StartDate" [disabled]="disabled">
              <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                       [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                       [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div>

        <div class="row form-group" *ngIf="((addForm.value.Status == 'Resolved') || (addForm.value.Status == 'Prevention') || (addForm.value.Status == 'Service Degraded'))">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">End Date (GMT) :</label>
          <div class="col-sm-5">
            <!-- <input id="dtEndDate" type="date" placeholder="Select End Date" class="form-control" name="EndDate" formControlName="EndDate" #EndDate> -->
            <!--<ngx-datetime-picker id="dtEndDate" [(selectedDateTime)]="endDate" class="form-control dateTimePicker" name="EndDate" [formControl]="ControlEndDate" formControlName="EndDate" #EndDate (change)="onTextChangeEndDate($event)"></ngx-datetime-picker>-->
            <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="endDate" placeholder="End date" [min]="minDate" formControlName="EndDate" [disabled]="disabled">
              <mat-datepicker-toggle matSuffix [for]="$any(endDate)"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #endDate [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                       [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                       [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Summary<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <textarea class="form-control" rows="5" id="txtarSummary" name="Summary" formControlName="Summary" placeholder="Enter Summary" #Summary></textarea>
          </div>
        </div>
        <!--for to Add separate field for “Next Update” schedule for the GSM communications  Meena :)-->
        <div class="row form-group" ng-show="showNextUpdate" *ngIf="((addForm.value.Priority == 'P1')||(addForm.value.Priority == 'P2')) && ((addForm.value.Status == 'In-progress')||(addForm.value.Status == 'Under Monitoring'))">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Next Update (GMT) <em style="color:red;">*</em> :</label>
          <div class="col-sm-5 datePicker">
            <!-- <input id="dtNextUpdate" type="date" placeholder="Select Next Update Date" class="form-control" name="NextUpdate" formControlName="NextUpdate" #NextUpdate required> -->
            <!--<ngx-datetime-picker id="NextUpdate" [(selectedDateTime)]="DateIdentified" class="form-control dateTimePicker" name="NextUpdate" [formControl]="ControlNextUpdate" formControlName="NextUpdate" #NextUpdate (change)="onTextChangeNextUpdate($event)"></ngx-datetime-picker>-->
            <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="picker" placeholder="Next Update" [min]="minDate" formControlName="NextUpdate"
                     [disabled]="disabled" (change)="onTextChangeNextUpdate($event)">
              <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                       [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                       [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Priority<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select name="Priority" formControlName="Priority" class="form-control" (change)="LoadNextUpdateDate();" #Priority>
              <option [ngValue]="null" selected>--select the priority--</option>
              <option *ngFor="let priority of issuepriority; let i = index" [value]="issuepriority[i].value" style="color:grey;">{{issuepriority[i].value}}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Status<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select name="Status" formControlName="Status" class="form-control" (change)="LoadNextUpdate();" #Status>
              <option [ngValue]="null" selected>--select the status--</option>
              <option *ngFor="let stat of status; let i = index" [value]="status[i].value" style="color:grey;">{{status[i].value}}</option>
            </select>

          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Bridge Detail<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <textarea class="form-control" rows="5" id="txtBridgelnk" name="BridgeDetails" formControlName="BridgeDetails" placeholder="Enter Bridge Invitation Link" #BridgeDetails></textarea>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Live Updates<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <textarea class="form-control" rows="5" id="txtCirclelnk" name="CircleLink" formControlName="CircleLink" placeholder="Enter Live Update" #CircleLink></textarea>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Service Priority<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select name="ServicePriority" formControlName="ServicePriority" class="form-control" #ServicePriority>
              <option [ngValue]="null" selected>--select the service priority--</option>
              <option *ngFor="let spriority of servicepriority; let i = index" [value]="servicepriority[i].value" style="color:grey;">{{servicepriority[i].value}}</option>
            </select>
          </div>
        </div>


        <!--Hot Debrief completed section removed from add Issue - started-->
        <!--<div class="row form-group">
    <label class="col-form-label col-md-4" for="text" style="text-align: left;">Hot Debrief Completed<em style="color:red;">*</em> :</label>
    <div class="col-sm-5">
      <label *ngFor="let choice of hotdebriefcompleted; let i = index" style="margin-left:1px;">
        <input type="radio" value="" name="HotDebriefCompleted" formControlName="HotDebriefCompleted" [checked]="hotdebriefcompleted[i].selected" [value]="hotdebriefcompleted[i].value" ng-change="ifHotdebriefDone()" #HotDebriefCompleted />{{hotdebriefcompleted[i].value}}
      </label>
    </div>
  </div>-->
        <!--<div class="row form-group" *ngIf="(addForm.value.HotDebriefCompleted == 'Yes')">
    <label class="col-form-label col-md-4" for="text" style="text-align: left;">Hot Debrief Facilitator<em style="color:red;">*</em> :</label>
    <div class="col-sm-5">-->
        <!--<input type="text" class="form-control " id="txtFacilitator" name="HotDebriefFacilitator" formControlName="HotDebriefFacilitator" placeholder="Search Enterprise ID" ng-disabled="IsDisabled" #HotDebriefFacilitator />-->
        <!--<div class="ng-autocomplete">
        <ng-autocomplete [data]="SearchPeople"
                         [searchKeyword]="keywordP"
                         placeholder="Enter name"
                         (selected)='selectPeopleEvent($event)'
                         (inputChanged)='LoadPeopleByNameHotDebriefFacilitator($event)'
                         historyIdentifier="SearchPeople"
                         [itemTemplate]="peopleTemplate"
                         [notFoundTemplate]="notFoundTemplateP"
                         [formControl]="HotDebriefFacilitator">
        </ng-autocomplete>

        <ng-template #peopleTemplate let-people>
          <a [innerHTML]="people.Name"></a>

        </ng-template>

        <ng-template #notFoundTemplateP let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>
  </div>-->
        <!--Hot Debrief completed section removed from add Issue - Ended-->


        <div class="row form-group" style="display: none">
          <input class="col-sm-5" name="LoggedInUser" formControlName="LoggedInUser" value="{{username}}" #LoggedInUser />
        </div>

        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Created By :</label>
          <div class="col-sm-5" id="createdBy" name="CreatedBy" #CreatedBy>
            {{username}}

            <!--@*@Accenture.ServiceOperationHub.Host.CurrentUser.EnterpriseId*@-->
          </div>
        </div>

        <br />
        <br />
        <div class="alert alert-danger" ng-show="updateerror" id="divError" *ngIf="errorMessage">

          {{errorMessage}}
        </div>
      </div><!--modal body closing-->
      <div class="form-group modal-footer">
        <div class="pull-right">
          <button type="submit" id="btnItIssueSave" class="btn btn-secondary">Save changes</button>
          <button id="addissueTrackerClose" type="button" class="btn btn-primary" data-dismiss="modal" (click)="modal.dismiss('Close click'); addForm.reset();">Close</button>
          <input type="hidden" id="hdnAppId" />
        </div>
      </div>
    </form>
    <!--</div>--><!--modal content closing-->
    <!--</div>--><!--modal dialog closing-->
  </ng-template>


  <form class="d-flex flex-row align-items-center flex-wrap search searchBoxes" style="margin-left:-14px" [formGroup]="searchformGroup" (ngSubmit)="FilterTrackerSummary()">
    <div class="row" id="divFilter">
      <input id="dtStart" placeholder="Select Start Date" formControlName="StartDate" name="startDate" class="form-control" #StartDate onfocus="(this.type='date')">
    </div>

    <div class="row">
      <input id="dtEnd" placeholder="Select End Date" formControlName="EndDate" name="endDate" class="form-control" #EndDate onfocus="(this.type='date')">
    </div>

    <div class="row">
      <!--<input type="text" id="txtapplication" class="form-control" aria-describedby="search-text" formControlName="ApplicationName" [(ngModel)]="searchText" placeholder="Application" name="appName" #ApplicationName (keyup)="findapps();" />-->
      <!--<input type="email" class="form-control" id="search-text" aria-describedby="search-text"
      [(ngModel)]="searchText" placeholder="Enter text to search"
      autofocus-->
      <!--<ul class="list-group list-group-flush">-->
      <!-- results of ngFor is passed to appFilter with argument searchText -->
      <!--<li class="list-group-item" *ngFor="let application of Applications | appFilter: searchText">
              {{application}}
          </li>
      </ul>-->
      <div class="ng-autocomplete">
        <ng-autocomplete [data]="applications"
                         [searchKeyword]="keyword"
                         placeholder="Enter Application"
                         (selected)='selectEvent($event)'
                         (inputChanged)='LoadApplication($event)'
                         historyIdentifier="applications"
                         [itemTemplate]="itemTemplate"
                         [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-option>
          <a [innerHTML]="option.Name"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>
    <div class="row" style="margin-left: 16px;">
      <select id="Status" name="StatusFilter" formControlName="Status" class="form-control" #Status>
        <option [ngValue]="null" selected>--Select Status--</option>
        <option *ngFor="let stat of status; let i = index" [value]="status[i].value" style="color:grey;">{{status[i].value}}</option>
      </select>
    </div>
    <div class="row" style="margin-left: 16px;">
      <select id="Priority" name="Priority" formControlName="Priority" class="form-control" #Priority>
        <option [ngValue]="null" selected>--Select Priority--</option>
        <option *ngFor="let priority of issuepriority; let i = index" [value]="issuepriority[i].value" style="color:grey;">{{issuepriority[i].value}}</option>
      </select>
    </div>
    <input type="hidden" id="loggeduser" class="form-control" name="LoggedInUser" formControlName="LoggedInUser" #LoggedInUser />

    <div class="row" id="divMoreFilters">
      <!-- <input type="text" class="form-control " id="txtfilterlead" placeholder="Search EnterpriseId" name="EnterpriseId" formControlName="EnterpriseId" #EnterpriseId /> -->
      <div class="ng-autocomplete">
        <ng-autocomplete [data]="SearchLeads"
                         [searchKeyword]="keywordL"
                         placeholder="Search EnterpriseID "
                         (selected)='selectLeadsEvent($event)'
                         (inputChanged)='LoadLeadsbyFilter($event)'
                         historyIdentifier="SearchLeads"
                         [itemTemplate]="leadsTemplate"
                         [notFoundTemplate]="notFoundTemplateL">
        </ng-autocomplete>

        <ng-template #leadsTemplate let-lead>
          <a [innerHTML]="lead.EnterprisedId"></a>
        </ng-template>

        <ng-template #notFoundTemplateL let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>

    <div class="row">
      <!--<select id="offering" name="OfferingFilter" [(ngModel)]="OfferingFilter" ng-options="i.value as i.value for i in servicepriority" class="form-control">-->
      <select id="offering" name="OfferingFilter" formControlName="ServicePriority" class="form-control" #ServicePriority>
        <option [ngValue]="null" selected>--Select Service Offering--</option>
        <option *ngFor="let priority of servicepriority; let i = index" [value]="servicepriority[i].value" style="color:grey;">{{servicepriority[i].value}}</option>
      </select>
    </div>
    <div class="row">
      <button type="submit" class="btn btn-primary"><span class="fa fa-search"></span> Search </button>
    </div>

    <div class="row">
      <button type="button" id="mailsend" class="btn btn-primary" (click)="open(shiftTurnOverModalForm); ShiftTrunOverModal();">Create Shift TurnOver </button>

    </div>
    <div class="row form-group" style="display: none">
      <input id="LoggedInUser" class="col-sm-5 form-control" name="LoggedInUser" formControlName="LoggedInUser" #LoggedInUser />
    </div>
  </form>

  <br />

  <form class="form-inline">
    <div id="dvalert" class="alert alert-danger" *ngIf="errorMessage2">
      {{errorMessage2}}
    </div>
  </form>

  <div id="divSuccess" class="row" ng-show="show" #divSuccess>
    <div style="overflow-x: scroll; white-space: nowrap;">

      <table class="table table-bordered table-striped issue-table" id="issue-table">
        <thead>
          <tr style="background-color: #f5f5f5;">
            <th>Select</th>
            <th>Incident Ticket</th>
            <th>Application Name</th>
            <th>Issue Status</th>
            <th>Priority</th>
            <th>Service Level Offering</th>
            <th>Lead/Operations MD</th>
            <th>Business Impact</th>
            <th>Impacted Service/s</th>
            <th>Issue Start Date (GMT)</th>
            <th>Issue End Date (GMT)</th>
            <th>Important Info</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
          <tr style="display:none;">
            <td id="LoggedInUser" name="LoggedInUser">{{username}}</td>
          </tr>
        </thead>
        <tbody id="divbody" #searchdivbody class="none">
          <tr *ngFor="let data of issuesFilter | paginate : { itemsPerPage: tableSize,
                                                                  currentPage: page,
                                                                  totalItems: count}; let i = index">
            <td style="text-align: center;" ng-init="isToolTipShow=false" (mouseover)="enter(data)" (mouseleave)="leave(data);">
              <input id={{data.IncidentTicket}} type="checkbox" value={{data.IncidentTicket}} (change)="EmailCheck(data)" [checked]=data.ischecked
                     *ngIf="((data.ServicePriority != 'Standard') || (data.Priority != 'P3' && data.Status == 'Prevention'))" #ischecked />
              <!--[checked]="data.ischecked"-->
              <div class="popover bottom" *ngIf="hoverIndex == data">
                <div class="popover-content" *ngIf="(data.Priority == 'P1' && data.Status == 'Resolved' && data.IssueSendMail == true)">Email already sent</div>
              </div>
            </td>
            <td style="display:none;">{{data.Id}}</td>
            <td>{{data.IncidentTicket}}</td>
            <td>{{data.ApplicationName}}</td>
            <td>{{data.Status}}</td>
            <td>{{data.Priority}}</td>
            <td>{{data.ServicePriority}}</td>
            <td>{{data.ServiceLead}}</td>
            <td>{{data.BusinessImpact}}</td>
            <td>{{data.ImpactedServiceApplicationName}}</td>
            <td>{{(data.StartDate)|date:'dd/MM/yyyy HH:mm:ss a'}}</td>
            <td>{{(data.EndDate)|date:'dd/MM/yyyy HH:mm:ss a'}}</td>
            <td><button type="button" class="btn btn-primary details" (click)="openDetailsForm(importantDetailsModal, data)"><span class="fa fa-trash"></span>Details</button></td>
            <td><button type="button" class="btn btn-primary" (click)="openEdit(modalForm, data)"><span class="fa fa-edit"></span>Edit</button></td>
            <td *ngIf="data.ModifiedBy?.IsAdmin == true"><button type="button" class="btn btn-primary" (click)="delete(openDeleteConfirmModal, data)"><span class="fa fa-trash"></span>Delete</button></td>
          </tr>
        </tbody>

      </table>
      <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev"
                             nextLabel="Next"
                             (pageChange)="onTableDataChange($event)">
        </pagination-controls>
      </div>
    </div>
  </div>

  <br />


  <ng-template #shiftTurnOverModalForm let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="myModalTitle">Add Shift Turn Over</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click'); turnOverModalForm.reset();"><span aria-hidden="true">&times;</span></button>
    </div>
    <form name='modalform' [formGroup]="turnOverModalForm" (ngSubmit)="onSaveData(openSendMailConfirmModal);">
      <div id="mymodbody" class="modal-body" style="margin-left: 40px;">
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Turn Over Date<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <input id="dtTurnOver" class="form-control" name="TurnOverDate" placeholder="Turn Over Date" formControlName="TurnOverDate" #TurnOverDate readonly />
          </div>
        </div>
        <div class="row form-group">

          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Current Health Status<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select id="HealthStatus" ng-model="selectedStatus" class="form-control" name="CurrentHealthStatus" formControlName="CurrentHealthStatus" #CurrentHealthStatus>
              <option [ngValue]="null">--Select Health Status--</option>
              <option *ngFor="let health of healthStatus; let i = index" [value]="healthStatus[i].value" style="color:grey;">{{healthStatus[i].value}}</option>
              <!--<option value="healthStatus[i].value" style="color:grey;" selected>{{healthStatus[i].value}}</option>-->
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Current Regional Service Manager<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <div class="ng-autocomplete" id="csermanager">
              <ng-autocomplete [data]="csermanager"
                               [searchKeyword]="keywordCSM"
                               placeholder="Current Regional Manager"
                               (selected)='selectEventCSM($event)'
                               (inputChanged)='LoadCurrentServiceManager($event)'
                               historyIdentifier="csermanager"
                               [itemTemplate]="itemTemplateCSM"
                               [notFoundTemplate]="notFoundTemplate"
                               [formControl]="currentManagerDraft">
              </ng-autocomplete>

              <ng-template #itemTemplateCSM let-csm>
                <a [innerHTML]="csm.EnterprisedId"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>

          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Current Shift Region<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select ng-model="selectedCurrentregion" class="form-control" name="selectedCurrentregion" formControlName="CurrentShiftRegion" #CurrentShiftRegion>
              <option [ngValue]="null" selected style="color:grey;">-- Select Region --</option>
              <option [value]="shiftRegion[i].value" style="color:grey;" *ngFor="let region of shiftRegion; let i = index">{{shiftRegion[i].value}}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">New Regional Service Manager<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <div class="ng-autocomplete" id="nsermanager">
              <ng-autocomplete [data]="nsermanager"
                               [searchKeyword]="keywordNSM"
                               placeholder="New Regional Manager"
                               (selected)='selectEventNSM($event)'
                               (inputChanged)='LoadNewServiceManager($event)'
                               historyIdentifier="nsermanager"
                               [itemTemplate]="itemTemplateNSM"
                               [notFoundTemplate]="notFoundTemplate"
                               [formControl]="newManagerDraft">
              </ng-autocomplete>

              <ng-template #itemTemplateNSM let-nsm>
                <a [innerHTML]="nsm.EnterprisedId"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
            <!-- <div class="row form-group" style="width: 100%;" *ngIf="itemTemplateNSM!==null">
          <mat-chip-list aria-label="Application selection">
              <mat-chip color="primary">
                  {{turnOverResult[0].NewRegionalManagerName}}
              </mat-chip>
          </mat-chip-list>
      </div>                 -->
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">New Shift Region<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select ng-model="selectedNewregion" class="form-control" name="selectedNewregion" formControlName="NewShiftRegion" #NewShiftRegion>
              <option [ngValue]="null" selected style="color:grey;">-- Select Region --</option>
              <option [value]="shiftRegion[i].value" style="color:grey;" *ngFor="let region of shiftRegion; let i = index">{{shiftRegion[i].value}}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Shift Turn Over attendees :</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" id="txtattendee" placeholder="Enter Turn Over Attendee" ng-model="Attendee" name="Attendee" formControlName="ShiftTurnOverAttendee" #ShiftTurnOverAttendee />
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Issue during this period<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <!--<input type="radio" ng-disabled="IsDisabled" name="response" value="{{choice.value}}" ng-model="$parent.selectedoption" />{{choice.value}}-->
            <label *ngFor="let choice of issueduringperiod; let i = index" style="margin-left:1px;">
              <input id="Issue_Period{{issueduringperiod[i].value}}" type="radio" value="" name="NewIssueRecorded" formControlName="NewIssueRecorded" [(ngModel)]="IssuePeriod" [checked]="issueduringperiod[i].selected" [value]="issueduringperiod[i].value" selected readonly #NewIssueRecorded />{{issueduringperiod[i].value}}
            </label>
          </div>
        </div>

        <div class="row form-group" *ngIf="inprogress.length > 0">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Issues Under Red Banner :</label>
          <div class="col-sm-5">
            <div *ngIf="inprogress.length > 0" (mouseover)="showInprogress=false;" (mouseleave)="showInprogress=true;" style="color:black;">
              View list
              <div class="popoverIncidentList bottom" [hidden]="showInprogress">
                <div class="popover-content" *ngIf="inprogress.length === 0">No critical open issues selected</div>
                <ul class="popover-content" *ngFor="let item of inprogress">
                  <li>{{item}}</li>
                </ul>

              </div>
            </div>
          </div>
        </div>



        <div class="row form-group" *ngIf="degraded.length > 0 ">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Issues Under Yellow Banner :</label>
          <div class="col-sm-5">
            <div *ngIf="degraded.length > 0 " (mouseover)="showdegrade=false;" (mouseleave)="showdegrade=true;" style="color:black;">
              View list
              <div class="popoverIncidentList bottom" [hidden]="showdegrade">
                <div class="popover-content" *ngIf="degraded.length === 0 ">No Monitoring/Service issue(s) selected</div>
                <ul class="popover-content" *ngFor="let item of degraded">
                  <li>{{item}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group" *ngIf="resolved.length > 0 ">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Issues Under Green Banner :</label>
          <div class="col-sm-5">
            <div *ngIf="resolved.length > 0 " (mouseover)="showresolved=false;" (mouseleave)="showresolved=true;" style="color:black;">
              View list
              <div class="popoverIncidentList bottom" [hidden]="showresolved">
                <div class="popover-content" *ngIf="resolved.length === 0 ">No Closed issue(s) selected</div>
                <ul class="popover-content" *ngFor="let item of resolved">
                  <li>{{item}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group" *ngIf="prevention.length > 0 ">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Issues Under Blue Banner :</label>
          <div class="col-sm-5">
            <div *ngIf="prevention.length > 0 " (mouseover)="showprevention=false;" (mouseleave)="showprevention=true;">
              View list
              <div class="popoverIncidentList bottom" [hidden]="showprevention">
                <div class="popover-content" *ngIf="prevention.length === 0 ">No Preventive Action issue(s) selected</div>
                <ul class="popover-content" *ngFor="let item of prevention">
                  <li>{{item}}</li>
                </ul>
              </div>

            </div>
          </div>
        </div>
        <!--@*Automate the extraction of planned activities from the Monitoring MEENA :)   *@-->
        <!--<div class="row form-group">
    <label class="col-form-label col-md-4" for="text" style="text-align: left;">Planned Operation Activities :</label>
    <div class="col-sm-8">

      <h2 style="font-weight: bold; font-size: 16px; text-decoration: underline;">Planned Operation Activities</h2>

      <textarea wrap="soft" class="form-control" rows="10" cols="80" maxlength="80" ng-model="PlannedOperationActivities" name="PlannedOperationActivities" formControlName="PlannedOperationActivities" #PlannedOperationActivities>
                 </textarea>


    </div>

  </div>-->


        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Item Status<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select id="itemstatus" ng-model="selectedItem" class="form-control" name="ItemStatus" formControlName="ItemStatus" #ItemStatus>
              <option [ngValue]="null" selected style="color:grey;">-- Select Item Status --</option>
              <option *ngFor="let item of itemStatus; let i = index" [value]="itemStatus[i].value" style="color:grey;">{{itemStatus[i].value}}</option>
            </select>
          </div>
        </div>

        <div class="row form-group" style="display: none">
          <input class="col-sm-5" name="LoggedInUser" formControlName="LoggedInUser" value="{{username}}" #LoggedInUser />
        </div>

        <div class="row form-group">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Created By :</label>
          <div class="col-sm-5" id="createdBy" name="CreatedBy" style="margin-top:8px;" #CreatedBy>{{username}}</div>
        </div>
        <!--<div class="row form-group">
    <label class="col-form-label col-md-4" for="text" style="text-align: left;">Created by :</label>
    <div class="col-sm-5" #LoggedInUser>
      <input type="hidden" id="createdby" class="form-control" name="CreatedBy" #CreatedBy [value]="CreatedBy" />
      <input type="hidden" id="createdby" class="form-control" name="LoggedInUser" formControlName="LoggedInUser" [value]="username" #LoggedInUser />
    </div>
  </div>-->
      </div>

      <br />
      <div class="alert alert-danger" ng-show="error" id="divError" *ngIf="errorMessage">
        {{errorMessage}}
      </div>
      <div class="modal-footer">
        <div class="pull-right">
          <button type="submit" id="btnItIssueSave" class="btn btn-secondary">Save changes</button>
          <button type="button" id="shiftTurnOverFormClose" class="btn btn-primary" data-dismiss="modal" (click)="modal.dismiss('Close click'); turnOverModalForm.reset();">Close</button>
        </div>
      </div>


    </form>
  </ng-template>
  <!-- <ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px' }"
  [template]="openSendMailConfirmModal"></ngx-loading> -->
  <!--PREVIEW CONFIRM MODAL-->

  <ng-template #openSendMailConfirmModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Send Mail</h4>
    </div>
    <div id="mymodbody" class="modal-body" style="height: 100px;">
      <p>Do you want to continue to send email?</p>
    </div>
    <div class="alert alert-danger" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
    <div class="modal-footer">
      <div class="pull-right">
        <button type="button" class="btn btn-default" style="color: #333; background-color: #fff; border-radius: 4px; border: 1px solid transparent;border-color: #ccc" (click)="MailPreview();open(emailPreview)">Preview</button>
        <button id="openSendMailConfirmModal" type="button" class="btn btn-primary" (click)="modal.dismiss('Close click')">Cancel</button>

      </div>
    </div>
  </ng-template>
  <!--MAIL PREVIEW MODAL-->
  <ng-template #emailPreview let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Mail Preview</h4>
      <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click'); clearArraysWhenClose();"><span aria-hidden="true">&times;</span></button>-->
    </div>
    <div id="mymodbody" class="modal-body" style="height: auto;" [innerHTML]="shiftTurnOverFinalEmailToBeSent">

    </div>
    <div class="alert alert-danger" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
    <div class="modal-footer">
      <div class="pull-right">
        <button type="button" id="btnStoMailSendContinue" class="btn btn-default" style="color: #333; background-color: #fff; border-radius: 4px; border: 1px solid transparent;border-color: #ccc" (click)="SendMail();">Send</button>
        <button id="mailPreviewClose" type="button" class="btn btn-primary" (click)="modal.dismiss('Close click');">Cancel</button>

      </div>
    </div>
  </ng-template>
  <!--DELETE CONFIRM MODAL-->

  <ng-template #openDeleteConfirmModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete</h4>
    </div>
    <div id="mymodbody" class="modal-body" style="height: 100px;">
      <p>Do you want to continue to delete this record ?</p>
    </div>
    <div class="alert alert-danger" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
    <div class="modal-footer">
      <div class="pull-right">
        <button id="DelBtn" type="button" class="btn btn-default" style="color: #333; background-color: #fff; border-radius: 4px; border: 1px solid transparent;border-color: #ccc">Delete</button>
        <button id="cancelDelBtn" type="button" class="btn btn-primary" (click)="modal.dismiss('Close click')">Cancel</button>

      </div>
    </div>
  </ng-template>
  <div id="divpager"></div>



  <input type="hidden" id="hdnHotDebriefFacilitator" value="0">

  <!--<confmodaldirective modal-body='body' modal-footer='footer' modal-header='header'></confmodaldirective>


  <previewmodal modal-body='body' modal-footer='footer' modal-header='header'></previewmodal>-->

  <input type="hidden" id="hdnIssueId" />
  <input type="hidden" id="hdnShiftId" />

  <!--INPORTANT DETAILS MODAL-->
  <ng-template #importantDetailsModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Details:</h4>
      <button type="button" id="addImportantDetClose" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click'); importantDetailsModalForm.reset(); removeNotes();"><span aria-hidden="true">&times;</span></button>
    </div>
    <form name='modalform' [formGroup]="importantDetailsModalForm" (ngSubmit)="saveDetails();">
      <div id="mymodbody" class="modal-body">
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">1. Did we receive any alert? If yes, what type?<em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select id="opt" ng-model="selectedStatus" class="form-control" name="AlertReceived" formControlName="AlertReceived" #AlertReceived>
              <option [ngValue]="null">--Select--</option>
              <option *ngFor="let opt of fourOptions; let i = index" [value]="fourOptions[i].value" style="color:grey;">{{fourOptions[i].value}}</option>
            </select>
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.AlertReceived.touched) && importantDetailsModalForm.controls.AlertReceived.errors?.['required']">
              This is a required field
            </span>
          </div>
          <div class="row form-group">
            <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;"></label>
            <div class="col-sm-5">
              <input type="text" id="additionalCom" class="form-control" placeholder="Comments" name="AlertReceivedDesc" formControlName="AlertReceivedDesc" #AlertReceivedDesc />
              <!--<span class="text-danger"
                    *ngIf="(importantDetailsModalForm.controls.AlertReceivedDesc.touched) && importantDetailsModalForm.controls.AlertReceivedDesc.errors?.['required']">
                This is a required field
              </span>-->
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">2. For P1/P2 issue, was the ticket priority downgraded after resolved communication? <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select id="opt" ng-model="selectedStatus" class="form-control" name="IsDowngraded" formControlName="IsDowngraded" #IsDowngraded>
              <option [ngValue]="null">--Select--</option>
              <option *ngFor="let opt of threeOptions; let i = index" [value]="threeOptions[i].value" style="color:grey;">{{threeOptions[i].value}}</option>
            </select>
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.IsDowngraded.touched) && importantDetailsModalForm.controls.IsDowngraded.errors?.['required']">
              This is a required field
            </span>
          </div>
          <div class="row form-group" *ngIf="importantDetailsModalForm.value.IsDowngraded == 'Yes'">
            <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;"></label>
            <div class="col-sm-5">
              <input type="text" id="additionalCom" class="form-control" placeholder="Comments" name="IsDowngradedDesc" formControlName="IsDowngradedDesc" #IsDowngradedDesc />
              <span class="text-danger"
                    *ngIf="(importantDetailsModalForm.controls.IsDowngradedDesc.touched) && importantDetailsModalForm.controls.IsDowngradedDesc.errors?.['required']">
                This is a required field
              </span>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">3. For Preventive issue, was the ticket priority changed to P3 after GSM confirmed it is a Preventive issue?  <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select id="opt" ng-model="selectedStatus" class="form-control" name="IsChanged" formControlName="IsChanged" #IsChanged>
              <option [ngValue]="null">--Select--</option>
              <option *ngFor="let opt of threeOptions; let i = index" [value]="threeOptions[i].value" style="color:grey;">{{threeOptions[i].value}}</option>
            </select>
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.IsChanged.touched) && importantDetailsModalForm.controls.IsChanged.errors?.['required']">
              This is a required field
            </span>
          </div>
          <div class="row form-group" *ngIf="importantDetailsModalForm.value.IsChanged == 'Yes'">
            <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;"></label>
            <div class="col-sm-5">
              <input type="text" id="additionalCom" class="form-control" placeholder="Comments" name="IsChangedDesc" formControlName="IsChangedDesc" #IsChangedDesc />
              <span class="text-danger"
                    *ngIf="(importantDetailsModalForm.controls.IsChangedDesc.touched) && importantDetailsModalForm.controls.IsChangedDesc.errors?.['required']">
                This is a required field
              </span>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">4. What is the problem ticket number?  <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" name="ProblemTicketNo" formControlName="ProblemTicketNo" #ProblemTicketNo />
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.ProblemTicketNo.touched) && importantDetailsModalForm.controls.ProblemTicketNo.errors?.['required']">
              This is a required field
            </span>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">5. Was GSM / GPC engaged on time?   <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select id="opt" ng-model="selectedStatus" class="form-control" name="EngagedOnTime" formControlName="EngagedOnTime" #EngagedOnTime>
              <option [ngValue]="null">--Select--</option>
              <option *ngFor="let opt of twoOptions; let i = index" [value]="twoOptions[i].value" style="color:grey;">{{twoOptions[i].value}}</option>
            </select>
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.EngagedOnTime.touched) && importantDetailsModalForm.controls.EngagedOnTime.errors?.['required']">
              This is a required field
            </span>
          </div>
          <div class="row form-group" *ngIf="importantDetailsModalForm.value.EngagedOnTime == 'No'">
            <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;"></label>
            <div class="col-sm-5">
              <input type="text" id="additionalCom" class="form-control" placeholder="Comments" name="GSMGPCDesc" formControlName="GSMGPCDesc" #GSMGPCDesc />
              <span class="text-danger"
                    *ngIf="(importantDetailsModalForm.controls.GSMGPCDesc.touched) && importantDetailsModalForm.controls.GSMGPCDesc.errors?.['required']">
                This is a required field
              </span>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">6. How long was the outage/service degradation? What took it so long?  <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" name="HowLongDegrade" formControlName="HowLongDegrade" #HowLongDegrade />
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.HowLongDegrade.touched) && importantDetailsModalForm.controls.HowLongDegrade.errors?.['required']">
              This is a required field
            </span>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">7. Was the issue reported to GPCOfficerOfTheWatch bot?  <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select id="opt" ng-model="selectedStatus" class="form-control" name="IsReported" formControlName="IsReported" #IsReported>
              <option [ngValue]="null">--Select--</option>
              <option *ngFor="let opt of twoOptions; let i = index" [value]="twoOptions[i].value" style="color:grey;">{{twoOptions[i].value}}</option>
            </select>
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.IsReported.touched) && importantDetailsModalForm.controls.IsReported.errors?.['required']">
              This is a required field
            </span>
          </div>
          <div class="row form-group" *ngIf="importantDetailsModalForm.value.IsReported == 'No'">
            <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;"></label>
            <div class="col-sm-5">
              <input type="text" id="additionalCom" class="form-control" placeholder="Comments" name="IsReportedDesc" formControlName="IsReportedDesc" #IsReportedDesc />
              <span class="text-danger"
                    *ngIf="(importantDetailsModalForm.controls.IsReportedDesc.touched) && importantDetailsModalForm.controls.IsReportedDesc.errors?.['required']">
                This is a required field
              </span>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">8. What is the appropriate Cause Category?  <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" name="CauseCategory" formControlName="CauseCategory" #CauseCategory />
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.CauseCategory.touched) && importantDetailsModalForm.controls.CauseCategory.errors?.['required']">
              This is a required field
            </span>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">9. If caused by a deployment, what is the change number/activity name? <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" name="CauseNum" formControlName="CauseNum" #CauseNum />
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.CauseNum.touched) && importantDetailsModalForm.controls.CauseNum.errors?.['required']">
              This is a required field
            </span>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">10. Which tech team will lead the root cause investigation? <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" name="WhichTechTeam" formControlName="WhichTechTeam" #WhichTechTeam />
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.WhichTechTeam.touched) && importantDetailsModalForm.controls.WhichTechTeam.errors?.['required']">
              This is a required field
            </span>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">11. Why this incident was not prevented?  <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" name="WhyNotPrevented" formControlName="WhyNotPrevented" #WhyNotPrevented />
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.WhyNotPrevented.touched) && importantDetailsModalForm.controls.WhyNotPrevented.errors?.['required']">
              This is a required field
            </span>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">12. Was a playbook used in the bridge? <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select id="opt" ng-model="selectedStatus" class="form-control" name="WasPlaybookUsed" formControlName="WasPlaybookUsed" #WasPlaybookUsed>
              <option [ngValue]="null">--Select--</option>
              <option *ngFor="let opt of threeOptions; let i = index" [value]="threeOptions[i].value" style="color:grey;">{{threeOptions[i].value}}</option>
            </select>
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.WasPlaybookUsed.touched) && importantDetailsModalForm.controls.WasPlaybookUsed.errors?.['required']">
              This is a required field
            </span>
          </div>
          <div class="row form-group" *ngIf="importantDetailsModalForm.value.WasPlaybookUsed == 'No'">
            <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;"></label>
            <div class="col-sm-5">
              <input type="text" id="additionalCom" class="form-control" placeholder="Comments" name="WasPlaybookUsedDesc" formControlName="WasPlaybookUsedDesc" #WasPlaybookUsedDesc />
              <span class="text-danger"
                    *ngIf="(importantDetailsModalForm.controls.WasPlaybookUsedDesc.touched) && importantDetailsModalForm.controls.WasPlaybookUsedDesc.errors?.['required']">
                This is a required field
              </span>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">13. Was the Gone Fishing Page or in-app notification posted?  <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <select id="opt" ng-model="selectedStatus" class="form-control" name="GoneFishingPage" formControlName="GoneFishingPage" #GoneFishingPage>
              <option [ngValue]="null">--Select--</option>
              <option *ngFor="let opt of twoOptions; let i = index" [value]="twoOptions[i].value" style="color:grey;">{{twoOptions[i].value}}</option>
            </select>
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.GoneFishingPage.touched) && importantDetailsModalForm.controls.GoneFishingPage.errors?.['required']">
              This is a required field
            </span>
          </div>
          <div class="row form-group" *ngIf="importantDetailsModalForm.value.GoneFishingPage == 'No'">
            <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;"></label>
            <div class="col-sm-5">
              <input type="text" id="additionalCom" class="form-control" placeholder="Comments" name="GoneFishingPageDesc" formControlName="GoneFishingPageDesc" #GoneFishingPageDesc />
              <span class="text-danger"
                    *ngIf="(importantDetailsModalForm.controls.GoneFishingPageDesc.touched) && importantDetailsModalForm.controls.GoneFishingPageDesc.errors?.['required']">
                This is a required field
              </span>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">14. For P1 issue, did the MD / backup MD joined the bridge? <em style="color:red;">*</em>:</label>
          <div class="col-sm-5">
            <select id="opt" ng-model="selectedStatus" class="form-control" name="MdJoined" formControlName="MdJoined" #MdJoined>
              <option [ngValue]="null">--Select--</option>
              <option *ngFor="let opt of twoOptions; let i = index" [value]="twoOptions[i].value" style="color:grey;">{{twoOptions[i].value}}</option>
            </select>
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.MdJoined.touched) && importantDetailsModalForm.controls.MdJoined.errors?.['required']">
              This is a required field
            </span>
          </div>
          <div class="row form-group" *ngIf="importantDetailsModalForm.value.MdJoined == 'No'">
            <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;"></label>
            <div class="col-sm-5">
              <input type="text" id="additionalCom" class="form-control" placeholder="Comments" name="MdJoinedDesc" formControlName="MdJoinedDesc" #MdJoinedDesc />
              <span class="text-danger"
                    *ngIf="(importantDetailsModalForm.controls.MdJoinedDesc.touched) && importantDetailsModalForm.controls.MdJoinedDesc.errors?.['required']">
                This is a required field
              </span>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">15. Was hot debrief conducted? <em style="color:red;">*</em> :</label>
          <div class="col-sm-5">
            <label *ngFor="let choice of getTwoChoiceRadio; let i = index" style="margin-left:1px;">
              <input type="radio" name="DebriefConducted" formControlName="DebriefConducted" [checked]="getTwoChoiceRadio[i].selected" [value]="getTwoChoiceRadio[i].value" #DebriefConducted />{{getTwoChoiceRadio[i].value}}
            </label>
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.DebriefConducted.touched) && importantDetailsModalForm.controls.DebriefConducted.errors?.['required']">
              This is a required field
            </span>
          </div>
        </div>
        <div class="row form-group" *ngIf="importantDetailsModalForm.value.DebriefConducted == 'Yes'">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">EID <em style="color:red;">*</em></label>
          <div class="col-sm-5">
            <input type="text" class="form-control" placeholder="EID" name="DebriefEID" formControlName="DebriefEID" #DebriefEID />
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.DebriefEID.touched) && importantDetailsModalForm.controls.DebriefEID.errors?.['required']">
              This is a required field
            </span>
            <!--<span class="text-danger" [hidden]="DebriefConductedState">
          This is a required field
        </span>-->
          </div>
        </div>
        <div class="row form-group" *ngIf="importantDetailsModalForm.value.DebriefConducted == 'No'">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">Comment <em style="color:red;">*</em></label>
          <div class="col-sm-5">
            <input type="text" class="form-control" placeholder="Comments" name="DebriefNotes" formControlName="DebriefNotes" #DebriefNotes />
            <span class="text-danger"
                  *ngIf="(importantDetailsModalForm.controls.DebriefNotes.touched) && importantDetailsModalForm.controls.DebriefNotes.errors?.['required']">
              This is a required field
            </span>
            <!--<span class="text-danger" [hidden]="DebriefConductedState">
          This is a required field
        </span>-->
          </div>
        </div>
        <!--<div class="row form-group" *ngIf="importantDetailsModalForm.value.DebriefConducted == 'Yes'">
      <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;"></label>
      <div class="col-sm-5">
        <input type="text" class="form-control" name="DebriefNotes" formControlName="DebriefNotes" #DebriefNotes />
      </div>
    </div>-->
        <!-- Note section -->
        <div class="row form-group" formArrayName="Notes" *ngFor="let control of Notes.controls; let i = index;">
          <label *ngIf="i == 0" class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">Note :</label>
          <label *ngIf="i != 0" class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;"></label>
          <div class="col-sm-5">
            <textarea type="textarea" id="Notes" (keyup)="checkNotes($event)" maxlength="1000" class="form-control" name="Notes" [formControlName]="i" #Notes></textarea>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;"></label>
          <div class="col-sm-5">
            <button type="button" (click)="addNotes()" id="btnNewNoteAdd" class="btn btn-secondary">Add</button>
          </div>
        </div>

        <!--<div class="row form-group">
      <label class="col-form-label col-md-4 detailsLabel" for="text" style="text-align: left;">Note :</label>
      <div class="col-sm-5">
        <textarea type="textarea" id="additionalCom" class="form-control" name="Notes" formControlName="Notes" #Notes></textarea>
      </div>
    </div>-->
      </div>
      <div class="alert alert-danger" *ngIf="errorMessage">
        {{errorMessage}}
      </div>
      <div class="alert alert-danger" *ngIf="noteError">
        {{noteError}}
      </div>
      <div class="form-group modal-footer">
        <div class="pull-right">
          <button type="submit" id="btnImportantDetSave" class="btn btn-secondary">Save changes</button>
          <button id="addImportantDetClose" type="button" class="btn btn-primary" data-dismiss="modal" (click)="modal.dismiss('Close click'); importantDetailsModalForm.reset(); removeNotes(); addNotes();">Close</button>
          <input type="hidden" id="hdnAppId" />
        </div>
      </div>
    </form>
  </ng-template>

</div><!--main div closing-->
