import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { OnboardingInterface } from '../models/onboarding-interface';
import { RequestOnboardingResponseInterface } from '../models/RequestOnboardingResponse';

@Injectable({
  providedIn: 'root'
})
export class RequestOnboardingService {
  baseUrl: string = environment.baseUrl;
  url = this.baseUrl + "api/OnboardingForm/FetchOnboardingDetails";
  delData: any;



  constructor(private http: HttpClient) {

  }
  FetchOnboardingDetails(form: any): Observable<RequestOnboardingResponseInterface> {
    return this.http.post<OnboardingInterface>(this.url, form).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }
  addForms(form: any): Observable<RequestOnboardingResponseInterface> {
    let addUrl = this.baseUrl + "api/OnboardingForm/SaveFormRecord"
    return this.http.post<OnboardingInterface>(addUrl, form).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable) 
    );
  }
  approveForms(form: any): Observable<RequestOnboardingResponseInterface> {
    let addUrl = this.baseUrl + "api/Approver/ApproveFormRecord"
    return this.http.post<OnboardingInterface>(addUrl, form).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }

  deleteForms(form: any): Observable<RequestOnboardingResponseInterface> {
    let url = this.baseUrl + "api/Approver/DeleteOnboardingRequest";
    return this.http.post<RequestOnboardingResponseInterface>(url, this.delData)
  }
 
  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  }
}

