import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { UserInfoService } from '../services/user-info.service';
import { IssueInterface } from '../models/issue-interface';
import { IssueTrackerResponseInterface } from '../models/issueTrackerResponse';
import { ShiftturnoverModel } from '../models/shiftturnover-model';
import { ShiftturnoverResponse } from '../models/shiftturnover-response';
import { environment } from '../../environments/environment';
import { ImportantDetailsResponseModel } from '../models/important-details-response-model';
import { ImportantDetails } from '../models/important-details.model';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
//type ProfileType = {
//    givenName?: string,
//    surname?: string,
//    userPrincipalName?: string,
//    id?: string
//}
@Injectable({
    providedIn: 'root'
})
export class IssuesService {
    baseUrl:string = environment.baseUrl;
    url = this.baseUrl+"api/IssueTracker/FetchTrackerSummary";
    delData : any;

    // headers = {
    //     'content-type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Methods': 'POST, OPTIONS'
    // };
    
    // headers = new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${this._recv_token}`
    //   })
    constructor(private http: HttpClient) {
      
    }

    FetchTrackerSummary(tracker:any): Observable<IssueTrackerResponseInterface> {
        return this.http.post<IssueInterface>(this.url,tracker).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    FilterTrackerSummary(tracker: any): Observable<IssueTrackerResponseInterface> {
        let url = this.baseUrl+"api/IssueTracker/FilterTrackerSummary";
        return this.http.post<IssueTrackerResponseInterface>(url, tracker).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    addIssues(issue: any): Observable<IssueTrackerResponseInterface> {
        let addUrl = this.baseUrl+"api/IssueTracker/Add";
      return this.http.post<IssueInterface>(addUrl, issue).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    deleteIssues(tracker: any): Observable<IssueTrackerResponseInterface> {
        let url = this.baseUrl+"api/IssueTracker/Delete";
        return this.http.post<IssueTrackerResponseInterface>(url, this.delData)
    }
    updateIssues(issue: IssueInterface): Observable<IssueInterface> {
        let updateUrl = this.baseUrl+"api/IssueTracker/Add";
        return this.http.post<IssueInterface>(updateUrl, issue)
    }
    addShiftTurnOver(ShiftTurnOver: any): Observable<ShiftturnoverResponse> {
        let addUrl = this.baseUrl+"api/ShiftTurnOver/Add";
        return this.http.post<ShiftturnoverResponse>(addUrl, ShiftTurnOver).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
  }

  CheckDraftTurnOverData(): Observable<ShiftturnoverResponse> {
    var data = { LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    return this.http.post<ShiftturnoverModel>(this.baseUrl + "api/ShiftTurnOver/FindTurnOverDataByItemStatus", data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }
  FetchIssueDetailByTurnOverId(data:any):Observable<ShiftturnoverResponse> {
    return this.http.post<ShiftturnoverResponse>(this.baseUrl + "api/IssueTracker/FetchIssueDetailByTurnOverId", data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }
  AddImportantDetails(impDetails: any): Observable<ImportantDetailsResponseModel> {
    let addImpDetUrl = this.baseUrl + "api/IssueTracker/AddImportantDetails";
    return this.http.post<ImportantDetailsResponseModel>(addImpDetUrl, impDetails).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }
    private extractData(res: any) {
        let body = res;
        return body;
    }
    private handleErrorObservable(error: any) {
        console.error(error.message || error);
        return throwError(error);
    }
} 
