import { Component, OnInit, ViewChild, Input, Output, ElementRef } from '@angular/core';
import * as XLSX from 'xlsx';
import { DatePipe } from "@angular/common";
import { IssuesService } from '../services/issue-tracker.service';
import { UserInfoService } from '../services/user-info.service';
import { IssueInterface } from '../models/issue-interface';
import { ProfileInterface } from '../models/profile-interface';
import { IssueTrackerResponseInterface } from '../models/issueTrackerResponse';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { ApplicationModel } from '../models/application-model';
import { AppSearchResponse } from '../models/appSearchResponse';
import { CommonService } from '../services/common.service';
import { RootObject } from '../models/root-object';
import { PeopleModel } from '../models/people-model';
import { UserPeopleResponse } from '../models/user-people-response';
import { ShiftturnoverModel } from '../models/shiftturnover-model';
import { ShiftturnoverResponse } from '../models/shiftturnover-response';
import { environment } from '../../environments/environment';
import { ImportantDetails } from '../models/important-details.model';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  ValidatorFn,
  Validators,
  RequiredValidator,
  FormControlDirective
} from '@angular/forms';
import { catchError, map, Observable, switchMap, throwError } from 'rxjs';
import { ApplicationCommonModel } from '../models/application-common-model';
import { ApplicationCommonResponseModel } from '../models/application-common-response';
import { UserModel } from '../models/user-model';
import { UserResponseModel } from '../models/user-model-response';
import { ThemePalette } from '@angular/material/core';
import { Conditional } from '@angular/compiler';
import { ImportantDetailsResponseModel } from '../models/important-details-response-model';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
type ProfileType = {
  givenName: string,
  surname: string,
  userPrincipalName: string,
  id: string
}
@Component({
  selector: 'app-issuetracker',
  templateUrl: './issue-tracker.component.html',
  styleUrls: ['./issue-tracker.component.css']
})
export class IssuetrackerComponent implements OnInit {
  noteValue: string = "";
  noteError: string = "";
  baseURL: string = environment.baseUrl;
  closeResult: string = '';
  fileName = 'IssuesExcelSheet.xlsx';
  issues: IssueInterface[] = [];
  issuesFilter: IssueInterface[] = [];
  issueTrackerResponse: IssueTrackerResponseInterface | undefined;
  profile!: ProfileType;
  errorMessage = '';
  errorMessage2 = '';
  issueName = '';
  issuepriority: any[] = [];
  status: any[] = [];
  biFlag: any[] = [];
  servicepriority: any[] = [];
  hotdebriefcompleted: any[] = [];
  shiftRegion: any[] = [];
  itemStatus: any[] = [];
  healthStatus: any[] = [];
  issueduringperiod: any[] = [];
  fourOptions: any[] = [];
  threeOptions: any[] = [];
  twoOptions: any[] = [];
  getTwoChoiceRadio: any[] = [];
  todayDate = '';
  minDate: Date = new Date(2010, 0, 1); // ASA Business Bypass Logic
  Applications: ApplicationCommonModel[] = [];
  AppResponse: ApplicationCommonResponseModel | undefined;
  options: ApplicationModel[] = [];
  public applications: any[] = [];
  selectedAirIds: any[] = [];
  UserResponse: UserResponseModel | undefined;
  UserPeopleResponse: UserPeopleResponse | undefined;
  shiftTurnOver: ShiftturnoverModel[] = [];
  public turnOverResult: ShiftturnoverModel[] = [];
  FetchIssueDetailByTurnOverIdResponse: ShiftturnoverResponse | undefined;
  FetchIssueDetailByTurnOverIdResult: ShiftturnoverModel[] = [];
  ImportantDetailsModal: ImportantDetails | undefined;
  ImportantDetailsResponseModel: ImportantDetailsResponseModel | undefined;
  shiftTurnOverFinalEmailToBeSent = '';
  ShiftTurnOverResponse: ShiftturnoverResponse | undefined;
  public CreatedBy: string = '';
  RootOBJ: any;
  RootObjV: RootObject | undefined;
  lotOfPeople: PeopleModel[] = [];
  leads: UserModel[] = [];
  public SearchLeads: any[] = [];
  public SearchPeople: any[] = [];
  public csermanager: any[] = [];
  public nsermanager: any[] = [];
  keyword = 'Name';
  keywordL = 'EnterprisedId';
  keywordP = 'EnterpriseId';
  keywordCSM = 'EnterprisedId';
  keywordNSM = 'EnterprisedId';
  applicationNameAddForm: any = [];
  leadsNameAddForm: any = [];
  applicationNameAddFormImpactedS: any = [];
  csmNameShiftTurnOver: any = [];
  tempApps = '';
  samplePrev = '';
  hoverIndex: any;
  public _recv_token: any;
  public ControlStartDate = new FormControl();
  public ControlEndDate = new FormControl();
  public ControlNextUpdate = new FormControl();
  public HotDebriefFacilitator = new FormControl();
  public IssuePeriod: any = "No";
  //To store the selected checkboxes while creating the Checkpoint email
  clearId: any = [];
  @ViewChild('LoggedInUser') LoggedInUser!: ElementRef;
  @ViewChild('ApplicationName') ApplicationName!: ElementRef;
  @ViewChild('maindivbody', { read: ElementRef, static: false }) maindivBody!: ElementRef;
  @ViewChild('searchdivbody', { read: ElementRef, static: false }) searchdivbody!: ElementRef;
  @ViewChild('itemTemplate') itemTemplate: any;
  @ViewChild('divSuccess') divSuccess!: ElementRef;
  @ViewChild('picker') picker: any;
  @ViewChild('endDate') endDate: any;
  public currentManagerDraft = new FormControl();
  public newManagerDraft = new FormControl();
  public formTitle: string = '';
  @Input()
  selectedDateTime!: Date;
  @Input()
  DateIdentified!: Date;
  //@Input() startDate!: Date;
  //@Input() endDate!: Date;
  txtValue: string = '';

  //date picker
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = true;
  public stepMinutes = [0o0, 15, 30, 45];
  public stepHour = 1;
  public stepMinute = 15;
  public stepSecond = 1;
  public defaultTime = [new Date().getHours(), 0, 0]
  public color: ThemePalette = 'primary';

  deldata: any = {
    Id: 0, IncidentTicket: '', StartDate: '', EndDate: '', ApplicationName: '',
    ServiceLead: '', Status: '', Priority: '',
    ServicePriority: '', LoggedInUser: sessionStorage.getItem('LoggedInUser')
  }
  addForm = new FormGroup({
    Id: new FormControl(), ApplicationId: new FormControl(), AirId: new FormControl(), ApplicationName: new FormControl(), OtherApplication: new FormControl(), ImpactedServiceApplicationName: new FormControl(), OtherImpactedServiceApplication: new FormControl(),
    ServiceLead: new FormControl(), BusinessImpactFlag: new FormControl(), BusinessImpact: new FormControl(), StartDate: new FormControl(), EndDate: new FormControl(), Summary: new FormControl(), Priority: new FormControl(), Status: new FormControl(), BridgeDetails: new FormControl(),
    CircleLink: new FormControl(), ServicePriority: new FormControl(), CreatedBy: new FormControl(), CreateDateTime: new FormControl(), ModifiedBy: new FormControl(), ModifiedDateTime: new FormControl(),
    SendMail: new FormControl(), ischecked: new FormControl(), BusinessCycleAwareness: new FormControl(), NewIssueRecorded: new FormControl(), LastModifiedBy: new FormControl(), OpenedBy: new FormControl(), EndDatetimeForReport: new FormControl(), StartDatetimeForReport: new FormControl(),
    CreateDateTimeForReport: new FormControl(), ModifiedDateTimeForReport: new FormControl(), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser')),
    HotDebriefCompleted: new FormControl(), HotDebriefFacilitator: new FormControl(), IssueSendMail: new FormControl(), IncidentTicket: new FormControl(),
    CurrentHealthStatus: new FormControl(), NextUpdate: new FormControl(), NextUpdateForReport: new FormControl(), plannedActivity: new FormControl(), PlannedOperationActivities: new FormControl()
  });


  searchformGroup = new FormGroup({
    StartDate: new FormControl(), EndDate: new FormControl(),
    ApplicationName: new FormControl(), Priority: new FormControl(),
    ServiceLead: new FormControl(), Status: new FormControl(),
    ServicePriority: new FormControl(), EnterpriseId: new FormControl(),
    LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser')),
    ApplicationId: new FormControl()
  });

  turnOverModalForm = new FormGroup({
    Id: new FormControl(),
    TurnOverDate: new FormControl(new Date().toISOString()),
    CurrentHealthStatus: new FormControl(),
    CurrentRegionalManager: new FormControl(),
    CurrentShiftRegion: new FormControl('', Validators.minLength(0)),
    NewRegionalManager: new FormControl(),
    NewShiftRegion: new FormControl('', Validators.minLength(0)),
    NewIssueRecorded: new FormControl(), OpenIssues: new FormControl(),
    DegradedIssues: new FormControl(),
    ClosedIssues: new FormControl(), PreventiveIssues: new FormControl(),
    ItemStatus: new FormControl('Draft'),
    ShiftTurnOverAttendee: new FormControl('', Validators.minLength(0)), //PlannedOperationActivities: new FormControl(),
    CreatedBy: new FormControl(), CreatedDateTime: new FormControl(),
    LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser')), oldCurrentManager: new FormControl(), NewManager: new FormControl(),
    oldNewManager: new FormControl(), CurrentManagerId: new FormControl(), NewManagerId: new FormControl(),
    selectedNewregion: new FormControl()
  });

  importantDetailsModalForm = new FormGroup({
    Id: new FormControl(),
    EnterpriseId: new FormControl(sessionStorage.getItem('LoggedInUser')),
    IncidentNumber: new FormControl(),
    AlertReceived: new FormControl('', Validators.required),
    AlertReceivedDesc: new FormControl(''),
    IsDowngraded: new FormControl('', Validators.required),
    IsDowngradedDesc: new FormControl('', Validators.required),
    IsChanged: new FormControl('', Validators.required),
    IsChangedDesc: new FormControl('', Validators.required),
    ProblemTicketNo: new FormControl('', Validators.required),
    EngagedOnTime: new FormControl('', Validators.required),
    GSMGPCDesc: new FormControl('', Validators.required),
    HowLongDegrade: new FormControl('', Validators.required),
    IsReported: new FormControl('', Validators.required),
    IsReportedDesc: new FormControl('', Validators.required),
    CauseCategory: new FormControl('', Validators.required),
    CauseNum: new FormControl('', Validators.required),
    WhichTechTeam: new FormControl('', Validators.required),
    WhyNotPrevented: new FormControl('', Validators.required),
    WasPlaybookUsed: new FormControl('', Validators.required),
    WasPlaybookUsedDesc: new FormControl('', Validators.required),
    GoneFishingPage: new FormControl('', Validators.required),
    GoneFishingPageDesc: new FormControl('', Validators.required),
    MdJoined: new FormControl('', Validators.required),
    MdJoinedDesc: new FormControl('', Validators.required),
    DebriefConducted: new FormControl('', Validators.required),
    DebriefEID: new FormControl('', Validators.required),
    DebriefNotes: new FormControl('', Validators.required),
    //Notes: new FormControl(),
    Notes: new FormArray([new FormControl('', [Validators.maxLength(1000)])]),
    ModifiedBy: new FormControl(),CreatedBy: new FormControl(),
    LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser'))
  });

  get Notes() {
    return this.importantDetailsModalForm.get('Notes') as FormArray
  }

  addNotes() {
    this.Notes.push(new FormControl('', [Validators.maxLength(1000)]));
  }

  removeNotes() {
    this.Notes.clear();
    //let index = this.Notes.length;
    //for (let i = 1; i <= index-1; i++) {
    //  console.log(i);
    //  this.Notes.removeAt(i);
    //}
  }

  checkNotes(data: any)
  {
    this.noteValue = data.target.value;
    if (this.noteValue.length >= 1000) {
      this.noteError = "Max limit is 1000";
    }
    else {
      this.noteError = "";
    }
  }

  QuestionnaireTemplate = {
    headers: true,
    columns: [
      { columnid: 'IncidentNumber', title: 'Incident Name' },
      { columnid: 'AlertReceived', title: 'Alert ReceDid we receive any alert? If yes, what type?' },
      { columnid: 'AlertReceivedDesc', title: 'Comments' },
      { columnid: 'IsDowngraded', title: 'For P1/P2 issue, was the ticket priority downgraded after resolved communication? ' },
      { columnid: 'IsDowngradedDesc', title: 'Comments' },
      { columnid: 'IsChanged', title: 'For Preventive issue, was the ticket priority changed to P3 after GSM confirmed it is a Preventive issue? ' },
      { columnid: 'IsChangedDesc', title: 'Comments' },
      { columnid: 'ProblemTicketNo', title: 'What is the problem ticket number?' },
      { columnid: 'EngagedOnTime', title: 'Was GSM / GPC engaged on time?' },
      { columnid: 'GSMGPCDesc', title: 'Comments' },
      { columnid: 'HowLongDegrade', title: 'How long was the outage/service degradation? What took it so long? ' },
      { columnid: 'IsReported', title: 'Was the issue reported to GPCOfficerOfTheWatch bot? ' },
      { columnid: 'IsReportedDesc', title: 'Comments' },
      { columnid: 'CauseCategory', title: 'What is the appropriate Cause Category? ' },
      { columnid: 'CauseNum', title: 'If caused by a deployment, what is the change number/activity name?' },
      { columnid: 'WhichTechTeam', title: 'Which tech team will lead the root cause investigation?' },
      { columnid: 'WhyNotPrevented', title: 'Why this incident was not prevented? ' },
      { columnid: 'WasPlaybookUsed', title: 'Was a playbook used in the bridge?' },
      { columnid: 'WasPlaybookUsedDesc', title: 'Comments' },
      { columnid: 'GoneFishingPage', title: 'Was the Gone Fishing Page or in-app notification posted?' },
      { columnid: 'GoneFishingPageDesc', title: 'Comments' },
      { columnid: 'MdJoined', title: 'For P1 issue, did the MD / backup MD joined the bridge?' },
      { columnid: 'MdJoinedDesc', title: 'Comments' },
      { columnid: 'DebriefConducted', title: 'Was hot debrief conducted?' },
      { columnid: 'DebriefEID', title: 'Facilitors EID' },
      { columnid: 'DebriefNotes', title: 'Comments' },
      { columnid: 'Notes', title: 'Notes' },
    ]
  };

  public tracker = {
    StartDate: null, EndDate: null, ApplicationId: 0, Priority: null,
    ServiceLead: null, Status: null,
    ServicePriority: null, PageNumber: 1, RowsperPage: 5,
    LoggedInUser: sessionStorage.getItem('LoggedInUser')
  };
  mailPrevData = {
    ShiftTurnOverId: 0, CurrentHealthStatus: '', LoggedInUser: sessionStorage.getItem('LoggedInUser'), OpenIssues: [], PreventiveIssues: [],
    DegradedIssues: [], ClosedIssues: [], NewIssueRecorded: [] };
  //for pagination
  page: number = 1;
  count: number = 0;
  tableSize: number = 5;
  tableSizes: any = [3, 6, 9, 12];
  getusername: any[] = [];
  public username!: string;
  searchText: string = this.ApplicationName?.nativeElement.value;
  user = '';
  isToolTipShow: boolean;
  showInprogress: boolean = false;
  showdegrade: boolean = false;
  showprevention: boolean = false;
  showresolved: boolean = false;
  arrDegraded: any[] = [];
  arrInprogress: any[] = [];
  arrResolved: any[] = [];
  arrPreventive: any[] = [];
  arrDegradedIds: any[] = [];
  arrInprogressIds: any[] = [];
  arrResolvedIds: any[] = [];
  arrPreventiveIds: any[] = [];
  arrAllSelectedIds: any[] = [];

  inprogress: any[] = [];
  resolved: any[] = [];
  degraded: any[] = [];
  prevention: any[] = [];

  inprogressids: any[] = [];
  resolvedids: any[] = [];
  degradedids: any[] = [];
  preventionids: any[] = [];


  StartDateValue: any;
  EndDateValue: any;
  public turnOverResultcheck: any[] = [];
  DebriefConductedState:string = 'hidden';
  successNotification(reason: string) {
    Swal.fire({
      title: 'Success',
      text: reason,
      icon: 'success',
      confirmButtonText: 'OK'
    });
  }

  errorNotification(reason: string) {
    Swal.fire({
      title: 'Error',
      text: reason,
      icon: 'warning',
      confirmButtonText: 'OK'
    })
  }
  public loading = false;
  //for alasql excel export
  alasql = require('alasql')
  ItTemplate = {
    headers: true,
    columns: [
      { columnid: 'IncidentTicket', title: 'Incident Name' },
      { columnid: 'ApplicationName', title: 'AirId-Application Name' },
      { columnid: 'ServiceLead', title: 'Service Lead' },
      { columnid: 'Priority', title: 'Priority' },
      { columnid: 'StartDatetimeForReport', title: 'Issue Start Date (GMT)' },
      { columnid: 'EndDatetimeForReport', title: 'Issue End Date (GMT)' },
      { columnid: 'BusinessImpactFlag', title: 'Business Impact Flag' },
      { columnid: 'BusinessImpact', title: 'Business Impact' },
      { columnid: 'ImpactedServiceApplicationName', title: 'Impacted Service/s' },
      { columnid: 'Summary', title: 'Summary' },
      { columnid: 'NextUpdateForReport', title: 'Next Update (GMT)' },
      { columnid: 'Status', title: 'Status' },
      { columnid: 'BridgeDetails', title: 'Bridge Details' },
      { columnid: 'CircleLink', title: 'Circle Link' },
      { columnid: 'ServicePriority', title: 'Service Priority' },
      { columnid: 'OpenedBy', title: 'CreatedBy' },
      { columnid: 'CreateDateTimeForReport', title: 'Created Date' },
      { columnid: 'HotDebriefCompleted', title: 'Hot Debrief Completed' },
      { columnid: 'HotDebriefFacilitator', title: 'Hot Debrief Facilitator' },
      { columnid: 'LastModifiedBy', title: 'Last ModifiedBy' },
      { columnid: 'ModifiedDateTimeForReport', title: 'Last Modified Date' }
    ]
  };

  
  constructor(private issueService: IssuesService, private modalService: NgbModal, private formBuilder: FormBuilder, private http: HttpClient, private userInfoService: UserInfoService, private common: CommonService, private datePipe: DatePipe) {

    this.issuepriority = this.getIssuePriority();
    this.issueduringperiod = this.getIssueduringperiod().choices;
    this.status = this.getStatus();
    this.biFlag = this.getBiFlag();
    this.servicepriority = this.getServciePriority();
    this.hotdebriefcompleted = this.getHotdebrifecompliance().choices;
    this.shiftRegion = this.getShiftRegion();
    this.itemStatus = this.getItemStatus();
    this.healthStatus = this.getHealthStatus();
    this.fourOptions = this.getFourOptions();
    this.threeOptions = this.getThreeOptions();
    this.twoOptions = this.getTwoOptions();
    this.getTwoChoiceRadio = this.getTwoChoice().choices;
    this.issueService.FetchTrackerSummary(this.tracker);
    this.issueService.FilterTrackerSummary(this.searchformGroup.value);
    this.issueService.delData = this.deldata;
    this.issueService.AddImportantDetails(this.importantDetailsModalForm.value);
    this.todayDate = this.getDate();
    this.isToolTipShow = false;

  }


  async ngOnInit(): Promise<void> {
    function delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    this.getProfile();
    await delay(2000);
    this.fetchIssues();

  }
  LoadNextUpdateDate() {

    if (!((this.addForm.get('Priority')?.value == 'P1') && (this.addForm.get('Status')?.value == 'In-progress')) ||
      !((this.addForm.get('Priority')?.value == 'P2') && (this.addForm.get('Status')?.value == 'Under Monitoring')) ||
      !((this.addForm.get('Priority')?.value == 'P1') && (this.addForm.get('Status')?.value == 'Under Monitoring')) ||
      !((this.addForm.get('Priority')?.value == 'P2') && (this.addForm.get('Status')?.value == 'In-progress'))) {

      this.ControlNextUpdate = new FormControl();
      this.addForm.get('NextUpdate')?.setValue(null);
    }
  }
  LoadNextUpdate() {
    if (!((this.addForm.get('Priority')?.value == 'P1') && (this.addForm.get('Status')?.value == 'In-progress')) ||
      !((this.addForm.get('Priority')?.value == 'P2') && (this.addForm.get('Status')?.value == 'Under Monitoring')) ||
      !((this.addForm.get('Priority')?.value == 'P1') && (this.addForm.get('Status')?.value == 'Under Monitoring')) ||
      !((this.addForm.get('Priority')?.value == 'P2') && (this.addForm.get('Status')?.value == 'In-progress'))) {
      this.ControlNextUpdate = new FormControl();
      this.addForm.get('NextUpdate')?.setValue(null);
    }
  }
  onTextChangeStartDate(value: any) {
    //if (value.srcElement.innerHTML == '') {
    //  this.addForm.get('StartDate')?.setValue(null);
    //  this.ControlStartDate = new FormControl(null);

    //}
  }
  onTextChangeEndDate(value: any) {
    if ($('#dtEndDate').find('div:eq(0)').hasClass('invalid')) {
      this.addForm.get('EndDate')?.setValue(null);
    }
  }

  onTextChangeNextUpdate(value: any) {
    if ($('#NextUpdate').find('div:eq(0)').hasClass('invalid')) {
      this.addForm.get('NextUpdate')?.setValue(null);
    }
  }



  onTextChange(value: any) {
    if (this.addForm.get('NextUpdate')?.value != null || this.addForm.get('NextUpdate')?.value != '') {
      this.addForm.get('EndDate')?.setValue(null);
      this.ControlEndDate = new FormControl(null);
    }
  }

  getDate() {

    const now = new Date().toLocaleDateString();
    //this.turnOverModalForm.get("TurnOverDate")?.setValue(now);
    return now;
  }

  getIssuePriority() {
    return [{ Id: "1", value: "P1" },
    { Id: "2", value: "P2" },
    { Id: "3", value: "P3" }]
  }
  getIssueduringperiod() {
    return {
      choices: [{
        "choiceid": 1,
        "value": "Yes"
      }, {
        "choiceid": 2,
        "value": "No"
      }]
    }
  }
  getStatus() {
    return [{ Id: "1", value: "In-progress" },
    { Id: "2", value: "Under Monitoring" },
    { Id: "3", value: "Resolved" },
    { Id: "4", value: "Prevention" },
    { Id: "5", value: "Service Degraded" }]
  }
  getBiFlag() {
    return [{ Id: "1", value: "Yes" },
    { Id: "2", value: "No" },
    { Id: "3", value: "Presently Unknown" }]
  }
  getServciePriority() {
    return [{ Id: "1", value: "AO" },
    { Id: "2", value: "NAO" },
    { Id: "3", value: "Standard" }]
  }
  getShiftRegion() {
    return [{ Id: "1", value: "AMR" },
    { Id: "2", value: "APAC" },
    { Id: "3", value: "EME" },
    { Id: "4", value: "INDIA" },
    { Id: "5", value: "INDIA/EME" }
    ]
  }
  getItemStatus() {
    return [{ Id: "1", value: "Draft" },
    { Id: "2", value: "Final" }]
  }
  getHotdebrifecompliance() {
    return {
      choices: [{
        "choiceid": 1,
        "value": "Yes"
      }, {
        "choiceid": 2,
        "value": "No"
      }]
    }
  }
  getHealthStatus() {
    return [{ Id: "1", value: "Green" },
    { Id: "2", value: "Red" },
    { Id: "3", value: "Yellow" }]
  }
  getFourOptions() {
    return [{ Id: "1", value: "Yes" },
    { Id: "2", value: "No" },
    { Id: "3", value: "N/A" },
    { Id: "4", value: "TBD" }]
  }
  getThreeOptions() {
    return [{ Id: "1", value: "Yes" },
    { Id: "2", value: "No" },
    { Id: "3", value: "N/A" }]
  }
  getTwoOptions() {
    return [{ Id: "1", value: "Yes" },
      { Id: "2", value: "No" },
      { Id: "3", value: "N/A" }]
  }
  getTwoChoice() {
    return {
      choices: [{
        "choiceid": 1,
        "value": "Yes"
      }, {
        "choiceid": 2,
        "value": "No"
      }]
    }
  }
  LoadApplication(Search: string) {
    this.loading = true;
    if (Search == '') {
      this.selectedAirIds = [];
      this.searchformGroup.get("ApplicationName")?.setValue(null);
    }
    this.common.FindApplicationName("=" + Search + "=" + sessionStorage.getItem('LoggedInUser'))
      .subscribe(response => {
        this.loading = false;
        this.AppResponse = response;
        this.options = this.AppResponse!.Result;
        this.applications = this.options;
        this.applications.map(app => app.Name = `${app.AirId} - ${app.Name}`);
      });
    //return this.options;
  }

  fetchIssues() {
    this.issueService.FetchTrackerSummary(this.tracker).subscribe(response => {
      this.issueTrackerResponse = response;
      this.issuesFilter = this.issueTrackerResponse.Result;
      if (response.ErrorMessage == '') {
        this.issueService.CheckDraftTurnOverData().subscribe(res => {
          this.ShiftTurnOverResponse = res;
          this.turnOverResult = this.ShiftTurnOverResponse!.Result;

          //});
          var turnOverResult = this.turnOverResult;
          if (turnOverResult != null) {
            if (turnOverResult.length > 0) {
              this.issueService.FetchIssueDetailByTurnOverId(turnOverResult[0]).subscribe(resp => {
                this.FetchIssueDetailByTurnOverIdResponse = resp;
                this.FetchIssueDetailByTurnOverIdResult = this.FetchIssueDetailByTurnOverIdResponse!.Result;
                var issueTrackerResult = this.FetchIssueDetailByTurnOverIdResult;
                if (issueTrackerResult != null) {
                  for (var i = 0; i < issueTrackerResult.length; i++) {
                    if ((issueTrackerResult[i].Status == "Under Monitoring" && issueTrackerResult[i].Priority == "P1") || issueTrackerResult[i].Status == "Service Degraded" || (issueTrackerResult[i].Status == "In-progress" && issueTrackerResult[i].Priority == "P2")) {
                      if (this.arrDegradedIds.indexOf(issueTrackerResult[i].Id?.toString()) == -1) {
                        this.arrAllSelectedIds.push(issueTrackerResult[i].Id);
                        this.arrDegradedIds.push(issueTrackerResult[i].Id?.toString());
                        this.arrDegraded.push(issueTrackerResult[i].IncidentTicket?.toString());
                      }
                    } else if ((issueTrackerResult[i].Status == "Resolved" && issueTrackerResult[i].Priority == "P1") || (issueTrackerResult[i].Status == "Resolved" && issueTrackerResult[i].Priority == "P2") || (issueTrackerResult[i].Status == "Under Monitoring" && issueTrackerResult[i].Priority == "P2")) {
                      if (this.arrResolvedIds.indexOf(issueTrackerResult[i].Id?.toString()) == -1) {

                        this.arrAllSelectedIds.push(issueTrackerResult[i].Id);
                        this.arrResolvedIds.push(issueTrackerResult[i].Id?.toString());
                        this.arrResolved.push(issueTrackerResult[i].IncidentTicket?.toString());
                      }
                    }
                    else if (issueTrackerResult[i].Status == "In-progress" && issueTrackerResult[i].Priority == "P1") {
                      if (this.arrInprogressIds.indexOf(issueTrackerResult[i].Id?.toString()) == -1) {

                        this.arrAllSelectedIds.push(issueTrackerResult[i].Id);
                        this.arrInprogressIds.push(issueTrackerResult[i].Id?.toString());
                        this.arrInprogress.push(issueTrackerResult[i].IncidentTicket?.toString());
                      }
                    }
                    else if (issueTrackerResult[i].Status == "Prevention") {
                      if (this.arrPreventiveIds.indexOf(issueTrackerResult[i].Id?.toString()) == -1) {
                        this.arrAllSelectedIds.push(issueTrackerResult[i].Id);
                        this.arrPreventiveIds.push(issueTrackerResult[i].Id?.toString());
                        this.arrPreventive.push(issueTrackerResult[i].IncidentTicket?.toString());
                      }
                    }
                  } //for closing
                }
                //return this.FetchIssueDetailByTurnOverIdResult;
                this.inprogress = this.arrInprogress;
                this.resolved = this.arrResolved;
                this.degraded = this.arrDegraded;
                this.prevention = this.arrPreventive;

                this.inprogressids = this.arrInprogressIds;
                this.resolvedids = this.arrResolvedIds;
                this.degradedids = this.arrDegradedIds;
                this.preventionids = this.arrPreventiveIds;

                for (var i = 0; i < this.issuesFilter.length; i++) {

                  if (this.arrDegradedIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

                    this.issuesFilter[i].ischecked = true;
                  }
                  if (this.arrInprogressIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

                    this.issuesFilter[i].ischecked = true;
                  }
                  if (this.arrResolvedIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

                    this.issuesFilter[i].ischecked = true;
                  }
                  if (this.arrPreventiveIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

                    this.issuesFilter[i].ischecked = true;

                  }

                }
              }); //CheckbyID
            }//if response
          }

        });//checkTurnover
      }
    });
  }
  ShiftTrunOverModal() {
    this.issueService.CheckDraftTurnOverData()
      .subscribe(response => {
        this.ShiftTurnOverResponse = response;
        this.turnOverResult = this.ShiftTurnOverResponse!.Result;

        this.OpenShiftTurnOver(this.turnOverResult);
        return this.turnOverResult;
      });
  }


  //CheckDraftTurnOverData() {
  //  this.loading = true;
  //  var data = { LoggedInUser: sessionStorage.getItem('LoggedInUser') };
  //  return this.http.post<ShiftturnoverModel>(this.baseURL + "api/ShiftTurnOver/FindTurnOverDataByItemStatus", data).pipe(
  //    map(this.extractData),
  //    catchError(this.handleErrorObservable)
  //  ).subscribe(response => {
  //    this.loading = false;
  //    this.ShiftTurnOverResponse = response;
  //    this.turnOverResult = this.ShiftTurnOverResponse!.Result;
  //    console.log(this.turnOverResult);
  //    //this.FetchIssueDetailByTurnOverId(this.turnOverResult[0]);
  //    this.OpenShiftTurnOver();
  //    return this.turnOverResult;
  //  });
  //}
  //FetchIssueDetailByTurnOverId(turnOver: any) {
  //  console.log("this.FetchIssueDetailByTurnOverId function call", turnOver);
  //      return this.http.post<ShiftturnoverModel>(this.baseURL + "api/IssueTracker/FetchIssueDetailByTurnOverId", turnOver).pipe(
  //        map(this.extractData),
  //        catchError(this.handleErrorObservable)
  //      ).subscribe(response => {
  //        this.FetchIssueDetailByTurnOverIdResponse = response;
  //        this.FetchIssueDetailByTurnOverIdResult = this.FetchIssueDetailByTurnOverIdResponse!.Result;
  //        console.log("this.FetchIssueDetailByTurnOverIdResult",this.FetchIssueDetailByTurnOverIdResult);
  //        return this.FetchIssueDetailByTurnOverIdResult;
  //      });
  //    }


  OpenShiftTurnOver(ShiftTurnOver: any) {

    var healthstatus = [
      { Id: "1", value: "Red" },
      { Id: "2", value: "Yellow" },
      { Id: "3", value: "Green" }];
    var ShiftTurnOverId = 0;
    $('#HealthStatus').prop('disabled', true);
    $('#Issue_PeriodYes').prop('disabled', true);
    $('#Issue_PeriodNo').prop('disabled', true);
    $('#dtTurnOver').prop('disabled', true);
    if (ShiftTurnOver !== null) {
      if (ShiftTurnOver[0].Id == 0) {
        var turnoverdate = this.datePipe.transform(new Date().toISOString());
        this.turnOverModalForm.get("TurnOverDate")?.setValue(turnoverdate);
      }
      else {
        var turnoverdate = this.datePipe.transform(this.turnOverResult[0].TurnOverDate, "dd MMM yyyy");
        this.turnOverModalForm.get("TurnOverDate")?.setValue(turnoverdate);
      }
      this.currentManagerDraft = new FormControl(this.turnOverResult[0].CurrentRegionalManagerName);
      this.newManagerDraft = new FormControl(this.turnOverResult[0].NewRegionalManagerName);
      //this.CreatedBy = this.turnOverResult[0].CreatedBy;

      this.turnOverModalForm.patchValue({
        Id: ShiftTurnOver[0].Id,
        CurrentHealthStatus: ShiftTurnOver[0].CurrentHealthStatus,
        CurrentShiftRegion: ShiftTurnOver[0].CurrentShiftRegion,
        oldCurrentManager: ShiftTurnOver[0].CurrentRegionalManagerName,
        NewManager: ShiftTurnOver[0].NewRegionalManagerName,
        oldNewManager: ShiftTurnOver[0].NewRegionalManagerName,
        CurrentRegionalManager: ShiftTurnOver[0].CurrentRegionalManager,
        NewRegionalManager: ShiftTurnOver[0].NewRegionalManager,
        NewShiftRegion: ShiftTurnOver[0].NewShiftRegion, NewIssueRecorded: ShiftTurnOver[0].NewIssueRecorded,
        ShiftTurnOverAttendee: ShiftTurnOver[0].ShiftTurnOverAttendee,
        ItemStatus: ShiftTurnOver[0].ItemStatus, CreatedBy: ShiftTurnOver[0].CreatedBy,
        //PlannedOperationActivities: ShiftTurnOver[0].PlannedOperationActivities
      });
      if (ShiftTurnOver.ItemStatus == "Final") {
        $('#itemstatus').prop('disabled', true);
      }
      else {
        this.turnOverModalForm.get('ItemStatus')?.setValue('Draft')
        $('#itemstatus').prop('disabled', false);
      }
      this.turnOverModalForm.get('CreatedBy')?.setValue(ShiftTurnOver[0].CreatedBy);
      this.turnOverModalForm.get('CreatedDateTime')?.setValue(ShiftTurnOver[0].CreatedDateTime);
      //this.turnOverModalForm.get('PlannedOperationActivities')?.setValue(ShiftTurnOver[0].PlannedOperationActivities);

      //For to update Health Status Automation based on Issue selection
      if (this.arrInprogress.length > 0) {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[0]['value']);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("Yes");
        //this.IssuePeriod = "Yes";
      }
      else if (this.arrInprogress.length == 0 && this.arrDegraded.length > 0) {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[1]['value']);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("Yes");
        //this.IssuePeriod = "Yes";
      }
      else if (this.arrResolved.length > 0) {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[2]['value']);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("Yes");
        //this.IssuePeriod = "Yes";
      }
      else if (this.arrPreventive.length > 0) {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[2]['value']);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("Yes");
        //this.IssuePeriod = "Yes";
      }
      else if (this.arrInprogress.length == 0 && this.arrDegraded.length == 0) {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[2]['value']);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("No");
        //this.IssuePeriod = "No";
      }
      else {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[2]['value']);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("No");
        //this.IssuePeriod = "No";

      }

    } //first if closing
    else {

      this.turnOverModalForm.get('TurnOverDate')?.setValue(new Date().toISOString());

      //this.turnOverModalForm.get("PlannedOperationActivities")?.setValue(ShiftTurnOver[0]["PlannedOperationActivities"]);

      //For to update Health Status Automation based on Issue selection
      if (this.arrInprogress.length > 0) {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[0]["value"]);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("Yes");
        this.IssuePeriod = "Yes";
        //getscope.IsDisabled = true;
      }
      else if (this.arrInprogress.length == 0 && this.arrDegraded.length > 0) {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[1]["value"]);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("Yes");
        this.IssuePeriod = "Yes";
        //getscope.IsDisabled = true;
      }
      else if (this.arrResolved.length > 0) {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[2]["value"]);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("Yes");
        this.IssuePeriod = "Yes";
        //getscope.IsDisabled = true;
      }
      else if (this.arrPreventive.length > 0) {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[2]["value"]);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("Yes");
        this.IssuePeriod = "Yes";
        //getscope.IsDisabled = true;
      }
      else if (this.arrInprogress.length == 0 && this.arrDegraded.length == 0) {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[2]["value"]);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("No");
        this.IssuePeriod = "No";
        //getscope.IsDisabled = true;

      }
      else {
        this.turnOverModalForm.get("CurrentHealthStatus")?.setValue(healthstatus[2]["value"]);
        this.turnOverModalForm.get("NewIssueRecorded")?.setValue("No");
        this.IssuePeriod = "No";
        //getscope.IsDisabled = true;

      }
    }
  }
  //add shift turnover 

  onSaveData(openSendMailConfirmModal: any) {
    //this.loading = true;
    $('#btnItIssueSave').prop('disabled', true);
    this.turnOverModalForm.get('OpenIssues')?.setValue(this.arrInprogressIds);
    this.turnOverModalForm.get('DegradedIssues')?.setValue(this.arrDegradedIds);
    this.turnOverModalForm.get('ClosedIssues')?.setValue(this.arrResolvedIds);
    this.turnOverModalForm.get('PreventiveIssues')?.setValue(this.arrPreventiveIds);
    this.turnOverModalForm.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));

    this.issueService.addShiftTurnOver(this.turnOverModalForm.value)
      .subscribe(response => {
        this.loading = false;
        if (response.ErrorMessage == '') {
          this.ShiftTurnOverResponse = response;
          this.shiftTurnOver = this.ShiftTurnOverResponse.Result;

          this.mailPrevData.ShiftTurnOverId = this.shiftTurnOver[0].Id;
          this.mailPrevData.CurrentHealthStatus = this.turnOverModalForm.get("CurrentHealthStatus")?.value;
          //For CheckPoint Email - Without Important Details
          this.mailPrevData.DegradedIssues = this.turnOverModalForm.get("DegradedIssues")?.value;
          this.mailPrevData.OpenIssues = this.turnOverModalForm.get("OpenIssues")?.value;
          this.mailPrevData.PreventiveIssues = this.turnOverModalForm.get("PreventiveIssues")?.value;
          this.mailPrevData.ClosedIssues = this.turnOverModalForm.get("ClosedIssues")?.value;

          if (this.turnOverModalForm.value.ItemStatus == 'Final') {
            this.open(openSendMailConfirmModal);
          } else {
            var saveMessage = "Successful";
            this.successNotification(saveMessage);
            //this.turnOverModalForm.reset();

            var IssueTracker: any = (this.turnOverResult[0].SelectedOpenIssueIds).split(',');
            for (let Issue of IssueTracker) {
              if (this.arrDegradedIds.map(Number).indexOf(Issue) !== -1) {
                Issue.ischecked = true;
              }
              if (this.arrInprogressIds.map(Number).indexOf(Issue) !== -1) {
                Issue.ischecked = true;
              }
              if (this.arrResolvedIds.map(Number).indexOf(Issue) !== -1) {
                Issue.ischecked = true;
              }
              if (this.arrPreventiveIds.map(Number).indexOf(Issue) !== -1) {
                Issue.ischecked = true;

              }
            }
            $('#btnItIssueSave').prop('disabled', false);
          }
          $('#shiftTurnOverFormClose').trigger('click');
        } else {
          //this.loading = false;
          //this.errorMessage = response.ErrorMessage;
          this.errorMessage = response.ErrorMessage;
          setTimeout(() => { $('#btnItIssueSave').prop('disabled', false); }, 5000);
        }

      });
  }

  onTableDataChange(event: any) {
    this.page = event;

    for (var i = 0; i < this.issuesFilter.length; i++) {

      if (this.arrDegradedIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

        this.issuesFilter[i].ischecked = true;
      }
      if (this.arrInprogressIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

        this.issuesFilter[i].ischecked = true;
      }
      if (this.arrResolvedIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

        this.issuesFilter[i].ischecked = true;
      }
      if (this.arrPreventiveIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

        this.issuesFilter[i].ischecked = true;

      }

    }
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.fetchIssues();
    //for (var i = 0; i < this.issuesFilter.length; i++) {

    //  if (this.arrDegradedIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

    //    this.issuesFilter[i].ischecked = true;
    //  }
    //  if (this.arrInprogressIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

    //    this.issuesFilter[i].ischecked = true;
    //  }
    //  if (this.arrResolvedIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

    //    this.issuesFilter[i].ischecked = true;
    //  }
    //  if (this.arrPreventiveIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

    //    this.issuesFilter[i].ischecked = true;

    //  }

    //}
  }
  FilterTrackerSummary() {
    this.loading = true;
    //var divsuccess = this.divSuccess.nativeElement;
    //divsuccess.arrDegraded = new Array();
    //divsuccess.arrInprogress = new Array();
    //divsuccess.arrResolved = new Array();
    //divsuccess.arrPreventive = new Array();
    //divsuccess.arrDegradedIds = new Array();
    //divsuccess.arrInprogressIds = new Array();
    //divsuccess.arrResolvedIds = new Array();
    //divsuccess.arrPreventiveIds = new Array();
    //divsuccess.arrAllSelectedIds = new Array();

    ///// broadcast these array values
    //this.inprogress = divsuccess.arrInprogress;
    //this.resolved = divsuccess.arrResolved;
    //this.degraded = divsuccess.arrDegraded;
    //this.prevention = divsuccess.arrPreventive;

    //this.inprogressids = divsuccess.arrInprogressIds;
    //this.resolvedids = divsuccess.arrResolvedIds;
    //this.degradedids = divsuccess.arrDegradedIds;
    //this.preventionids = divsuccess.arrPreventiveIds;


    this.issueService.FilterTrackerSummary(this.searchformGroup.value)
      .subscribe(response => {
        this.loading = false;
        this.issueTrackerResponse = response;
        this.issuesFilter = this.issueTrackerResponse.Result;
        if (response.ErrorMessage != '') {

          //this.errorNotification(response.ErrorMessage);
          this.errorMessage2 = response.ErrorMessage;
        } else if (this.issueTrackerResponse.Result == null) {
          let message = "No Data found for selected search criteria";
          // this.errorNotification(message);
          this.errorMessage2 = message;
        } else {
          for (var i = 0; i < this.issuesFilter.length; i++) {

            if (this.arrDegradedIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

              this.issuesFilter[i].ischecked = true;
            }
            if (this.arrInprogressIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

              this.issuesFilter[i].ischecked = true;
            }
            if (this.arrResolvedIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

              this.issuesFilter[i].ischecked = true;
            }
            if (this.arrPreventiveIds.map(Number).indexOf(this.issuesFilter[i].Id) !== -1) {

              this.issuesFilter[i].ischecked = true;

            }

          }
        }
      }
      );
  }

  ExportExcel() {
    $('#btnItExportExcel').prop('disabled', true);

    return this.http.post<IssueInterface>(this.baseURL + 'api/IssueTracker/ExportItToExcel', this.searchformGroup.value).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.issueTrackerResponse = response;
      if (this.issueTrackerResponse!.Result == null) {
        var message = "No Data found for selected search criteria";
        //this.errorNotification(message);
        this.errorMessage = message;
      } else {
        var date = new Date();
        var day = date.getDate();     // Get current date
        var month = date.getMonth() + 1; // current month
        var year = date.getFullYear();
        var CurrentDate = year + "/" + month + "/" + day;

        //console.log(this.issueTrackerResponse!.Result);
        this.alasql('SELECT * INTO XLS("Issue Tracker Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.ItTemplate, this.issueTrackerResponse!.Result]);
        //For Important Details pop - up form
        this.alasql('SELECT * INTO XLS("Important Details Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.QuestionnaireTemplate, this.issueTrackerResponse!.result]);
        $('#btnItExportExcel').prop('disabled', false);
      }
    });
  }
  EmailCheck(issue: any) {
    var element = <HTMLInputElement>document.getElementById(issue.IncidentTicket);

    (this.clearId.indexOf(issue.Id) == -1) ? this.clearId.push(issue.IncidentTicket) : false;

    if (element.checked) {
      if ((issue.Status == "Under Monitoring" && issue.Priority == "P1") || (issue.Status == "In-progress" && issue.Priority == "P2")
        || issue.Status == "Service Degraded") {
        if (this.arrAllSelectedIds.map(Number).indexOf(issue.Id) == -1) {
          this.arrAllSelectedIds.push(issue.Id);
        }
        if (this.arrDegradedIds.map(Number).indexOf(issue.Id) == -1) {
          this.arrDegradedIds.push(issue.Id.toString());
          this.arrDegraded.push(issue.IncidentTicket.toString());
          // Save session data to storageService
          sessionStorage.setItem(issue.IncidentTicket.toString(), 'yellow');
        }
      }
      else if ((issue.Status == "Resolved" && issue.Priority == "P1") || (issue.Status == "Resolved" && issue.Priority == "P2")
        || (issue.Status == "Under Monitoring" && issue.Priority == "P2")) {
        if (this.arrAllSelectedIds.map(Number).indexOf(issue.Id) == -1) {
          this.arrAllSelectedIds.push(issue.Id);
        }
        if (this.arrResolvedIds.map(Number).indexOf(issue.Id) == -1) {
          this.arrResolvedIds.push(issue.Id.toString());
          this.arrResolved.push(issue.IncidentTicket.toString());
          // Save session data to storageService
          sessionStorage.setItem(issue.IncidentTicket.toString(), 'green');
        }
      }
      else if (issue.Status == "In-progress" && issue.Priority == "P1") {
        if (this.arrAllSelectedIds.map(Number).indexOf(issue.Id) == -1) {
          this.arrAllSelectedIds.push(issue.Id);
        }
        if (this.arrInprogressIds.map(Number).indexOf(issue.Id) == -1) {
          this.arrInprogressIds.push(issue.Id.toString());
          this.arrInprogress.push(issue.IncidentTicket.toString());
          // Save session data to storageService
          sessionStorage.setItem(issue.IncidentTicket.toString(), 'red');
        }
      }
      else if (issue.Status == "Prevention") {
        if (this.arrAllSelectedIds.map(Number).indexOf(issue.Id) == -1) {
          this.arrAllSelectedIds.push(issue.Id);
        }
        if (this.arrPreventiveIds.map(Number).indexOf(issue.Id) == -1) {
          this.arrPreventiveIds.push(issue.Id.toString());
          this.arrPreventive.push(issue.IncidentTicket.toString());
        }
      }
    } //main if
    else {
      if ((issue.Status == "Under Monitoring" && issue.Priority == "P1") || (issue.Status == "In-progress" && issue.Priority == "P2")
        || issue.Status == "Service Degraded") {
        var indexId = this.arrDegradedIds.indexOf(issue.Id);
        this.arrDegradedIds.splice(indexId, 1);

        var index = this.arrDegraded.indexOf(issue.IncidentTicket);
        this.arrDegraded.splice(index, 1);

        var selectedIdsIndex = this.arrAllSelectedIds.indexOf(issue.Id);
        this.arrAllSelectedIds.splice(selectedIdsIndex, 1);
        // Remove session data from storageService
        sessionStorage.removeItem(issue.IncidentTicket);
      }
      else if ((issue.Status == "Resolved" && issue.Priority == "P1") || (issue.Status == "Resolved" && issue.Priority == "P2")
        || (issue.Status == "Under Monitoring" && issue.Priority == "P2")) {
        var indexId = this.arrResolvedIds.map(Number).indexOf(issue.Id);
        this.arrResolvedIds.splice(indexId, 1);

        var index = this.arrResolved.map(Number).indexOf(issue.IncidentTicket);
        this.arrResolved.splice(index, 1);

        var selectedIdsIndex = this.arrAllSelectedIds.map(Number).indexOf(issue.Id);
        this.arrAllSelectedIds.splice(selectedIdsIndex, 1);
        // Remove session data from storageService
        sessionStorage.removeItem(issue.IncidentTicket);
      }
      else if (issue.Status == "In-progress" && issue.Priority == "P1") {
        var indexId = this.arrInprogressIds.map(Number).indexOf(issue.Id);
        this.arrInprogressIds.splice(indexId, 1);

        var index = this.arrInprogress.map(Number).indexOf(issue.IncidentTicket);
        this.arrInprogress.splice(index, 1);

        var selectedIdsIndex = this.arrAllSelectedIds.map(Number).indexOf(issue.Id);
        this.arrAllSelectedIds.splice(selectedIdsIndex, 1);
        // Remove session data from storageService
        sessionStorage.removeItem(issue.IncidentTicket);
      }
      else if (issue.Status == "Prevention") {
        var indexId = this.arrPreventiveIds.map(Number).indexOf(issue.IncidentTicket);
        this.arrPreventiveIds.splice(indexId, 1);

        var index = this.arrPreventive.map(Number).indexOf(issue.IncidentTicket);
        this.arrPreventive.splice(index, 1);

        var selectedIdsIndex = this.arrAllSelectedIds.map(Number).indexOf(issue.Id);
        this.arrAllSelectedIds.splice(selectedIdsIndex, 1);

        issue.ischecked = false;
      }


    }
    this.inprogress = this.arrInprogress;
    this.resolved = this.arrResolved;
    this.degraded = this.arrDegraded;
    this.prevention = this.arrPreventive;

    this.inprogressids = this.arrInprogressIds;
    this.resolvedids = this.arrResolvedIds;
    this.degradedids = this.arrDegradedIds;
    this.preventionids = this.arrPreventiveIds;
  }



  openSendMailConfirmModal(content: any) {
    // if(this.turnOverModalForm.get("ItemStatus")!.value=='Draft'){

    this.modalService.open({ content, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
    });
    // }else{

    // }
    //if statement closing
  }
  returnFalse() {

  }
  MailPreview() {
    var user: string = sessionStorage.getItem('LoggedInUser')!.toString();
    let params = { ShiftTurnOverId: this.mailPrevData.ShiftTurnOverId, loggedInUser: user };
    return this.http.get<ShiftturnoverModel>(this.baseURL + "api/CheckPointStatus/Preview", { params }).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.shiftTurnOverFinalEmailToBeSent = response;
      if (response.ErrorMessage != undefined) {
        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      } else {
        this.closeResult;
        $('#openSendMailConfirmModal').trigger('click');
      }

    });
  }
  emailPreview(content: any) {
    if (this.turnOverModalForm.get("ItemStatus")?.value == "Final") {

      this.modalService.open({ content, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `closed with: ${result}`;
      }, (reason: any) => {
        this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
      });
    } else {

    }
    //if statement closing
  }
  SendMail() {
    var data = {
      Id: this.mailPrevData.ShiftTurnOverId, CurrentHealthStatus: this.mailPrevData.CurrentHealthStatus, LoggedInUser: sessionStorage.getItem('LoggedInUser'),
      PreventiveIssues: this.mailPrevData.PreventiveIssues, OpenIssues: this.mailPrevData.OpenIssues, DegradedIssues: this.mailPrevData.DegradedIssues };

    //console.log(data);

    $('#btnStoMailSendContinue').prop('disabled', true);//16/01/2023
    return this.http.post<ShiftturnoverModel>(this.baseURL + "api/CheckPointStatus/SendMail", data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage == '') {
        var messgae = "Sent!";
        this.successNotification(messgae);
        $('#btnStoMailSendContinue').prop('disabled', false);
        $('#mailPreviewClose').trigger('click');
        //data.ischecked = false;
        for (let id of this.clearId) {
          var element = <HTMLInputElement>document.getElementById(id);
          if (element.checked) {
            $('#' + id).prop("checked", false);
          }
        }
        //var data = getscope.IssueTracker;

        if (this.issuesFilter.length > 0) {

          for (let i = 0; i < this.issuesFilter.length; i++) {
            this.issuesFilter[i].ischecked = false;//16/01/2023
          }
        }

        // clearing the arrays
        this.arrDegraded = [];
        this.arrInprogress = [];
        this.arrResolved = [];
        this.arrPreventive = [];
        this.arrDegradedIds = [];
        this.arrInprogressIds = [];
        this.arrResolvedIds = [];
        this.arrPreventiveIds = [];
        this.arrAllSelectedIds = [];

        this.inprogress = [];
        this.resolved = [];
        this.degraded = [];
        this.prevention = [];

        this.inprogressids = [];
        this.resolvedids = [];
        this.degradedids = [];
        this.preventionids = [];
        this.turnOverModalForm.reset(); //16/01/2023
        this.turnOverModalForm.get('NewIssueRecorded')!.setValue('No');
        this.fetchIssues();
        //this.ngOnInit();
      } else {
        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      }
    });
  }
  clearArraysWhenClose() {
    // clearing the arrays
    this.arrDegraded = [];
    this.arrInprogress = [];
    this.arrResolved = [];
    this.arrPreventive = [];
    this.arrDegradedIds = [];
    this.arrInprogressIds = [];
    this.arrResolvedIds = [];
    this.arrPreventiveIds = [];
    this.arrAllSelectedIds = [];

    this.inprogress = [];
    this.resolved = [];
    this.degraded = [];
    this.prevention = [];

    this.inprogressids = [];
    this.resolvedids = [];
    this.degradedids = [];
    this.preventionids = [];

    for (let id of this.clearId) {
      var element = <HTMLInputElement>document.getElementById(id);
      if (element.checked) {
        $('#' + id).prop("checked", false);
      }
    }
    for (let key in sessionStorage) {
      if (key.startsWith('INC') || key.startsWith('Inc')) {
        sessionStorage.removeItem(key);
      }
    }
  }
  dateReset() {
    this.ControlStartDate = new FormControl();
    this.ControlEndDate = new FormControl();
    this.ControlNextUpdate = new FormControl();
    this.HotDebriefFacilitator = new FormControl();
    this.addForm.reset();
    this.applicationNameAddForm = [];
    this.leadsNameAddForm = [];
    this.applicationNameAddFormImpactedS = [];

  }
  //add/update issue tracker
  onSubmit() {
    $("#btnItIssueSave").prop("disabled", true);
    //this.loading = true;
    var StartDate = null;
    if (this.addForm.value.StartDate != null) {
      StartDate = new Date(this.addForm.get('StartDate')?.value.getTime() - this.addForm.get('StartDate')?.value.getTimezoneOffset() * 60000);
    } else {
      this.errorMessage = "Issue start date field cannot be empty.";
    }
    var EndDate = null;
    if (this.addForm.value.EndDate != null) {
      if (this.addForm.value.Status == "Resolved" || this.addForm.value.Status == "Prevention") {
        EndDate = new Date(this.addForm.get('EndDate')?.value.getTime() - this.addForm.get('EndDate')?.value.getTimezoneOffset() * 60000);
      } else {
        this.errorMessage = "Issue end date can only be given for resolved and prevention issues.";
        EndDate = null;
      }
    }
    if ((EndDate != null && StartDate != null) && (this.addForm.value.Status == "Resolved" || this.addForm.value.Status == "Prevention")) {
      if (EndDate <= StartDate) {
        this.errorMessage = "Start Date cannot be greater than the End Date.";
      }
    }
    var NextUpdate = null;
    if (this.addForm.value.NextUpdate != null) {
      if (((this.addForm.value.Priority == 'P1') && (this.addForm.value.Status == 'In-progress')) ||
        ((this.addForm.value.Priority == 'P2') && (this.addForm.value.Status == 'Under Monitoring')) ||
        ((this.addForm.value.Priority == 'P1') && (this.addForm.value.Status == 'Under Monitoring')) ||
        ((this.addForm.value.Priority == 'P2') && (this.addForm.value.Status == 'In-progress'))) {

        NextUpdate = new Date(this.addForm.get('NextUpdate')?.value.getTime() - this.addForm.get('NextUpdate')?.value.getTimezoneOffset() * 60000);
        // EndDate = null;
      } else {
        this.errorMessage = "Next Update (GMT) field must be filled out for all ongoing or under monitoring P1/P2 issues.";
        NextUpdate = null;
      }
    } else {
      if (((this.addForm.value.Priority == 'P1') && (this.addForm.value.Status == 'In-progress')) ||
        ((this.addForm.value.Priority == 'P2') && (this.addForm.value.Status == 'Under Monitoring')) ||
        ((this.addForm.value.Priority == 'P1') && (this.addForm.value.Status == 'Under Monitoring')) ||
        ((this.addForm.value.Priority == 'P2') && (this.addForm.value.Status == 'In-progress'))) {
        this.errorMessage = "Next Update (GMT) field must be filled out for all ongoing or under monitoring P1/P2 issues.";
      }
    }
    if (this.addForm.value.HotDebriefCompleted == 'Yes' && this.addForm.value.HotDebriefFacilitator == '' && this.HotDebriefFacilitator == new FormControl()) {
      var ErrorMessage = "HotDebriefFacilitator name is mandatory";
      //this.errorNotification(ErrorMessage);
      this.errorMessage = ErrorMessage;

    } else {
      if (this.addForm.value.HotDebriefCompleted == 'No') {
        this.addForm.get('HotDebriefFacilitator')?.setValue('');
        this.HotDebriefFacilitator = new FormControl('');
      }
      this.addForm.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));
      if (this.addForm.value.ServicePriority == null || this.addForm.value.ServicePriority == "") {
        this.errorMessage = "Please enter Service Priority.";
      }
      if (this.addForm.value.CircleLink == null || this.addForm.value.CircleLink == "") {
        this.errorMessage = "Please enter live update.";
      }
      if (this.addForm.value.BridgeDetails == null || this.addForm.value.BridgeDetails == "") {
        this.errorMessage = "Please enter bridge details.";
      }
      if (this.addForm.value.Status == null || this.addForm.value.Status == "") {
        this.errorMessage = "Status field cannot be empty.";
      }
      if (this.addForm.value.Priority == null || this.addForm.value.Priority == "") {
        this.errorMessage = "Priority field cannot be empty.";
      }
      if (this.addForm.value.Summary == null || this.addForm.value.Summary == "") {
        this.errorMessage = "Summary field cannot be empty.";
      }
      if ((this.addForm.value.BusinessImpactFlag == null || this.addForm.value.BusinessImpactFlag == "") || (this.addForm.value.BusinessImpact == null || this.addForm.value.BusinessImpact == "")) {
        this.errorMessage = "Business field cannot be empty.";
      }
      if (this.addForm.value.ImpactedServiceApplicationName == null || this.addForm.value.ImpactedServiceApplicationName == "") {
        this.errorMessage = "Choose valid Impacted Service."
      }
      if (this.addForm.value.ServiceLead == null || this.addForm.value.ServiceLead == "") {
        this.errorMessage = "Service Lead field cannot be empty."
      }
      if (this.addForm.value.ApplicationName == null || this.addForm.value.ApplicationName == "") {
        this.errorMessage = "Choose valid Service."
      }
      if (this.addForm.value.IncidentTicket == null || this.addForm.value.IncidentTicket == "") {
        this.errorMessage = "Incident Ticket field cannot be empty."
      }

      var addFormData = {
        Id: this.addForm.value.Id, ApplicationId: this.addForm.value.ApplicationId, AirId: this.addForm.value.AirId, ApplicationName: this.addForm.value.ApplicationName, OtherApplication: this.addForm.value.OtherApplication, ImpactedServiceApplicationName: this.addForm.value.ImpactedServiceApplicationName,
        OtherImpactedServiceApplication: this.addForm.value.OtherImpactedServiceApplication, ServiceLead: this.addForm.value.ServiceLead, BusinessImpactFlag: this.addForm.value.BusinessImpactFlag, BusinessImpact: this.addForm.value.BusinessImpact, StartDate: StartDate, EndDate: EndDate, Summary: this.addForm.value.Summary, Priority: this.addForm.value.Priority, Status: this.addForm.value.Status, BridgeDetails: this.addForm.value.BridgeDetails,
        CircleLink: this.addForm.value.CircleLink, ServicePriority: this.addForm.value.ServicePriority, CreatedBy: sessionStorage.getItem('LoggedInUser'), CreateDateTime: this.addForm.value.CreateDateTime, ModifiedBy: this.addForm.value.ModifiedBy, ModifiedDateTime: this.addForm.value.ModifiedDateTime,
        SendMail: this.addForm.value.SendMail, ischecked: this.addForm.value.ischecked, BusinessCycleAwareness: this.addForm.value.BusinessCycleAwareness, NewIssueRecorded: this.addForm.value.NewIssueRecorded, LastModifiedBy: this.addForm.value.LastModifiedBy, OpenedBy: this.addForm.value.OpenedBy, EndDatetimeForReport: this.addForm.value.EndDatetimeForReport, StartDatetimeForReport: this.addForm.value.StartDatetimeForReport,
        CreateDateTimeForReport: this.addForm.value.CreateDateTimeForReport, ModifiedDateTimeForReport: this.addForm.value.ModifiedDateTimeForReport, LoggedInUser: sessionStorage.getItem('LoggedInUser'),
        HotDebriefCompleted: this.addForm.value.HotDebriefCompleted, HotDebriefFacilitator: this.addForm.value.HotDebriefFacilitator, IssueSendMail: this.addForm.value.IssueSendMail, IncidentTicket: this.addForm.value.IncidentTicket,
        CurrentHealthStatus: this.addForm.value.CurrentHealthStatus, NextUpdate: NextUpdate, NextUpdateForReport: this.addForm.value.NextUpdateForReport, plannedActivity: this.addForm.value.plannedActivity, PlannedOperationActivities: this.addForm.value.PlannedOperationActivities
      };

      this.issueService.addIssues(addFormData)
        .subscribe(response => {
          if (response.ErrorMessage == '') {
            $("#btnItIssueSave").prop("disabled", false);
            var saveMessage = "Saved successfully!!";
            this.successNotification(saveMessage);
            $('#addissueTrackerClose').trigger('click');
            this.fetchIssues();
            this.addForm.reset();
            this.applicationNameAddForm = [];
            this.leadsNameAddForm = [];
            this.applicationNameAddFormImpactedS = [];
            this.dateReset();
          } else {
            this.errorMessage = response.ErrorMessage;
            $("#btnItIssueSave").prop("disabled", false);
          }

        });
    }
  }
  // updateIssues() {
  //     console.log(this.editForm.value);
  //     this.issueService.updateIssues(this.editForm.value)
  //         .subscribe(response => {
  //             this.fetchIssues();
  //         },
  //          error => this.errorMessage = error);
  // }
  //openDeleteConfirm(content: any, data: IssueInterface) {
  //  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
  //    this.closeResult = `closed with: ${result}`;
  //  }, (reason: any) => {
  //    this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
  //  });

  //}

  delete(content: any, data: IssueInterface) {
    //this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    //  this.closeResult = `closed with: ${result}`;
    //}, (reason: any) => {
    //  this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
    //});
    this.loading = true;
    this.deldata.Id = data.Id;
    this.deldata.IncidentTicket = data.IncidentTicket;
    this.deldata.StartDate = data.StartDate;
    this.deldata.EndDate = data.EndDate;
    this.deldata.ApplicationName = data.ApplicationName;
    this.deldata.ServiceLead = data.ServiceLead;
    this.deldata.Status = data.Status;
    this.deldata.Priority = data.Priority;
    this.deldata.ServicePriority = data.ServicePriority;
    this.deldata.LoggedInUser = sessionStorage.getItem('LoggedInUser');
    this.issueService.deleteIssues(this.deldata)
      .subscribe(response => {
        this.loading = false;
        this.fetchIssues();
        if (response.ErrorMessage != '') {

          this.errorMessage = response.ErrorMessage;
        } else {
          var message = "Deleted Successfully!!";
          this.successNotification(message);
          data.ischecked = false;
          this.EmailCheck(data);
        }
      });
  }
  ErrorMessageReset() {
    this.errorMessage = '';
  }
  enter(i: any) {
    this.hoverIndex = i;
    i.IssueSendMail == true ? this.isToolTipShow = true : this.isToolTipShow = false;
  }

  leave(i: any) {
    this.isToolTipShow = false;
    this.hoverIndex = null;
  }
  openEdit(content: any, data: IssueInterface) {
    // this.editForm.value = data;
    this.formTitle = "Edit";
    this.ErrorMessageReset();
    this.errorMessage2 = '';
    $("#myModalTitle").text("Edit");
    var log = { Id: data.Id, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<IssueInterface>(this.baseURL + 'api/IssueTracker/FetchIssueDetails', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage != '') {
        //  this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      } else {



        if (response.Result.StartDate != null) {
          this.ControlStartDate = new FormControl(new Date(response.Result.StartDate));
        }
        if (response.Result.EndDate != null) {
          this.ControlEndDate = new FormControl(new Date(response.Result.EndDate));
        } else {
          this.ControlEndDate = new FormControl(response.Result.EndDate);
        }


        //(response.Result.StartDate!=null) ? this.addForm.get('StartDate')?.setValue(this.ControlStartDate) : alert('Unexpected error occured');
        //(response.Result.EndDate == null) ? this.ControlEndDate = new FormControl() : this.addForm.get('EndDate')?.setValue(this.ControlEndDate);
        if (((response.Result.Priority == 'P1') && (response.Result.Status == 'In-progress')) ||
          ((response.Result.Priority == 'P2') || (response.Result.Status == 'Under Monitoring')) ||
          ((response.Result.Priority == 'P1') && (response.Result.Status == 'Under Monitoring')) ||
          ((response.Result.Priority == 'P2') || (response.Result.Status == 'In-progress'))) {
          //if (response.Result.NextUpdate == null) {
          //  this.addForm.get('NextUpdate')?.setValue(null);
          //} else {
          this.ControlNextUpdate = new FormControl(new Date(response.Result.NextUpdate));
          //}
        } else {
          this.ControlNextUpdate = new FormControl(null);
        }



        this.addForm.patchValue({
          Id: response.Result.Id, ApplicationId: response.Result.ApplicationId, AirId: response.Result.AirId, ApplicationName: response.Result.ApplicationName,
          OtherApplication: response.Result.OtherApplication, ImpactedServiceApplicationName: response.Result.ImpactedServiceApplicationName,
          OtherImpactedServiceApplication: response.Result.OtherImpactedServiceApplication, StartDate: this.ControlStartDate.value, EndDate: this.ControlEndDate.value, NextUpdate: this.ControlNextUpdate.value,
          ServiceLead: response.Result.ServiceLead, BusinessImpactFlag: response.Result.BusinessImpactFlag, BusinessImpact: response.Result.BusinessImpact,
          Summary: response.Result.Summary, Priority: response.Result.Priority, Status: response.Result.Status, BridgeDetails: response.Result.BridgeDetails,
          CircleLink: response.Result.CircleLink, ServicePriority: response.Result.ServicePriority, CreatedBy: response.Result.OpenedBy, CreateDateTime: response.Result.CreateDateTime, ModifiedBy: response.Result.ModifiedBy, ModifiedDateTime: response.Result.ModifiedDateTime,
          SendMail: response.Result.SendMail, ischecked: response.Result.ischecked, BusinessCycleAwareness: response.Result.BusinessCycleAwareness, NewIssueRecorded: response.Result.NewIssueRecorded, LastModifiedBy: sessionStorage.getItem('LoggedInUser'), OpenedBy: response.Result.OpenedBy,
          EndDatetimeForReport: response.Result.EndDatetimeForReport, StartDatetimeForReport: response.Result.StartDatetimeForReport,
          CreateDateTimeForReport: response.Result.CreateDateTimeForReport, ModifiedDateTimeForReport: response.Result.ModifiedDateTimeForReport,
          HotDebriefCompleted: response.Result.HotDebriefCompleted, HotDebriefFacilitator: response.Result.HotDebriefFacilitator, IssueSendMail: response.Result.IssueSendMail, IncidentTicket: response.Result.IncidentTicket,
          CurrentHealthStatus: response.Result.CurrentHealthStatus, NextUpdateForReport: response.Result.NextUpdateForReport, plannedActivity: response.Result.plannedActivity, PlannedOperationActivities: response.Result.PlannedOperationActivities
        });
        //this.startDate = data.StartDate;
        this.addForm.get("Id")?.setValue(data.Id);
        this.applicationNameAddForm = response.Result.ApplicationName?.split(';');
        this.applicationNameAddFormImpactedS = response.Result.ImpactedServiceApplicationName?.split(';');
        this.leadsNameAddForm = response.Result.ServiceLead?.split(';');
        this.HotDebriefFacilitator = new FormControl(response.Result.HotDebriefFacilitator);
        this.username = response.Result.OpenedBy;


        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `closed with: ${result}`;
        }, (reason: any) => {
          this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
        });
      }
    });
  }


  public getProfile(): any {
    this.http.get<ProfileType>(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
        this.username = profile.userPrincipalName.substring(0, profile.userPrincipalName.indexOf("@"));
        this.searchformGroup.get("LoggedInUser")?.setValue(sessionStorage.getItem('LoggedInUser'));
        this.addForm.get("LoggedInUser")?.setValue(sessionStorage.getItem('LoggedInUser'));
        //this.editForm.get("LoggedInUser")?.setValue(this.username);
        this.turnOverModalForm.get("LoggedInUser")?.setValue(sessionStorage.getItem('LoggedInUser'));
        this.user = this.username;
        this.tracker.LoggedInUser = sessionStorage.getItem('LoggedInUser');
        this.mailPrevData.LoggedInUser = sessionStorage.getItem('LoggedInUser');
      });
    return this.username;
  }

  LoadApplicationAdd(Search: string) {
    this.loading = true;
    this.common.FindApplicationName("=" + Search + "=" + this.user)
      .subscribe(response => {
        this.loading = false;
        this.AppResponse = response;
        this.options = this.AppResponse!.Result;
        this.applications = this.options;
        this.applications.map(app => app.Name = `${app.AirId} - ${app.Name}`);
      });
    //return this.options;
  }
  LoadApplicationImpactAdd(Search: string) {
    this.loading = true;
    this.common.FindApplicationName("=" + Search + "=" + this.user)
      .subscribe(response => {
        this.loading = false;
        this.AppResponse = response;
        this.options = this.AppResponse!.Result;
        this.applications = this.options;
        this.applications.map(app => app.Name = `${app.AirId} - ${app.Name}`);
      });
  }


  LoadPeopleByNameHotDebriefFacilitator(Search: string) {
    if (Search == '') {
      this.addForm.get('HotDebriefFacilitator')?.setValue('');
    }
    this.LoadPeopleByName(Search);
  }
  LoadPeopleByNameLeads(Search: string) {
    if (Search == '') {
      this.addForm.get('ServiceLead')?.setValue('');
    }
    this.LoadPeopleByName(Search);
  }
  LoadPeopleByName(Search: string) {
    this.loading = true;
    this.common.FetchPeopleByName("=" + Search + "=" + this.user)
      .subscribe(response => {
        this.loading = false;
        this.UserPeopleResponse = response;
        this.RootOBJ = this.UserPeopleResponse!.Result;

        this.lotOfPeople = this.RootOBJ.Value;
        this.SearchPeople = this.lotOfPeople;
        this.SearchPeople.map(people => people.Name = `${people.EnterpriseId} -- ${people.StandardJobDescription}`);
        //this.lotOfPeople.map(people => people.EnterpriseId = `${people.EnterpriseId} -- ${people.StandardJobDescription}`);
        //console.log(this.lotOfPeople);
      });
    //return this.options;
  }

  LoadLeadsbyFilter(Search: string) {
    if (Search == '') {
      //this.selectedAirIds = [];
      this.searchformGroup.get("ServiceLead")?.setValue(null);
    }
    this.loading = true;
    this.common.FetchLeadsbyFilter("=" + Search + "=" + this.user)
      .subscribe(response => {
        this.loading = false;
        this.UserResponse = response;
        this.leads = this.UserResponse!.Result;
        this.SearchLeads = this.leads;
        //this.SearchLeads.map(people => people.Name = `${people.EnterpriseId} -- ${people.StandardJobDescription}`);
      });
    //return this.options;
  }
  LoadCurrentServiceManager(Search: string) {
    this.loading = true;
    this.common.FetchCurrentServiceManager("=" + Search + "=" + this.user)
      .subscribe(response => {
        this.loading = false;
        this.UserResponse = response;
        this.leads = this.UserResponse!.Result;
        this.csermanager = this.leads;
      });
  }
  LoadNewServiceManager(Search: string) {
    this.loading = true;
    this.common.FetchNewServiceManager("=" + Search + "=" + this.user)
      .subscribe(response => {
        this.loading = false;
        this.UserResponse = response;
        this.leads = this.UserResponse!.Result;
        this.nsermanager = this.leads;
      });
  }
  selectEvent(e: any) {
    //console.log("here airid", e.AirId);
    this.selectedAirIds.push(e.Id);
    this.searchformGroup.get("ApplicationId")?.setValue(e.Id);
    var dataS = e.AirId + ' - ' + e.Name;
    //this.applicationName.push(dataS);
    this.searchformGroup.get("ApplicationName")?.setValue(e.Name);
    return this.selectedAirIds;
  }
  //to remove the selected app name from the array : applicationName
  remove(app: any): void {
    const index = this.applicationNameAddForm.indexOf(app);

    if (index >= 0) {
      this.applicationNameAddForm.splice(index, 1);
    }
  }
  selectEventAdd(e: any) {
    var dataS = e.AirId + ' - ' + e.Name;
    if (this.applicationNameAddForm.indexOf(e.Name) === -1) {
      this.applicationNameAddForm.push(e.Name)
      this.addForm.get("ApplicationId")?.setValue(e.Id);
      //console.log(this.applicationNameAddForm);
      this.addForm.get("AirId")?.setValue(e.AirId);
      //this.addForm.get("OtherApplication")?.setValue(e.Id);
      var tempApps = this.applicationNameAddForm.join(';');
      this.addForm.get("ApplicationName")?.setValue(tempApps);
      $('#srrbtnPreventiveExportExcel').prop('disabled', true);
    }
    else {
      this.errorNotification("Application Name already exists");
    }

    // this.applicationNameAddForm.push(e.Name);

  }
  autocompleteclick(e: any) {
    $('#LeadsAutocomplete').find('input:text').val('');
    $('#ServiceAutocomplete').find('input:text').val('');
    $('#ImpactedServiceAutocomplete').find('input:text').val('');
  }
  selectEventImpactAdd(e: any) {
    var dataS = e.AirId + ' - ' + e.Name;
    var message = "Application already added once";
    this.applicationNameAddFormImpactedS.indexOf(e.Name) === -1 ? this.applicationNameAddFormImpactedS.push(e.Name) : this.errorMessage = message;
    //        this.errorNotification("Application already added once");
    // this.applicationNameAddFormImpactedS.push(e.Name);
    var tempImpactApps = this.applicationNameAddFormImpactedS.join(';');
    this.addForm.get("ImpactedServiceApplicationName")?.setValue(tempImpactApps);
    //$('#itemTemplateImpactS').prop('value', '');
    $('div.input-container').find('input').attr('ng-reflect-model', '');
  }
  removeIS(aapI: any): void {
    const index = this.applicationNameAddFormImpactedS.indexOf(aapI);

    if (index >= 0) {
      this.applicationNameAddFormImpactedS.splice(index, 1);
    }
  }
  selectLeadsEvent(l: any) {
    if (this.leadsNameAddForm.indexOf(l.EnterprisedId) === -1) {
      this.leadsNameAddForm.push(l.EnterprisedId);
      //this.addForm.get("ServiceLead")?.setValue(l.EnterprisedId);
      this.searchformGroup.get("ServiceLead")?.setValue(l.EnterprisedId);

      var tempLeadsName = this.leadsNameAddForm.join(';');
      this.addForm.get("ServiceLead")?.setValue(tempLeadsName);
    }
    else {
      this.errorNotification("Enterprise Id already exists");
    }
  }
  selectLeadsEventADDLead(l: any) {
    if (this.leadsNameAddForm.indexOf(l.EnterpriseId) === -1) {
      this.leadsNameAddForm.push(l.EnterpriseId);
      //this.addForm.get("ServiceLead")?.setValue(l.EnterprisedId);
      this.searchformGroup.get("ServiceLead")?.setValue(l.EnterpriseId);

      var tempLeadsName = this.leadsNameAddForm.join(';');
      this.addForm.get("ServiceLead")?.setValue(tempLeadsName);
    }
    else {
      this.errorNotification("Enterprise Id already exists");
    }
  }
  removeL(lead: any): void {
    const index = this.leadsNameAddForm.indexOf(lead);

    if (index >= 0) {
      this.leadsNameAddForm.splice(index, 1);
    }
    var tempLeadsName = this.leadsNameAddForm.join(';');
    this.addForm.get("ServiceLead")?.setValue(tempLeadsName);
  }
  selectPeopleEvent(p: any) {
    this.addForm.get("HotDebriefFacilitator")?.setValue(p.EnterpriseId);
  }
  selectEventCSM(csm: any) {
    this.turnOverModalForm.get("CurrentRegionalManager")?.setValue(csm.Id);
    this.csmNameShiftTurnOver.push(csm.EnterpriseId);
  }
  selectEventNSM(nsm: any) {
    this.turnOverModalForm.get("NewRegionalManager")?.setValue(nsm.Id);
  }

  open(content: any) {
    this.formTitle = "Add New";
    this.ErrorMessageReset();
    this.errorMessage2 = '';
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `dismissed ${this.getdismissreason(reason)}`;
    });
  }
  // -----------------------------Important details section-------------------------------------

  // To check if the incident number is present in the important details database table
  //openDetailsForm(content: any, data: any) {
  //  this.ErrorMessageReset();
  //  this.errorMessage2 = '';
  //  this.errorMessage = '';
  //  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
  //    this.closeResult;
  //  }, (reason: any) => {
  //    this.closeResult;
  //  });
  //  var fetch = { IncidentNumber: data.IncidentTicket, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
  //  return this.http.post<any>(this.baseURL + 'api/IssueTracker/FetchFromImportantDetails', fetch).pipe(
  //    map(this.extractData),
  //    catchError(this.handleErrorObservable)
  //  ).subscribe(response => {
  //    this.loading = false;
  //    this.ImportantDetailsResponseModel = response;
  //    this.ImportantDetailsModal = this.ImportantDetailsResponseModel?.Result;
  //    console.log(this.ImportantDetailsModal);
  //    if (response.ErrorMessage != '') {
  //      this.errorMessage = response.ErrorMessage;
  //    } else {
  //      this.importantDetailsModalForm.patchValue({
  //        Id: this.ImportantDetailsModal!.Id,
  //        IncidentNumber: this.ImportantDetailsModal!.IncidentNumber,
  //        AlertReceived: this.ImportantDetailsModal!.AlertReceived,
  //        AlertReceivedDesc: this.ImportantDetailsModal!.AlertReceivedDesc,
  //        IsDowngraded: this.ImportantDetailsModal!.IsDowngraded,
  //        IsDowngradedDesc: this.ImportantDetailsModal!.IsChangedDesc,
  //        IsChanged: this.ImportantDetailsModal!.IsChanged,
  //        IsChangedDesc: this.ImportantDetailsModal!.IsChangedDesc,
  //        ProblemTicketNo: this.ImportantDetailsModal!.ProblemTicketNo,
  //        EngagedOnTime: this.ImportantDetailsModal!.EngagedOnTime,
  //        HowLongDegrade: this.ImportantDetailsModal!.HowLongDegrade,
  //        IsReported: this.ImportantDetailsModal!.IsReported,
  //        IsReportedDesc: this.ImportantDetailsModal!.IsReportedDesc,
  //        CauseCategory: this.ImportantDetailsModal!.CauseCategory,
  //        CauseNum: this.ImportantDetailsModal!.CauseNum,
  //        WhichTechTeam: this.ImportantDetailsModal!.WhichTechTeam,
  //        WhyNotPrevented: this.ImportantDetailsModal!.WhyNotPrevented,
  //        WasPlaybookUsed: this.ImportantDetailsModal!.WasPlaybookUsed,
  //        GoneFishingPage: this.ImportantDetailsModal!.GoneFishingPage,
  //        GoneFishingPageDesc: this.ImportantDetailsModal!.GoneFishingPageDesc,
  //        MdJoined: this.ImportantDetailsModal!.MdJoined,
  //        DebriefConducted: this.ImportantDetailsModal!.DebriefConducted,
  //        DebriefEID: this.ImportantDetailsModal!.DebriefEID,
  //        DebriefNotes: this.ImportantDetailsModal!.DebriefNotes,
  //        Notes: this.ImportantDetailsModal!.Notes,
  //        CreatedBy: this.ImportantDetailsModal!.CreatedBy,
  //        ModifiedBy: this.ImportantDetailsModal!.ModifiedBy,
  //        LoggedInUser: sessionStorage.getItem('LoggedInUser')
  //      });
  //    }
  //  });
  //}
 
  populateNotes(data: any[]) {
    //console.log(data);
    //console.log(data.values);
    for (let i = 0; i < data.length-1; i++) {
      this.Notes.push(new FormControl(data[i]));
    }
  }
  openDetailsForm(content: any, data: any) {
    this.ErrorMessageReset();
    this.errorMessage2 = '';
    this.errorMessage = '';
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult;
    }, (reason: any) => {
      this.closeResult;
    });
    var fetch = { IncidentNumber: data.IncidentTicket, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    return this.http.post<any>(this.baseURL + 'api/IssueTracker/FetchFromImportantDetails', fetch).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.ImportantDetailsResponseModel = response;
      this.ImportantDetailsModal = this.ImportantDetailsResponseModel?.Result;
      this.removeNotes(); // Removing extra textarea
      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
      } else {
        if (this.ImportantDetailsModal != null) {
          this.importantDetailsModalForm.patchValue({
            Id: this.ImportantDetailsModal!.Id,
            IncidentNumber: this.ImportantDetailsModal!.IncidentNumber,
            AlertReceived: this.ImportantDetailsModal!.AlertReceived,
            AlertReceivedDesc: this.ImportantDetailsModal!.AlertReceivedDesc,
            IsDowngraded: this.ImportantDetailsModal!.IsDowngraded,
            IsDowngradedDesc: this.ImportantDetailsModal!.IsDowngradedDesc,
            IsChanged: this.ImportantDetailsModal!.IsChanged,
            IsChangedDesc: this.ImportantDetailsModal!.IsChangedDesc,
            ProblemTicketNo: this.ImportantDetailsModal!.ProblemTicketNo,
            EngagedOnTime: this.ImportantDetailsModal!.EngagedOnTime,
            GSMGPCDesc: this.ImportantDetailsModal!.GSMGPCDesc,
            HowLongDegrade: this.ImportantDetailsModal!.HowLongDegrade,
            IsReported: this.ImportantDetailsModal!.IsReported,
            IsReportedDesc: this.ImportantDetailsModal!.IsReportedDesc,
            CauseCategory: this.ImportantDetailsModal!.CauseCategory,
            CauseNum: this.ImportantDetailsModal!.CauseNum,
            WhichTechTeam: this.ImportantDetailsModal!.WhichTechTeam,
            WhyNotPrevented: this.ImportantDetailsModal!.WhyNotPrevented,
            WasPlaybookUsed: this.ImportantDetailsModal!.WasPlaybookUsed,
            WasPlaybookUsedDesc: this.ImportantDetailsModal!.WasPlaybookUsedDesc,
            GoneFishingPage: this.ImportantDetailsModal!.GoneFishingPage,
            GoneFishingPageDesc: this.ImportantDetailsModal!.GoneFishingPageDesc,
            MdJoined: this.ImportantDetailsModal!.MdJoined,
            MdJoinedDesc: this.ImportantDetailsModal!.MdJoinedDesc,
            DebriefConducted: this.ImportantDetailsModal!.DebriefConducted,
            DebriefEID: this.ImportantDetailsModal!.DebriefEID,
            DebriefNotes: this.ImportantDetailsModal!.DebriefNotes,
            /*Notes: this.ImportantDetailsModal!.Notes,*/
            CreatedBy: this.ImportantDetailsModal!.CreatedBy,
            ModifiedBy: this.ImportantDetailsModal!.ModifiedBy,
            LoggedInUser: sessionStorage.getItem('LoggedInUser'),

          });
          this.populateNotes(this.ImportantDetailsModal!.Notes); //Populating note section with it's respective values
        } else {
          this.addNotes();
          this.importantDetailsModalForm.patchValue({
            IncidentNumber: data.IncidentTicket,
            LoggedInUser: sessionStorage.getItem('LoggedInUser')
          });
        }
      }// else closing
    });
  }
  // To save data in the important details database table
  saveDetails() {
    if (this.importantDetailsModalForm.value.DebriefConducted == 'Yes') {
      //this.importantDetailsModalForm.controls['DebriefEID'].setValidators([Validators.required]);
      this.importantDetailsModalForm.controls['DebriefEID'].addValidators([Validators.required]);
      this.importantDetailsModalForm.controls['DebriefEID'].updateValueAndValidity();
    }
    else {
      //this.importantDetailsModalForm.controls['DebriefEID'].clearValidators();
      this.importantDetailsModalForm.controls['DebriefEID'].removeValidators([Validators.required]);
      this.importantDetailsModalForm.controls['DebriefEID'].updateValueAndValidity();
    }

    if (this.importantDetailsModalForm.value.DebriefConducted == 'No') {
      //this.importantDetailsModalForm.controls['DebriefNotes'].setValidators([Validators.required]);
      this.importantDetailsModalForm.controls['DebriefNotes'].addValidators([Validators.required]);
      this.importantDetailsModalForm.controls['DebriefNotes'].updateValueAndValidity();
    }
    else {
      //this.importantDetailsModalForm.controls['DebriefNotes'].clearValidators();
      this.importantDetailsModalForm.controls['DebriefNotes'].removeValidators([Validators.required]);
      this.importantDetailsModalForm.controls['DebriefNotes'].updateValueAndValidity();
    }

    if (this.importantDetailsModalForm.value.IsDowngraded == 'Yes') {
      //this.importantDetailsModalForm.controls['IsDowngradedDesc'].setValidators([Validators.required]);
      this.importantDetailsModalForm.controls['IsDowngradedDesc'].addValidators([Validators.required]);
      this.importantDetailsModalForm.controls['IsDowngradedDesc'].updateValueAndValidity();
    }
    else {
      //this.importantDetailsModalForm.controls['IsDowngradedDesc'].clearValidators();
      this.importantDetailsModalForm.controls['IsDowngradedDesc'].removeValidators([Validators.required]);
      this.importantDetailsModalForm.controls['IsDowngradedDesc'].updateValueAndValidity();
    }

    if (this.importantDetailsModalForm.value.IsChanged == 'Yes') {
      this.importantDetailsModalForm.controls['IsChangedDesc'].addValidators([Validators.required]);
      this.importantDetailsModalForm.controls['IsChangedDesc'].updateValueAndValidity();
    }
    else {
      this.importantDetailsModalForm.controls['IsChangedDesc'].removeValidators([Validators.required]);
      this.importantDetailsModalForm.controls['IsChangedDesc'].updateValueAndValidity();
    }

    //if (this.importantDetailsModalForm.value.AlertReceived == 'No') {
    //  //this.importantDetailsModalForm.controls['DebriefNotes'].setValidators([Validators.required]);
    //  this.importantDetailsModalForm.controls['AlertReceivedDesc'].addValidators([Validators.required]);
    //  this.importantDetailsModalForm.controls['AlertReceivedDesc'].updateValueAndValidity();
    //}
    //else {
    //  //this.importantDetailsModalForm.controls['DebriefNotes'].clearValidators();
    //  this.importantDetailsModalForm.controls['AlertReceivedDesc'].removeValidators([Validators.required]);
    //  this.importantDetailsModalForm.controls['AlertReceivedDesc'].updateValueAndValidity();
    //}

    if (this.importantDetailsModalForm.value.EngagedOnTime == 'No') {
      //this.importantDetailsModalForm.controls['DebriefNotes'].setValidators([Validators.required]);
      this.importantDetailsModalForm.controls['GSMGPCDesc'].addValidators([Validators.required]);
      this.importantDetailsModalForm.controls['GSMGPCDesc'].updateValueAndValidity();
    }
    else {
      //this.importantDetailsModalForm.controls['DebriefNotes'].clearValidators();
      this.importantDetailsModalForm.controls['GSMGPCDesc'].removeValidators([Validators.required]);
      this.importantDetailsModalForm.controls['GSMGPCDesc'].updateValueAndValidity();
    }

    if (this.importantDetailsModalForm.value.IsReported == 'No') {
      //this.importantDetailsModalForm.controls['DebriefNotes'].setValidators([Validators.required]);
      this.importantDetailsModalForm.controls['IsReportedDesc'].addValidators([Validators.required]);
      this.importantDetailsModalForm.controls['IsReportedDesc'].updateValueAndValidity();
    }
    else {
      //this.importantDetailsModalForm.controls['DebriefNotes'].clearValidators();
      this.importantDetailsModalForm.controls['IsReportedDesc'].removeValidators([Validators.required]);
      this.importantDetailsModalForm.controls['IsReportedDesc'].updateValueAndValidity();
    }

    if (this.importantDetailsModalForm.value.WasPlaybookUsed == 'No') {
      //this.importantDetailsModalForm.controls['DebriefNotes'].setValidators([Validators.required]);
      this.importantDetailsModalForm.controls['WasPlaybookUsedDesc'].addValidators([Validators.required]);
      this.importantDetailsModalForm.controls['WasPlaybookUsedDesc'].updateValueAndValidity();
    }
    else {
      //this.importantDetailsModalForm.controls['DebriefNotes'].clearValidators();
      this.importantDetailsModalForm.controls['WasPlaybookUsedDesc'].removeValidators([Validators.required]);
      this.importantDetailsModalForm.controls['WasPlaybookUsedDesc'].updateValueAndValidity();
    }

    if (this.importantDetailsModalForm.value.GoneFishingPage == 'No') {
      //this.importantDetailsModalForm.controls['DebriefNotes'].setValidators([Validators.required]);
      this.importantDetailsModalForm.controls['GoneFishingPageDesc'].addValidators([Validators.required]);
      this.importantDetailsModalForm.controls['GoneFishingPageDesc'].updateValueAndValidity();
    }
    else {
      //this.importantDetailsModalForm.controls['DebriefNotes'].clearValidators();
      this.importantDetailsModalForm.controls['GoneFishingPageDesc'].removeValidators([Validators.required]);
      this.importantDetailsModalForm.controls['GoneFishingPageDesc'].updateValueAndValidity();
    }

    if (this.importantDetailsModalForm.value.MdJoined == 'No') {
      //this.importantDetailsModalForm.controls['DebriefNotes'].setValidators([Validators.required]);
      this.importantDetailsModalForm.controls['MdJoinedDesc'].addValidators([Validators.required]);
      this.importantDetailsModalForm.controls['MdJoinedDesc'].updateValueAndValidity();
    }
    else {
      //this.importantDetailsModalForm.controls['DebriefNotes'].clearValidators();
      this.importantDetailsModalForm.controls['MdJoinedDesc'].removeValidators([Validators.required]);
      this.importantDetailsModalForm.controls['MdJoinedDesc'].updateValueAndValidity();
    }

    //if (this.importantDetailsModalForm.value.DebriefConducted == 'Yes') {
    //  this.importantDetailsModalForm.get('DebriefEID')?.addValidators([Validators.required]);
    //  this.importantDetailsModalForm.get('DebriefEID')?.updateValueAndValidity();
    //  this.DebriefConductedState = '';
    //}
    //else {
    //  this.importantDetailsModalForm.get('DebriefEID')?.removeValidators([Validators.required]);
    //  this.importantDetailsModalForm.get('DebriefEID')?.updateValueAndValidity();
    //  this.DebriefConductedState = 'hidden';
    //}
    if (!this.importantDetailsModalForm.valid) {
      this.errorMessage = "Please fill all the required fields";
    } else {
      this.errorMessage = '';
      this.DebriefConductedState = 'hidden';
      $('#btnImportantDetSave').prop('disabled', true);
      this.errorMessage = '';
      this.errorMessage2 = '';
      if (this.importantDetailsModalForm.value.CreatedBy == null || this.importantDetailsModalForm.value.CreatedBy == '') {
        this.importantDetailsModalForm.get('CreatedBy')?.setValue(sessionStorage.getItem('LoggedInUser'));
      }
      this.issueService.AddImportantDetails(this.importantDetailsModalForm.value)
        .subscribe(response => {
          if (response.ErrorMessage != '') {
            $('#btnImportantDetSave').prop('enabled', false);
            this.errorMessage = response.ErrorMessage;
          } else {
            this.importantDetailsModalForm.reset();
            var message = "Saved Successfully !!";
            this.successNotification(message);
            $('#btnImportantDetSave').prop('enabled', false);
            $("#addImportantDetClose").trigger('click');
          }
        });
    }
  }
  private getdismissreason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing esc';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private reset() {
    this.addForm.value.Id = 0;
    this.addForm.value.IncidentTicket = '';
    this.errorMessage = '';
  }

  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  }
}

