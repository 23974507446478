

<div class="main">
  <div class="flex-row" ng-controller="ServiceReviewController">
    <h1 class="flex-row border-bottom" style="font-size:36px;padding-bottom:9px;border-bottom:1px solid #eee;">
      <span>Service Review</span>

      <button type="button" id="btnSrExportExcel" class="btn btn-primary" style="margin-left: 310px; margin-right: 20px;" (click)="exportData();"><span class="fa fa-download"></span>Export to Excel</button>
      <button type="button" id="btnSrAddNew" (click)="openAddForm(modaladdForm);" class="btn btn-primary" style="margin-right: 20px;"><span class="fa fa-plus"></span>Add New</button>
      <button type="button" id="btnAnnouncement" class="btn btn-primary" (click)="AnnouncementAdmin(modalform_announcement)">Announcement</button>
    </h1>
  </div>

  <div class="row">
    <form class="d-flex flex-row align-items-center flex-wrap searchForm" [formGroup]="searchForm" (ngSubmit)="FilterServiceReview();">
      <div class="col-md-2">
        <input id="dtStart" placeholder="Start Date" class="form-control" name="dtStartDate" formControlName="dtStartDate" #dtStartDate onfocus="(this.type='date')">

      </div>
      <div class="col-md-2">
        <input id="dtend" placeholder="End Date" class="form-control" name="dtEndDate" formControlName="dtEndDate" #dtEndDate onfocus="(this.type='date')">
        <input id="LoggedInUser" type="hidden" class="form-control" [value]="username" name="LoggedInUser" formControlName="LoggedInUser" #LoggedInUser>
      </div>
      <div class="col-md-2" id="statusFilter" ng-controller="ServiceReviewController">
        <select id="Status" ng-model="selectedStatus" class="form-control" name="Status" formControlName="Status" #Status>
          <option [ngValue]="null" selected>--Select Status--</option>
          <option *ngFor="let status of Srstatus; let i = index" [value]="Srstatus[i].value">{{Srstatus[i].value}}</option>
        </select>
      </div>
            <div class="col-md-2" id="applicationFilterSR" ng-controller="ServiceReviewController" #applicationFilterSR>

        <ng-autocomplete [data]="applications"
                         [searchKeyword]="keyword"
                         placeholder="Enter Application"
                         (click)='autocompleteclickSR($event)'
                         (selected)='selectEventSearch($event)'
                         (inputChanged)='LoadApplications($event)'
                         historyIdentifier="applications"
                         [itemTemplate]="itemTemplate"
                         [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-option>
          <a [innerHTML]="option.Name" (click)="getSelectedAirIds(option)"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>

      </div>

      <div id="SRIdFilter" class="col-md-2">
        <input type="text" id="txtSRId" class="form-control" placeholder="SR ID" name="Id" formControlName="Id" #Id />
      </div>
      <!-- Added as part of DSR048 Req-6 Implementation -->
      <div class="col-md-2" id="servicetierFilter" ng-controller="ServiceReviewController">
        <select id="ServiceTier" ng-model="selectedServiceTier" class="form-control" name="ServiceTier" formControlName="ServiceTier" #ServiceTier>
          <option [ngValue]="null" selected>--Select Service Tier--</option>
          <option *ngFor="let status of Ststatus; let i = index" [value]="Ststatus[i].value">{{Ststatus[i].value}}</option>
        </select>
      </div>
      <div class="col-md-2" id="onboardingstatusFilter" ng-controller="ServiceReviewController">
        <select id="OnboardingStatus" ng-model="selectedOnboardingStatus" class="form-control" name="OnboardingStatus" formControlName="OnboardingStatus" #OnboardingStatus>
          <option [ngValue]="null" selected>--Select Onboarding Status--</option>
          <option *ngFor="let status of Onbstatus; let i = index" [value]="Onbstatus[i].value">{{Onbstatus[i].value}}</option>
        </select>
      </div>      
      <div class="col-md-2" style="width:auto;">
        <button type="submit" id="btnSrSearch" class="btn btn-primary"><span class="fa fa-search"></span> Search </button>
      </div>
    </form>
    <div class="row form-group" style="width: 100%;">
      <div class="col-lg-12">
        <mat-chip-list aria-label="Application selection">
          <mat-chip color="primary" *ngFor="let app of applicationNameAddForm;" (removed)="toggleSelectedAirIds(app)">
            {{app}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />

  <div id="dvalert" ng-controller="ServiceReviewController" class="alert alert-danger" *ngIf="errorMessage2">
    {{errorMessage2}}
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage" id="dvError">
    {{errorMessage}}
  </div>


  <div id="dvserviceReview" class="row" ng-show="show">
    <div id="accordion" class="card-group" style="width:100%; padding-bottom:2.5px">
      <div class="card" style="border:none">
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true"
                               (closed)="panelOpenState = false" *ngFor="let app of serviceRmodel" style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;">
            <!--<div class="card-header">
            <div class="row">-->
            <mat-expansion-panel-header *ngIf="app.parentCount == 0" (click)="LoadIssues(app);">
              <!--Division for normal applications-->
              <mat-panel-title>
                <strong>{{app.Name}}</strong><span>-{{app.AirId}}</span>
              </mat-panel-title>
              <mat-panel-description id="light">
                <strong>Over All Status :</strong>
                <span id="OverAllStatus" [class]="app.Overallstatus"></span>
                Service Review # {{app.RecordCount}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <!--</div>
            </div>-->
            <!--Division for clustered applications-->
            <mat-expansion-panel-header *ngIf="app.parentCount == 1" (click)="LoadIssues(app);">
              <!--Division for normal applications-->
              <mat-panel-title>
                <strong>{{app.Name}}</strong>
              </mat-panel-title>
              <mat-panel-description id="light">
                <strong>Over All Status :</strong>
                <span id="OverAllStatus" [class]="app.Overallstatus"></span>
                Service Review # {{app.RecordCount}}
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div style="margin-bottom:4px">
              <!--id="collapse{{app.ApplicationId}}" -->
              <div class="card-body" id="dvbody" ng-controller="ServiceReviewController">
                <table class="table table-bordered" style="width:100%">
                  <thead>
                    <tr style="background-color: #f5f5f5;">
                    <tr style="background-color: #f5f5f5;">
                      <td style="border:solid; border-width:1px; text-align: center"><strong>Select</strong></td>
                      <td style="border:solid; border-width:1px; text-align: left"><strong>SR ID</strong></td>
                      <td style="border:solid; border-width:1px; text-align: left"><strong>Application Name</strong></td>
                      <td style="border:solid; border-width:1px; text-align: center"><strong>Issue/Action Item</strong></td>
                      <td style="border: solid; border-width: 1px; text-align: center"><strong>Status</strong></td>
                      <td style="border: solid; border-width: 1px; text-align: center; white-space: pre-wrap;"><strong>Latest Updates</strong></td>
                      <td style="border: solid; border-width: 1px; text-align: center"><strong>Edit/View</strong></td>

                    </tr>


                  </thead>
                  <tbody>
                    <tr class="custom-column" *ngFor="let issue of IssuesPanel">
                      <td style="border:solid; border-width:1px; text-align: center">
                        <input type="checkbox" id={{issue.Id}} name="checkdId" ng-model="issue.ischecked" [checked]=" issue.Status == 'Not Started' || issue.Status == 'On Track' ? true : null" #el (change)="selectionChange(app, issue,el)" />
                      </td>
                      <td style="border:solid; border-width:1px; text-align: left">{{issue.Id}}</td>
                      <td style="border:solid; border-width:1px; text-align: left">{{issue.Name}}</td>
                      <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{issue.IssueActionItem}}</td>
                      <td style="border:solid; border-width:1px; text-align: center;word-break:break-word;width:12%">{{issue.Status}}</td>
                      <td id='permalink_section' style="border:solid; border-width:1px; text-align: left;white-space:normal; word-break:break-word" [innerHTML]="issue.LatestUpdate"></td>
                      <td style="border:solid; border-width:1px; text-align: center">
                        <button type="button" id="btnEdit" class="btn btn-primary" (click)="openEdit(modaladdForm, issue, app)"><span class="fa fa-edit"></span>Edit</button>

                        <!--@*<button type="button" id="btnView" class="btn btn-primary" ng-click="Edit(issue.Id,issue.Status);" ng-if="issue.Status == 'Completed'"><span class="glyphicon glyphicon-edit"></span>View</button>*@-->
                      </td>

                    </tr>
                  </tbody>

                </table>
                <ng-template matExpansionPanelContent>
                  <mat-accordion class="example-headers-align" multi="false">

                    <!-- cdkVirtualFor -->
                    <mat-expansion-panel style="width: 96%;margin: 0 auto;">
                      <mat-expansion-panel-header (click)="LoadPPS(IssuesPanel,app);" style="cursor: pointer">
                        <mat-panel-title>

                          <!-- <div class="table table-bordered" style="width:100%;background-color: #f5f5f5; cursor: pointer" >
                          <h4 class="card-title">-->
                          <strong>Production Process Status</strong>
                          <!--  </h4>

                          </div> -->
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div id="collapsePPS">
                        <div class="custom-column">
                          <div *ngIf="IssuesPPS==null">
                            <button type="button" id="btnPPSAddNew" style="margin: 0 0 3px;" (click)="openPPS(modalPPSForm, app); addPPSForm.reset();dataResetPPS();" class="btn btn-primary"><span class="fa fa-plus"></span>Add New</button>
                          </div>
                          <table class="table table-bordered" style="width:100%;table-layout:fixed" *ngIf="IssuesPPS!=null || recordCount > 0">
                            <thead>
                              <tr style="background-color: #f5f5f5;">
                                <!-- <td style="border:solid; border-width:1px; text-align: center"><strong>Open Risks</strong></td>
                                <td style="border: solid; border-width: 1px; text-align: center"><strong>Alerts/Reports Received (Actions needed, Who to Contact)</strong></td>
                                <td style="border: solid; border-width: 1px; text-align: center"><strong>Any Challenges(P1/P2)</strong></td> -->
                                <td style="border: solid; border-width: 1px; text-align: center"><strong>Questions/ Clarifications/ Other Items</strong></td>
                                <td style="border: solid; border-width: 1px; text-align: center" class="col-xs-1"><strong>Edit</strong></td>
                                <td style="border: solid; border-width: 1px; text-align: center" class="col-xs-1"><strong>Planned Server Activities</strong></td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="custom-column" *ngFor="let issuepps of IssuesPPS;">
                                <ng-container *ngIf="loadIssueData.ApplicationId == issuepps.ApplicationId">
                                  <!-- <td id='permalink_section' style="border:solid; border-width:1px; text-align: left;white-space: normal; word-break:break-word" [innerHTML]="issuepps.OpenRisks"></td>
                                  <td id='permalink_section' style="border:solid; border-width:1px; text-align: left;white-space: normal; word-break:break-word" [innerHTML]="issuepps.AlertsReceived"></td>
                                  <td id='permalink_section' style="border:solid; border-width:1px; text-align: left;white-space: normal; word-break:break-word" [innerHTML]="issuepps.Challenges"></td> -->
                                  <td id='permalink_section' style="border:solid; border-width:1px; text-align: left;white-space: normal; word-break:break-word" [innerHTML]="issuepps.OtherItems"></td>
                                  <td style="border:solid; border-width:1px; text-align: center" class="col-xs-1"><button type="button" id="btnEdit" class="btn btn-primary" [disabled]="IsLoaded" (click)="EditPPS(modalPPSForm,issuepps);"><span class="fa fa-edit"></span>Edit</button></td>
                                  <td style="border:solid; border-width:1px; text-align: center" class="col-xs-1">

                                    <a href="javascript:;" id="patchrfId" style="color:blue;" (click)="FetchPatchActivity(modalformpatchActivity, issuepps.Id, app.ApplicationId);">Click here to add</a>

                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                          <div *ngIf="(IssuesPPS!=null || recordCount > 0)">
                            <button type="button" id="btnSendEmailPPS" style="width: auto;float: right;" (click)="ppsconfirmModal(modalformPPSconfirmModal,IssuesPPS,app)" class="btn btn-primary">Send Email</button>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </ng-template>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
  <!--Loading screen-->
  <!--<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px' }"
  [template]="sample"></ngx-loading>
  <ng-template #sample let-modal></ng-template>-->
  <ng-template #modaladdForm let-modal>
    <form name='modaladdForm' [formGroup]="addForm">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">{{formTitle}}</h4>
        <button id="formcrossbtn" type="button" class="close" data-dismiss="modal" (click)="modal.dismiss('Button click'); resetData();"><span>&times; </span></button>
      </div>
      <div id="modbody" class="modal-body" style="margin-left: 70px;">
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Issue/Action Item<em style="color:red;">*</em> :</label>
          <div class="col-md-6">
            <input type="hidden" ng-model="actionItem" class="form-control" name="Id" formControlName="Id" #Id />
            <input type="text" ng-model="actionItem" id="txtactionItem" class="form-control" style="width:100%;" placeholder="Action items" name="IssueActionItem" formControlName="IssueActionItem" #IssueActionItem />
          </div>
        </div>
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Application<em style="color:red;">*</em> :</label>
          <div class="col-md-6" id="selectEventAdd">
            <ng-autocomplete [data]="options"
                             [searchKeyword]="keyword"
                             placeholder="Enter Application"
                             (selected)='selectEventAdd($event)'
                             (inputChanged)='LoadApplications($event)'
                             historyIdentifier="applications"
                             [itemTemplate]="itemTemplate"
                             [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-option>
              <a [innerHTML]="option.Name" (click)="getSelectedAirIds(option)"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
        <fieldset [disabled]="clusterChildBlock">
          <div id="childApplicationFilterSR" class="row form-group mb-3" *ngIf="(addForm.value.Name !== '')">
            <label class="col-form-label col-md-4" for="text" style="text-align: left;">Cluster Application<em style="color:red;">*</em> :</label>
            <div class="col-md-6" id="clusterAppSR">
              <!-- <input type="text" ng-model="selectedclusterAppSR" id="clusterAppSR" class="form-control" placeholder="Cluster Application" name="ClusterApplication" formControlName="ClusterApplication" #ClusterApplication/>
                <input type="hidden" id="clusterAirId" /> -->

              <ng-autocomplete [data]="chilApplications"
                               [searchKeyword]="keyword"
                               placeholder="Enter Cluster Application"
                               (selected)='selectEventAddClusterApp($event)'
                               (inputChanged)='FetchClusteredChildApps($event)'
                               historyIdentifier="applications"
                               [itemTemplate]="itemTemplate"
                               [notFoundTemplate]="notFoundTemplate"
                               [disabled]="unchanged">
              </ng-autocomplete>

              <ng-template #itemTemplate let-option>
                <a [innerHTML]="option.Name" (click)="getSelectedAirIdsClusterd(option)"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>


            </div>
          </div>
        </fieldset>
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Date Identified (GMT)<em style="color:red;">*</em> :</label>
          <div class="col-md-6 datePicker">
            <!-- <input id="dtIdentified" type="date" placeholder="Date Identified" class="form-control" name="DateIdentified" formControlName="DateIdentified" #DateIdentified/> -->
            <!--<ngx-datetime-picker [(selectedDateTime)]="selectedDateTime" id="DateIdentified" [formControl]="ControlDateIdentified" class="form-control dateTimePicker" name="DateIdentified" formControlName="DateIdentified" #DateIdentified (ngModelChange)="onTextChange($event)"></ngx-datetime-picker>-->
            <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="picker" placeholder="Date identified"[min]="minDate" formControlName="DateIdentified"
                     [disabled]="disabled">
              <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                       [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                       [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div>

        <!-- DSR048 Removal of DUe date -->
        <!-- <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Due Date (GMT)<em style="color:red;">*</em> :</label>
          <div class="col-md-6 datePicker"> -->
            <!-- <input id="dtdue" type="date" placeholder="Due Date" class="form-control" name="DueDate" formControlName="DueDate" #DueDate/> -->
            <!--<ngx-datetime-picker [(selectedDateTime)]="selectedDateTimedue" id="DueDate" [formControl]="ControlDueDate" class="form-control dateTimePicker" name="DueDate" formControlName="DueDate" #DueDate (ngModelChange)="onTextChange($event)"></ngx-datetime-picker>-->
            <!-- <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="dueDate" placeholder="Due date" formControlName="DueDate"
                     [disabled]="disabled">
              <mat-datepicker-toggle matSuffix [for]="$any(dueDate)"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #dueDate [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                       [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                       [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div> -->

        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Workstream<em style="color:red;">*</em>:</label>
          <div class="col-md-6">
            <select id="ddlWorkStream" ng-model="selectedWorkStream" class="form-control" name="Workstream" formControlName="Workstream" #Workstream (change)="LoadSubWorkStreamData();">
              <option [ngValue]="null" selected>--Select Workstream--</option>
              <option *ngFor="let wrkstrm of workstreamSR; let i = index" [value]="workstreamSR[i].value">{{workstreamSR[i].value}}</option>
            </select>
          </div>
        </div>

        <div class="row form-group mb-3" ng-show="divSubWorkStream" *ngIf="(addForm.value.Workstream == 'Data Analysis')">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Sub-Workstream<em style="color:red;">*</em>:</label>
          <div class="col-md-6">
            <select id="ddlSubWorkStream" ng-model="selectedSubWorkstream" class="form-control" name="SubWorkstream" formControlName="SubWorkstream" #SubWorkstream (change)="LoadSelectedCategoryCycle();">
              <option [ngValue]="null" selected>--Select Status--</option>
              <option *ngFor="let subWorkstream of subWorkstreamSR; let i = index" [value]="subWorkstreamSR[i].value">{{subWorkstreamSR[i].value}}</option>
            </select>
          </div>
        </div>
        
        <div class="row form-group mb-3" ng-show="divCategory" *ngIf="(addForm.value.Workstream == 'Data Analysis' || addForm.value.SubWorkstream == 'P1/P2')">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Category<em style="color:red;">*</em>:</label>
          <div class="col-md-6">
            <select id="ddlCategory" ng-model="selectedCategory" class="form-control" name="Category" formControlName="Category" #Category>
              <option [ngValue]="null" selected>--Select Status--</option>
              <option *ngFor="let category of categorySR; let i = index" [value]="categorySR[i].value">{{categorySR[i].value}}</option>
            </select>
          </div>
        </div>
        <div class="row form-group mb-3" ng-show="divOtherCategory" *ngIf="(addForm.value.Workstream == 'Data Analysis' && addForm.value.Category == 'Others � Please specify')">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Other Category<em style="color:red;">*</em>:</label>
          <div class="col-md-6">
            <input type="text" ng-model="otherCategory" class="form-control " id="txtOtherCategory" placeholder="Please enter other category" name="OtherCategory" formControlName="OtherCategory" #OtherCategory />
          </div>
        </div>

        <div class="row form-group mb-3" ng-show="divCycleStage" *ngIf="(addForm.value.Workstream == 'Data Analysis')">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Cycle Stage<em style="color:red;">*</em>:</label>
          <div class="col-md-6">
            <select id="ddlCycleStage" ng-model="selectedCycleStage" class="form-control" style="width:65%;" name="CycleStage" formControlName="CycleStage" #CycleStage>
              <option [ngValue]="null" selected>--Select Status--</option>
              <option *ngFor="let cycleStage of cycleStageSR; let i = index" [value]="cycleStageSR[i].value">{{cycleStageSR[i].value}}</option>
            </select>
          </div>
        </div>
        <!-- Added as per DSR048 Requirement 5 -->
        <div class="row form-group mb-3" ng-show="divIncidentRelated" *ngIf="(addForm.value.Workstream == 'Incident Related')">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Incident Ticket<em style="color:red;">*</em>:</label>
          <div class="col-md-6">
            <input type="hidden" ng-model="Incident" class="form-control" name="Id" formControlName="Id" #Id />
            <input type="text" ng-model="Incident" id="txtIncident" class="form-control" style="width:100%;" placeholder="INCXXXXXXXX" name="IncidentTicket" formControlName="IncidentTicket" #IncidentTicket />
          </div>
        </div>

        <div class="row form-group mb-3" ng-show="divRelatedKPI" *ngIf="(addForm.value.Workstream == 'Incident Related')">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Related KPI<em style="color:red;">*</em>:</label>
          <div class="col-md-6">
            <select id="ddlRelatedKPI" ng-model="selectedRelatedKPI" class="form-control" name="RelatedKPI" formControlName="RelatedKPI" #RelatedKPI>
              <option [ngValue]="null" selected>--Select Status--</option>
              <option *ngFor="let RelatedKPI of relatedKPISR; let i = index" [value]="relatedKPISR[i].value">{{relatedKPISR[i].value}}</option>
            </select>
          </div>
        </div>
        <div class="row form-group mb-3" ng-show="divIncidentRelatedKPI" *ngIf="addForm.value.Workstream === 'Incident Related' && addForm.value.RelatedKPI && addForm.value.RelatedKPI !== '--Select Status--'">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Related KPI Info:</label>
          <div class="col-md-6">
            <input type="hidden" ng-model="Info" class="form-control" name="Id" formControlName="Id" #Id />
            <input type="text" ng-model="Info" id="txtRelatedKPIInfo" class="form-control" style="width:100%;" placeholder="Enter text" name="RelatedKPIInfo" formControlName="RelatedKPIInfo" #RelatedKPIInfo />
          </div>
        </div>        
        <div class="row form-group mb-3" ng-show="divPrbMngmnt" *ngIf="(addForm.value.Workstream == 'Problem Management' || addForm.value.Workstream == 'Retrospective Incident Analysis')">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Incident Ticket<em style="color:red;">*</em>:</label>
          <div class="col-md-6">
            <input type="hidden" ng-model="Incident" class="form-control" name="Id" formControlName="Id" #Id />
            <input type="text" ng-model="Incident" id="txtIncident" class="form-control" style="width:100%;" placeholder="INCXXXXXXXX" name="IncidentTicket" formControlName="IncidentTicket" #IncidentTicket />
          </div>
        </div>
        <div class="row form-group mb-3" ng-show="divOthers" *ngIf="(addForm.value.Workstream == 'Others')">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Other Workstream Category<em style="color:red;">*</em>:</label>
          <div class="col-md-6">
            <input type="hidden" ng-model="Others" class="form-control" name="Id" formControlName="Id" #Id />
            <input type="text" ng-model="Others" id="txtOthers" class="form-control" style="width:100%;" name="OtherWorkstreamCategory" formControlName="OtherWorkstreamCategory" #OtherWorkstreamCategory />
          </div>
        </div>
        <!-- DSR048 Removal of Raised by -->
        <!-- <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Raised By<em style="color:red;">*</em> :</label>
          <div class="col-md-6" id="peopleTemplate">

            <ng-autocomplete [data]="lotOfPeople"
                             [searchKeyword]="keywordP"
                             placeholder="Enter name"
                             (selected)='selectPeopleEventForRaisedBy($event)'
                             (inputChanged)='LoadPeopleByName($event)'
                             historyIdentifier="SearchPeople"
                             [itemTemplate]="peopleTemplate"
                             [notFoundTemplate]="notFoundTemplateP">
            </ng-autocomplete>

            <ng-template #peopleTemplate let-people>
              <a [innerHTML]="people.Name"></a>

            </ng-template>

            <ng-template #notFoundTemplateP let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div> -->
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Assigned To<em style="color:red;">*</em> :</label>
          <div class="col-md-6" id="peopleTemplateAssigned">

            <ng-autocomplete [data]="lotOfPeople"
                             [searchKeyword]="keywordP"
                             placeholder="Enter name"
                             (selected)='selectPeopleEventForAssignedTo($event)'
                             (inputChanged)='LoadPeopleByName($event)'
                             historyIdentifier="SearchPeople"
                             [itemTemplate]="peopleTemplate"
                             [notFoundTemplate]="notFoundTemplateP">
            </ng-autocomplete>

            <ng-template #peopleTemplate let-people>
              <a [innerHTML]="people.Name"></a>

            </ng-template>

            <ng-template #notFoundTemplateP let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Status<em style="color:red;">*</em>:</label>
          <div class="col-md-6">
            <select id="Status" ng-model="selectedStatus" class="form-control" name="Status" formControlName="Status" #Status>
              <option [ngValue]="null" selected>--Select Status--</option>
              <option *ngFor="let status of Srstatus; let i = index" [value]="Srstatus[i].value">{{Srstatus[i].value}}</option>
            </select>
          </div>
        </div>

        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Latest Updates<em style="color:red;">*</em> :</label>
          <div class="col-md-6">
            <!--<ckeditor #editor
                      id="description"
                      name="description"
                      >
            </ckeditor>-->

            <ckeditor class="tool-guidance" data="" rows="2" [editor]="Editor" name="LatestUpdate" formControlName="LatestUpdate" #LatestUpdate (change)="changeLatestUpdate($event)"></ckeditor>
            <!-- <ckeditor [editor]="Editor" data="" ng-Model="LatestUpdate" name="LatestUpdate" formControlName="LatestUpdate" #LatestUpdate (change)="changeLatestUpdate($event)"></ckeditor> -->
            <!--<textarea ck-editor class="form-control" rows="2" id="txtLatestUpdates" ng-model="latestUpdate" placeholder="Latest Updates" name="LatestUpdate" formControlName="LatestUpdate" #LatestUpdate></textarea>-->
          </div>
          <!--<a href="javascript:;" id="hrfLatestUpdates" style="color:blue;" ng-click="ShowLatestUpdatesPopup();">Edit</a>-->
        </div>

        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Files Upload:</label>
          <div class="col-md-6">
            <input type="file" id="file" multiple
                   (change)="getFileDetails($event)" accept="all/*" name="file" #file />
            <button (click)="uploadFiles()" class="btn btn-primary" style="width: 28%; height: 35px; text-align: center; margin-top: 12px; margin-left: 135px;">Upload File</button>
          </div>
        </div>
        <div class="row form-group mb-3" *ngIf="SelectedFiles.length > 0">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Attached Images:</label>
          <div class="col-md-6">
            <ul class="ft-file-list">
              <li *ngFor="let file of SelectedFiles">
                <div class="ft-modal-upload-filename">
                  <a type="button" style="color:#0000EE " (click)="previewAttachment(file)">{{file.fName}}</a>
                  <a class="text-danger ft-modal-upload-delete-file" (click)="removeFile(file)"> <fa-icon [icon]="['fas', 'trash']"></fa-icon></a>
                </div>

              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--<div id="addIssueAlert" ng-controller="ServiceReviewController" class="alert alert-danger" *ngIf="errorMessage">
          <strong></strong>
      {{errorMessage}}
      </div>-->
      <div class="alert alert-danger" *ngIf="errorMessage" id="addIssueAlert">
        {{errorMessage}}
      </div>
      <div class="form-group mb-3 modal-footer">
        <div class="float-left" id="CreatedByDiv" style="margin-left:150px;margin-top:-20px;" *ngIf="addForm.value.Id != null">
          <label class="col-form-label col-md-6"></label>
          <div class="col-form-label col-md-12" id="issues">
            Created at <strong>{{CreatedDate | date:'MM/dd/yyyy HH:mm:ss a'}} </strong>by <strong>{{CreatedByDisplay}} </strong><br />
            Last Modified at <strong>{{LastModifiedDate | date:'MM/dd/yyyy HH:mm:ss a'}}</strong> by <strong>{{LastModifiedBy}}</strong> <br />
          </div>

        </div>
        <div class="float-right">
          <button id="btnSrSaveIssue" type="submit" class="btn btn-secondary double_button" (click)="SubmitaddForm();">Save changes</button>
          <button id="formclosebtn" type="button" class="btn btn-primary" (click)="modal.dismiss('Button click'); resetData();">Close</button>
        </div>
      </div>
    </form>
  </ng-template>
  <input type="hidden" id="IssueId" value="0">

  <div id="divpager"></div>

  <!-- Modal form for Production Process Status-->
  <!-- PPS stands for Production Process Status-->
  <ng-template #modalPPSForm let-modal>
    <form name='modalform_pps' [formGroup]="addPPSForm" (ngSubmit)="SaveUpdate_pps($event)">
      <!-- (ngSubmit)="SubmitForm_pps();"-->
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel_pps">{{formTitle}}</h4>
        <button id="formcrossbtn_pps" type="button" class="close" data-dismiss="modal" (click)="modal.dismiss('Button click'); dataResetPPS();"><span>&times; </span></button>
      </div>
      <div id="modbody_pps" class="modal-body" style="margin-left: 70px;">
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-6" for="text" style="text-align: left; font-weight: bold;">Production Process Status</label>
          <input type="hidden" id="hdnPPSId" value="" name="ApplicationId" formControlName="ApplicationId" #ApplicationId>
        </div>
<!-- Commenting out as part of DSR048 Req-2 Implementation -->
        <!-- <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Open Risks:</label>
          <div class="col-md-6">
            <ckeditor class="tool-guidance" rows="2" [editor]="Editor" ng-Model="OpenRisks" name="OpenRisks" formControlName="OpenRisks" #OpenRisks (change)="changeOpenRisks($event)"></ckeditor> -->
            <!--<textarea ck-editor class="form-control" rows="2" name="OpenRisks" ng-model="openRisks" placeholder="Open Risks" formControlName="OpenRisks" #OpenRisks></textarea>-->

          <!-- </div> -->
          <!-- <a href="javascript:;" id="hrfopenRisks" style="color:blue;" ng-click="ShowopenRisksPopup();">Edit</a> -->
        <!-- </div>
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Alerts/Reports Received (Actions Needed, Who to Contact): </label>
          <div class="col-md-6">
            <ckeditor class="tool-guidance" rows="2" [editor]="Editor" ng-Model="AlertsReceived" name="AlertsReceived" formControlName="AlertsReceived" #AlertsReceived (change)="changeAlertsReceived($event)"></ckeditor> -->
            <!--<textarea ck-editor class="form-control" rows="2" name="AlertsReceived" placeholder="Alerts/Reports Received" formControlName="AlertsReceived" #AlertsReceived></textarea>-->
          <!-- </div> -->
          <!-- <a href="javascript:;" id="hrfAlertsReceived" style="color:blue;" ng-click="ShowAlertPopup();">Edit</a> -->
        <!-- </div>
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Any Challenges(P1/P2): </label>
          <div class="col-md-6">
            <ckeditor class="tool-guidance" rows="2" [editor]="Editor" ng-Model="Challenges" name="Challenges" formControlName="Challenges" #Challenges (change)="changeChallenges($event)"></ckeditor> -->
            <!--<textarea ck-editor class="form-control" rows="2" name="Challenges" placeholder="Enter text" formControlName="Challenges" #Challenges></textarea>-->
          <!-- </div> -->
          <!-- <a href="javascript:;" id="hrfChallenges" style="color:blue;" ng-click="ShowChallengesPopup();">Edit</a> -->
        <!-- </div> -->
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Questions/ Clarifications/ Other Items :</label>
          <div class="col-md-6">
            <ckeditor class="tool-guidance" rows="2" [editor]="Editor" ng-Model="OtherItems" name="OtherItems" formControlName="OtherItems" #OtherItems (change)="changeOtherItems($event)"></ckeditor>
            <!--<textarea ck-editor class="form-control" rows="2" name="OtherItems" placeholder="Questions/Clarifications/Other Items" formControlName="OtherItems" #OtherItems></textarea>-->
          </div>
          <!-- <a href="javascript:;" id="hrfOtherItems" style="color:blue;" ng-click="ShowOtherItemsPopup();">Edit</a> -->
        </div>
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Send Email To<em style="color:red;">*</em> :</label>
          <div class="col-md-6">
            <!-- <input type="text" class="form-control " id="temp1" style="width:100%;" placeholder="Recepient Email Address" name="SendEmailTo" formControlName="SendEmailTo" #SendEmailTo /> -->
            <div class="ng-autocomplete" id="sendEmailAutocompleteSR">
              <ng-autocomplete [data]="SearchPeople"
                               [searchKeyword]="keywordP"
                               placeholder="Recepient Email Address"
                               (click)='autocompleteclickEmailSR($event)'
                               (selected)='selectPeopleEmailTo($event)'
                               (inputChanged)='onEmailToInputChanged($event)'
                               historyIdentifier="SearchPeople"
                               [itemTemplate]="peopleTemplate"
                               [notFoundTemplate]="notFoundTemplateP">
              </ng-autocomplete>

              <ng-template #peopleTemplate let-people>
                <a [innerHTML]="people.EnterpriseId" required></a>

              </ng-template>

              <ng-template #notFoundTemplateP let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
            <div class="row form-group" style="width: 100%;" *ngIf="ppsEmailTo!==null">
              <mat-chip-list aria-label="Application selection">
                <mat-chip color="primary" *ngFor="let address of ppsEmailTo;" (removed)="removeTo(address)">
                  {{address}}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Email To CC :</label>
          <div class="col-md-6">
            <!-- <input type="text" class="form-control " id="temp2" style="width:100%;" placeholder="Recepient Email Address" name="SendEmailCC" formControlName="SendEmailCC" #SendEmailCC /> -->
            <div class="ng-autocomplete" id="emailToCcAutocompleteSR">
              <ng-autocomplete [data]="SearchPeople"
                               [searchKeyword]="keywordP"
                               placeholder="Recepient Email Address"
                               (click)='autocompleteclickEmailSR($event)'
                               (selected)='selectPeopleEmailCC($event)'
                               (inputChanged)='onEmailToInputChanged($event)'
                               historyIdentifier="SearchPeople"
                               [itemTemplate]="peopleTemplate"
                               [notFoundTemplate]="notFoundTemplateP">
              </ng-autocomplete>

              <ng-template #peopleTemplate let-people>
                <a [innerHTML]="people.EnterpriseId" required></a>

              </ng-template>

              <ng-template #notFoundTemplateP let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
            <div class="row form-group" style="width: 100%;" *ngIf="ppsEmailCC!==null">
              <mat-chip-list aria-label="Application selection">
                <mat-chip color="primary" *ngFor="let address of ppsEmailCC;" (removed)="removeCC(address)">
                  {{address}}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
              </mat-chip-list>
            </div>

          </div>
        </div>
      </div>
      <br />
      <br />
      <!-- <div id="addIssueAlert_pps" ng-controller="ServiceReviewController" class="alert alert-danger" ng-show="error_pps"> -->
      <strong></strong>
      <!-- </div> -->
      <div class="alert alert-danger" *ngIf="errorMessage" id="addIssueAlert_pps">
        {{errorMessage}}
      </div>
      <div class="modal-footer" ng-controller="ServiceReviewController">
        <div class="float-right">
          <button id="btnSrSave_pps" type="submit" class="btn btn-secondary double_button">Save changes</button>
          <button id="formclosebtn_pps" type="button" class="btn btn-primary" (click)="modal.dismiss('Button click'); dataResetPPS();">Close</button>
        </div>
      </div>
    </form>
  </ng-template>
  <input type="hidden" id="hdnMtId" value="0">
  <input type="hidden" id="hdnPPSId" value="0">
  <input type="hidden" id="hdnAppId" value="0">
  <input type="hidden" id="hdnNextMtId" value="0">
  <input type="hidden" id="hdnActivityId" value="0">
  <input type="hidden" id="hdnCommentId" value="0">
  <input type="hidden" id="hdnRaisedBy" value="0">
  <input type="hidden" id="hdnAssignedTo" value="0">
  <input type="hidden" id="hdnActivityApplication" value="0">
  <input type="hidden" id="hdnActivityAppName" value="0">
  <input type="hidden" id="hdnOtherCategory">
  <input type="hidden" id="hdnSubWorkStream">
  <input type="hidden" id="hdnCategory">
  <input type="hidden" id="hdnCycleStage">
  <input type="hidden" id="hdnWorkstream">


  <ng-template #modalform_announcement let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel_announcement">Latest Announcements</h4>
      <button type="button" id="btnAnnouncementAddNew" class="btn btn-primary double_button" style="margin-left: 63%;" [hidden]="!isUserAdmin" (click)="AddNew_announcement(modalAddNewAnnouncement)"><span class="fa fa-plus"></span>Add New</button>
      <button id="formcrossbtn_announcement" type="button" style="float: right;" class="close btn btn-danger" data-dismiss="modal" (click)="modal.dismiss('Cross click'); DateReset();"><span>&times; </span></button>
    </div>

    <div id="NoAnnouncement" style="text-align: center; font-weight: bold;padding-top:20px!important;padding-bottom:20px!important;" *ngIf="!Announcements">
      {{noAnnouncement}}
    </div>
    <ngb-carousel *ngIf="Announcements">
      <ng-template ngbSlide *ngFor="let announcement of Announcements; let i = index">
        <div class="carousel-caption">
          <h3>{{announcement.Description}}</h3>
          <p>{{announcement.AnnStartDate | date:'MM/dd/yyyy HH:mm:ss a'}}</p>
        </div>
        <div class="float-right">
          <button type="button" id="btnAnnouncementDelete" style="float: right;" [hidden]="!isUserAdmin" (click)="Delete_announcement(announcement.Id)" class="btn btn-primary"><span class="material-icons">delete</span></button>

        </div>
      </ng-template>

    </ngb-carousel>

    <div style="margin:0 0 0.5% 92%">
      <button id="formclosebtn_announcement" type="button" class="btn btn-primary" (click)="modal.dismiss('Button click'); DateReset();">Close</button>
    </div>
    <!--<div id="carousel-announcement" class="carousel slide" data-ride="carousel" style="min-height:320px;background-color:lightSkyBlue;">
    <ol class="carousel-indicators">
        <li data-target="#carousel-announcement" data-slide-to="{$index}" ng-class="{active:!$index}" *ngFor="let announcement of Announcements"></li>
    </ol>
    <div class="carousel-inner">

        <div class="carousel-item" ng-class="{active:!$index}" *ngFor="let announcement of Announcements">
            <div class="carousel-content" style="text-align: center; font-weight: bold;">
                <div class="offset-3 col-6">
                    <h3 style="padding-top:110px!important;padding-bottom:110px!important;">{{announcement.Description}}</h3>
                </div>
                <div class="offset-1 col-9">
                    <div class="float-left">
                        {{announcement.AnnStartDate | date:'MM/dd/yyyy HH:mm:ss a'}}
                    </div>
                    <div class="float-right">
                        <button type="button" id="btnAnnouncementDelete" style="float: right;" ng-show="isUserAdmin" (click)="Delete_announcement(announcement)" class="btn btn-primary"><span class="material-icons">delete</span></button>

                    </div>
                </div>

            </div>

        </div>
    </div>-->
    <div id="dvalertAnn" ng-controller="ServiceReviewController" class="alert alert-danger" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
    <!--<div ng-controller="ServiceReviewController" class="modal-footer">

            <div class="float-right">
                <button id="formclosebtn_announcement" type="button" class="btn btn-primary" (click)="modal.dismiss('Button click')">Close</button>
            </div>
        </div>
    </div>-->
  </ng-template>

  <ng-template #modalformpatchActivity let-modal>
    <form name='modalformpatchActivity' [formGroup]="SubmitPatchActivityForm">
      <div class="modal-header">
        <h4 class="modal-title" id="patchActivityModalLabel">Planned Server Activities</h4>
        <button id="patchActivityClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Button click');ClosePatchActivity();"><span aria-hidden="true">&times;</span></button>
      </div>
      <div id="modpatchActivitybody" class="modal-body" style="height:80%">
        <div id="addpatchActivityAlert" ng-controller="ServiceReviewController" class="alert alert-danger" *ngIf="errorMessage">
          <strong>Error!</strong> {{errorMessage}}
        </div>
        <!--<div class="alert alert-danger" *ngIf="errorMessage" id="addpatchActivityAlert">
            {{errorMessage}}
        </div>-->
        <div class="row form-group mb-3" [hidden]="!divActivityApplication">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Application:</label>
          <div class="col-md-6">
            <input type="text" class="form-control" id="txtActivityApplication" ng-model="activityApplication" placeholder="Application" name="Name" formControlName="Name" #Name readonly>
            <input type="hidden" class="form-control" id="hdnPPSId" name="PPSId" formControlName="PPSId" #PPSId />
            <input type="hidden" class="form-control" id="hdnAppId" name="ApplicationId" formControlName="ApplicationId" #ApplicationId />
            <input type="hidden" class="form-control" name="Id" formControlName="Id" #Id />
          </div>
        </div>
        <div class="row form-group mb-3" [hidden]="!divActivityClusteredApp">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Cluster Application:</label>
          <div class="col-md-6" id="ClusterAppActivity">
            <!--<input type="text" class="form-control" id="txtActivityClusterApp" placeholder="Cluster Application" name="ClusterApp" formControlName="ClusterApp" #ClusterApp>-->
            <ng-autocomplete [data]="chilApplicationsForPatchActivity"
                             [searchKeyword]="keywordPCluster"
                             placeholder="Enter Cluster Application"
                             (inputChanged)='FetchClusteredChildAppsForPatchActivity($event)'
                             historyIdentifier="applications"
                             [itemTemplate]="itemTemplate"
                             [notFoundTemplate]="notFoundTemplate"
                             [disabled]="unchanged"
                             [formControl]="childAppName">
            </ng-autocomplete>
            <!--(selected)='selectEventAddClusterAppForPatchActivity($event)' -->
            <ng-template #itemTemplate let-option>
              <a [innerHTML]="option.Name" (click)="getSelectedAirIdsClusterdForPatch(option)"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>

        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Incident Description<em style="color:red;">*</em> :</label>
          <div class="col-md-6">
            <ckeditor class="tool-guidance" rows="2" [editor]="Editor" ng-Model="IncidentDescription" id="IncidentDescription" name="IncidentDescription" formControlName="IncidentDescription" #IncidentDescription (change)="changeDes($event)"></ckeditor>
            <!--<textarea ck-editor class="form-control" rows="2" id="txtIncidentRaised" ng-model="incidentDescription" placeholder="Incident Description" name="IncidentDescription" formControlName="IncidentDescription" #IncidentDescription></textarea>-->
          </div>
          <!-- <a href="javascript:;" id="hrfIncdesc" style="color:blue;" ng-click="ShowIncdescPopup();">Edit</a> -->
        </div>

        <div class="row form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;">Comments :</label>
          <div class="col-md-6">
            <ckeditor class="tool-guidance" rows="2" [editor]="Editor" ng-Model="Comments" name="Comments" formControlName="Comments" #Comments (change)="changeCmnt($event)"></ckeditor>
            <!--<textarea ck-editor class="form-control" rows="2" id="txtCommentsPA" placeholder="Comments" name="Comments" formControlName="Comments" #Comments></textarea>-->
            <input type="hidden" class="form-control" id="modifiedBy" name="ModifiedBy" formControlName="ModifiedBy" #ModifiedBy />
          </div>
          <!-- <a href="javascript:;" id="hrfcomments_pps" style="color: blue;" ng-click="ShowCommentsPopup();">Edit</a> -->
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label col-md-4" for="text" style="text-align: left;"></label>
          <div ng-controller="ServiceReviewController" class="modal-footer" style="padding-left:148px;">

            <div class="col-md-6">
              <button type="submit" id="btnPatchActivitySave" class="btn btn-secondary double_button" (click)="SubmitFormPatchActivity(modalformpatchActivity);">Save changes</button>
              <button id="PatchActivityclosebtn" type="button" class="btn btn-primary" (click)="modal.dismiss('Button click'); ClosePatchActivity();">Close</button>
            </div>
          </div>
        </div>
        <div ng-show="showcomm">
          <br />
          <br />
        </div>
        <div ng-show="commhistory" *ngIf="Activity==null">No historical Patching Activities exists.</div>
        <!--<div ng-show="commhistory">
            <br />
        </div>-->
        <div id="patchActivityform" ng-show="showcomm" style="height: 200px; overflow-y: auto; width: 100%; word-wrap: break-word;">
          <table class="table table-bordered" style="width:100%">
            <thead>
              <tr style="background-color: #f5f5f5;">
                <td style="border: solid; border-width: 1px; text-align: center; width:40% !important"><strong>Incident Description</strong></td>
                <td style="border: solid; border-width: 1px; text-align: center; width:40% !important"><strong>Comments</strong></td>
                <td style="border: solid; border-width: 1px; text-align: center" class="col-1"><strong>Edit</strong></td>
                <td style="border: solid; border-width: 1px; text-align: center"><strong>Delete</strong></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let actDetail of Activity;" class="custom-column">
                <td id='permalink_section' style="border:solid; border-width:1px; text-align: left ; white-space: pre-wrap; word-wrap:break-word; width:40% !important" [innerHTML]="actDetail.IncidentDescription"></td>
                <td id='permalink_section' style="border:solid; border-width:1px; text-align: left ; white-space: pre-wrap; word-wrap:break-word;width:40% !important" [innerHTML]="actDetail.Comments"></td>
                <td style="border:solid; border-width:1px; text-align: center" class="col-1"><button type="button" id="btnEdit" class="btn btn-primary" style="width:69px" (click)="EditpatchActivity(actDetail);"><span class="fa fa-edit"></span>Edit</button></td>
                <td style="border:solid; border-width:1px; text-align: center">
                  <button type="button" id="btnPatchActivityDelete" class="btn btn-primary" style="width:85px" (click)="Deactivate(modalformpatchActivity, actDetail);"><span class="fa fa-trash"></span>Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #modalformPPSconfirmModal let-modal>
    <form name='modalformPPSconfirmModal'>
      <div class="modal-header">
        <h4 class="modal-title">Send Mail</h4>
        <button id="PPSClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body" style="height:100px;">
        <p>Do you want to continue to send email?</p>
      </div>
      <br />
      <div class="alert alert-danger" ng-controller="ServiceReviewController" *ngIf="errorMessage">
        {{errorMessage}}
      </div>
      <div class="alert alert-danger" *ngIf="errorMessage">
        {{errorMessage}}
      </div>
      <div class="modal-footer">
        <div class="float-right">

          <button type="button" class="btn btn-secondary double_button" (click)="openPPSMailPreview(modalformPreviewModal);">Preview</button>
          <button id="modalformPPSconfirmModalClose" type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Cancel</button>

        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #modalformPreviewModal let-modal>
    <form name='modalformPreviewModal' (ngSubmit)="PPSSendMail();">

      <div class="modal-header">
        <h4 class="modal-title">MAIL PREVIEW</h4>
        <button id="PPSMailPreviewClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
      </div>


      <div class="modal-body" style="height:auto;">
        <p style="word-break: break-all;">To: {{EmailToPreview}}</p>
        <p style="word-break: break-all;">CC: {{EmailCCPreview}}</p>
        <br />
        <p>Hi Team,</p>
        <ng-container *ngFor="let apps of ServiceReviewPrivew">
          <p>Please find the updates from {{apps.Name}} Service Review.</p>
        </ng-container>
        <div class="card-group" style="width:100%; padding-bottom:2.5px" *ngFor="let apps of ServiceReviewPrivew">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <!--Division for normal apps-->
                <div *ngIf="apps.parentCount == 0">
                  <h4 class="card-title">
                    &nbsp;&nbsp;
                    <strong>{{apps.Name}}</strong> - {{apps.AirId}}
                  </h4>
                </div>
                <!--Division for clustered apps-->
                <div *ngIf="apps.parentCount == 1">
                  <h4 class="card-title">
                    &nbsp;&nbsp;
                    <strong>{{apps.Name}}</strong>
                  </h4>
                </div>

              </div>
            </div>
            <div class="card-body" id="dvbody">
              <table class="table table-bordered" style="width:100%" *ngIf="ServiceReviewPrivewDetail!=null">
                <thead>
                  <tr style="background-color: #f5f5f5;">
                    <td style="border:solid; border-width:1px; text-align: left"><strong>SR ID</strong></td>
                    <td style="border:solid; border-width:1px; text-align: left"><strong>Application Name</strong></td>
                    <td style="border:solid; border-width:1px; text-align: left"><strong>Issue/Action Item</strong></td>
                    <td style="border: solid; border-width: 1px; text-align: left;padding-right:30px"><strong>Status</strong></td>
                    <td style="border: solid; border-width: 1px; text-align: left"><strong>Latest Updates</strong></td>
                    <td style="border: solid; border-width: 1px; text-align: left" ng-if="ServiceReviewPrivewDetail != null"><strong>Attachment</strong></td>

                  </tr>
                </thead>
                <tbody>
                  <tr class="custom-column" *ngFor="let previewDetail of ServiceReviewPrivewDetail">
                    <td id='permalink_section' style="border:solid; border-width:1px; text-align: left; word-break:break-word">{{previewDetail.Id}}</td>
                    <td id='permalink_section' style="border:solid; border-width:1px; text-align: left; word-break:break-word">{{previewDetail.Name}}</td>
                    <td id='permalink_section' style="border:solid; border-width:1px; text-align: left; word-break:break-word">{{previewDetail.IssueActionItem}}</td>
                    <td id='permalink_section' style="border:solid; border-width:1px; text-align: left; word-break:break-word ;width:12%">{{previewDetail.Status}}</td>
                    <td id='permalink_section' style="border:solid; border-width:1px; text-align: left; white-space: pre-wrap;word-break:break-word" [innerHTML]="previewDetail.LatestUpdate"></td>

                    <td style="border:solid; border-width:1px; text-align: left;" valign='top' *ngIf="previewDetail.FileNames.length === 0">No Attachment</td>
                    <td id="attachmentPopOver" style="border:solid; border-width:1px; text-align: left;" valign='top' *ngIf="previewDetail.FileNames.length != 0">
                      <table>
                        <tr>
                          <td class="header_nav" valign='top' style="width:20px; height:inherit;background-color: #007bff !important;border-color: #007bff">
                            <a (mouseover)="enter(previewDetail)" style="color:#eee !important;" (mouseleave)="leave(previewDetail)">
                              View
                            </a>
                            <ul style="right: 100px;position:absolute;background-color:white;text-decoration: none;color: black;position: absolute;margin-bottom:2px;display: block;padding: 0 0px; border: 1px solid #ccc;border: 1px solid rgba(0,0,0,.2);border-radius: 6px;white-space: nowrap;" *ngIf="hoverIndex == previewDetail">
                              <li *ngFor="let item of previewDetail.FileNames;">{{item}}</li>
                            </ul>
                          </td>
                        </tr>
                      </table>

                    </td>
                  </tr>
                </tbody>
              </table>


              <table class="table table-bordered" style="width:100%" *ngFor="let ppsDetail of PPSPreviewDetail">
                <!-- Removed as per DSR048 Requirement - 2 implementation -->
                <!-- <tr *ngIf="ppsDetail.OpenRisks!=''" class="custom-column">
                  <td style="border: solid; border-width: 1px; text-align: left;background-color: #f5f5f5;text-align:left;width:100px;"><strong>Open Risks</strong></td>
                  <td id='permalink_section' style="border: solid; border-width: 1px; text-align: left;white-space: pre-wrap;word-break:break-word; padding-right:0px !important" [innerHTML]="ppsDetail.OpenRisks"></td>
                </tr>
                <tr *ngIf="ppsDetail.AlertsReceived!=''" class="custom-column">
                  <td style="border: solid; border-width: 1px; text-align: left;background-color: #f5f5f5;text-align:left;width:100px;"><strong>Alerts/Reports Received (Actions needed, Who to Contact)</strong></td>
                  <td id='permalink_section' style="border: solid; border-width: 1px; text-align: left;white-space: pre-wrap;word-break:break-word; padding-right:0px !important" [innerHTML]="ppsDetail.AlertsReceived"></td>
                </tr>
                <tr *ngIf="ppsDetail.Challenges!=''" class="custom-column">
                  <td style="border: solid; border-width: 1px; text-align: left;background-color: #f5f5f5;text-align:left;width:100px;"><strong>Any Challenges(P1/P2)</strong></td>
                  <td id='permalink_section' style="border: solid; border-width: 1px; text-align: left;white-space: pre-wrap;word-break:break-word; padding-right:0px !important" [innerHTML]="ppsDetail.Challenges"></td>
                </tr> -->
                <tr *ngIf="ppsDetail.OtherItems!=''" class="custom-column">
                  <td style="border: solid; border-width: 1px; text-align: left;background-color: #f5f5f5;text-align:left;width:100px;"><strong>Questions/ Clarifications/ Other Items</strong></td>
                  <td id='permalink_section' style="border: solid; border-width: 1px; text-align: left;white-space: pre-wrap;word-break:break-word; padding-right:0px !important" [innerHTML]="ppsDetail.OtherItems"></td>
                </tr>
                <tr class="custom-column" *ngIf="PPSIncidentDesc != null">
                  <td style="border: solid; border-width: 1px; text-align: left;background-color: #f5f5f5;text-align:left;width:100px;"><strong>Planned Server Activities</strong></td>
                  <td style="border: solid; border-width: 1px; text-align: left">
                    <div *ngFor="let ppsInDesc of PPSIncidentDesc" class="white-space: pre-wrap;">
                      <!--$index+1}}--><div id='permalink_section' style="text-align: left; margin-top:-24px !important;margin-left:15px;word-break:break-word; padding-right:0px !important" [innerHTML]="ppsInDesc.IncidentDescription"></div>
                    </div>
                  </td>
                </tr>
                <tr class="custom-column" *ngIf="PPSIncidentDesc == null && ServiceReviewPrivewDetail == null
                                                                    && ppsDetail.OpenRisks =='' && ppsDetail.AlertsReceived ==''
                                                                    && ppsDetail.Challenges == '' && ppsDetail.OtherItems == ''">
                  <td><strong>No open SR action Items and No Production Process Information</strong></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="alert alert-danger" id="dvalert_preview" ng-controller="ServiceReviewController" *ngIf="errorMessage">
        {{errorMessage}}
      </div>
      <!-- <div class="alert alert-danger" *ngIf="errorMessage">
        {{errorMessage}}
      </div> -->
      <div class="modal-footer">
        <div class="float-right">
          <button type="submit" id="PPSbtnStoMailSendContinue" class="btn btn-secondary double_button">Send Mail</button>

          <button id="ppsSendMailClose" type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Cancel</button>
        </div>
      </div>

    </form>
  </ng-template>
  <input type="hidden" id="hdnMailPPSId" value="0">
  <input type="hidden" id="hdnMailAppId" value="0">
  <input type="hidden" id="hdnSReviewsIds" value="0">
  <ng-template #modalAddNewAnnouncement let-modal>
    <form name='modalAddNewAnnouncement' [formGroup]="SaveDataAnnouncementForm" (ngSubmit)="SaveData_announcement();">
      <div class="modal-header">
        <h4 class="modal-title" id="annModalLabel">Add New Announcement</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click'); CloseForm_announcement();"><span aria-hidden="true">&times;</span></button>
      </div>
      <div id="layoutbody" class="modal-body" style="margin-left: 40px;">
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-3" for="text" style="text-align: left;">Announcement<em style="color:red;">*</em> :</label>
          <div class="col-md-6">
            <textarea class="form-control" rows="5" id="txtAnnouncementDesc" ng-model="AnnouncementDesc" placeholder="Enter Announcement Here" name="Description" formControlName="Description" #Description></textarea>
          </div>
        </div>

        <div class="row form-group mb-3">
          <label class="col-form-label col-md-3" for="text" style="text-align: left;">Start Date<em style="color:red;">*</em> :</label>
          <div class="col-md-6 datePicker">
            <!--<input id="dtAnnStartDate" type="date" placeholder="Select Start Date" class="form-control" name="AnnStartDate" formControlName="AnnStartDate" #AnnStartDate />-->
            <!--<ngx-datetime-picker [(selectedDateTime)]="selectedDateTimeAnnStart" id="dtAnnStartDate" [formControl]="ControldtAnnStartDate" class="form-control dateTimePicker" name="AnnStartDate" formControlName="AnnStartDate" #AnnStartDate (ngModelChange)="onTextChange($event)"></ngx-datetime-picker>-->
            <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="picker" placeholder="Start date" [min]="minDate" formControlName="AnnStartDate"
                     [disabled]="disabled">
              <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                       [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                       [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div>
        <div class="row form-group mb-3">
          <label class="col-form-label col-md-3" for="text" style="text-align: left;">End Date<em style="color:red;">*</em> :</label>
          <div class="col-md-6 datePicker">
            <!--<input id="dtAnnEndDate" type="date" placeholder="Select End Date" class="form-control" name="AnnEndDate" formControlName="AnnEndDate" #AnnEndDate />-->
            <!--<ngx-datetime-picker [(selectedDateTime)]="selectedDateTimeAnnEnd" id="dtAnnEndDate" [formControl]="ControldtAnnEndDate" class="form-control dateTimePicker" name="AnnEndDate" formControlName="AnnEndDate" #AnnEndDate (ngModelChange)="onTextChange($event)"></ngx-datetime-picker>-->
            <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="annEnd" placeholder="End date" [min]="minDate" formControlName="AnnEndDate"
                     [disabled]="disabled">
              <mat-datepicker-toggle matSuffix [for]="$any(annEnd)"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #annEnd [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                       [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                                       [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [defaultTime]="defaultTime">
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div>

      </div>
      <br />
      <div id="addIssueAlert_announcement" ng-controller="ServiceReviewController" class="alert alert-danger" [hidden]="error_announcement">
        <strong></strong> {{errorMessageAnnouncement}}
      </div>
      <div class="modal-footer">
        <div class="float-right">
          <button type="submit" id="btnSaveRecordAnn" class="btn btn-secondary double_button">Save changes</button>
          <button id="SaveData_announcementClose" type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click'); CloseForm_announcement();">Close</button>
        </div>
      </div>
    </form>
  </ng-template>
  <!--<ng-template #CommentsPopupModal let-modal>
      <form name='commentspopupmodal' [formGroup]="commentsData">
              <div class="modal-header">
                  <h4 class="modal-title">comments</h4>
                  <button id="commentspopupclosebtn" type="button" class="close" data-dismiss="modal" aria-label="close" ng-click="commentspopupmodalclose();"><span aria-hidden="true">&times;</span></button>

              </div>
              <div class="modal-body">
                  <table>
                      <tr class="spaceunder">
                          <td><strong>comments</strong></td>
                      </tr>
                      <tr>
                          <td>
                              <textarea ck-editor name="txtcommentspopup" class="form-control" rows="4" cols="80" style="height:300px;width:200px"></textarea>
                          </td>
                      </tr>
                  </table>
              </div>
              <br />
              <div class="modal-footer">
                  <div class="float-right">
                      <button type="button" class="btn btn-secondary" ng-click="commentspopupmodalok();">ok</button>
                      <button type="button" class="btn btn-primary" ng-click="commentspopupmodalclose();">cancel</button>

                  </div>
              </div>
      </form>
  </ng-template>-->
  <!--@*Latest Updates Modal*@-->
  <!--<form name='LatestUpdatesModal'>
      <div id="LatestUpdatesPopup" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="LatestUpdatesPopupModalLabel">
          <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title">Latest Updates</h4>
                      <button id="LatestUpdatesClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="LatestUpdatesModalClose();"><span aria-hidden="true">&times;</span></button>

                  </div>
                  <div class="modal-body">
                      <table>
                          <tr class="spaceUnder">
                              <td><strong>Latest Updates</strong></td>
                          </tr>

                          <tr>
                              <td style="height:300px !important;width:600px !important">
                                  <textarea ck-editor name="txtLatestUpdatesPopup" ng-model="LatestUpdatesPopup" class="form-control" rows="4" cols="80"></textarea>
                              </td>
                          </tr>
                      </table>
                  </div>
                  <br />
                  <div class="modal-footer">
                      <div class="float-right">
                          <button type="button" class="btn btn-secondary" ng-click="LatestUpdatesModalOk();">Ok</button>
                          <button type="button" class="btn btn-primary" ng-click="LatestUpdatesModalClose();">Cancel</button>

                      </div>
                  </div>
              </div>

          </div>
      </div>
  </form>-->
  <!--<form name='OpenRiskModal'>
      <div id="OpenRiskModalPopup" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="OpenRiskModalLabel">
          <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title">Open Risk</h4>
                      <button id="OpenRiskClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="OpenRiskModalClose();"><span aria-hidden="true">&times;</span></button>

                  </div>
                  <div class="modal-body">
                      <table>
                          <tr class="spaceUnder">
                              <td><strong>Open Risk</strong></td>
                          </tr>

                          <tr>
                              <td>
                                  @*<textarea class="ckeditor" cols="80" id="editor2" rows="10" name="editor2" ng-model="OpenRiskPopup"></textarea>*@
                                  <textarea ck-editor name="txtOpenRiskPopup" ng-model="OpenRiskPopup" class="form-control" rows="4" cols="80" style="height:300px;width:200px"></textarea>
                              </td>
                          </tr>
                      </table>
                  </div>
                  <br />
                  <div class="modal-footer">
                      <div class="float-right">
                          <button type="button" class="btn btn-secondary" ng-click="OpenRiskModalOk();">Ok</button>
                          <button type="button" class="btn btn-primary" ng-click="OpenRiskModalClose();">Cancel</button>

                      </div>
                  </div>
              </div>

          </div>
      </div>
  </form>-->
  <!--<form name='IncDescModal'>
      <div id="IncDescPopup" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="IncDescModalLabel">
          <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title">Incident Description</h4>
                      <button id="IncDescClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="IncDescModalClose();"><span aria-hidden="true">&times;</span></button>

                  </div>
                  <div class="modal-body">
                      <table>
                          <tr class="spaceUnder">
                              <td><strong>Incident Description</strong></td>
                          </tr>
                          <tr>
                              @*
                              <td><textarea id="txtIncDescPopup" class="form-control" rows="4" cols="80" style="height:300px;width:200px"></textarea></td>*@
                              <td><textarea ck-editor name="txtIncDescPopup" class="form-control" rows="4" cols="80" style="height:300px;width:200px"></textarea></td>
                          </tr>
                      </table>
                  </div>
                  <br />
                  <div class="modal-footer">
                      <div class="float-right">
                          <button type="button" class="btn btn-secondary" ng-click="IncDescModalOk();">Ok</button>
                          <button type="button" class="btn btn-primary" ng-click="IncDescModalClose();">Cancel</button>

                      </div>
                  </div>
              </div>

          </div>
      </div>
  </form>-->
  <!--<form name='AlertsModal'>
      <div id="AlertsModalPopup" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="AlertsModalPopupLabel">
          <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title">Alerts/Reports Received (Actions Needed, Who to Contact)</h4>
                      <button id="AlertsClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="ChallengesModalClose();"><span aria-hidden="true">&times;</span></button>

                  </div>
                  <div class="modal-body">
                      <table>
                          <tr class="spaceUnder">
                              <td><strong>Alerts/Reports Received (Actions Needed, Who to Contact)</strong></td>
                          </tr>
                          <tr>
                              <td>
                                  @*<textarea id="txtAlertsRecievedPopup" class="form-control" rows="4" cols="80" style="height:300px;width:200px"></textarea>*@
                                  <textarea ck-editor name="txtAlertsRecievedPopup" class="form-control" rows="4" cols="80" style="height:300px;width:200px"></textarea>
                              </td>

                          </tr>
                      </table>
                  </div>
                  <br />
                  <div class="modal-footer">
                      <div class="float-right">
                          <button type="button" class="btn btn-secondary" ng-click="AlertsModalOk();">Ok</button>
                          <button type="button" class="btn btn-primary" ng-click="AlertsModalClose();">Cancel</button>

                      </div>
                  </div>
              </div>

          </div>
      </div>
  </form>-->
  <!--<form name='ChallengesModal'>
      <div id="ChallengesModalPopup" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ChallengesModalLabel">
          <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title">Any Challenges(P1/P2)</h4>
                      <button id="ChallengesClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="ChallengesModalClose();"><span aria-hidden="true">&times;</span></button>

                  </div>
                  <div class="modal-body">
                      <table>
                          <tr class="spaceUnder">
                              <td><strong>Any Challenges(P1/P2)</strong></td>
                          </tr>
                          <tr>
                              <td>
                                  @*<textarea id="txtChallengesPopup" class="form-control" rows="4" cols="80" style="height:300px;width:200px"></textarea>*@
                                  <textarea ck-editor name="txtChallengesPopup" class="form-control" rows="4" cols="80" style="height:300px;width:200px"></textarea>
                              </td>
                          </tr>
                      </table>
                  </div>
                  <br />
                  <div class="modal-footer">
                      <div class="float-right">
                          <button type="button" class="btn btn-secondary" ng-click="ChallengesModalOk();">Ok</button>
                          <button type="button" class="btn btn-primary" ng-click="ChallengesModalClose();">Cancel</button>

                      </div>
                  </div>
              </div>

          </div>
      </div>
  </form>-->
  <!--<form name='OtherItemsModal'>
      <div id="OtherItemsModalPopup" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="OtherItemsModalLabel">
          <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title">Questions/Clarifications/Other Items</h4>
                      <button id="OtherItemsClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="OtherItemsModalClose();"><span aria-hidden="true">&times;</span></button>

                  </div>
                  <div class="modal-body">
                      <table>
                          <tr class="spaceUnder">
                              <td><strong>Questions/Clarifications/Other Items</strong></td>
                          </tr>
                          <tr>
                              <td>
                                  @*<textarea id="txtOtherItemsPopup" class="form-control" rows="4" cols="80" style="height:300px;width:200px"></textarea>*@
                                  <textarea ck-editor name="txtOtherItemsPopup" class="form-control" rows="4" cols="80" style="height:300px;width:200px"></textarea>
                              </td>
                          </tr>
                      </table>
                  </div>
                  <br />
                  <div class="modal-footer">
                      <div class="float-right">
                          <button type="button" class="btn btn-secondary" ng-click="OtherItemsModalOk();">Ok</button>
                          <button type="button" class="btn btn-primary" ng-click="OtherItemsModalClose();">Cancel</button>

                      </div>
                  </div>
              </div>

          </div>
      </div>
  </form>-->

</div><!--main div closing-->
