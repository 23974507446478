import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { DatePipe } from "@angular/common";
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';

import { HTTP_INTERCEPTORS, HttpClientModule, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel, Configuration } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes } from '@azure/msal-angular';

//import { SearchSOPComponent } from './search-sop/search-sop.component';
import { NavbarComponent } from './navbar/navbar.component';
import { UploadSOPComponent } from './upload-sop/upload-sop.component';
import { UploadSamplesComponent } from './upload-samples/upload-samples.component';
import { MonitoringTrackerComponent } from './monitoring-tracker/monitoring-tracker.component';
import { IssuetrackerComponent } from './issue-tracker/issue-tracker.component';
import { ShiftTurnOverComponent } from './shift-turn-over/shift-turn-over.component';
import { ReportsComponent } from './reports/reports.component';
import { ImportantLinksComponent } from './important-links/important-links.component';
import { ServiceReviewComponent } from './service-review/service-review.component';
import { AccessdeniedComponent } from './accessdenied/accessdenied.component';
import { CriticalErrorComponent } from './critical-error/critical-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchSopComponent } from './search-sop/search-sop.component';
import { SearchfilterPipe } from './searchfilter.pipe';
import { AngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
//import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatFormFieldModule } from '@angular/material/form-field';
//import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
//import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatChipsModule } from '@angular/material/chips';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { MatExpansionModule } from '@angular/material/expansion';
import * as alasql from 'alasql';
import * as $ from 'jquery';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NgxLoadingModule } from "ngx-loading";
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RequestOnboardingComponent } from './request-onboarding/request-onboarding.component';
import { OnboardingRequestApproverComponent } from './onboarding-request-approver/onboarding-request-approver.component';
import { AccessDeniedVpnComponent } from './access-denied-vpn/access-denied-vpn.component';
import { NxtGenServicePulseComponent } from './nxt-gen-service-pulse/nxt-gen-service-pulse.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BnNgIdleService } from 'bn-ng-idle';
import { InfiniteScrollModule } from "ngx-infinite-scroll";

const appRoutes: Routes = [
  { path: 'access-denied', component: AccessdeniedComponent },
];

let url = environment.baseUrl;
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.uiClienId,
      authority: environment.authority,
      redirectUri: environment.redirectUrl,
      postLogoutRedirectUri: ''
    },
    cache: {
      //cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();


  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); //angular_UI
  protectedResourceMap.set(environment.baseUrl, environment.scopeUri)


  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}


export const accessRequest = {
  scopes: []
};

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: accessRequest
  };
}






@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    //SearchSOPComponent,
    NavbarComponent,
    UploadSOPComponent,
    UploadSamplesComponent,
    MonitoringTrackerComponent,
    IssuetrackerComponent,
    ShiftTurnOverComponent,
    ReportsComponent,
    ImportantLinksComponent,
    ServiceReviewComponent,
    SearchfilterPipe,
    SearchSopComponent,
    AccessdeniedComponent,
    CriticalErrorComponent,
    PageNotFoundComponent,
    RequestOnboardingComponent,
    OnboardingRequestApproverComponent,
    AccessDeniedVpnComponent,
    NxtGenServicePulseComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    HttpClientModule,
    NgbModule,
    MsalModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule,
    CKEditorModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatIconModule,
    AutocompleteLibModule,
    MatInputModule,
    MatExpansionModule,
    MatChipsModule,
    MatTabsModule,
    FontAwesomeModule,
    RouterModule.forRoot(appRoutes),
    NgxLoadingModule.forRoot({}),
    //DateTimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatDatepickerModule,
    InfiniteScrollModule,
    RouterModule.forRoot(appRoutes, { useHash: true })

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    BnNgIdleService,
    DatePipe
    //IssuesService,
    // AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
