import { Component, OnInit, ViewChild, Input, Output, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MonitoringInterface } from '../models/monitoring-tracker-model';

import { catchError, delay, map, Observable, startWith, throwError } from 'rxjs';
import { ServiceReviewModel } from '../models/service-review-model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceReviewResponseModel } from '../models/service-review-response-model';
import * as $ from 'jquery';
import { MonitoringTrackerResponseInterface } from '../models/monitoring-tracker-response-model';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
    givenName: string,
    surname: string,
    userPrincipalName: string,
    id: string
}


@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
    baseURL:string = environment.baseUrl;
    alasql = require('alasql');
    serviceRresponse: ServiceReviewResponseModel | undefined;
    MonitoringTrackerResponse: MonitoringTrackerResponseInterface | undefined;

    @ViewChild('Workstream') Workstream!: ElementRef;
    @ViewChild('dtStartDate') dtStartDate!: ElementRef;
    @ViewChild('dtEndDate') dtEndDate!: ElementRef;
    @ViewChild('StartDate') StartDate!: ElementRef;
    @ViewChild('EndDate') EndDate!: ElementRef;


    successNotification() {
        Swal.fire({
            title: 'Success',
            text: 'Saved Successfully',
            icon: 'success',
            confirmButtonText: 'OK'
        })
      }

    //errorNotification(reason:string) {
    //    Swal.fire({
    //                title: 'Error',
    //                text: reason,
    //                icon: 'warning',
    //                confirmButtonText: 'OK'
    //            })
    //}

    selectedAirIds: any[] = [];

    searchForm = {
        Workstream: '', dtStartDate: '', dtEndDate: '', LoggedInUser: ''
    };
    filter = { dtStartDate: '', dtEndDate: '', Status: '', FocusArea: '', LoggedInUser: '' }
    
    //srrexportPreventiveData
    templateworkStream = {
    headers: true,
    columns: [
        { columnid: 'AirId', title: 'AirId' },
        { columnid: 'Name', title: 'Application' },
        { columnid: 'Id', title: 'SR Id' },
        { columnid: 'IssueActionItem', title: 'Issue/Action Item' },
        { columnid: 'DateIdentifiedforReport', title: 'Date Identified (GMT)' },
        // { columnid: 'DueDateforReport', title: 'Due Date (GMT)' },
        // { columnid: 'RaisedBy', title: 'Raised By' },
        { columnid: 'AssignedTo', title: 'Assigned To' },
        { columnid: 'Status', title: 'Status' },
        { columnid: 'LatestUpdate', title: 'Latest Update' },
        { columnid: 'OpenedBy', title: 'Created By' },
        { columnid: 'Workstream', title: 'Workstream' },
        { columnid: 'SubWorkstream', title: 'Sub Workstream' },
        { columnid: 'Category', title: 'Category' },
        { columnid: 'CycleStage', title: 'Cycle Stage' },
        { columnid: 'IncidentTicket', title: 'Incident Ticket' },
        { columnid: 'RelatedKPI', title: 'Related KPI' },
        { columnid: 'RelatedKPIInfo', title: 'Related KPI Info' },
        { columnid: 'OtherWorkstreamCategory', title: 'Other Workstream category' },        
        { columnid: 'CreateDatetimeForReport', title: 'Created DateTime' },
        { columnid: 'LastModifiedBy', title: 'Modified By' },
        { columnid: 'ModifiedDatetimeForReport', title: 'Modified DateTime' },
    ]
     };
    //exportPreventiveData
    preventivetemplate = {
        headers: true,
        columns: [
            { columnid: 'AirId', title: 'AIRID' },
            { columnid: 'Name', title: 'Application Name' },
            { columnid: 'ItemStartDateForReport', title: 'Start Date (GMT)' },
            { columnid: 'RemedyIncident', title: 'Incident Ticket' },
            { columnid: 'IncidentDescription', title: 'Incident Description' },
            { columnid: 'PreventionActivity', title: 'Prevention Activity' },
            { columnid: 'MD', title: 'MD' },
            { columnid: 'ItemEndDateForReport', title: 'End Date (GMT)' },
            { columnid: 'Duration', title: 'Duration' },

            { columnid: 'IssueCategory', title: 'Prevention Category ' },  //edited by Meena :)

            { columnid: 'AlertReceived', title: 'Alert/Episode Received?' },
            { columnid: 'AlertType', title: 'Type of Alert' },
            { columnid: 'IncidentReportedBy', title: 'Incident Reported by?' },
            { columnid: 'ServiceTier', title: 'Service Tier' }
        ]
    };

    profile!: ProfileType;
    public username!: string;
    public givenname!: string;
    SrrErrorMessage: string | undefined;
  errorMessage: string | undefined;
  errorMessage2: string | undefined;
    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {
        this.getProfile();
    }

    public getProfile():string {
        this.http.get<ProfileType>(GRAPH_ENDPOINT)
            .subscribe(profile => {
                this.profile = profile;
                this.username = profile.userPrincipalName.substring(0, profile.userPrincipalName.indexOf("@"));
                this.givenname = profile.givenName;
                this.searchForm.LoggedInUser = this.username;
                this.filter.LoggedInUser = this.username;
            });
        return this.username;
    }

  srrexportPreventiveData() {
    this.errorMessage = '';
    this.errorMessage2 = '';
        $('#srrbtnPreventiveExportExcel').prop('disabled', true);

        this.searchForm.Workstream = this.Workstream?.nativeElement.value;
        this.searchForm.dtStartDate = this.dtStartDate?.nativeElement.value;
        this.searchForm.dtEndDate = this.dtEndDate?.nativeElement.value;

        let status = $('#statusFilter option:selected').text();


        return this.http.post<ServiceReviewModel>(this.baseURL+ 'api/ServiceReview/ExportToExcelSrReport', this.searchForm).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        ).subscribe(response => {
            $('#srrbtnPreventiveExportExcel').prop('disabled', false);
            this.serviceRresponse = response;

            let date = new Date();
            let day = date.getDate();     // Get current date
            let month = date.getMonth() + 1; // current month
            let year = date.getFullYear();
            let CurrentDate = year + "/" + month + "/" + day;
            this.alasql('SELECT * INTO XLS("Service Review Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.templateworkStream, this.serviceRresponse!.Result]);

            if(response.ErrorMessage != ''){
              //this.errorNotification(response.ErrorMessage);
              this.errorMessage = response.ErrorMessage;
            }else if(response.Result == null){
                var message = "No data found";
              // this.errorNotification(message);
              this.errorMessage2 = message;
            }
        });


    }

  exportPreventiveData() {
    this.errorMessage = '';
    this.errorMessage2 = '';
        $('#btnPreventiveExportExcel').prop('disabled', true);
        this.filter.dtStartDate = this.StartDate?.nativeElement.value;
    this.filter.dtEndDate = this.EndDate?.nativeElement.value;
    this.filter.FocusArea = 'Prevent';
    this.filter.Status = 'Completed';
        return this.http.post<MonitoringInterface>(this.baseURL+ 'api/MonitoringTracker/ExportToExcelPreventionReport', this.filter).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        ).subscribe(response => {
            $('#btnPreventiveExportExcel').prop('disabled', false);
            this.MonitoringTrackerResponse = response;
            var date = new Date();
            var day = date.getDate();     // Get current date
            var month = date.getMonth() + 1; // current month
            var year = date.getFullYear();
            var CurrentDate = year + "/" + month + "/" + day;

            this.alasql('SELECT * INTO XLS("Preventive Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.preventivetemplate, this.MonitoringTrackerResponse!.Result]);
            if(response.ErrorMessage != ''){
              // this.errorNotification(response.ErrorMessage);
              this.errorMessage = response.ErrorMessage;
            }else if(response.Result == null){
                var message = "No data found";
              this.errorMessage = message;
            }
        });

    }

    private extractData(res: any) {
        let body = res;
        return body;
    }
    private handleErrorObservable(error: any) {
        console.error(error.message || error);
        return throwError(error);
    }
}
