import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalGuard } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject, throwError } from 'rxjs';
import { catchError, delay, filter, map, takeUntil } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NotificationResponse } from './models/notification-response';
import { NotificationModel } from './models/notification-model';
import { environment } from '../environments/environment';
import { ApplicationModel } from './models/application-model';
import { ApplicationCommonModel } from './models/application-common-model';
import { ApplicationCommonResponseModel } from './models/application-common-response';
import { CommonService } from './services/common.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as $ from 'jquery';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { NotificationService } from './services/notification.service';
import { EventMessage, EventType } from '@azure/msal-browser';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { Router } from '@angular/router';
//import { faFileExcel} from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { UserInfoModel } from './models/user-info-model';
import { AzureProfileDataService } from './services/azure-profile-data.service';
import Swal from 'sweetalert2';
import { BnNgIdleService } from 'bn-ng-idle';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
type ProfileType = {
    givenName: string,
    surname: string,
    userPrincipalName: string,
    id: string
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
    title = 'Global Prevention Center';
    isIframe = false;
    loginDisplay = false;
    profile!: ProfileType;
    public username!: string;
    private readonly _destroying$ = new Subject<void>();
    public static getProfile: any;
    givenName!: string;
    payload: any;
    idToken!: string;
    Count: NotificationModel[] = [];
    Notifications: NotificationModel[] = [];
    NotificationResponse: NotificationResponse | undefined;
    baseUrl: string = environment.baseUrl;
    closeResult: string = '';
    errorMessage: string = "";
    @ViewChild('txtSearch') txtSearch!: ElementRef;
    keyword = 'Name';
    public applications: any[] = [];
    options: ApplicationModel[] = [];
    Applications: ApplicationCommonModel[] = [];
    AppResponse: ApplicationCommonResponseModel | undefined;
    public Editor: any = ClassicEditor;
    //public EditorReference: any;
    selectedAirIds: any[] = [];
    myControl = new FormControl();
    private _router: Router;
    selectedappname: string = '';
    data = { AppId: 0, LoggedInUser: '' };
    dataTodelete = { Id: 0, LoggedInUser: '' };
    user_info = { enterpriseId: '', givenname: '' };
    appNotification: string = "Application Name";
    makeDisable: boolean = false;
    formDisabled: boolean = false;
    isVisible: boolean = true;
    ifOnboardRequester: string = "";
    public CreatedDate: any;
    public CreatedByDisplay: string | undefined;
    public LastModifiedDate: any;
    public LastModifiedBy: string | undefined;
    public formTitle: string = '';
    public userLoggedIn: boolean = false;

    modalTitle: string = "";
    successNotification(reason: string) {
        Swal.fire({
            title: 'Success',
            text: reason,
            icon: 'success',
            confirmButtonText: 'OK'
        })
    }

    errorNotification(reason: string) {
        Swal.fire({
            title: 'Error',
            text: reason,
            icon: 'warning',
            confirmButtonText: 'OK'
        })
    }

    warningNotification(reason: string) {
        Swal.fire({
            title: 'session expired',
            text: reason,
            confirmButtonText: 'OK',

        })
    }


    addNewNotification = new FormGroup({
        Id: new FormControl(), AppId: new FormControl(), ApplicationName: new FormControl(), NotificationDesc: new FormControl(),
        OtherAppName: new FormControl(), Comments: new FormControl(), LoggedInUser: new FormControl(), selectedotherservice: new FormControl()
    });


    _oauthData = { isAuthenticated: false, userName: '', loginError: '', profile: '', jwttoken: '', idToken: {} };
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private http: HttpClient,
        private modalService: NgbModal,
        private common: CommonService,
        private notificationS: NotificationService,
        library: FaIconLibrary,
        _router: Router,
        private bnIdle: BnNgIdleService,
        private get_profile_service: AzureProfileDataService
    ) {
        this.notificationS.SaveNotification(this.addNewNotification.value);

        library.addIconPacks(fas, far);
        this._router = _router;
        this.bnIdle.startWatching(3600).subscribe((res) => {
            if (res) {
                console.log("session expired");
                let doneMessage = "session expired"
                this.warningNotification(doneMessage);
                this.logout();
            }
        })
    }


    AcceptTerms(id: string) {
        var e = document.getElementById(id);
        e!.style.display = (e!.style.display == 'block') ? 'none' : 'block';

        this.isVisible = false;
        localStorage.setItem('terms', 'true');
    }

    async ngOnInit(): Promise<void> {
        if (sessionStorage.getItem('IsShowed') == "1") {
            this.isVisible = false;
        }
        else {
            this.isVisible = true;
        }
        this.isIframe = window !== window.parent && !window.opener;

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            });
        localStorage.getItem('terms') == 'true' ? this.isVisible = false : this.isVisible = true;

    }
    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
        this.getProfile();
    }
    getProfile() {
        this.get_profile_service.getUserProfile().subscribe(profileInfo => {
            this.profile = profileInfo;
            this.username = this.profile.userPrincipalName.substring(0, this.profile.userPrincipalName.indexOf("@"));
            this.data.LoggedInUser = this.username;
            this.addNewNotification.get("LoggedInUser")?.setValue(this.username);
            this.dataTodelete.LoggedInUser = this.username;
            this.user_info.enterpriseId = this.username;
            this.user_info.givenname = this.username;
            //console.log(profileInfo);
            this.checkUserAccess();
            sessionStorage.setItem('LoggedInUser', this.username);
        });
    }


    async checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);


            const request = {
                scopes: ["User.Read"],
                response_type: "code", //change 14_02_2024
                account: activeAccount || accounts[0]
            };

            const authResult = await this.authService.instance.acquireTokenSilent(request);

            //return authResult.accessToken
            this._oauthData.idToken = authResult.idToken;
            var id_token_auth = authResult.idToken;
            sessionStorage.setItem('ddrumsdk.idtoken', id_token_auth);
            //console.log(this._oauthData.idToken);
        }


    }

    loginRedirect() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    loginPopup() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        } else {
            this.authService.loginPopup()
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        }
    }

    //login() {

    //    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    //        if (this.msalGuardConfig.authRequest) {
    //            this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
    //                .subscribe((response: AuthenticationResult) => {
    //                    this.authService.instance.setActiveAccount(response.account);
    //                    this.checkUserAccess();
    //                });
    //        } else {
    //            this.authService.loginPopup()
    //                .subscribe((response: AuthenticationResult) => {
    //                    this.authService.instance.setActiveAccount(response.account);
    //                    this.checkUserAccess();
    //                });
    //        }

    //    } else {
    //        if (this.msalGuardConfig.authRequest) {
    //            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    //        } else {
    //            this.authService.loginRedirect();
    //        }
    //    }
    //}

    logout() {
        sessionStorage.clear();
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.authService.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
            localStorage.setItem('terms', 'false');
        } else {
            this.authService.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
            localStorage.setItem('terms', 'false');
        }
    }


    checkUserAccess() {

        //if ((this._oauthData.idToken != null && this._oauthData.idToken != undefined) && this.profile != undefined) {
        //            console.log("if state 212");
        this.http.post<UserInfoModel>(this.baseUrl + "api/Home/CheckUserAccess", this.user_info).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        ).subscribe({
            next: (response: any) => {
                //               console.log("success"+ response);
                if (response.isUserValid) {
                    this.userLoggedIn = true;
                    //console.log('Sucessfully login -----', response);
                    this.GetNotificationCountbyUser();
                } else if (response.isUserValid == false) {
                    this.ifOnboardRequester = "hidden";
                    this.userLoggedIn = true;
                    this._router.navigateByUrl('/access-denied');
                } else {
                    this.userLoggedIn = true;
                    this._router.navigateByUrl('/access-denied-vpn');
                }
            },
            error: (error: any) => {
                if (error.status == 0 || 401 || 404) {
                    console.log(error);
                    // check error status code is 500, if so, do some action
                    this.userLoggedIn = true;
                    this._router.navigateByUrl('/access-denied-vpn');
                } else if (error.status == 500) {
                    this.userLoggedIn = true;
                    this._router.navigateByUrl('/critical-error');
                }
            }
        });
        // }
        //});

    }




    LoadApplication(Search: string) {
        this.common.FindApplicationName("=" + Search + "=" + this.username)
            .subscribe(response => {
                this.AppResponse = response;
                this.options = this.AppResponse!.Result;
                this.applications = this.options;
                this.applications.map(app => app.Name = `${app.AirId} - ${app.Name}`);
                if (response.ErrorMessage != '') {
                    this.errorNotification(response.ErrorMessage);
                }
            });
    }

    getSelectedAirIds(e: any) {
        //console.log("here airid", e.Id);
        this.selectedAirIds.push(e.Id);

        this.addNewNotification.get("AppId")?.setValue(e.Id);

        this.data.AppId = e.Id;
        return this.selectedAirIds;
    }
    onselectAppName(e: any) {
        this.addNewNotification.get("ApplicationName")?.setValue(e.Name);
    }
    FindNotificationByFilter() {

        return this.http.post<NotificationResponse>(this.baseUrl + "api/Notification/FindNotificationByFilter", this.data)
            .subscribe(response => {

                this.NotificationResponse = response;
                this.Notifications = this.NotificationResponse.Result;

                if (response.ErrorMessage != '') {
                    this.errorNotification(response.ErrorMessage);
                }
            });
    }
    GetNotificationCountbyUser() {
        let LoggedInUser = sessionStorage.getItem('LoggedInUser');
        //console.log("notification", LoggedInUser);
        let data =
        {
            LoggedInUser: LoggedInUser
        };
        return this.http.post<NotificationResponse>(this.baseUrl + "api/Notification/GetNotificationCountbyUser", data)
            .subscribe(response => {

                this.NotificationResponse = response;
                this.Count = this.NotificationResponse.Result;
                if (response.ErrorMessage != '') {
                    this.errorNotification(response.ErrorMessage);
                }
            });
    }
    FetchNotificationSummary(content: any) {

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
        let LoggedInUser = sessionStorage.getItem('LoggedInUser');
        //console.log("notification", LoggedInUser);
        let data =
        {
            LoggedInUser: LoggedInUser
        };
        return this.http.post<NotificationResponse>(this.baseUrl + "/api/Notification/FetchNotificationSummary", data)
            .subscribe(response => {

                this.NotificationResponse = response;
                this.Notifications = this.NotificationResponse.Result;
                if (response.ErrorMessage != '') {
                    this.errorNotification(response.ErrorMessage);
                }
            });
    }
    AddNew(content: any) {
        this.makeDisable = false;
        this.appNotification = '';
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    changeNotificationUpdates({ editor }: ChangeEvent) {
        //console.log("NotificationUpdates");
        var NotificationUpdates = $.trim(this.Editor['instances']['NotificationDesc'].getData());

        //console.log("NotificationUpdates", NotificationUpdates);
        this.addNewNotification.get('NotificationDesc')?.setValue(NotificationUpdates);
    }
    changeCommentUpdates({ editor }: ChangeEvent) {
        var comment = $.trim(this.Editor['instances']['Comments'].getData());
        this.addNewNotification.get('Comments')?.setValue(comment);
    }

    SaveData() {
        this.addNewNotification.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));
        this.notificationS.SaveNotification(this.addNewNotification.value)
            .subscribe(response => {
                if (response.ErrorMessage != '') {
                    this.errorNotification(response.ErrorMessage);
                } else {
                    var message = "Saved Successfully";
                    this.successNotification(message);
                    this.fetchData();
                    this.addNewNotification.reset();
                    this.appNotification = '';
                    this.makeDisable = false;
                    this.GetNotificationCountbyUser();
                    this.dataReset();
                }

            });

    }
    dataReset() {
        this.addNewNotification.reset();
        this.CreatedByDisplay = '';
        this.CreatedDate = '';
        this.LastModifiedBy = '';
        this.LastModifiedDate = '';
    }
    fetchData() {
        let LoggedInUser = sessionStorage.getItem('LoggedInUser');
        let data =
        {
            LoggedInUser: LoggedInUser
        };
        return this.http.post<NotificationResponse>(this.baseUrl + "/api/Notification/FetchNotificationSummary", data)
            .subscribe(response => {

                this.NotificationResponse = response;
                this.Notifications = this.NotificationResponse.Result;

            });
    }
    OpenEditForm(content: any, notification: NotificationModel) {
        var data = { Id: notification.Id, LoggedInUser: sessionStorage.getItem('LoggedInUser') };

        this.notificationS.FetchNotificationById(data)
            .subscribe(response => {
                if (response.ErrorMessage != '') {
                    this.errorNotification(response.ErrorMessage);
                } else {
                    $('#txtAppServiceOther').prop('disabled', true);
                    this.addNewNotification.get("Id")?.setValue(notification.Id);
                    this.appNotification = notification.ApplicationName;
                    this.makeDisable = true;
                    this.addNewNotification.patchValue({
                        Id: notification.Id, AppId: response.Result[0].AppId, ApplicationName: notification.ApplicationName,
                        NotificationDesc: response.Result[0].NotificationDesc, Comments: response.Result[0].Comments,
                        OtherAppName: response.Result[0].OtherAppName
                    });
                    this.CreatedByDisplay = response.Result[0].CreatedBy.EnterpriseId;
                    this.CreatedDate = response.Result[0].CreateDateTime;
                    this.LastModifiedBy = response.Result[0].ModifiedBy.EnterpriseId;
                    this.LastModifiedDate = response.Result[0].ModifiedDateTime;
                    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                        this.closeResult = `Closed with: ${result}`;
                    }, (reason) => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                    });
                }

            });
    }
    DeleteRecord(notification: NotificationModel) {
        this.dataTodelete.Id = notification.Id;

        return this.http.post<NotificationModel>(this.baseUrl + '/api/Notification/DeleteRecord', this.dataTodelete).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        ).subscribe(response => {
            let LoggedInUser = sessionStorage.getItem('LoggedInUser');
            //console.log("notification", LoggedInUser);
            let data =
            {
                LoggedInUser: LoggedInUser
            };
            return this.http.post<NotificationResponse>(this.baseUrl + "/api/Notification/FetchNotificationSummary", data)
                .subscribe(response => {

                    this.NotificationResponse = response;
                    this.Notifications = this.NotificationResponse.Result;
                    if (response.ErrorMessage != '') {
                        this.errorNotification(response.ErrorMessage);
                    } else {
                        var message = "Deleted Successfully";
                        this.successNotification(message);
                    }
                });
        });
    }
    SaveReadStatusbyUser(notificationId: number) {
        var data =
        {
            Id: notificationId,
            LoggedInUser: sessionStorage.getItem('LoggedInUser')
        };
        return this.http.post<NotificationModel>(this.baseUrl + '/api/Notification/SaveReadStatusbyUser', data).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        ).subscribe(response => {
            this.fetchData();
        });

    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    private extractData(res: any) {
        let body = res;
        return body;
    }
    private handleErrorObservable(error: any) {
        console.error(error.message || error);
        return throwError(error);
    }
}
