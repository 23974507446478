<div class="main">
    <div>
        <h1 class="pb-2 mt-4 mb-2 border-bottom">
            Shift Turn Over <!--&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
            <!--@*<button type="button" ng-click="ShiftTrunOverModal();" class="btn btn-primary"><span class="glyphicon glyphicon-plus"></span>Add New</button>*@-->
        </h1>
    </div>

    <br />

    <!--<div id="dvalert" class="alert alert-danger" ng-show="false">-->
        <!--{{errorMessage}}-->

    <!--</div>-->
    <form class="flex-row form-inline" [formGroup]="hiddenform" style="display:none;">
        <div class="row form-group">
            <div class="row form-group">
                <input type="hidden" id="loggeduser" class="form-control" name="LoggedInUser" formControlName="LoggedInUser" #LoggedInUser />
            </div>
        </div>
    </form>
    <div id="divSuccess" class="row" ng-show="show" ng-controller="ShiftTurnOverController">
        
        <div class="card-group" style="width:100%" id="accordion" >

            <div class="card" style="border:none">
                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false" *ngFor="let sto of ShiftTurnOverModel"style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;">
                        <mat-expansion-panel-header (click)="FetchTurnOverDetail(sto);">
                            <mat-panel-title>
                                <h4 class="card-title">
                                    <strong>{{sto.TurnOverDate | date :  "fullDate"}}</strong>
                                </h4>
                            </mat-panel-title>           
                        </mat-expansion-panel-header>
                        <div class="shiftTurntableWrap">
                                <table class="table table-bordered shiftTurnTable" id="collapse{{sto.Id}}">
                                    <thead>
                                        <tr style="background-color: #f5f5f5;">
                                            <th>Current Health Status</th>
                                            <th>Current Regional Service Operation Manager</th>
                                            <th>Current Shift Region</th>
                                            <th>Shift turnover attendees</th>
                                            <th>Issue during this period</th>
                                            <th>Critical open issues(Red)</th>
                                            <th>Monitoring/Service degraded(Yellow)</th>
                                            <th>Closed Issues(Green)</th>
                                            <th>Preventive Actions(Blue)</th>
                                            <th>Item Status</th>
                                        </tr>
                                    </thead>
                                    <tbody id="divbody">
                                        <tr *ngFor="let shift of ShiftTurnOverModelDetails">
                                            <td>{{shift.CurrentHealthStatus}}</td>
                                            <td>{{shift.CurrentRegionalManagerName}}</td>
                                            <td>{{shift.CurrentShiftRegion}}</td>
                                            <td>{{shift.ShiftTurnOverAttendee}}</td>
                                            <td>{{shift.NewIssueRecorded}}</td>
                                            <td>{{shift.SelectedOpenIssuesNames}}</td>
                                            <td>{{shift.SelectedDegradedIssueNames}}</td>
                                            <td>{{shift.SelectedClosedIssueNames}}</td>
                                            <td>{{shift.SelectedPreventiveIssueNames}}</td>
                                            <td>{{shift.ItemStatus}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>

    <!--<modaldirective modal-body='body' modal-footer='footer' modal-header='header'></modaldirective>


    <confmodaldirective modal-body='body' modal-footer='footer' modal-header='header'></confmodaldirective>

    <previewmodal modal-body='body' modal-footer='footer' modal-header='header'></previewmodal>-->



    <input type="hidden" id="hdnShiftId" />
</div>
