import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, Input, Output, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, delay, map, Observable, startWith, throwError } from 'rxjs';
import { ApplicationModel } from '../models/application-model';
import { ApplicationCommonModel } from '../models/application-common-model';
import { CommonService } from '../services/common.service';
import { ApplicationCommonResponseModel } from '../models/application-common-response';
import { ServiceReviewServiceService } from '../services/service-review-service.service';
import { ServiceReviewResponseModel } from '../models/service-review-response-model';
import { ServiceReviewModel } from '../models/service-review-model';
import { ProductionProcessModel } from '../models/production-process-model';
import { ProductionProcessResponse } from '../models/production-process-response-model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AnnouncementModel } from '../models/announcement-model';
import { AnnouncementResponseModel } from '../models/announcement-response-model';
import { ActivityDetailModel } from '../models/activity-detail-model';
import { ActivityDetailResponseModel } from '../models/activity-detail-response-model';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { PreviewAttachmentModel } from '../models/preview-attachment-response-model';
import Swal from 'sweetalert2';
import { UserPeopleResponse } from '../models/user-people-response';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as alasql from 'alasql';
import * as $ from 'jquery';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { RootObject } from '../models/root-object';
import { PeopleModel } from '../models/people-model';
//import { MatCarousel, MatCarouselComponent, MatCarouselSlide, MatCarouselSlideComponent } from 'ng-mat-carousel';
//import { MatCarouselSlide, MatCarouselSlideComponent } from '@ngmodule/material-carousel';
//declare var $: any;
import { environment } from '../../environments/environment';
import { UserResponseModel } from '../models/user-model-response';
import { ApplicationUserModel } from '../models/application-user-model';
import { ThemePalette } from '@angular/material/core';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName: string,
  surname: string,
  userPrincipalName: string,
  id: string
}


@Component({
  selector: 'app-service-review',
  templateUrl: './service-review.component.html',
  styleUrls: ['./service-review.component.css'],
  providers: [NgbCarouselConfig]
})
export class ServiceReviewComponent implements OnInit {
  //for ckeditor
  public Editor: any = ClassicEditor;
  baseURL: string = environment.baseUrl;
  public EditorReference: any;
  //for alasql excel exportS
  alasql = require('alasql')
  errorMessage: string = "";
  errorMessage2: string = "";
  errorMessageAnnouncement: string = "";
  error_announcement: boolean = true;
  Srstatus: any[] = [];
  Ststatus: any[] = [];
  Onbstatus: any[] = [];
  workstreamSR: any[] = [];
  subWorkstreamSR: any[] = [];
  cycleStageSR: any[] = [];
  categorySR: any[] = [];
  relatedKPISR: any[] = [];
  files?: FileList;
  selectedAirIds: any[] = [];
  filename!: string;
  sendImagesFile: any[] = [];
  sendImagesDB: string[] = [];
  SelectedFiles: any[] = [];
  public ImageAttachmentPath: any[] = [];
  //options: ApplicationCommonModel[] = [];
  applicationNameAddForm: any = [];
  Applications: ApplicationCommonModel[] = [];
  public applications: any[] = [];
  public chilApplications: any[] = [];
  public chilApplicationsForPatchActivity: any[] = [];
  appIdForEdit: any;
  AppResponse: ApplicationCommonResponseModel | undefined;
  Activity: ActivityDetailModel[] = [];
  activityDetails: ActivityDetailModel[] = [];
  activityResponse: ActivityDetailResponseModel | undefined;
  // IssuesPPS: ServiceReviewModel[] = [];
  IssuesPPS: ProductionProcessModel[] = [];
  PPSPreviewDetail: ProductionProcessModel[] = [];
  PPSIncidentDesc: ProductionProcessModel[] = [];
  ppsResponse: ProductionProcessResponse | undefined;
  Issues: ServiceReviewModel[] = [];
  IssuesPanel: ServiceReviewModel[] = [];
  serviceRmodel: ServiceReviewModel[] = [];
  mail: ServiceReviewModel[] = [];
  exportXLS: ServiceReviewModel[] = [];
  ServiceReviewPrivewDetail: ServiceReviewModel[] = [];
  ServiceReviewPrivew: ServiceReviewModel[] = [];
  ClusterParent: ServiceReviewModel[] = [];
  serviceRresponse: ServiceReviewResponseModel | undefined;
  Announcements: AnnouncementModel[] = [];
  announcementResponse: AnnouncementResponseModel | undefined;
  productionProcess: ProductionProcessModel[] = [];
  productionResponse: ProductionProcessResponse | undefined;
  previewAttachmentResponse: PreviewAttachmentModel | undefined;
  ppsEmailTo: any = [];
  ppsEmailCC: any = [];
  public serviceReviewIdsToBeSend: any = [];
  noAnnouncement = '';
  public navbarCollapsed = true;
  public isOpen: boolean | undefined;
  profile!: ProfileType;
  public username!: string;
  keyword = 'Name';
  public SearchPeople: any[] = [];
  public panelOpenState: boolean = false;
  keywordP = 'EnterpriseId';
  keywordPCluster = 'Name';
  UserResponse: UserResponseModel | undefined;
  UserPeopleResponse: UserPeopleResponse | undefined;
  RootOBJ: any;
  RootObjV: RootObject | undefined;
  lotOfPeople: PeopleModel[] = [];
  txtValue: string = '';
  applicationName: string = '';
  childcount: any;
  showPopover: boolean = true;
  hoverIndex: any;
  //public LoggedInUserID :string = 'username';
  @ViewChild('LoggedInUser') LoggedInUser!: ElementRef;
  @ViewChild('Id') Id!: ElementRef;
  @ViewChild('applicationFilterSR') applicationFilterSR!: ElementRef;
  @ViewChild('dtStartDate') dtStartDate!: ElementRef;
  @ViewChild('dtEndDate') dtEndDate!: ElementRef;
  @ViewChild('ServiceReviewIds') ServiceReviewIds!: ElementRef;
  @ViewChild('txtSearch') txtSearch!: ElementRef;
  @ViewChild('file') file!: ElementRef;
  @ViewChild('picker') picker: any;
  @ViewChild('dueDate') dueDate: any;
  @ViewChild('annEnd') annEnd: any;
  divActivityApplication: boolean = false;
  divActivityClusteredApp: boolean = false;
  @Input()
  selectedDateTime!: Date;
  @Input()
  selectedDateTimedue!: Date;
  selectedDateTimeAnnStart!: Date;
  selectedDateTimeAnnEnd!: Date;
  minDate: Date = new Date(2010, 0, 1); // ASA Business Bypass Logic
  public count!: number;
  public ControlDateIdentified = new FormControl();
  public ControlDueDate = new FormControl();
  public ControldtAnnStartDate = new FormControl();
  public ControldtAnnEndDate = new FormControl();
  public childAppName = new FormControl();
  public unchanged: boolean = true;
  public clusterChildBlock: boolean = true;
  public parentAppId: number | null = 0;
  public parentApplicationName: string | null = '';
  public hdnPPSId: number = 0;
  public appId: number = 0;
  public isUserAdmin: boolean = false;
  public FetchClusteredChildAppsForPatchActivityData: number = 0;
  public formTitle: string = '';
  //date picker
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = true;
  public stepMinutes = [0o0, 15, 30, 45];
  public stepHour = 1;
  public stepMinute = 15;
  public stepSecond = 1;
  public defaultTime = [new Date().getHours(), 0, 0]
  public color: ThemePalette = 'primary';
  searchForm = new FormGroup({
    Id: new FormControl(), ApplicationId: new FormControl(0), dtStartDate: new FormControl(), dtEndDate: new FormControl(),
    Status: new FormControl('Active'),ServiceTier: new FormControl(),OnboardingStatus: new FormControl(), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser')), ApplicationIds: new FormControl()
  });
  addForm = new FormGroup({
    Id: new FormControl(), ApplicationId: new FormControl(), Name: new FormControl(), IssueActionItem: new FormControl(), DateIdentified: new FormControl(),
    DueDate: new FormControl(), RaisedBy: new FormControl(), AssignedTo: new FormControl(), Status: new FormControl(),
    LatestUpdate: new FormControl(''), CreatedBy: new FormControl(), ImagesPath: new FormControl(this.sendImagesDB), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser')),
    Workstream: new FormControl(), SubWorkstream: new FormControl(), Category: new FormControl(), OtherCategory: new FormControl(), CycleStage: new FormControl(), IncidentTicket: new FormControl(), RelatedKPI: new FormControl(), RelatedKPIInfo: new FormControl(), OtherWorkstreamCategory: new FormControl(),
    ClusterApplication: new FormControl()
  });
  dataPatch = { PPSId: 0, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
  SubmitPatchActivityForm = new FormGroup({
    Id: new FormControl(), PPSId: new FormControl(), ApplicationId: new FormControl(), MTId: new FormControl(0), CommentId: new FormControl(0),
    Comments: new FormControl(), IncidentDescription: new FormControl(), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser')),
    Name: new FormControl(), ModifiedBy: new FormControl()
  });
  SaveDataAnnouncementForm = new FormGroup({
    Id: new FormControl(0), Description: new FormControl(), AnnStartDate: new FormControl(),
    AnnEndDate: new FormControl(), CreatedBy: new FormControl(), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser'))
  });
  loadIssueData = {
    ApplicationId: 0, LoggedInUser: sessionStorage.getItem('LoggedInUser'),
    dtStartDate: '', dtEndDate: '', Status: '', Id: 0
  };
  dataSRItem = {
    ApplicationId: 0, ServiceReviewIds: '', LoggedInUser: sessionStorage.getItem('LoggedInUser')
  };
  dataApp = { ApplicationId: 0, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
  dataPPSItem = { Id: 0, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
  dataSendmail = {
    ApplicationId: 0, Id: 0,
    ServiceReviewIds: '', LoggedInUser: sessionStorage.getItem('LoggedInUser')
  };
  delAnnouncement = { Id: 0, IsActivated: false, LoggedInUser: sessionStorage.getItem('LoggedInUser') };

  deactiveActivity = { Id: 0, MTId: '', IsActivated: false, LoggedInUser: sessionStorage.getItem('LoggedInUser') };

  ExcelPPSTemplate = {
    headers: true,
    columns:
      [
        { columnid: 'Name', title: 'Application Name / Clustered Application Name' },
        { columnid: 'OpenRisks', title: 'Open Risks' },
        { columnid: 'AlertsReceived', title: 'Alerts / Reports Received(Actions needed, Who to Contact)' },
        { columnid: 'Challenges', title: 'Any Challenges(P1 / P2)' },
        { columnid: 'OtherItems', title: 'Questions / Clarifications / Other Items' },
      ]
  };
  //Export to excel template
  template = {
    headers: true,
    columns: [
      { columnid: 'AirId', title: 'AIR ID' },
      { columnid: 'Name', title: 'Application' },
      { columnid: 'Id', title: 'SR ID' },
      { columnid: 'IssueActionItem', title: 'Issue/Action Item' },
      { columnid: 'DateIdentifiedforReport', title: 'Date Identified (GMT)' },
      { columnid: 'DueDateforReport', title: 'Due Date (GMT)' },
      { columnid: 'RaisedBy', title: 'Raised By' },
      { columnid: 'AssignedTo', title: 'Assigned To' },
      { columnid: 'Status', title: 'Status' },
      { columnid: 'LatestUpdate', title: 'Latest Updates' },
      { columnid: 'OpenedBy', title: 'Created By' },
      { columnid: 'Workstream', title: 'Workstream' },
      { columnid: 'SubWorkstream', title: 'Sub WorkStream' },
      { columnid: 'Category', title: 'Category' },
      { columnid: 'CycleStage', title: 'Cycle Stage' },
      { columnid: 'IncidentTicket', title: 'Incident Ticket' },
      { columnid: 'RelatedKPI', title: 'Related KPI' },
      { columnid: 'RelatedKPIInfo', title: 'Related KPI Info' },
      { columnid: 'OtherWorkstreamCategory', title: 'Other Workstream category' },
      { columnid: 'CreateDatetimeForReport', title: 'Created DateTime' },
      { columnid: 'LastModifiedBy', title: 'Modified By' },
      { columnid: 'ModifiedDatetimeForReport', title: 'Modified DateTime' },
    ]
  };

  addPPSForm = new FormGroup({
    Id: new FormControl(), ApplicationId: new FormControl(), OpenRisks: new FormControl(''), AlertsReceived: new FormControl(''),
    Challenges: new FormControl(''), OtherItems: new FormControl(''), SendEmailTo: new FormControl(), SendEmailCC: new FormControl(), CreatedBy: new FormControl(), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser'))
  });
  closeResult: string = '';
  EmailToPreview = '';
  EmailCCPreview = '';
  myControl = new FormControl();
  options: ApplicationModel[] = [];
  enterpriseId = sessionStorage.getItem('LoggedInUser');

  fileToUpload: any;
  imageUrl: any;
  SRIds: string = '';
  SRIdsToSend: any = [];
  public recordCount: number = 0;
  IsLoaded: boolean = true;
  successNotification(reason: string) {
    Swal.fire({
      title: 'Success',
      text: reason,
      icon: 'success',
      confirmButtonText: 'OK'
    });
  }

  //errorNotification(reason: string) {
  //  Swal.fire({
  //    title: 'Error',
  //    text: reason,
  //    icon: 'warning',
  //    confirmButtonText: 'OK'
  //  })
  //}
  public loading = false;
  //for edit form
  public CreatedDate: any;
  public CreatedByDisplay: string | undefined;
  public LastModifiedDate: any;
  public LastModifiedBy: string | undefined;

  constructor(private http: HttpClient, private common: CommonService, private reviewservice: ServiceReviewServiceService, private modalService: NgbModal, private formBuilder: FormBuilder, config: NgbCarouselConfig) {
    this.Srstatus = this.getStatus();
    this.Ststatus = this.getServiceTier();
    this.Onbstatus = this.getOnboardingStatus();
    this.workstreamSR = this.getworkstreamSR();
    this.subWorkstreamSR = this.LoadSubWorkStreamData()
    this.cycleStageSR = this.getcycleStageSR();
    this.relatedKPISR = this.getrelatedKPISR();
    //this.reviewservice.FilterServiceReview(this.searchForm.value);
    // this.reviewservice.loadIssue = this.loadIssueData;
    this.reviewservice.LoadPPS(this.loadIssueData);
    this.reviewservice.FetchServiceReviewDetails(this.loadIssueData);
    this.reviewservice.addServiceReview(this.addForm.value);
    this.reviewservice.addPatchActivity(this.SubmitPatchActivityForm.value);
    //this.reviewservice.SaveData_announcement(this.SaveDataAnnouncementForm.value);
    this.reviewservice.SaveUpdate_pps(this.addPPSForm.value);

    config.interval = 2000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;
  }



  async ngOnInit(): Promise<void> {
    function delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    this.getProfile();
    await delay(2000);
    this.FilterServiceReview();
  }

  getSelectedAirIds(e: any) {
    this.selectedAirIds.push(e.Id);
    var tempIds = this.selectedAirIds.join(',');
    this.searchForm.get("ApplicationIds")?.setValue(tempIds);
    this.addForm.get("ApplicationId")?.setValue(e.Id);

    return this.selectedAirIds;
  }

  GetClusterChildCount(parentAppId: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    var data = { Id: parentAppId, LoggedInUser: sessionStorage.getItem('LoggedInUser') };

    return this.http.post<ServiceReviewModel>(this.baseURL + "api/ServiceReview/GetClusterChildCount", data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {

      if (response.Result == null) {
        this.serviceRresponse = response;
        this.childcount = response.Result;
        //$("#clusterAppSR").val("");
        //$("#clusterAppSR").prop("disabled", true);
        //if (count > 0) {
        //  $("#clusterAppSR").prop("disabled", false);
        //}
      }
    });

  }

  getSelectedAirIdsClusterd(e: any) {

    this.errorMessage = '';
    this.errorMessage2 = '';
    //this.addForm.get("ApplicationId")?.setValue(e.Id);
    //return this.selectedAirIds;
  }
  selectEventAddClusterApp(e: any) {
    this.addForm.get("ApplicationId")?.setValue(e.Id);
    this.addForm.get("Name")?.setValue(e.Name);

  }
  selectEventSearch(e: any) {
    var dataS = e.Name;
    if (this.applicationNameAddForm.indexOf(e.Name) === -1) {
      this.applicationNameAddForm.push(e.Name)
      var tempApps = this.applicationNameAddForm.join(';');
    }
    else {
      // this.errorNotification("Application Name already exists");
      var message = "Application Name already exists";
      this.errorMessage2 = message;
    }

  }
  onTextChange(value: any) {
    this.txtValue = value;
    if (this.txtValue == '') {
      //this.message="Textbox is empty !!!";
      this.addForm.get('DateIdentified')?.setValue('');
      this.addForm.get('DueDate')?.setValue('');

    }

  }
  //to remove the selected app name from the array : applicationName
  toggleSelectedAirIds(app: any): void {
    this.errorMessage = '';
    this.errorMessage2 = '';
    const index = this.applicationNameAddForm.indexOf(app);
    // const indexS = this.selectedAirIds.indexOf(app);

    if (index >= 0) {
      this.applicationNameAddForm.splice(index, 1);
      this.selectedAirIds.splice(index, 1);

    }
    var tempIds = this.selectedAirIds.join(',');
    this.searchForm.get("ApplicationIds")?.setValue(tempIds);
    if (this.applicationNameAddForm == '') {
      this.searchForm.get("ApplicationIds")?.setValue(null);
      this.FilterServiceReview();
    }
  }

  selectPeopleEventForRaisedBy(raisedBy: any) {
    this.addForm.get("RaisedBy")?.setValue(raisedBy.EnterpriseId);
  }
  selectPeopleEventForAssignedTo(assignedTo: any) {
    this.addForm.get("AssignedTo")?.setValue(assignedTo.EnterpriseId);
  }
  enter(i: any) {
    this.hoverIndex = i;
  }

  leave(i: any) {
    this.hoverIndex = null;
  }

  LoadPeopleByName(Search: string) {
    this.loading = true;
    this.common.FetchPeopleByName("=" + Search + "=" + this.username)
      .subscribe(response => {
        this.loading = false;
        this.UserPeopleResponse = response;
        this.RootOBJ = this.UserPeopleResponse!.Result;
        this.lotOfPeople = this.RootOBJ!.Value;
        this.lotOfPeople.map(people => people.Name = `${people.EnterpriseId} -- ${people.StandardJobDescription}`);
      });
    //return this.options;
  }
  LoadPeopleForEmailTo(Search: string) {
    this.loading = true;
    this.common.FetchPeopleByName("=" + Search + "=" + this.username)
      .subscribe(response => {
        this.loading = false;
        this.UserPeopleResponse = response;
        this.RootOBJ = this.UserPeopleResponse!.Result;
        this.lotOfPeople = this.RootOBJ!.Value;
        this.SearchPeople = this.lotOfPeople;
        // Added custom input to suggestions if not found
      if (this.SearchPeople.length === 0 && Search.trim() !== '') {
        this.SearchPeople.push({ EnterpriseId: Search });
      }
      });
  }
  // selectPeopleEmailTo(p: any) {
  //   var message = "Email ID already exists";
  //   let emailToAdd = typeof p === 'string' ? p : p.EnterpriseId;
  //   this.ppsEmailTo.indexOf(p.EnterpriseId) === -1 ? this.ppsEmailTo.push(p.EnterpriseId) : this.errorMessage = message;
  //   var tempApps = this.ppsEmailTo.join(';');
  //   this.addPPSForm.get("SendEmailTo")?.setValue(tempApps);
  // }
  selectPeopleEmailTo(p: any) {
    var message = "Email ID already exists";
    let emailToAdd = typeof p === 'string' ? p : p.EnterpriseId;
    
    if (this.ppsEmailTo.indexOf(emailToAdd) === -1) {
      this.ppsEmailTo.push(emailToAdd);
      var tempApps = this.ppsEmailTo.join(';');
      this.addPPSForm.get("SendEmailTo")?.setValue(tempApps);
    } else {
      this.errorMessage = message;
    }
  }
  onEmailToInputChanged(event: string) {
    this.LoadPeopleForEmailTo(event);
    
    // If the input is not empty and Enter key is pressed, add the custom input
    if (event && event.trim() !== '' && event.endsWith('\n')) {
      this.selectPeopleEmailTo(event.trim());
    }
  }
  //to remove the selected app name from the array : ppsEmailTo
  removeTo(app: any): void {
    const index = this.ppsEmailTo.indexOf(app);

    if (index >= 0) {
      this.ppsEmailTo.splice(index, 1);
      var tempApps = this.ppsEmailTo.join(';');
      this.addPPSForm.get("SendEmailTo")?.setValue(tempApps);
    }
  }
  LoadPeopleForEmailCC(Search: string) {
    this.loading = true;
    this.common.FetchPeopleByName("=" + Search + "=" + this.username)
      .subscribe(response => {
        this.loading = false;
        this.UserPeopleResponse = response;
        this.RootOBJ = this.UserPeopleResponse!.Result;
        this.lotOfPeople = this.RootOBJ!.Value;
        this.SearchPeople = this.lotOfPeople;
      });
  }
  selectPeopleEmailCC(p: any) {
    var message = "Email ID already exists";
    this.ppsEmailCC.indexOf(p.EnterpriseId) === -1 ? this.ppsEmailCC.push(p.EnterpriseId) : this.errorMessage = message;
    var tempApps = this.ppsEmailCC.join(';');
    this.addPPSForm.get("SendEmailCC")?.setValue(tempApps);
  }
  //to remove the selected app name from the array : ppsEmailCC
  removeCC(app: any): void {
    const index = this.ppsEmailCC.indexOf(app);
    if (index >= 0) {
      this.ppsEmailCC.splice(index, 1);
      var tempApps = this.ppsEmailCC.join(';');
      this.addPPSForm.get("SendEmailCC")?.setValue(tempApps);
    }
  }
  FilterServiceReview() {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.loading = true;

    if (this.searchForm.value.Id == null) {
      //this.searchForm.get('Id')?.setValue(-1);
    }

    //searchForm = new FormGroup({
    //  Id: new FormControl(), ApplicationId: new FormControl(0), dtStartDate: new FormControl(), dtEndDate: new FormControl(),
    //  Status: new FormControl('Active'), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser')), ApplicationIds: new FormControl()
    //});

    let filter = {
      ApplicationIds: this.searchForm.value.ApplicationIds, dtStartDate: this.searchForm.value.dtStartDate, dtEndDate: this.searchForm.value.dtEndDate,
      Status: this.searchForm.value.Status,ServiceTier: this.searchForm.value.ServiceTier,OnboardingStatus: this.searchForm.value.OnboardingStatus, LoggedInUser: sessionStorage.getItem('LoggedInUser'), Id: (this.searchForm.value.Id == null) || (this.searchForm.value.Id == '') ? -1 : this.searchForm.value.Id
    };
    if (this.searchForm.value.Status == '') {
      //this.searchForm.get('Status')?.setValue('');
      filter.Status = '';
    } else {
      var status = $('#statusFilter option:selected').text();
      //this.searchForm.get('Status')?.setValue(status);
      filter.Status = status;

    }
    this.reviewservice.FilterServiceReview(filter)
      .subscribe(response => {
        this.loading = false;
        this.serviceRresponse = response;
        this.serviceRmodel = this.serviceRresponse.Result;
        if (response.ErrorMessage != '') {

          // this.errorNotification(response.ErrorMessage);
          this.errorMessage = response.ErrorMessage;
        } else if (response.Result == null) {
          var message = "No Data found for the selected search filters";
          // this.errorNotification(message);
          this.errorMessage = message;
        }

      });


  }

  public getProfile(): string {
    this.http.get<ProfileType>(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
        this.username = profile.userPrincipalName.substring(0, profile.userPrincipalName.indexOf("@"));
        //this.searchForm.get("LoggedInUser")?.setValue(this.username);
        //this.searchForm.get("Id")?.setValue(-1);
        this.addForm.get("Name")?.setValue('null');
        // this.addForm.get("LoggedInUser")?.setValue(this.username);
        this.addForm.get("CreatedBy")?.setValue(sessionStorage.getItem('LoggedInUser'));
        //this.SubmitPatchActivityForm.get("LoggedInUser")?.setValue(this.username);


        this.loadIssueData.LoggedInUser = sessionStorage.getItem('LoggedInUser');
        this.dataPPSItem.LoggedInUser = sessionStorage.getItem('LoggedInUser');
        //this.addPPSForm.get("LoggedInUser")?.setValue(this.username);
        //this.addPPSForm.get("CreatedBy")?.setValue(sessionStorage.getItem('LoggedInUser'));

        this.dataSendmail.LoggedInUser = sessionStorage.getItem('LoggedInUser');
        this.dataPatch.LoggedInUser = sessionStorage.getItem('LoggedInUser');
        this.SaveDataAnnouncementForm.get("LoggedInUser")?.setValue(sessionStorage.getItem('LoggedInUser'));
        this.delAnnouncement.LoggedInUser = sessionStorage.getItem('LoggedInUser');
        this.enterpriseId = sessionStorage.getItem('LoggedInUser');
        this.deactiveActivity.LoggedInUser = sessionStorage.getItem('LoggedInUser');
      });

    return this.username;
  }




  getStatus() {
    return [{ statusId: "1", value: "Not Started" },
    { statusId: "2", value: "On Track" },
    { statusId: "3", value: "Completed" },
    { statusId: "4", value: "Cancelled" },
    { statusId: "5", value: "Active" }];
  }

  getServiceTier() {
    return [{ statusId: "1", value: "AO" },
    { statusId: "2", value: "NAO" }];
  }

  getOnboardingStatus() {
    return [{ statusId: "1", value: "Fully Onboarded" },
    { statusId: "2", value: "Service Management" }];
  }
// Code commented out as per DSR048 Requirement 5
  // getworkstreamSR() {
  //   return [{ workstreamId: "1", value: "Regular Items" },
  //   { workstreamId: "2", value: "Data Analysis Group" },
  //   { workstreamId: "3", value: "Rehearsals" },
  //   { workstreamId: "4", value: "Alert Review Initiative" },
  //   { workstreamId: "5", value: "SOP-Related" }];
  // }
// Added as per DSR048 Requirement 5
  getworkstreamSR() {
    return [
      { workstreamId: "1", value: "Incident Related" },
      { workstreamId: "2", value: "Problem Management" },
      { workstreamId: "3", value: "Retrospective Incident Analysis" },
      { workstreamId: "4", value: "Data Analysis" },
      { workstreamId: "5", value: "Rehearsals" },
      { workstreamId: "6", value: "Mock Drills" },
      { workstreamId: "7", value: "Playbook Audit" },
      { workstreamId: "8", value: "SOP Audit" },
      { workstreamId: "9", value: "SOP Change Related" },
      { workstreamId: "10", value: "Others" }
    ];
  }
  
  LoadSubWorkStreamData() {
    return [
      { subWorkstreamId: "1", value: "Preventions" },
      { subWorkstreamId: "2", value: "P1/P2" }];
  }
  LoadSelectedCategoryCycle() {
    if (this.addForm.value.SubWorkstream == "P1/P2") {

      this.categorySR = this.LoadLoadCategoryCycleStageDataP1P2();

    }
    else if (this.addForm.value.SubWorkstream == "Preventions") {
      this.categorySR = this.LoadCategoryCycleStageData();
    }
  }
  LoadLoadCategoryCycleStageDataP1P2() {
    return [
      { categoryId: "1", value: "Capacity Issue" },
      { categoryId: "2", value: "Configuration Issue" },
      { categoryId: "3", value: "Defect" },
      { categoryId: "4", value: "Hardware Failure" },
      { categoryId: "5", value: "Negligence" },
      { categoryId: "6", value: "Unknown" },
      { categoryId: "7", value: "Vendor Issue" },
      { categoryId: "8", value: "Vendor Service" },
      { categoryId: "9", value: "Vendor Software" },
      { categoryId: "10", value: "Others � Please specify" }
    ];
  }
  LoadCategoryCycleStageData() {
    return [
      { categoryId: "1", value: "Disk space" },
      { categoryId: "2", value: "Unknown" },
      { categoryId: "3", value: "Configuration" },
      { categoryId: "4", value: "Vendor" },
      { categoryId: "5", value: "Long running job" },
      { categoryId: "6", value: "Cluster failover" },
      { categoryId: "7", value: "High CPU Usage" },
      { categoryId: "8", value: "High Memory Usage" },
      { categoryId: "9", value: "IIS" },
      { categoryId: "10", value: "Others � Please specify" }]
  }

  getcycleStageSR() {
    return [
      { cycleStageId: "1", value: "1" },
      { cycleStageId: "2", value: "2" },
      { cycleStageId: "3", value: "3" },
      { cycleStageId: "4", value: "4" },
      { cycleStageId: "5", value: "5" },
      { cycleStageId: "6", value: "6" }
    ];
  }
//Added as per DSR048 Requirement 5
getrelatedKPISR() {
  return [
    { cycleStageId: "1", value: "Playbook Usage" },
    { cycleStageId: "2", value: "GSM Engagement" },
    { cycleStageId: "3", value: "Leadership Joining Compliance" },
    { cycleStageId: "4", value: "Priority Mismatch" },
    { cycleStageId: "5", value: "Alerts" },
    { cycleStageId: "6", value: "Bot Usage" },
    { cycleStageId: "7", value: "Others" },
  ];
}
  resetData() {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.addForm.reset();
    this.ControlDueDate = new FormControl();
    this.ControlDateIdentified = new FormControl();
    this.SelectedFiles = [];
    this.ControldtAnnStartDate = new FormControl();
    this.ControldtAnnEndDate = new FormControl();
    this.CreatedByDisplay = '';
    this.CreatedDate = '';
    this.LastModifiedBy = '';
    this.LastModifiedDate = '';
  }

  dataResetPPS() {
    this.ppsEmailTo = [];
    this.ppsEmailCC = [];
  }

  getFileDetails(e: any) {
    this.files = e.target.files;
  }

  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  uploadFiles(): Observable<any> {
    this.loading = true;
    this.sendImagesFile = [];
    if (this.files != undefined) {
      this.loading = false;
      for (let k = 0; k < this.files.length; k++) {
        let filename = this.files[k].name;
        this.filename = filename;
        let index = filename.lastIndexOf(".");
        let strsubstring = filename.substring(index, filename.length);
        if (strsubstring.toUpperCase() == '.JPG' || strsubstring.toUpperCase() == '.JPEG' || strsubstring.toUpperCase() == '.PNG' || strsubstring.toUpperCase() == '.GIF' || strsubstring.toUpperCase() == '.MSG' || strsubstring.toUpperCase() == '.XLSX' || strsubstring.toUpperCase() == '.DOCX' || strsubstring.toUpperCase() == '.ZIP' || strsubstring.toUpperCase() == '.TXT' || strsubstring.toUpperCase() == '.PDF') {
          this.sendImagesFile.push(this.files[k]);
          // if (!this.sendImagesFile.includes(this.files[k].name)) this.sendImagesFile.push(this.files[k])

        }
        else {
          alert("File Extension Is InValid - Please Upload valid Extension Files");

        }
      }

      if (this.sendImagesFile) {
        if (this.sendImagesFile.length > 0) {
          let fd = new FormData();
          for (let k = 0; k < this.sendImagesFile.length; k++) {
            fd.append("uploadedFile", this.sendImagesFile[k]);
            this.S4();
            // then to call it, plus stitch in '4' in the third group
            let guid = (this.S4() + this.S4() + "-" + this.S4() + "-4" + this.S4().substr(0, 3) + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4()).toLowerCase();
            let uploadedFile = this.sendImagesFile[k].name;
            let index = uploadedFile.lastIndexOf(".");
            let ext = uploadedFile.substring(index, this.filename.length);
            let imgN = uploadedFile.replace(ext, "");
            let imageName = imgN + "_" + guid.toString() + ext;
            // if (!this.sendImagesDB.includes(imageName)) this.sendImagesDB.push(imageName)
            this.sendImagesDB.push(imageName);
            this.SelectedFiles.push(({ name: imageName, fName: this.sendImagesFile[k].name }));
            fd.append("UniqVal", guid.toString());

          }

          this.http.post(this.baseURL + "api/ServiceReview/UploadImages", fd).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
          ).subscribe(response => {
            this.loading = false;
            if (response.ErrorMessage != '') {

              // this.errorNotification(response.ErrorMessage);
              this.errorMessage = response.ErrorMessage;
            } else {
              this.ImageAttachmentPath = response.Result;
            }
          });
        }

      }

      $('#file').val('');

    }
    else {
      this.loading = false;
      alert("Please select at least one valid file.");
    }
    return <any>this.SelectedFiles;
  }
  previewAttachment(file: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    let previewAttachmentData = { ImagesName: file.name, FileName: file.fName, LoggedInUser: sessionStorage.getItem('LoggedInUser') };

    const headers = { Accept: "application/octet-stream" };
    return this.http.post(this.baseURL + "api/ServiceReview/previewAttachment", previewAttachmentData, { responseType: 'arraybuffer', headers: headers })
      .subscribe(response => {

        var blob = new Blob([response], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = previewAttachmentData.FileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url); //to release memory when not in use

      });
  }

  removeFile(file: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.loading = true;
    let data = { Id: file.Id, FileName: file.fName, ImagesName: file.name, LoggedInUser: sessionStorage.getItem("LoggedInUser") };

    return this.http.post<ServiceReviewModel>(this.baseURL + "api/ServiceReview/removeImageFile", data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.SelectedFiles.splice(0, 1);
      this.FetchAttachmentImages(file.SId);
      if (response.ErrorMessage != '') {

        // this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      } else {
        var message = "Removed";
        this.successNotification(message);
      }
    });

  }
  FetchAttachmentImages(IssueId: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.SelectedFiles = [];
    var data = { Id: IssueId, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.loading = true;
    return this.http.post<ServiceReviewModel>(this.baseURL + "api/ServiceReview/FetchAttachmentImages", data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      for (var k = 0; k < response.Result.length; k++) {
        var resultSplit = response.Result[k].ImagesName;
        this.SelectedFiles.push({ name: resultSplit, Id: response.Result[k].ImageId, SId: IssueId, ImageName: response.Result[k].ImagesName, fName: response.Result[k].FileName });

      }
      if (response.ErrorMessage != '') {

        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      }
      return <any>this.SelectedFiles;
    });
  }

  FetchPatchActivity(content: any, issuepps: any, app: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.loading = true;
    this.unchanged = false;
    var dataPatch = { PPSId: issuepps, LoggedInUser: sessionStorage.getItem('LoggedInUser') };

    var dataApp = { ApplicationId: app, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.FetchClusteredChildAppsForPatchActivityData = app;

    //this.GetClusterParentDetails(dataApp);

    this.SubmitPatchActivityForm.get("PPSId")?.setValue(issuepps);
    this.SubmitPatchActivityForm.get("ApplicationId")?.setValue(app);
    var LoggedInUserID = sessionStorage.getItem('LoggedInUser');
    this.SubmitPatchActivityForm.get("LoggedInUser")?.setValue(LoggedInUserID);
    this.SubmitPatchActivityForm.get("ModifiedBy")?.setValue(LoggedInUserID);
    //var LoggedInUserID:string = sessionStorage.getItem('LoggedInUser');
    //this.dataPatch.LoggedInUser = LoggedInUserID;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    return this.http.post<ActivityDetailModel>(this.baseURL + "api/ServiceReview/FetchPatchActivity", dataPatch).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.activityResponse = response;
      this.Activity = this.activityResponse!.Result;


      if (response.ErrorMessage != '') {

        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      } else {
        this.GetClusterParentDetails(dataApp);
      }
    });
  }

  GetClusterParentDetails(clusterD: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.loading = true;
    let url = this.baseURL + "api/ServiceReview/GetClusterParentDetails";
    return this.http.post<ServiceReviewModel>(url, clusterD).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.serviceRresponse = response;
      this.ClusterParent = this.serviceRresponse!.Result;
      //this.SubmitPatchActivityForm.get("ApplicationId")?.setValue(this.ClusterParent[0].Id);
      if (response.ErrorMessage != '') {

        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      } else {
        // if ($("#hdnActivityApplication").val() != '0') {
        // this.divActivityApplication = true;
        // this.divActivityClusteredApp = true;
        //$("#txtActivityClusterApp").prop('disabled', false);
        //} else {
        if (this.ClusterParent == null) {
          this.parentAppId = null;
          this.parentApplicationName = null;
          this.divActivityApplication = false;
          this.divActivityClusteredApp = false;
          $("#txtActivityClusterApp").prop('disabled', true);
          // }
        } else {
          this.parentAppId = this.ClusterParent[0].Id;
          this.parentApplicationName = this.ClusterParent[0].Name;

          if (this.parentApplicationName != null) {
            this.divActivityApplication = true;
            this.divActivityClusteredApp = true;
            $("#txtActivityApplication").val(this.parentApplicationName);
            $("#hdnActivityAppName").val(this.parentApplicationName);
            $("#hdnActivityApplication").val(this.parentAppId);
            $("#txtActivityApplication").prop("disabled", true);
          }
        }
      }
    });
  }

  FetchClusteredChildApps(Search: string) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.loading = true;
    var parentAppId = this.addForm.get("ApplicationId")?.value;
    var childFilter = "=" + Search + "=" + parentAppId + "=" + sessionStorage.getItem('LoggedInUser');
    this.common.FetchClusteredChildApps(childFilter)
      .subscribe(response => {
        this.loading = false;
        this.AppResponse = response;
        this.options = this.AppResponse!.Result;
        this.chilApplications = this.options;
        this.chilApplications.map(app => app.Name = `${app.AirId} - ${app.Name}`);
        if (response.ErrorMessage != '') {

          //this.errorNotification(response.ErrorMessage);
          this.errorMessage = response.ErrorMessage;
        }
      });
  }

  changeDes({ editor }: ChangeEvent) {
    //const EditorData = editor.getData();
    var IncDesc = $.trim(this.Editor['instances']['IncidentDescription'].getData());
    //console.log(IncDesc);
    this.SubmitPatchActivityForm.get('IncidentDescription')?.setValue(IncDesc);
  }
  changeCmnt({ editor }: ChangeEvent) {
    //const EditorData = editor.getData();
    var comment = $.trim(this.Editor['instances']['comments'].getData());
    this.SubmitPatchActivityForm.get('Comments')?.setValue(comment);
  }
  FetchClusteredChildAppsForPatchActivity(Search: string) {
    this.loading = true;
    var parentAppId = this.FetchClusteredChildAppsForPatchActivityData;
    var childFilter = "=" + Search + "=" + parentAppId + "=" + sessionStorage.getItem('LoggedInUser');

    this.common.FetchClusteredChildApps(childFilter)
      .subscribe(response => {
        this.loading = false;
        this.AppResponse = response;
        this.options = this.AppResponse!.Result;
        this.chilApplicationsForPatchActivity = this.options;
        this.chilApplicationsForPatchActivity.map(app => app.Name = `${app.AirId} - ${app.Name}`);
        if (response.ErrorMessage != '') {

          // this.errorNotification(response.ErrorMessage);
          this.errorMessage = response.ErrorMessage;
        }
      });
  }

  getSelectedAirIdsClusterdForPatch(e: any) {

    this.SubmitPatchActivityForm.get('ApplicationId')?.setValue(e.Id);
  }
  ClosePatchActivity() {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.childAppName = new FormControl();
    this.SubmitPatchActivityForm.get('IncidentDescription')?.setValue('');
    this.SubmitPatchActivityForm.get('Comments')?.setValue('');
    $("#hdnActivityAppName").val('0');
  }
  SubmitFormPatchActivity(modalformpatchActivity: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.loading = true;
    //if (this.SubmitPatchActivityForm.value.IncidentDescription = '') {
    //  var message = "Incident Description is mandetory";
    //  this.errorNotification(message);
    //} else {
    var appId = this.IssuesPPS[0].ApplicationId;
    var ppsId = this.SubmitPatchActivityForm.value.PPSId;
    this.reviewservice.addPatchActivity(this.SubmitPatchActivityForm.value)
      .subscribe(response => {
        this.loading = false;
        //this.FetchPatchActivity(this.dataPatch);
        if (response.ErrorMessage != '') {

          //this.errorNotification(response.ErrorMessage);
          this.errorMessage = response.ErrorMessage;
        } else {
          $('#patchrfId').trigger('click');
          var message = "Release/Patch Activity Data Saved Successfully.";
          this.successNotification(message);

          //this.SubmitPatchActivityForm.reset();
          //this.childAppName = new FormControl();
          //this.unchanged = false;
          this.childAppName = new FormControl();
          this.SubmitPatchActivityForm.get('IncidentDescription')?.setValue('');
          this.SubmitPatchActivityForm.get('Comments')?.setValue('');
          this.SubmitPatchActivityForm.get('MTId')?.setValue(0);
          this.SubmitPatchActivityForm.get('Id')?.setValue(null);
          this.SubmitPatchActivityForm.get('CommentId')?.setValue(0);
          $('#PatchActivityclosebtn').trigger('click');
          //this.LoadPPS();
          this.FetchPatchActivity(modalformpatchActivity, ppsId, appId);
          // $('#patchrfId').trigger('click');
        }
      });
    // }
  }
  EditpatchActivity(actDetail: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.unchanged = true;
    var data = { Id: actDetail.Id, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    return this.http.post<ActivityDetailModel>(this.baseURL + 'api/ServiceReview/EditActivity', data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.activityResponse = response;
      this.activityDetails = this.activityResponse!.Result;
      if (response.ErrorMessage != '') {

        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      } else {
        this.childAppName = new FormControl(this.activityDetails[0].Name);
        this.SubmitPatchActivityForm.patchValue({
          Id: this.activityDetails[0].Id, PPSId: this.activityDetails[0].PPSId, ApplicationId: this.activityDetails[0].ApplicationId, MTId: this.activityDetails[0].MTId, CommentId: this.activityDetails[0].CommentId,
          Comments: this.activityDetails[0].Comments, IncidentDescription: this.activityDetails[0].IncidentDescription
        });


      }
    });

  }
  Deactivate(modalformpatchActivity: any, actDetail: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.loading = true;
    this.deactiveActivity.Id = actDetail.Id;
    this.deactiveActivity.MTId = actDetail.MTId;
    var app_id = this.IssuesPPS[0].ApplicationId;
    return this.http.post<ActivityDetailModel>(this.baseURL + 'api/ServiceReview/DeleteActivity', this.deactiveActivity).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      if (response.ErrorMessage != '') {

        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      } else {
        $('#PatchActivityclosebtn').trigger('click');
        this.FetchPatchActivity(modalformpatchActivity, actDetail.PPSId, app_id);
        var success = "Record has been deleted successfully.";
        this.successNotification(success);
      }
    });

  }
  LoadApplications(Search: string) {
    this.loading = true;
    //let user = this.LoggedInUser?.nativeElement.value;
    this.common.FindSRFapplicationName("=" + Search + "=" + sessionStorage.getItem('LoggedInUser'))
      .subscribe(response => {
        this.loading = false;
        this.AppResponse = response;
        this.options = this.AppResponse!.Result;
        this.applications = this.options;
        this.applications.map(app => (app.parentCount == 0) ? app.Name = `${app.AirId} -- ${app.Name}` : app.Name = `${app.Name}`);

        if (response.ErrorMessage != '') {

          //this.errorNotification(response.ErrorMessage);
          this.errorMessage = response.ErrorMessage;
        }
      });
  }

  LoadIssues(app: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.loading = true;
    this.loadIssueData.ApplicationId = app.ApplicationId;

    this.loadIssueData.Id = this.searchForm.value.Id;
    if (this.searchForm.value.Id == -1 || this.searchForm.value.Id == null) {
      this.loadIssueData.Id = 0;
    } else {
      this.loadIssueData.Id = this.searchForm.value.Id;
    }
    if (this.searchForm.value.Status == '') {
      this.loadIssueData.Status = '';
    } else {
      var status = $('#statusFilter option:selected').text();
      this.loadIssueData.Status = status;
    }

    this.dataSRItem.ServiceReviewIds = '';
    this.dataSendmail.ServiceReviewIds = '';
    this.SRIds = '';
    this.SRIdsToSend = [];
    this.loadIssueData.LoggedInUser = sessionStorage.getItem('LoggedInUser');
    this.reviewservice.FetchServiceReviewDetails(this.loadIssueData)
      .subscribe(response => {
        this.loading = false;
        this.serviceRresponse = response;
        this.IssuesPanel = this.serviceRresponse.Result;

        if (response.ErrorMessage != '') {

          // this.errorNotification(response.ErrorMessage);
          this.errorMessage = response.ErrorMessage;
        } else if (this.IssuesPanel == null) {
          var errorMessage = "No items exists currently.";
          // this.errorNotification(errorMessage);
          this.errorMessage = errorMessage;
        } else {
          for (let issue of this.IssuesPanel) {
            if (issue.Status == 'Not Started' || issue.Status == 'On Track') {
              this.SRIds = this.SRIds + ',' + issue.Id;
            }
          }
          this.SRIdsToSend = this.SRIds.split(',');
          this.dataSRItem.ServiceReviewIds = this.SRIds;
          this.dataSendmail.ServiceReviewIds = this.SRIds;

        }
      });
    //this.errorMessage = this.serviceRresponse!.ErrorMessage;
  }

  selectionChange(app: any, issue: any, el: HTMLElement) {
    var SRIdsString = '';
    this.dataSRItem.ServiceReviewIds = '';
    let checked = document.querySelectorAll("[type='checkbox']:checked");
    const index = this.SRIdsToSend.indexOf(el.id);
    if (checked) {

      if (this.SRIdsToSend.indexOf(el.id) > -1) {
        this.SRIdsToSend.splice(index, 1);
      } else {
        this.SRIdsToSend.push(el.id);
      }
    };
    for (let ids of this.SRIdsToSend) {
      SRIdsString = SRIdsString + ',' + ids;
    }
    this.dataSRItem.ServiceReviewIds = SRIdsString;
    this.dataSendmail.ServiceReviewIds = SRIdsString;

  }
  LoadPPS(issue: any, app: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    var appId = app.ApplicationId;

    this.loadIssueData.ApplicationId = appId;
    //this.loadIssueData.Id = issue[0].Id;
    this.loadIssueData.LoggedInUser = sessionStorage.getItem('LoggedInUser');

    this.dataApp.ApplicationId = appId;
    this.dataSRItem.ApplicationId = appId;
    this.dataSendmail.ApplicationId = appId;
    this.addPPSForm.get('ApplicationId')?.setValue(appId);
    this.appId = appId;

    this.SubmitPatchActivityForm.get("Name")?.setValue(app.Name);

    this.reviewservice.LoadPPS(this.loadIssueData)
      .subscribe(response => {
        //this.loading = false;
        this.ppsResponse = response;
        this.IssuesPPS = this.ppsResponse.Result;
        this.recordCount = this.IssuesPPS[0].RecordCount;
        this.IsLoaded = false;
        //this.addPPSForm.reset();

        if (response.ErrorMessage != '') {

          this.errorMessage = response.ErrorMessage;
        }
      });
  }

  openPPS(content: any, app: any) {
    this.formTitle = "Add New";
    this.addPPSForm.get('ApplicationId')?.setValue(this.appId);
    this.hdnPPSId = 0;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  EditPPS(content: any, issuepps: any) {

    this.formTitle = "Edit";
    this.hdnPPSId = issuepps.Id;
    //var data = { ApplicationId: issuepps.Id, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    //this.reviewservice.LoadPPS(data)
    //  .subscribe(response => {
    //    //this.loading = false;
    //    this.ppsResponse = response;
    //    this.IssuesPPS = this.ppsResponse.Result;
    this.addPPSForm.patchValue(
      {
        Id: issuepps.Id, ApplicationId: issuepps.ApplicationId, OpenRisks: issuepps.OpenRisks, AlertsReceived: issuepps.AlertsReceived,
        Challenges: issuepps.Challenges, OtherItems: issuepps.OtherItems, SendEmailTo: issuepps.SendEmailTo, SendEmailCC: issuepps.SendEmailCC
      });
    //setTimeout(() => {
    this.ppsEmailTo = this.IssuesPPS[0].SendEmailTo?.split(';');
    this.ppsEmailCC = this.IssuesPPS[0].SendEmailCC?.split(';');
    //}, 1000);

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    //if (response.ErrorMessage != '') {

    //  this.errorMessage = response.ErrorMessage;
    //}
    //  });


  }

  changeOpenRisks({ editor }: ChangeEvent) {
    var openRisks = $.trim(this.Editor['instances']['OpenRisks'].getData());
    this.addPPSForm.get('OpenRisks')?.setValue(openRisks);
  };
  changeAlertsReceived({ editor }: ChangeEvent) {
    var alertsReceived = $.trim(this.Editor['instances']['AlertsReceived'].getData());
    this.addPPSForm.get('AlertsReceived')?.setValue(alertsReceived);
  };
  changeChallenges({ editor }: ChangeEvent) {
    var challenges = $.trim(this.Editor['instances']['Challenges'].getData());
    this.addPPSForm.get('Challenges')?.setValue(challenges);
  };
  changeOtherItems({ editor }: ChangeEvent) {
    var otherItems = $.trim(this.Editor['instances']['OtherItems'].getData());
    this.addPPSForm.get('OtherItems')?.setValue(otherItems);
  };

  SaveUpdate_pps({ editor }: ChangeEvent) {
    this.loading = true;
    if (this.addPPSForm.value.OpenRisks == null) {
      this.addPPSForm.get('OpenRisks')?.setValue('');
    }
    if (this.addPPSForm.value.AlertsReceived == null) {
      this.addPPSForm.get('AlertsReceived')?.setValue('');
    }
    if (this.addPPSForm.value.Challenges == null) {
      this.addPPSForm.get('Challenges')?.setValue('');
    }
    if (this.addPPSForm.value.OtherItems == null) {
      this.addPPSForm.get('OtherItems')?.setValue('');
    }
    var LoggedInUserID = sessionStorage.getItem('LoggedInUser');
    $('#hdnPPSId').val(this.hdnPPSId);

    this.addPPSForm.get('Id')?.setValue(this.hdnPPSId);
    this.addPPSForm.get('ApplicationId')?.setValue(this.appId);
    this.addPPSForm.get('LoggedInUser')?.setValue(LoggedInUserID);

    this.reviewservice.SaveUpdate_pps(this.addPPSForm.value)
      .subscribe(response => {
        this.loading = false;

        //this.reset();
        if (response.ErrorMessage != '') {
          // this.errorNotification(response.ErrorMessage);
          this.errorMessage = response.ErrorMessage;
        } else {

          this.addPPSForm.reset();
          var message = "Saved Successfully";
          this.successNotification(message);
          this.ppsEmailTo = [];
          this.ppsEmailCC = [];
          this.FilterServiceReview();
          $('#formclosebtn_pps').trigger('click');

        }
      });
  }
  changeLatestUpdate({ editor }: ChangeEvent) {
    //const EditorData = editor.getData();
    var LatestUpdates = $.trim(this.Editor['instances']['LatestUpdate'].getData());
    this.addForm.get('LatestUpdate')?.setValue(LatestUpdates);
  }

  selectEventAdd(e: any) {
    this.addForm.get("ApplicationId")?.setValue(e.Id);
    this.GetClusterChildCount(e.Id);

    if (e.parentCount == 1) {
      this.unchanged = false;
      this.GetClusterChildCount(e.Id);
      this.clusterChildBlock = false;
    } else {
      this.unchanged = true;
      //this.addForm.get("ApplicationId")?.setValue('');
      $('#clusterAppSR').find('input').val('');
      this.clusterChildBlock = true;
    }
  }

  dateReset() {
    this.ControlDateIdentified = new FormControl();
    this.ControlDueDate = new FormControl();
    this.sendImagesDB = [];
  }
  SubmitaddForm() {
    $('#btnSrSaveIssue').prop('disabled', true);
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.loading = true;
    this.addForm.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));
    //Added to resolve Date Identified date change issue
    let formData = this.addForm.value;
    if (formData.DateIdentified) {
      let date = new Date(formData.DateIdentified);
      formData.DateIdentified = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    }
    this.reviewservice.addServiceReview(this.addForm.value)
      .subscribe(response => {
        this.loading = false;


        //this.reset();
        if (response.ErrorMessage != '') {
          $('#btnSrSaveIssue').prop('disabled', false);
          // this.errorNotification(response.ErrorMessage);
          this.errorMessage = response.ErrorMessage;
        } else {
          if (this.addForm.value.Id == null || 0) {
            this.searchForm.get("ApplicationIds")?.setValue(null);
          }
          this.addForm.reset();

          var message = "Saved Successfully !!";
          this.successNotification(message);
          $('#btnSrSaveIssue').prop('disabled', false);
          $("#formclosebtn").trigger('click');
          this.sendImagesFile = [];
          this.SelectedFiles = [];
          this.FilterServiceReview();
          this.dateReset();
          this.CreatedByDisplay = '';
          this.CreatedDate = '';
          this.LastModifiedBy = '';
          this.LastModifiedDate = '';
        }
      });
  }
  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  }

  openAddForm(content: any) {
    this.formTitle = "Add New";
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.SelectedFiles = [];
    this.sendImagesFile = [];

    var box = document.getElementById('issues');
    if (box != null) {
      box.style.display = 'none';
    }
    $("#myModalLabel").text("Add New");
    this.addForm.get('Status')?.setValue("Not Started");
    //Commented to resolve placeholder issue for workstream
    // this.addForm.get('Workstream')?.setValue("Others");


    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  refershForm() {
    $('#SaveData_announcementClose').trigger('click');
  }
  openEdit(content: any, issue: ServiceReviewModel, app: any) {
    this.formTitle = "Edit";
    this.errorMessage = '';
    this.errorMessage2 = '';
    $("#myModalLabel").text("Edit");
    this.loadIssueData.Id = issue.Id;
    var box = document.getElementById('issues');
    if (box != null) {
      box.style.display = 'block';
    }
    //}
    this.reviewservice.FetchServiceReviewDetails(this.loadIssueData)
      .subscribe(response => {
        this.serviceRresponse = response;
        this.Issues = this.serviceRresponse.Result;
        this.CreatedByDisplay = this.Issues[0].CreatedBy.EnterpriseId;
        this.CreatedDate = this.Issues[0].CreateDateTime;
        this.LastModifiedBy = response.Result[0].ModifiedBy.EnterpriseId;
        this.LastModifiedDate = this.Issues[0].ModifiedDateTime;

        //For parent application
        $('#selectEventAdd').find('input').val(this.Issues[0].Name).attr('readonly', 'true').off('click');
        $('#selectEventAdd').find('div#suggestions').attr('hidden', 'true');

        $('#childApplicationFilterSR').attr('hidden', 'true');
        this.FetchAttachmentImages(issue.Id);
        if (issue.parentCount == 0) {
          this.FetchClusteredChildAppsForEdit(this.Issues[0].Name, this.Issues[0].ApplicationId);

          $('#peopleTemplate').find('input').val(issue.RaisedBy);
          $('#peopleTemplateAssigned').find('input').val(issue.AssignedTo);
          this.addForm.get('Workstream')?.setValue(issue.Workstream);          
          this.addForm.get('SubWorkstream')?.setValue(issue.SubWorkstream);
          this.LoadSelectedCategoryCycle();
          console.log(this.Issues[0].Category)
          this.addForm.get('Category')?.setValue(this.Issues[0].Category);
          // (this.Issues[0].DateIdentified != null) ? this.ControlDateIdentified = new FormControl(new Date(this.Issues[0].DateIdentified)) : this.addForm.get('DateIdentified')?.setValue(this.Issues[0].DateIdentified);
          if (this.Issues[0].DateIdentified != null) {
            let dateIdentified = new Date(this.Issues[0].DateIdentified);
            let localDate = new Date(dateIdentified.getTime() + dateIdentified.getTimezoneOffset() * 60000);
            this.ControlDateIdentified = new FormControl(localDate);
          } 
          (this.Issues[0].DueDate != null) ? this.ControlDueDate = new FormControl(new Date(this.Issues[0].DueDate)) : this.addForm.get('DueDate')?.setValue(this.Issues[0].DueDate);
          if (this.addForm.value.ApplicationId == null) {
            this.addForm.get('ApplicationId')?.setValue(this.Issues[0].ApplicationId);
          }
          this.addForm.patchValue({
            Id: this.Issues[0].Id, Name: this.Issues[0].Name, IssueActionItem: this.Issues[0].IssueActionItem, DateIdentified: this.Issues[0].DateIdentified,
            DueDate: this.Issues[0].DueDate, RaisedBy: issue.RaisedBy, AssignedTo: issue.AssignedTo, Status: this.Issues[0].Status, Workstream: this.Issues[0].Workstream,
            LatestUpdate: this.Issues[0].LatestUpdate, CreatedBy: this.Issues[0].CreatedBy, ImagesPath: this.sendImagesDB, LoggedInUser: sessionStorage.getItem('LoggedInUser'),
            SubWorkstream: this.Issues[0].SubWorkstream, Category: this.Issues[0].Category,
            OtherCategory: this.Issues[0].OtherCategory, CycleStage: this.Issues[0].CycleStage, IncidentTicket: this.Issues[0].IncidentTicket, RelatedKPI: this.Issues[0].RelatedKPI, RelatedKPIInfo: this.Issues[0].RelatedKPIInfo, OtherWorkstreamCategory: this.Issues[0].OtherWorkstreamCategory
          });



        } else {
          $('#peopleTemplate').find('input').val(issue.RaisedBy);
          $('#peopleTemplateAssigned').find('input').val(issue.AssignedTo);
          this.addForm.get('Workstream')?.setValue(issue.Workstream);
          this.addForm.get('SubWorkstream')?.setValue(issue.SubWorkstream);
          this.LoadSelectedCategoryCycle();
          console.log(this.Issues[0].Category)
          this.addForm.get('Category')?.setValue(this.Issues[0].Category);

          // (this.Issues[0].DateIdentified != null) ? this.ControlDateIdentified = new FormControl(new Date(this.Issues[0].DateIdentified)) : this.addForm.get('DateIdentified')?.setValue(this.Issues[0].DateIdentified);
          if (this.Issues[0].DateIdentified != null) {
            let dateIdentified = new Date(this.Issues[0].DateIdentified);
            let localDate = new Date(dateIdentified.getTime() + dateIdentified.getTimezoneOffset() * 60000);
            this.ControlDateIdentified = new FormControl(localDate);
          } 
          (this.Issues[0].DueDate != null) ? this.ControlDueDate = new FormControl(new Date(this.Issues[0].DueDate)) : this.addForm.get('DueDate')?.setValue(this.Issues[0].DueDate);

          this.addForm.patchValue({
            Id: this.Issues[0].Id, ApplicationId: this.Issues[0].ApplicationId, Name: this.Issues[0].Name, IssueActionItem: this.Issues[0].IssueActionItem, DateIdentified: this.Issues[0].DateIdentified,
            DueDate: this.Issues[0].DueDate, RaisedBy: issue.RaisedBy, AssignedTo: issue.AssignedTo, Status: this.Issues[0].Status, Workstream: this.Issues[0].Workstream,
            LatestUpdate: this.Issues[0].LatestUpdate, CreatedBy: this.Issues[0].CreatedBy, ImagesPath: this.sendImagesDB, LoggedInUser: sessionStorage.getItem('LoggedInUser'),
            SubWorkstream: this.Issues[0].SubWorkstream, Category: this.Issues[0].Category,
            OtherCategory: this.Issues[0].OtherCategory, CycleStage: this.Issues[0].CycleStage, IncidentTicket: this.Issues[0].IncidentTicket, RelatedKPI: this.Issues[0].RelatedKPI, RelatedKPIInfo: this.Issues[0].RelatedKPIInfo, OtherWorkstreamCategory: this.Issues[0].OtherWorkstreamCategory

          });
        } //else parent==0 check closing
      });

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  FetchClusteredChildAppsForEdit(Search: string, appId: any) {
    this.loading = true;
    var parentAppId = appId;
    if (Search.indexOf("&") != -1) {
      var regex = "&";
      Search = Search.replace(regex, '%26');
    }
    var childFilter = "=" + Search + "=" + parentAppId + "=" + sessionStorage.getItem('LoggedInUser');
    this.common.FetchClusteredChildApps(childFilter)
      .subscribe(response => {
        this.loading = false;
        this.AppResponse = response;
        this.options = this.AppResponse!.Result;
        this.chilApplications = this.options;
        this.appIdForEdit = this.chilApplications[0].Id;
        this.addForm.get('ApplicationId')?.setValue(this.appIdForEdit);

      });
  }
  AddNew_announcement(content: any) {
    this.DateReset();
    $('#formclosebtn_announcement').trigger('click');
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  DateReset() {
    this.ControldtAnnStartDate = new FormControl();
    this.ControldtAnnEndDate = new FormControl();
    this.CreatedByDisplay = '';
    this.CreatedDate = '';
    this.LastModifiedBy = '';
    this.LastModifiedDate = '';
  }
  SaveData_announcement() {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.error_announcement = true;
    this.loading = true;

    if (this.SaveDataAnnouncementForm.value.Description == null) {
      this.error_announcement = false;
      var message = "Please enter announcement description";
      this.errorMessageAnnouncement = message;
    }
    var dtAnnStartDate = null;
    if (this.SaveDataAnnouncementForm.get('AnnStartDate')?.value != null) {
      dtAnnStartDate = new Date(this.SaveDataAnnouncementForm.get('AnnStartDate')?.value.getTime() - this.SaveDataAnnouncementForm.get('AnnStartDate')?.value.getTimezoneOffset() * 60000);
    }
    else {
      this.error_announcement = false;
      var message = "Please enter announcement start date";
      this.errorMessageAnnouncement = message;
    }
    var dtAnnEndDate = null;
    if (this.SaveDataAnnouncementForm.get('AnnEndDate')?.value != null) {
      dtAnnEndDate = new Date(this.SaveDataAnnouncementForm.get('AnnEndDate')?.value.getTime() - this.SaveDataAnnouncementForm.get('AnnEndDate')?.value.getTimezoneOffset() * 60000);
    }
    else {
      this.error_announcement = false;
      var message = "Please enter announcement end date";
      this.errorMessageAnnouncement = message;
    }
    if (dtAnnEndDate != null && dtAnnStartDate != null) {
      if (dtAnnEndDate <= dtAnnStartDate) {
        this.error_announcement = false;
        this.errorMessageAnnouncement = "End date must be later than start date!";
      }
    }

    if (this.SaveDataAnnouncementForm.value.CreatedBy == null) {
      this.SaveDataAnnouncementForm.get('CreatedBy')?.setValue(sessionStorage.getItem('LoggedInUser'))
    }
    this.SaveDataAnnouncementForm.get("LoggedInUser")?.setValue(sessionStorage.getItem('LoggedInUser'));


    let saveDataAnnouncementFormData = {
      Id: 0, Description: this.SaveDataAnnouncementForm.value.Description, AnnStartDate: dtAnnStartDate,
      AnnEndDate: dtAnnEndDate, CreatedBy: sessionStorage.getItem('LoggedInUser'), LoggedInUser: sessionStorage.getItem('LoggedInUser')
    };
    this.reviewservice.SaveData_announcement(saveDataAnnouncementFormData)
      .subscribe(response => {
        this.loading = false;
        if (response.ErrorMessage != '') {
          //this.error_announcement = false;
          //this.errorMessageAnnouncement = response.ErrorMessage;
        } else {
          var message = "Saved Successfully !!";
          this.successNotification(message);
          this.SaveDataAnnouncementForm.reset();
          $('#SaveData_announcementClose').trigger('click'); //closing the form
          $('#btnAnnouncementAddNew').trigger('click');// opening the carousel window
          this.DateReset();
          this.errorMessageAnnouncement = '';
          this.error_announcement = true;
        }
      });
  }
  AnnouncementAdmin(content: any) {
    //Annoucement admin check
    var data = { LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.reviewservice.announcementAdmin(data)
      .subscribe(response => {
        this.announcementResponse = response;
        if (this.announcementResponse.Error == false) {

          this.FetchAnnouncement(this.announcementResponse.Result);

          this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      });

  }
  FetchAnnouncement(isAdmin: any) {
    this.loading = true;
    let data: any = {
      LoggedInUser: sessionStorage.getItem('LoggedInUser')
    }
    return this.http.post<AnnouncementModel>(this.baseURL + 'api/ServiceReview/FetchAnnouncement', data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      if (response.ErrorMessage != '') {
        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      } else {
        this.isUserAdmin = isAdmin;
        this.announcementResponse = response;
        this.Announcements = this.announcementResponse!.Result;
        this.noAnnouncement = "No Announcements - Click Add New Button";
      }

    });
  }
  Delete_announcement(announcementId: any) {
    this.errorMessage = '';
    this.errorMessage2 = '';
    this.loading = true;
    this.delAnnouncement.Id = announcementId;
    return this.http.post<AnnouncementModel>(this.baseURL + 'api/ServiceReview/Delete_announcement', this.delAnnouncement).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      //this.AnnouncementAdmin();

      if (response.ErrorMessage != '') {
        var message = 'Unable to delete data' + response.ErrorMessage;
        // this.errorNotification(message);
        this.errorMessage = message;
      } else {
        var message = "Record has been deleted successfully.";
        this.successNotification(message);
        this.FetchAnnouncement(this.isUserAdmin);
        //$('btnAnnouncementAddNew').trigger('click');
        //$('#SaveData_announcementClose').trigger('click'); //closing the form
        //$('#btnAnnouncementAddNew').trigger('click');// opening the carousel window
      }
    });

  }
  CloseForm_announcement() {
    this.SaveDataAnnouncementForm.reset();
  }
  //opens mail preview confirmation modal post clicking on send mail button
  ppsconfirmModal(content: any, issuepps: any, app: any) {
    //this.ServiceReviewPPSModal();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.dataSRItem.LoggedInUser = sessionStorage.getItem('LoggedInUser');
    this.dataPPSItem.Id = issuepps[0].Id; //[0]
    this.dataSendmail.Id = issuepps[0].Id;


  }
  //opens email description modal post clicking on preview button
  openPPSMailPreview(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    //this.showPopover = true;

    this.FetchMOTMEmailIds();
    //this.FilterServiceReviewPrivew();

  }


  //to bring email preview details
  FetchMOTMEmailIds() {
    this.showPopover = true;
    $('#modalformPPSconfirmModalClose').trigger('click');
    this.loading = true;
    return this.http.post<ServiceReviewModel>(this.baseURL + 'api/ServiceReview/FetchMOTMEmailIds', this.dataPPSItem).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.productionResponse = response;
      this.productionProcess = this.productionResponse!.Result;
      this.EmailToPreview = this.productionProcess[0].SendEmailTo;
      this.EmailCCPreview = this.productionProcess[0].SendEmailCC;
      //this.addPPSForm.get('SendEmailTo')?.setValue(this.EmailToPreview);
      //this.addPPSForm.get('SendEmailCC')?.setValue(this.EmailCCPreview);
      this.FilterServiceReviewPrivew();
      if (response.ErrorMessage != '') {

        // this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      }
    });

  }
  //to bring 2nd half email preview details
  FilterServiceReviewPrivew() {
    this.loading = true;
    this.showPopover = true;
    return this.http.post<ServiceReviewModel>(this.baseURL + 'api/ServiceReview/FilterServiceReviewPrivew', this.dataApp).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.serviceRresponse = response;
      this.ServiceReviewPrivew = this.serviceRresponse!.Result;

      this.FetchServiceReviewPreviewDetail();
      if (response.ErrorMessage != '') {

        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      }
    });
  }
  //to bring 3rd portion email preview details
  FetchServiceReviewPreviewDetail() {
    this.loading = true;
    this.showPopover = true;
    return this.http.post<ServiceReviewModel>(this.baseURL + 'api/ServiceReview/FetchServiceReviewPreviewDetail', this.dataSRItem).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.serviceRresponse = response;
      this.ServiceReviewPrivewDetail = this.serviceRresponse!.Result;

      this.FetchPPSPrivewDetail();
      if (response.ErrorMessage != '') {

        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      }
    });
  }
  //to bring 4th portion email preview details
  FetchPPSPrivewDetail() {
    this.loading = true;
    return this.http.post<ProductionProcessModel>(this.baseURL + 'api/ServiceReview/FetchPPSPrivewDetail', this.dataPPSItem).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.ppsResponse = response;
      this.PPSPreviewDetail = this.ppsResponse!.Result;
      this.FetchPPSIncidentDesc();
      if (response.ErrorMessage != '') {

        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      }
    });
  }
  //to bring 5th portion email preview details
  FetchPPSIncidentDesc() {
    this.loading = true;
    return this.http.post<ProductionProcessModel>(this.baseURL + 'api/ServiceReview/FetchPPSIncidentDesc', this.dataPPSItem).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.ppsResponse = response;
      this.PPSIncidentDesc = this.ppsResponse!.Result;
      if (response.ErrorMessage != '') {

        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      }
    });
  }
  PPSSendMail() {
    $('#PPSbtnStoMailSendContinue').prop('disabled', true);
    this.showPopover = true;
    this.loading = true;
    return this.http.post<ServiceReviewModel>(this.baseURL + 'api/ServiceReview/SendPPSMail', this.dataSendmail).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.serviceRresponse = response;
      this.mail = this.serviceRresponse!.Result;
      if (response.ErrorMessage != '') {
        $('#PPSbtnStoMailSendContinue').prop('disabled', false);
        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      } else {
        var message = "Sent";
        this.successNotification(message);
        $('#ppsSendMailClose').trigger('click');
        $('#PPSbtnStoMailSendContinue').prop('disabled', false);
        this.FilterServiceReview(); // To load the SRIDs post send the PPS/MOTM email
        this.dataSendmail.ServiceReviewIds = '';
      }
    });
  }

  exportData() {
    this.loading = true;
    // url: "/api/ServiceReview/ExportToExcel",
    $('#btnSrExportExcel').prop('disabled', true);

    //var getscope = angular.element($('#dvalert')).scope();
    //getscope.error = false;
    //getscope.errorMessage = "";
    //common.show("Loading...");

    let filterscope = this.applicationFilterSR.nativeElement; //error is occuring here need to fix common.js

    let appIds = null;
    //if (filterscope.selectedAirIds.length > 0) {
    //    let obj = filterscope.selectedAirIds;
    //    for (let prop in obj) {
    //        if (appIds == undefined || appIds == null) {
    //            appIds = obj[prop].appId;
    //        }
    //        else {
    //            appIds = appIds + ',' + obj[prop].appId;
    //        }
    //    }
    //}

    let startdate = null
    //if (this.dtStartDate.nativeElement != null)
    //    startdate = new Date(this.dtStartDate.nativeElement.getTime() - this.dtStartDate.nativeElement.getTimezoneOffset() * 60000);

    let enddate = null
    //if (this.dtEndDate.nativeElement != null)
    //    enddate = new Date(this.dtEndDate.nativeElement.getTime() - this.dtEndDate.nativeElement.getTimezoneOffset() * 60000);


    let status = $('#statusFilter option:selected').text();
    /* let SRId = this.ServiceReviewIds.nativeElement;*/

    //if (SRId != '') {

    //    let SRIdValue = parseInt(SRId);
    //    if (SRIdValue == 0|| !$.isNumeric(SRId))
    //        SRId = -1;
    //}

    //else {
    //    SRId = -1;
    //}
    let SRId = this.searchForm.get('Id')?.value;
    if (this.searchForm.get('Id')?.value == "" || this.searchForm.get('Id')?.value == null || this.searchForm.get('Id')?.value == 0) {
      SRId = -1;
    }
    let filter = { ApplicationIds: this.searchForm.get('ApplicationIds')?.value, dtStartDate: this.searchForm.get('dtStartDate')?.value, dtEndDate: this.searchForm.get('dtEndDate')?.value, Status: this.searchForm.get('Status')?.value, LoggedInUser: sessionStorage.getItem('LoggedInUser'), Id: SRId }
    //console.log(filter);
    return this.http.post<ServiceReviewModel>(this.baseURL + 'api/ServiceReview/ExportToExcel', filter).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.serviceRresponse = response;
      //console.log(this.serviceRresponse);
      let date = new Date();
      let day = date.getDate();     // Get current date
      let month = date.getMonth() + 1; // current month
      let year = date.getFullYear();
      let CurrentDate = year + "/" + month + "/" + day;
      //console.log(CurrentDate);

      this.alasql('SELECT * INTO XLS("Production Process Status Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.ExcelPPSTemplate, this.serviceRresponse!.result]);

      this.alasql('SELECT * INTO XLS("Service Review Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [this.template, this.serviceRresponse!.Result]);
      //this.exportXLS = this.serviceRresponse!.Result;

      this.FetchPPSIncidentDesc();
      $('#btnSrExportExcel').prop('disabled', false);
      if (response.ErrorMessage != '') {

        // this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      }
    });

    //ServiceReviewFactory.ExportToExcel(filter).then(function (response) {
    //    var getscope = angular.element($('#dvalert')).scope();

    //    if (response.data.Error == true) {
    //        var trscope = angular.element($('#divSuccess')).scope();
    //        getscope.ExcelTemplate = "";
    //        trscope.show = false;
    //        getscope.error = true;
    //        getscope.errorMessage = response.data.ErrorMessage;
    //    }
    //    else {
    //        if (response.data.Result == null) {
    //            getscope.error = true;
    //            getscope.errorMessage = 'No data found for the selected criteria';
    //        }

    //        else {
    //            var date = new Date();
    //            var day = date.getDate();     // Get current date
    //            var month = date.getMonth() + 1; // current month
    //            var year = date.getFullYear();
    //            var CurrentDate = year + "/" + month + "/" + day;


    //            alasql('SELECT * INTO XLS("Production Process Status Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [ExcelPPSTemplate, response.data.result]);

    //            alasql('SELECT * INTO XLS("Service Review Report(' + CurrentDate + ')' + '.xls",?) FROM ?', [template, response.data.Result]);



    //            $('#dtStart').val('');
    //            $('#dtend').val('');
    //oute.reload();

    // }
    // }
    //this.FilterServiceReview();
    //common.close();
    //$('#btnSrExportExcel').prop('disabled', false);
    //  },

    //function (error) {
    //    common.close();
    //    $('#btnSrExportExcel').prop('disabled', false);
    //    $scope.status = 'Unable to load ' + error.message;
    //});
  }
  commentspopupmodal(content: any) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  autocompleteclickSR(e: any) {
    $('#applicationFilterSR').find('input:text').val('');
  }
  autocompleteclickEmailSR(e: any) {
    $('#sendEmailAutocompleteSR').find('input:text').val('');
    $('#emailToCcAutocompleteSR').find('input:text').val('');
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
