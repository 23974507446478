<mat-toolbar color="primary">
  <h3 class="accessDenied_title">Service Operation Hub</h3>
  <button class="logoutButton" mat-raised-button (click)="logout()" style="margin-left: 92%;"><fa-icon [icon]="['fas', 'right-from-bracket']"></fa-icon>Logout</button>
</mat-toolbar>
<div class="navbar" style="background-color: #fff; overflow: hidden;">
</div>
<div class="main" style="margin: 10% 0;">
  <div class="container accessDenied_container">
    <div class="jumbotron">
      <h1 style="color:red">Access Denied</h1>
      <p>Looks like a connectivity issue.</p><p> Don't worry please contact system administrator.</p>
    </div>
  </div>
</div>
