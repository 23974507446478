import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { SearchSopComponent } from './search-sop/search-sop.component';

import { MsalGuard } from '@azure/msal-angular';
import { IssuetrackerComponent } from './issue-tracker/issue-tracker.component';
import { UploadSOPComponent } from './upload-sop/upload-sop.component';
import { ServiceReviewComponent } from './service-review/service-review.component';
import { ShiftTurnOverComponent } from './shift-turn-over/shift-turn-over.component';
import { MonitoringTrackerComponent } from './monitoring-tracker/monitoring-tracker.component';
import { UploadSamplesComponent } from './upload-samples/upload-samples.component';
import { ReportsComponent } from './reports/reports.component';
import { ImportantLinksComponent } from './important-links/important-links.component';
import { environment } from '../environments/environment';
import { AccessdeniedComponent } from './accessdenied/accessdenied.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CriticalErrorComponent } from './critical-error/critical-error.component';
import { RequestOnboardingComponent } from './request-onboarding/request-onboarding.component';
import { OnboardingRequestApproverComponent } from './onboarding-request-approver/onboarding-request-approver.component';
import { AccessDeniedVpnComponent } from './access-denied-vpn/access-denied-vpn.component';
import { NxtGenServicePulseComponent } from './nxt-gen-service-pulse/nxt-gen-service-pulse.component';


const routes: Routes = [
  {
    path: '',
    component: SearchSopComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
  path: 'assets/template.xlsx',
  component: AccessdeniedComponent,
  canActivate: [
    MsalGuard
  ]
  },
  {
    // Needed for Error routing
    path: 'error',
    component: HomeComponent
  },
  {
    path: 'search-sop',
    component: SearchSopComponent
  },
  {
    path: 'upload-sop',
    component: UploadSOPComponent
  },
  {
    path: 'upload-sample',
    component: UploadSamplesComponent
  },
  {
    path: 'monitoring-tracker',
    component: MonitoringTrackerComponent
  },
  {
    path: 'issue-tracker',
    component: IssuetrackerComponent
  },
  {
    path: 'shift-turn-over',
    component: ShiftTurnOverComponent
  },
  {
    path: 'reports',
    component: ReportsComponent
  },
  {
    path: 'important-links',
    component: ImportantLinksComponent
  },
  {
    path: 'service-review',
    component: ServiceReviewComponent
  }
  ,
  {
    path: 'access-denied',
    component: AccessdeniedComponent
  },
  {
    path: 'critical-error',
    component: CriticalErrorComponent
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: 'request-onboarding',
    component: RequestOnboardingComponent
  },
  {
    path: 'onboarding-request-approver',
    component: OnboardingRequestApproverComponent
  },
  {
    path: 'access-denied-vpn',
    component: AccessDeniedVpnComponent
  },
  {
    path: 'nxt-gen-service-pulse',
    component: NxtGenServicePulseComponent
  },
  {
    path: '**',
    component: AccessdeniedComponent
  }
];

// {
//     path: 'auth-redirect',
//     component: HomeComponent
//   },

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


