import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ServiceReviewModel } from '../models/service-review-model';
import { ServiceReviewResponseModel } from '../models/service-review-response-model';
import { ProductionProcessResponse } from '../models/production-process-response-model';
import { ActivityDetailModel } from '../models/activity-detail-model';
import { ActivityDetailResponseModel } from '../models/activity-detail-response-model';
import { environment } from '../../environments/environment';
import { ProductionProcessModel } from '../models/production-process-model';
import { AnnouncementResponseModel } from '../models/announcement-response-model';
import { AnnouncementModel } from '../models/announcement-model';
@Injectable({
  providedIn: 'root'
})
export class ServiceReviewServiceService {
    baseURL:string = environment.baseUrl;
    filterUrl = this.baseURL+"api/ServiceReview/FilterServiceReview";
    ppsUrl = this.baseURL+"api/ServiceReview/FetchPPSDetails";
    fetchDetailsUrl = this.baseURL+"api/ServiceReview/FetchServiceReviewDetails";
    addurl = this.baseURL+"api/ServiceReview/SaveUpdateSr";
    addPatchUrl = this.baseURL+"api/ServiceReview/SavePatchActivity";
    
    loadIssue: any;
    addFormData: any;
    //headers = {
    //    'content-type': 'application/json',
    //    'Access-Control-Allow-Origin': '*',
    //    'Access-Control-Allow-Methods': 'POST, OPTIONS'
    //};
    constructor(private http: HttpClient) { }

    FilterServiceReview(filter: any): Observable<ServiceReviewResponseModel> {
        return this.http.post(this.filterUrl, filter).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    FetchServiceReviewDetails(Review: any): Observable<ServiceReviewResponseModel> {
        return this.http.post(this.fetchDetailsUrl, Review).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    LoadPPS(Review: any): Observable<ProductionProcessResponse> {
        return this.http.post(this.ppsUrl, Review).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    addServiceReview(sReview: any): Observable<ServiceReviewResponseModel> {
        //sReview.Id = 0;
        return this.http.post<ServiceReviewResponseModel>(this.addurl, sReview).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
    addPatchActivity(newPatchData: any): Observable<ActivityDetailResponseModel> {
        return this.http.post<ActivityDetailResponseModel>(this.addPatchUrl, newPatchData).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }
  SaveData_announcement(saveDataAnnouncementFormData: any): Observable<AnnouncementResponseModel> {
    return this.http.post<AnnouncementModel>(this.baseURL + 'api/ServiceReview/SaveData_announcement', saveDataAnnouncementFormData).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable));
    }
    SaveUpdate_pps(processStatus: any): Observable<ProductionProcessResponse> {
        //console.log("fromservice",processStatus)
      return this.http.post<ProductionProcessModel>(this.baseURL+'api/ServiceReview/SaveUpdate_pps', processStatus).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable));
    }
    announcementAdmin(data:any): Observable<AnnouncementResponseModel> {
      return this.http.post<AnnouncementModel>(this.baseURL + 'api/ServiceReview/AnnouncementAdmin', data).pipe(
        map(this.extractData),
        catchError(this.handleErrorObservable));
  }
  //previewAttachment(data: any): Observable<ServiceReviewResponseModel> {
  //  return this.http.post<AnnouncementModel>(this.baseURL + 'api/ServiceReview/previewAttachment', data).pipe(
  //    map(this.extractData),
  //    catchError(this.handleErrorObservable));
  //}
    private extractData(res: any) {
        let body = res;
        return body;
    }
    private handleErrorObservable(error: any) {
        console.error(error.message || error);
        return throwError(error);
    }
}
