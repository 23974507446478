<mat-toolbar color="primary">
   <h3 class="accessDenied_title">Service Operation Hub</h3>
    <button class="logoutButton" mat-raised-button (click)="logout()" style="margin-left: 92%;"><fa-icon [icon]="['fas', 'right-from-bracket']"></fa-icon>Logout</button>
</mat-toolbar>
<div class="navbar" style="background-color: #fff; overflow: hidden;">
    <!-- <ul>
        <li><fa-icon [icon]="['fas','address-card']"></fa-icon><a> Onboarding Form</a></li>
    </ul> -->
</div>
<div class="main" style="margin: 10% 0;">
  <div class="container accessDenied_container">
    <div class="jumbotron">
      <h1 style="color:red">Greetings!</h1>
      <p>This is GPC Digital site for GPC Monitoring team.</p><p>For onboarding please use the link below start the onboarding process.</p>
    </div>
    <button type="button" id="btnRequest" style="float: right; margin-top:5px" class=" btn btn-primary" (click)="RequestAccess();"><span class="fa fa-download"></span>Request Onboarding Application</button>

  </div>
    </div>
