import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SearchSopModel } from '../models/search-sop.model';
import { SearchSopResponse } from '../models/search-sop-response';
//import { SupportContactResponse } from '../models/support-contact-response';
import { ApplicationResponseModel } from '../models/application-response-model';
import * as $ from 'jquery';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchSopService {
    baseURL:string = environment.baseUrl;
  url = this.baseURL + "api/Alert/FindAlertByFilter";
  delData: any;
    //alert:any = {
    //    applicationid: '', sopnumber: '',
    //    alertsubject: '', alertdescription: '',
    //    loggedinuser: 'tarunima.ghosh'
    //};
    
    headers = {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, OPTIONS'
    };
    constructor(private http: HttpClient) { }
    
    FindAlertByFilter(alert: any): Observable<SearchSopResponse> {
        return this.http.post<SearchSopResponse>(this.url, alert).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }


  FetchServerListDetailsForApplication(form: any): Observable<SearchSopResponse> {
    return this.http.post<SearchSopResponse>(this.url, form).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }

  FetchControlMJobDetailsForApplication(form: any): Observable<SearchSopResponse> {
    return this.http.post<SearchSopResponse>(this.url, form).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }

  addServerlist(form: any): Observable<SearchSopResponse> {
    let addUrl = this.baseURL + "api/Alert/SaveServerList"
    return this.http.post<SearchSopResponse>(addUrl, form).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }

  addControlMJobslist(form: any): Observable<SearchSopResponse> {
    let addUrl = this.baseURL + "api/Alert/SaveControlMJobsList"
    return this.http.post<SearchSopResponse>(addUrl, form).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }

  deletedata(form: any): Observable<SearchSopResponse> {
    let addUrl = this.baseURL + "api/Alert/DeleteServerlist"
    return this.http.post<SearchSopResponse>(addUrl, form).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );

  }

  deletecontroldata(form: any): Observable<SearchSopResponse> {
    let addUrl = this.baseURL + "api/Alert/DeleteControlMJobsList"
    return this.http.post<SearchSopResponse>(addUrl, form).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    );
  }



    //FindApplication(): Observable<SearchSopResponse> {
    //    return this.http.post<SearchSopResponse>("api/Application/FindApplication", this.alert).pipe(
    //        map(this.extractData),
    //        catchError(this.handleErrorObservable)
    //    );
    //}

    //FindContactsByAirId(alert: any): Observable<ApplicationResponseModel> {
    //    return this.http.post<ApplicationResponseModel>("api/Alert/FindContactsByAirId", this.application).pipe(
    //        map(this.extractData),
    //        catchError(this.handleErrorObservable)
    //    );
    //}

    private extractData(res: any) {
        let body = res;
        return body;
    }
    private handleErrorObservable(error: any) {
        console.error(error.message || error);
        return throwError(error);
    }
}
