import { Component, OnInit } from '@angular/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { OnboardingInterface } from '../models/onboarding-interface';
import { catchError, map, throwError } from 'rxjs';
import { ServerListModel } from '../models/server-list-model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  baseUrl: string = environment.baseUrl;
  navAdminHide: boolean = true;
  navOnboardHide: boolean = true;
  isAdmin: boolean = false;
  constructor(library: FaIconLibrary, private http: HttpClient, private common: CommonService) {
    library.addIconPacks(fas, far);
  }
  // async ngOnInit(): Promise<void> {
  //   function delay(ms: number) {
  //     return new Promise(resolve => setTimeout(resolve, ms));
  //   }
  //   await delay(1500);
  //   this.checkIfApprover();
  // }
  ngOnInit(){
     //this.checkIfApprover();
  }
  checkIfApprover() {
    let data: any = {
      LoggedInUser: sessionStorage.getItem('LoggedInUser')
    }
    return this.http.post<OnboardingInterface>(this.baseUrl + 'api/Approver/IsApprover', data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.Result == true) {
        this.navOnboardHide = false;
      }
      //this.checkIfNextGenAdmin();
    });
   
  }


  checkIfAdmin() {
    let data: any = {
      LoggedInUser: sessionStorage.getItem('LoggedInUser')
    }
    console.log("nav", data);
    return this.http.post<ServerListModel>(this.baseUrl + 'api/Alert/IsAdmin', data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.Result == true) {
        this.isAdmin = true;

      }
    });
  }

  // checkIfNextGenAdmin() {
  //   let user_info = { enterpriseId: sessionStorage.getItem('LoggedInUser'), givenname: sessionStorage.getItem('LoggedInUser') };
  //   return this.http.post<OnboardingInterface>(this.baseUrl + 'api/NextGen/IsNextGenAdmin', user_info).pipe(
  //     map(this.extractData),
  //     catchError(this.handleErrorObservable)
  //   ).subscribe(response => {
  //     if (response.Result == true) {
  //       this.navAdminHide = false;
  //     }
  //   });
    
  // }

  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  }

}
