<style type="text/css">
    .btn-group {
        display: none !important;
    }
</style>
<!--<head>
    <link href="~/Content/Styles/font-awesome.min.css" rel="stylesheet">
</head>-->
<div class="main">

    <div class="row" ng-controller="MonitoringTrackerController" style="margin-top: -18px;">
        <h1 class="pb-2 mt-4 mb-2 border-bottom" style="width:100%">
            Reports
        </h1>
    </div>

    <div class="row" ng-controller="MonitoringTrackerController" style="margin-top: -23px;">
        <h3 class="pb-2 mt-4 mb-2 border-bottom" style="width:100%">
            Prevention Report
        </h3>
    </div>

    <div class="row" style="margin-left: -30px;margin-top: 10px;">
        <div class="col-md-2">
            <input id="dtStartPreven" placeholder="Date From" class="form-control" name="StartDate" #StartDate onfocus="(this.type='date')">
        </div>
        <div class="col-md-2">
            <input id="dtendPreven" placeholder="Date To" class="form-control" name="EndDate" #EndDate onfocus="(this.type='date')">
        </div>
        <div class="col-md-2" id="statusFilter" ng-controller="MonitoringTrackerController">
            <button type="button" id="btnPreventiveExportExcel" style="float: left;" class=" btn btn-primary" (click)="exportPreventiveData();">
                <span class="fa fa-download"></span>Generate
            </button>
        </div>
    </div>
    <br />
    <br />
    <div id="dvreportalert" ng-controller="MonitoringTrackerController" class="alert alert-danger" *ngIf="errorMessage2">
        {{errorMessage2}}
    </div>

    <div class="row" ng-controller="ServiceReviewController" style="margin-top: -26px;">
        <h3 class="pb-2 mt-4 mb-2 border-bottom" style="width:100%">
            Service Review Action Items Report
        </h3>
    </div>
    <div class="row" style="margin-left: -30px;margin-top: 10px;">
        <div class="col-md-2" style="font-weight:bold;margin-left:10px">
            Workstream
        </div>

        <div class="col-md-2" id="wrkStreamFilter" style="margin-right:55px">
            <select id="srrwrkStream" multiple="multiple" class="form-control" style="width:135% !important;height:120px !important;
        position:static !important;padding:.375rem .75rem !important;border:1px solid #ced4da !important" name="Workstream" #Workstream>
                <option value="ALL" selected="selected">ALL</option>
                //Commented existing options for DSR048 Requirement 5
                <!-- <option value="Regular Items">Regular Items</option>
                <option value="Data Analysis Group">Data Analysis Group</option>
                <option value="Rehearsals">Rehearsals</option>
                <option value="Alert Review Initiative">Alert Review Initiative</option>
                <option value="SOP-Related">SOP-Related</option> -->
                <option value="Incident Related">Incident Related</option>
                <option value="Problem Management">Problem Management</option>
                <option value="Retrospective Incident Analysis">Retrospective Incident Analysis</option>
                <option value="Data Analysis">Data Analysis</option>
                <option value="Rehearsals">Rehearsals</option>
                <option value="Mock Drills">Mock Drills</option>
                <option value="Playbook Audit">Playbook Audit</option>
                <option value="SOP Audit">SOP Audit</option>
                <option value="SOP Change Related">SOP Change Related</option>
                <option value="Others">Others</option>
            </select>
        </div>
        <div class="col-md-2">
            <input id="srrdtStart" placeholder="Start Date" class="form-control" name="dtStartDate" #dtStartDate onfocus="(this.type='date')">
        </div>
        <div class="col-md-2">
            <input id="srrdtend" placeholder="End Date" class="form-control" name="dtEndDate" #dtEndDate onfocus="(this.type='date')">
        </div>
        <div class="col-md-2" id="statusFilter" ng-controller="ServiceReviewController">
            <button type="button" id="srrbtnPreventiveExportExcel" style="float: left;" class=" btn btn-primary" (click)="srrexportPreventiveData();">
                <span class="fa fa-download"></span>Generate
            </button>
        </div>
    </div>
    <br />
    <div id="srrdvreportAlert" ng-controller="ServiceReviewController" class="alert alert-danger" *ngIf=" ">
        {{SrrErrorMessage}}
    </div>
</div>
