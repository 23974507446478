<div class="main">
  <br />
  <h1 class="page-header">Upload Standard Operating Procedures</h1>
  <div class="panel-group">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-title pull-left"><strong>Choose file to upload (Only .xls or .xlsx file)</strong></div>
        <div class="panel-title pull-right">
          <!--<mat-icon class="material-icons">download</mat-icon><br />
          <span class="material-icons">&#xE87C;</span>-->
          <br />
         <a style="color:blue; cursor: pointer;" (click)="checkIfLoggedIn()" oncontextmenu="return false;">Download Template</a>
        </div>
        <div class="clearfix"></div>
      </div>
      <ul class="list-group">
        <li class="list-group-item">
          <input type="file" file-model="ExcelFile" (change)="onChange($event)">
        </li>
        <li class="list-group-item">
          <input type="submit" id="btnUploadSop" value="Upload" class="btn btn-primary" (click)="onUpload()" />
        </li>
      </ul>
    </div>
  </div>
  <div class="alert alert-success" *ngIf="successMessage">
    {{successMessage}}
  </div>
  <div class="alert alert-danger" *ngIf="errormessage">
    {{errormessage}}
  </div>
  <!--<router-outlet></router-outlet>-->
</div>
