import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SearchSopService } from '../services/search-sop.service';
import { ApplicationCommonModel } from '../models/application-common-model';
import { ApplicationModel } from '../models/application-model';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { startWith, map, catchError } from 'rxjs/operators';
import { SearchSopResponse } from '../models/search-sop-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SearchSopModel } from '../models/search-sop.model';
import { SupportContactModel } from '../models/support-contact-model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationResponseModel } from '../models/application-response-model';
import { DownloadAlertSampleResponse } from '../models/download-alert-sample-response';
import { NotificationModel } from '../models/notification-model';
import { NotificationResponse } from '../models/notification-response';
import { environment } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { ApplicationCommonResponseModel } from '../models/application-common-response';
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { AppComponent } from '../app.component';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerListModel } from '../models/server-list-model';
import { ControlMJobsListModel } from '../models/controlM-jobs-list';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
//let url = protectedResources.apiGPCDigital.endpoint;

type ProfileType = {
    givenName: string,
    surname: string,
    userPrincipalName: string,
    id: string
}

@Component({
    selector: 'app-search-sop',
    templateUrl: './search-sop.component.html',
    styleUrls: ['./search-sop.component.css']
})
export class SearchSopComponent implements OnInit {
    baseUrl : string = environment.baseUrl;
    errorMessage: string = "";
    Applications: ApplicationCommonModel[] = [];
    searchResult: SearchSopModel[] = [];
    SearchSopResponse: SearchSopResponse | undefined;
    AppResponse: ApplicationCommonResponseModel | undefined;
    applicationModel: ApplicationModel[] = [];
    ContactResponse: ApplicationResponseModel | undefined;
    DownloadAlertRes: DownloadAlertSampleResponse | undefined;
    NotificationModel: NotificationModel[] = [];
    NotificationResponse: NotificationResponse | undefined;
    service!: SupportContactModel;
    serviceArray:any [] = [];
    resultArray: any[] = [];
    public navbarCollapsed = true;
    panelOpenState : boolean | undefined;
    closeResult: string = '';
    profile!: ProfileType;
    control = new UntypedFormControl('');
    filteredApplication!: Observable<string[]>;
    public username!: string; 
  public airID!: number;
  public formTitle: string = '';
    selectedAirIds: any[] = [];
    options: ApplicationModel[] = [];
    keyword= 'Name';
    public applications: any[] = [];
  public _recv_token: any;
  private _router: Router;
  modalTitle: string = "";
  isAdmin: boolean = false;
  onEdit: boolean = false;
  onNew: boolean = true;
  onOpen: boolean = true;
    //token:any;


    app: any;
  ServerList: any;
  ControlMList: any;

  successNotification(reason: string) {
    Swal.fire({
      title: 'Success',
      text: reason,
      icon: 'success',
      confirmButtonText: 'OK'
    });
  }

  confirm(slists: any) {
    Swal.fire({
      title: 'Delete this recored ?',
      icon: 'warning',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result['isConfirmed']) {
        this.delete(slists);
      }
    })
  }

  confirmDelete(clists: any) {
    Swal.fire({
      title: 'Delete this recored ?',
      icon: 'warning',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result['isConfirmed']) {
        this.deleteControlMData(clists);
      }
    })
  }
    //errorNotification(reason:string) {
    //    Swal.fire({
    //                title: 'Error',
    //                text: reason,
    //                icon: 'warning',
    //                confirmButtonText: 'OK'
    //            })
    //}
  public loading = false;

  addForm = new FormGroup({
    Id: new FormControl(), AirId_AppName: new FormControl(), Link: new FormControl(), AirId: new FormControl(), CreatedBy: new FormControl(), OpenedBy: new FormControl(), CreateDateTime: new FormControl(),
    ModifiedBy: new FormControl(), ModifiedDateTime: new FormControl(),
    LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser'))
  });

  controlForm = new FormGroup({
    Id: new FormControl(), AirId_AppName: new FormControl(), Link: new FormControl(), AirId: new FormControl(), CreatedBy: new FormControl(), OpenedBy: new FormControl(), CreateDateTime: new FormControl(),
    ModifiedBy: new FormControl(), ModifiedDateTime: new FormControl(),
    LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser'))
  });

    searchForm = new FormGroup({
        ApplicationId: new FormControl(), SopNumber: new FormControl(),
        AlertSubject: new FormControl('', Validators.minLength(0)),
        AlertDescription: new FormControl('', Validators.minLength(0)),
        AirId: new FormControl(), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser'))
    });

    @ViewChild('airid') airid!: ElementRef;
    @ViewChild('LoggedInUser') LoggedInUser!: ElementRef;
    @ViewChild('AlertSample') AlertSample!: ElementRef;
    @ViewChild('child') child:AppComponent | undefined;
    

    

  constructor(private SearchSopService: SearchSopService, private http: HttpClient, private modalService: NgbModal, private common: CommonService, _router: Router) {
 
    this._router = _router;
  
    }
  fetchApplications() {
    this.sopReset();
    this.onOpen = true;
      this.loading = true;
      this.loading = false;
    this.searchForm.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));
    if (this.searchForm.value.AirId == 5317) {
      this.onOpen = false;
    }
    if (this.searchForm.get('SopNumber')?.value != '' && this.searchForm.get('SopNumber')?.value != null) {
      var sopValue = parseInt(this.searchForm.value.SopNumber);
        this.searchForm.get('SopNumber')?.setValue(sopValue);
    }
    if (this.searchForm.value.ApplicationId <= 0 && this.searchForm.value.SopNumber <= 0 && this.searchForm.value.AlertSubject == '' && this.searchForm.value.AlertDescription == '' && this.searchForm.value.AirId <= 0) {
      this.searchForm.get('AlertSubject')?.setValue('');
      this.searchForm.get('AlertDescription')?.setValue('');
      this.searchForm.get('ApplicationId')?.setValue(0);
      this.searchForm.get('AirId')?.setValue(0);
      this.searchForm.get('SopNumber')?.setValue('');

        var errorMessage = "Please select at least one selection criteria.";
      this.errorMessage = errorMessage;
      this.searchResult.pop();
      //  this.errorNotification(errorMessage);
        this.ngOnInit();
    }
    else {
        this.SearchSopService.FindAlertByFilter(this.searchForm.value)
            .subscribe(response => {
                this.SearchSopResponse = response;
              this.searchResult = this.SearchSopResponse.Result;
              if (response.ErrorMessage != '') {
                this.errorMessage = response.ErrorMessage;
                //   this.errorNotification(response.ErrorMessage);
              } else if (response.Result == null) {
                var message = "No Data found for the selected search filters";
                
                this.errorMessage = message;
                //this.searchForm.reset();
                this.FetchServerListDetails();
                this.FetchControlMJobsDetails();
                this.ngOnInit();
                this.checkIfAdmin();
                //  this.errorNotification(message);
              } 
            });
      }
    }
    
     
    ngOnInit(): void {
      this.getProfile();
      this.checkIfAdmin();

      $('#txtSopNo').keydown(function (e) {
        if (e.shiftKey || e.ctrlKey || e.altKey) {
          e.preventDefault();
        } else {
          var key = e.keyCode;
          if (!((key == 8) || (key == 46) || (key >= 35 && key <= 40) || (key >= 48 && key <= 57) || (key >= 96 && key <= 105))) {
            e.preventDefault();
          }
        }
      });
    }
    
    public getProfile(): string {
        this.http.get<ProfileType>(GRAPH_ENDPOINT)
            .subscribe(profile => {
                this.profile = profile;
                this.username = profile.userPrincipalName.substring(0, profile.userPrincipalName.indexOf("@"));
                this.searchForm.get("LoggedInUser")?.setValue(this.username);
            });
        return this.username; 
    }

    getSelectedAirIds(e: any) {
        this.selectedAirIds.push(e.Id);
      this.searchForm.get("ApplicationId")?.setValue(e.Id);
      this.searchForm.get("AirId")?.setValue(e.AirId);
        return this.selectedAirIds;
  }

  checkIfAdmin() {
    let data: any = {
      LoggedInUser: sessionStorage.getItem('LoggedInUser')
    }
    return this.http.post<ServerListModel>(this.baseUrl + 'api/Alert/IsAdmin', data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.Result == true) {
        this.isAdmin = true;
    
      }
    });
  }

  FetchServerListDetails() {

    var log = { LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<ServerListModel>(this.baseUrl + 'api/Alert/FetchServerListData', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {


      this.app.ServerList = response.Result;
   

    });

  }



  FetchServerListDetailsForApplication(slists: any) {

    var log = { Id: slists.Id, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<ServerListModel>(this.baseUrl + 'api/Alert/FetchServerListData', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {

      this.app.ServerList = response.Result;
    

    });

  }

  openEdit(content: any, slists: ServerListModel) {
    this.formTitle = "Edit";
    $("#myModalTitle").text("Edit");
    this.onEdit = true;
    this.onNew = false;
    this.errorMessage = '';

    var log = { AirId: slists.AIRId, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    console.log(log);
    this.http.post<ServerListModel>(this.baseUrl + 'api/Alert/FetchServerListData', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
      }
      else {

        this.addForm.patchValue({
          Id: response.Result[0].Id, AirId_AppName: response.Result[0].AirId_AppName, Link: response.Result[0].Link,
          AirId: response.Result[0].AirId, CreatedBy: response.Result[0].OpenedBy, CreateDateTime: response.Result[0].CreateDateTime,
          ModifiedBy: response.Result[0].ModifiedBy, ModifiedDateTime: response.Result[0].ModifiedDateTime
        });

        this.username = response.Result[0].OpenedBy;


        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result: any) => {
          this.closeResult = `closed with: ${result}`;
        }, (reason: any) => {
          this.closeResult = `dismissed ${this.getDismissReason(reason)}`;
        });

      }
    });
  }

  delete(slists: ServerListModel) {

    let delData = { AirId: slists.AIRId, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    return this.http.post<ServerListModel>(this.baseUrl + 'api/Alert/DeleteServerlist', delData).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
      } else {
        
        var success = "Record has been deleted successfully.";
        this.successNotification(success);
        this.fetchApplications()
        this.FetchServerListDetails();
        this.addForm.reset();

      }
    });
  }

   
  findapps(Search: string) {
    this.sopReset();
        this.loading = true;
        if(Search == ''){
            this.selectedAirIds = [];
            this.searchForm.get("ApplicationId")?.setValue(0);
        }
        this.common.FindApplicationName("=" + Search + "=" + this.username)
            .subscribe(response => {
                this.loading = false;
                this.AppResponse = response;
                this.options = this.AppResponse!.Result;
                this.applications = this.options;
                this.applications.map(app => app.Name = `${app.AirId} - ${app.Name}`);
                if(response.ErrorMessage != ''){
                    //this.loading = false;
                  this.errorMessage = response.ErrorMessage;
                  //  this.errorNotification(response.ErrorMessage);
                }
            });
    }
  
  ContactService(content: any, alert:any) {
        this.loading = true;
        //let AirId = this.airid?.nativeElement.innerHTML;
        let AirId = alert.AirId;
        let LoggedInUser = sessionStorage.getItem('LoggedInUser');
        let application: any = { AirId: AirId, LoggedInUser: LoggedInUser }

        // const headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Authorization': `Bearer ${this._recv_token}`
        //   })
        //console.log("ContactService",this._recv_token);
     // this.open(content); //opening the modal window
        return this.http.post<ApplicationResponseModel>(this.baseUrl+"api/Alert/FindContactsByAirId", application).subscribe(response => {
          this.loading = false;
          this.ContactResponse = response;
            let resultData = JSON.parse(this.ContactResponse.Result);
          this.service = <SupportContactModel>resultData;
          if (response.ErrorMessage != '') {
            //this.loading = false;
            this.errorMessage = response.ErrorMessage;
         //   this.errorNotification(response.ErrorMessage);
          } else if (this.service == null) {
            var errorMessage = "No contact details found";
            this.errorMessage = errorMessage;
           // this.errorNotification(errorMessage);
            $('#contactModalClose').trigger('click');
          } else {
            this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }
 
        });

  }

  open(content: any) {
    this.formTitle = "Add New";
    this.modalTitle = "Add New";

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
  }



    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
  }




  SaveData() {
    $('#btnOrSaveIssue').prop('disabled', true);

    this.addForm.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));
    this.addForm.get('CreatedBy')?.setValue(sessionStorage.getItem('LoggedInUser'));
    this.addForm.get('ModifiedBy')?.setValue(sessionStorage.getItem('LoggedInUser'));

    if (this.addForm.value.AirId_AppName == null || this.addForm.value.AirId_AppName == "") {
      this.errorMessage = "AirId - ApplicationName field cannot be empty.";
      $("#btnOrSaveIssue").prop("disabled", false);
    }
    else if (this.addForm.value.Link == null || this.addForm.value.Link == "") {
      this.errorMessage = "ServerList Link field cannot be empty.";
      $("#btnOrSaveIssue").prop("disabled", false);
    }

    else if (this.addForm.value.AirId == null || this.addForm.value.AirId == "") {
      this.errorMessage = "Application AirId field cannot be empty.";
      $("#btnOrSaveIssue").prop("disabled", false);
    }

    else {

      this.SearchSopService.addServerlist(this.addForm.value)
        .subscribe(response => {
          if (response.ErrorMessage == '') {
            $("#btnOrSaveIssue").prop("disabled", false);
            var saveMessage = "Saved successfully!!";
            this.successNotification(saveMessage);
            $('#formclosebtn').trigger('click');
           // this.FetchServerListDetails();
            this.fetchApplications();
            this.addForm.reset();
            this.dateReset();
            this.username = "";
            //this.ngOnInit();

          } else {
            this.errorMessage = response.ErrorMessage;
            $("#btnOrSaveIssue").prop("disabled", false);
           
          }

        });
    }
   
  }

  FetchControlMJobsDetails() {

    var log = { LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<ControlMJobsListModel>(this.baseUrl + 'api/Alert/FetchControlMJobsList', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {


      this.app.ControlMList = response.Result;


    });

  }



  FetchControlMJobDetailsForApplication(clists: any) {

    var log = { Id: clists.Id, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<ControlMJobsListModel>(this.baseUrl + 'api/Alert/FetchControlMJobsList', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {

      this.app.ControlMList = response.Result;


    });

  }

  controlMEdit(content: any, clists: ControlMJobsListModel) {
    this.formTitle = "Edit";
    $("#myModalTitle").text("Edit");
    this.onEdit = true;
    this.onNew = false;
    this.errorMessage = '';

    var log = { AirId: clists.AIRId, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    console.log(log);
    this.http.post<ControlMJobsListModel>(this.baseUrl + 'api/Alert/FetchControlMJobsList', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
      }
      else {

        this.controlForm.patchValue({
          Id: response.Result[0].Id, AirId_AppName: response.Result[0].AirId_AppName, Link: response.Result[0].Link,
          AirId: response.Result[0].AirId, CreatedBy: response.Result[0].OpenedBy, CreateDateTime: response.Result[0].CreateDateTime,
          ModifiedBy: response.Result[0].ModifiedBy, ModifiedDateTime: response.Result[0].ModifiedDateTime
        });

        this.username = response.Result[0].OpenedBy;


        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result: any) => {
          this.closeResult = `closed with: ${result}`;
        }, (reason: any) => {
          this.closeResult = `dismissed ${this.getDismissReason(reason)}`;
        });

      }
    });
  }

  SaveControlMJobsData() {
    $('#btnOrSaveForm').prop('disabled', true);

    this.controlForm.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));
    this.controlForm.get('CreatedBy')?.setValue(sessionStorage.getItem('LoggedInUser'));
    this.controlForm.get('ModifiedBy')?.setValue(sessionStorage.getItem('LoggedInUser'));

    if (this.controlForm.value.AirId_AppName == null || this.controlForm.value.AirId_AppName == "") {
      this.errorMessage = "AirId - ApplicationName field cannot be empty.";
      $("#btnOrSaveForm").prop("disabled", false);
    }
    else if (this.controlForm.value.Link == null || this.controlForm.value.Link == "") {
      this.errorMessage = "ServerList Link field cannot be empty.";
      $("#btnOrSaveForm").prop("disabled", false);
    }

    else if (this.controlForm.value.AirId == null || this.controlForm.value.AirId == "") {
      this.errorMessage = "Application AirId field cannot be empty.";
      $("#btnOrSaveForm").prop("disabled", false);
    }

    else {

      this.SearchSopService.addControlMJobslist(this.controlForm.value)
        .subscribe(response => {
          if (response.ErrorMessage == '') {
            $("#btnOrSaveForm").prop("disabled", false);
            var saveMessage = "Saved successfully!!";
            this.successNotification(saveMessage);
            $('#formclosebtn').trigger('click');
            // this.FetchServerListDetails();
            this.fetchApplications();
            this.controlForm.reset();
            this.dateReset();
            this.username = "";
            //this.ngOnInit();

          } else {
            this.errorMessage = response.ErrorMessage;
            $("#btnOrSaveForm").prop("disabled", false);

          }

        });
    }

  }

  deleteControlMData(clists: ControlMJobsListModel) {

    let delData = { AirId: clists.AIRId, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    return this.http.post<ControlMJobsListModel>(this.baseUrl + 'api/Alert/DeleteControlMJobsList', delData).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.ErrorMessage != '') {
        this.errorMessage = response.ErrorMessage;
      } else {

        var success = "Record has been deleted successfully.";
        this.successNotification(success);
        this.fetchApplications()
        this.FetchControlMJobsDetails();
        this.controlForm.reset();

      }
    });
  }

  dateReset() {
    this.addForm.reset();
    this.onEdit = false;
    this.onNew = true;
    this.onOpen = true;
    this.sopReset();
  }

  formReset() {
     
      this.errorMessage = "";
    this.onEdit = false;
    this.onNew = true;
    this.onOpen = true;
    }
    
  
    downloadsop(sample:any) {
        // let AlertSample = this.AlertSample?.nativeElement.innerHTML;
        // let LoggedInUser = this.LoggedInUser?.nativeElement.value;
        let data =
        {
          AlertSample: sample, LoggedInUser: this.username
      };
      const headers = { Accept: "application/octet-stream" };      
      //let options = { headers: headers };
      return this.http.post(this.baseUrl + "api/Alert/DownloadSop", data, { responseType: 'blob', headers: headers })
        .subscribe(response => {
              this.loading = false;
          console.log(response);
          const url = URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = url;
          a.target = '_blank';
          a.download = 'SampleEmail.msg';
          document.body.appendChild(a);
          a.click();              
        });      
  }
   
  sopReset() {
    this.errorMessage = '';
}

    GetNotificationCountbyUser() {
        this.loading = true;
        let LoggedInUser = this.username;
        let data =
        {
            LoggedInUser: LoggedInUser
        };
        return this.http.post<NotificationResponse>(this.baseUrl+ "api/Notification/GetNotificationCountbyUser", data)
            .subscribe(response => {
                this.loading = false;
                this.NotificationResponse = response;
                this.NotificationModel = this.NotificationResponse.Result;
                if(response.ErrorMessage != ''){
                  this.errorMessage = response.ErrorMessage;
                   // this.errorNotification(response.ErrorMessage);
                }
            });
    }

  private getdismissreason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing esc';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

    private extractData(res: any) {
        let body = res;
        return body;
    }
    private handleErrorObservable(error: any) {
        console.error(error.message || error);
        return throwError(error);
    }
}
    

