<!--<!DOCTYPE html>
<html lang="en">
<head>
  <title>Global Prevention Center</title>-->
<!--<base href="@System.Configuration.ConfigurationManager.AppSettings[" AppUrl"]" />-->
<!--<meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="SHORTCUT ICON" href="~/Content/Images/favicon.ico" />

  <link href="~/Content/Styles/font-awesome.min.css" rel="stylesheet">
  <script>
        window.CKEDITOR_BASEPATH = '/Scripts/ckeditor/';
  </script>

  <script src="/Scripts/ckeditor/ckeditor.js"></script>

</head>

<body ng-hide="!userInfo.isAuthenticated">-->
<!--if (env == "production")
{
<div id="acn-alert" class="acn-auth-azure" data-widgetid="678" data-appid="129"></div>
<link rel="stylesheet" href="https://notificationsadmin.accenture.com/widget/acnalerts.css">
<script src="https://notificationsadmin.accenture.com/widget/acnalerts.js"></script>
<div id="acn-toast" class="acn-auth-azure" data-widgetid="679" data-appid="129"></div>
<link rel="stylesheet" href="https://notificationsadmin.accenture.com/widget/acntoasts.css">
<script src="https://notificationsadmin.accenture.com/widget/acntoasts.js"></script>
<div id="acn-modal" class="acn-auth-azure" data-widgetid="680" data-appid="129"></div>
<link rel="stylesheet" href="https://notificationsadmin.accenture.com/widget/acnmodal.css">
<script src="https://notificationsadmin.accenture.com/widget/acnmodal.js"></script>
}

if (env == "stage")
{
<div id="acn-alert" class="acn-auth-azure" data-widgetid="2439" data-appid="1055"></div>
<link rel="stylesheet" href="https://notificationsadmin.ciostage.accenture.com/widget/acnalerts.css">
<script src="https://notificationsadmin.ciostage.accenture.com/widget/acnalerts.js"></script>
<div id="acn-toast" class="acn-auth-azure" data-widgetid="2440" data-appid="1055"></div>
<link rel="stylesheet" href="https://notificationsadmin.ciostage.accenture.com/widget/acntoasts.css">
<script src="https://notificationsadmin.ciostage.accenture.com/widget/acntoasts.js"></script>
<div id="acn-modal" class="acn-auth-azure" data-widgetid="2441" data-appid="1055"></div>
<link rel="stylesheet" href="https://notificationsadmin.ciostage.accenture.com/widget/acnmodal.css">
<script src="https://notificationsadmin.ciostage.accenture.com/widget/acnmodal.js"></script>

}-->
<!--<form name='modalOnboardingForm'>!-->
<!--<mat-toolbar color="primary">
  <img class="logo" src="/assets/Images/gpcLogo.png" alt="GPC logo">
  <a class="title" href="/">{{title}}</a>

  <div class="toolbar-spacer"></div>-->
<!--<a mat-button [routerLink]="['profile']">Profile</a>-->
<!--<div class="navbar-collapse">
  <ul class="top-nav navbar-nav ml-auto">
    <li><img src="/assets/Images/accLogo.png" style="padding-top: 5px;" alt="Accenture logo"></li>

    <li style="padding-top: 10px;padding-left: 10px;padding-right: 10px;" class="user"><a style="color:#eee"><span id="spanWelcome" class="ng-binding">Welcome {{username}}</span></a></li>

  </ul>
</div>-->
<!--<button class="logoutButton" mat-raised-button (click)="logout()"><fa-icon [icon]="['fas', 'right-from-bracket']"></fa-icon>Logout</button>-->
<!-- <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button> -->
<!--<button class="logoutButton" mat-raised-button (click)="logout()">Logout</button>

</mat-toolbar>-->
<input hidden id="Environment" value={{env}} />
<input hidden id="loggedUser" value={{username}} />


<div class="container-fluid" id="onboardingAddNewForm" ng-controller="OnboardingFormController">

  <div class="row">
    <div class="navbar">
      <ul>
        <li><fa-icon [icon]="['fas','address-card']"></fa-icon><a> Onboarding Form</a></li>
      </ul>
    </div>

    <div class="col-md-9 offset-sm-3 col-lg-10 offset-md-2 main">

      <div class="row">
        <h1 class="pb-2 mt-4 mb-2 border-bottom" style="margin-left: -13px;">
          <span>CIO GPC Onboarding Form</span>
          <button type="button" id="btnItAddNew" style="float: right;margin-right: 30px; margin-top:5px" class="btn btn-primary" (click)="open(modalForm); dateReset();"><span class="fa fa-plus"></span>Add New</button>
        </h1>
        <div class="row searchAreaRow" *ngIf="pendingRequest != null">
          <form class="searchForm" [formGroup]="searchForm">
            <div class="searchBox col-lg-3">
              <select class="form-control" name="status" formControlName="status" #status>
                <option [ngValue]="null" selected>--Status--</option>
                <option *ngFor="let status of asStatus; let i = index" [value]="asStatus[i].value" style="color:grey;">{{asStatus[i].value}}</option>
              </select>
            </div>
            <div class="searchBox col-lg-3">
              <select class="form-control" name="service" formControlName="service" #service>
                <option [ngValue]="null" selected>--Service Tier--</option>
                <option *ngFor="let tier of asTier; let i = index" [value]="asTier[i].value" style="color:grey;">{{asTier[i].value}}</option>
              </select>
            </div>
            <div class="searchBoxButton col-lg-3">
              <button type="submit" id="btnSrSearch" class="btn btn-primary" (click)="FilterRequests();"><span class="fa fa-search"></span> Search </button>
            </div>

            <!--<div class="searchBoxButton col-lg-3">
        <button id="btnSrSearch" class="btn btn-primary"><span class="fa fa-refresh" (click)="FetchOnboardingDetails();"></span> Refresh </button>
      </div>-->
          </form>


        </div>

        <br />
        <br />

        <div class="alert alert-danger" *ngIf="errorMessage1" id="divError">
          {{errorMessage1}}
        </div>
        <!--Add new Form starts-->
        <ng-template #modalForm let-modal>
          <div class="modal-header">
            <h4 class="modal-title">Onboarding Request</h4>
            <button id="PPSClosebtn" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click'); formReset();"><span aria-hidden="true">&times;</span></button>
          </div>
          <form name='modalForm' [formGroup]="requestForm">
            <div class="icon-container" [hidden]="onresponse">
              <i class="loader"></i>
            </div>
            <fieldset [disabled]="formDisabled">
              <div id="modbody" class="modal-body" style="margin-left: 70px;">

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">1.Application AIR ID<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-5">
                    <input id="appAIRid" class="form-control" name="AirId" formControlName="AirId" #AirId [readonly]="onEdit" (change)="findAIRdetails($event);">

                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">2.Application Name<em style="color:red; margin-left: 5px;">*</em> :</label>
                  <div class="col-md-5">
                    <input id="appname" class="form-control" name="ApplicationName" formControlName="ApplicationName" [value]="appName" #ApplicationName [readonly]="ApplicationName">

                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">3.Service Operation MD:</label>
                  <div class="col-md-5">
                    <input id="serviceOpsMD" class="form-control" name="ServiceOperationMD" formControlName="ServiceOperationMD" [value]="serviceOpsMD" #ServiceOperationMD [readonly]="ServiceOperationMD">
                    <!--<div class="ng-autocomplete" id="MDAutocomplete">
                <ng-autocomplete [data]="SearchPeople"
                                 [searchKeyword]="keywordP"
                                 placeholder="Enterprise ID"
                                 (selected)='selectEventAddMD($event)'
                                 (inputChanged)='LoadPeopleByName($event)'
                                 historyIdentifier="SearchPeople"
                                 [itemTemplate]="leadsTemplate"
                                 [disabled]="formDisabled"
                                 [notFoundTemplate]="notFoundTemplateL">
                </ng-autocomplete>

                <ng-template #leadsTemplate let-people>
                  <a [innerHTML]="people.Name"></a>
                </ng-template>

                <ng-template #notFoundTemplateL let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>-->
                  </div>
                </div>
                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">4.Service Operation Lead:</label>
                  <div class="col-md-5">
                    <input id="serviceOpsLead" class="form-control" name="ServiceLead" formControlName="ServiceLead" [value]="serviceOpsLead" #ServiceLead [readonly]="ServiceLead">
                    <!--<div class="ng-autocomplete" id="ServiceAutocomplete">
                <ng-autocomplete [data]="SearchPeople"
                                 [searchKeyword]="keywordP"
                                 placeholder="Enterprise ID"
                                 (selected)='selectEventAddLead($event)'
                                 (inputChanged)='LoadPeopleByName($event)'
                                 historyIdentifier="SearchPeople"
                                 [itemTemplate]="leadsTemplate"
                                 [disabled]="formDisabled"
                                 [notFoundTemplate]="notFoundTemplateL">
                </ng-autocomplete>

                <ng-template #leadsTemplate let-people>
                  <a [innerHTML]="people.Name"></a>
                </ng-template>

                <ng-template #notFoundTemplateL let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>-->
                  </div>
                </div>


                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">5.Service Operations Manager :</label>
                  <div class="col-md-5">
                    <input id="serviceOpsLead" class="form-control" name="ServiceOperationsManager" formControlName="ServiceOperationsManager" [value] ="serviceOpsMan" #ServiceOperationsManager [readonly]="ServiceOperationsManager">
                    <!--<div class="ng-autocomplete" id="ServiceManager">
                <ng-autocomplete [data]="SearchPeople"
                                 [searchKeyword]="keywordP"
                                 placeholder="Enterprise ID"
                                 (selected)='selectEventAddSerManager($event)'
                                 (inputChanged)='LoadPeopleByName($event)'
                                 historyIdentifier="SearchPeople"
                                 [itemTemplate]="leadsTemplate"
                                 [disabled]="formDisabled"
                                 [notFoundTemplate]="notFoundTemplateL">
                </ng-autocomplete>
                <ng-template #leadsTemplate let-people>
                  <a [innerHTML]="people.Name"></a>
                </ng-template>

                <ng-template #notFoundTemplateL let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>-->
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">6.Application Point of Contact (Onboarding) :</label>
                  <div class="col-md-5" id="ApplicationPointofContact">
                    <input id="appLead" class="form-control" name="ApplicationPointofContact" formControlName="ApplicationPointofContact" #ApplicationPointofContact [readonly]="ApplicationPointofContact">

                    <!--<ng-autocomplete [data]="SearchPeople"
                               [searchKeyword]="keywordP"
                               placeholder="Enterprise ID"
                               (selected)='selectEventAddPOC($event)'
                               (inputChanged)='LoadPeopleByName($event)'
                               historyIdentifier="SearchPeople"
                               [itemTemplate]="ApplicationPointofContact"
                               [disabled]="formDisabled"
                               [notFoundTemplate]="notFoundTemplateL">
              </ng-autocomplete>

              <ng-template #ApplicationPointofContact let-people>
                <a [innerHTML]="people.Name"></a>
              </ng-template>

              <ng-template #notFoundTemplateL let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>-->
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;"> 7.Application Service Tier<em style="color:red;">*</em> : </label>
                  <div class="col-sm-5">
                    <input id="appTier" class="form-control" name="ApplicationServiceTier" formControlName="ApplicationServiceTier" #ApplicationServiceTier [value]="appTier" [readonly]="ApplicationServiceTier"/>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;"> 8.GPC Onboarding Type : </label>
                  <div class="col-sm-5">
                    <select name="GPCOnboardingType" formControlName="GPCOnboardingType" class="form-control" #GPCOnboardingType>
                      <option [ngValue]="null" selected>--select the Onboarding Type--</option>
                      <option *ngFor="let tier of gpcType; let i = index" [value]="gpcType[i].value" style="color:grey;">{{gpcType[i].value}}</option>
                    </select>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;"> 9.Monitoring Alert Source : </label>
                  <div class="col-sm-5">
                    <select name="MonitoringAlertSource" formControlName="MonitoringAlertSource" class="form-control" #MonitoringAlertSource>
                      <option [ngValue]="null" selected>--select the Monitoring Alert Source--</option>
                      <option *ngFor="let source of maSource; let i = index" [value]="maSource[i].value" style="color:grey;">{{maSource[i].value}}</option>
                    </select>
                  </div>
                </div>


                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">
                    10.Total amount of P1/P2 incidents in previous Fiscal Year :
                  </label>
                  <div class="col-md-5">
                    <input type="text" id="txtTotalamountofpreviousFiscalYear" class="form-control" style="width:100%;" name="TotalAmountP1P2IncidentsFiscalYear" formControlName="TotalAmountP1P2IncidentsFiscalYear" #TotalAmountP1P2IncidentsFiscalYear />
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">
                    11.Application Distribution List (Support):
                  </label>
                  <div class="col-md-5">
                    <input type="text" id="txtApplicationDistributionList" class="form-control" style="width:100%;" name="ApplicationDistributionList" formControlName="ApplicationDistributionList" #ApplicationDistributionList />
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;"> 12.High Availability Enabled  : </label>
                  <div class="col-sm-5">
                    <select name="HighAvailabilityEnabled" formControlName="HighAvailabilityEnabled" class="form-control" #HighAvailabilityEnabled>
                      <option [ngValue]="null" selected>--select the High Availability Enabled--</option>
                      <option *ngFor="let enabled of haEnabled; let i = index" [value]="haEnabled[i].value" style="color:grey;">{{haEnabled[i].value}}</option>
                    </select>
                  </div>
                </div>

                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">
                    13.Number of Application Users :
                  </label>
                  <div class="col-md-5">
                    <input type="text" id="txtNumberofApplicationUser" class="form-control" style="width:100%;" name="NumberofApplicationUsers" formControlName="NumberofApplicationUsers" #NumberofApplicationUsers />
                  </div>
                </div>


                <div class="row form-group mb-3">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">14.Files Upload:</label>
                  <div class="col-md-6">
                    <input type="file" id="file" multiple
                           (change)="getFileDetails($event)" accept="all/*" name="file" #file [disabled]="ifApproved" />
                    <button (click)="uploadFiles()" class="btn btn-primary" style="width: 28%; height: 35px; text-align: center; margin-top: 12px; margin-left: 135px;" [disabled]="ifApproved">Upload File</button>
                  </div>
                </div>
                <div class="row form-group mb-3" *ngIf="SelectedFiles.length > 0">
                  <label class="col-form-label col-md-4" for="text" style="text-align: left;">Attached Images:</label>
                  <div class="col-md-6">
                    <ul class="ft-file-list">
                      <li *ngFor="let file of SelectedFiles">
                        <div class="ft-modal-upload-filename">
                          <a type="button" style="color:#0000EE " (click)="previewAttachment(file)">{{file.fName}}</a>
                          <a class="text-danger ft-modal-upload-delete-file" (click)="removeFile(file)" *ngIf="requestForm.value.Status !='Onboarded'"> <fa-icon [icon]="['fas', 'trash']"></fa-icon></a>
                        </div>

                      </li>
                    </ul>
                  </div>
                </div>

              </div> <!--Modal body closing-->
            </fieldset>
            <div class="alert alert-danger" *ngIf="errorMessage">
              {{errorMessage}}
            </div>
            <div class="row form-group" style="display: none">
              <input class="col-sm-5" name="LoggedInUser" formControlName="LoggedInUser" value="{{username}}" #LoggedInUser />
            </div>

            <div class="row form-group" style="display: none">
              <label class="col-form-label col-md-4" for="text" style="text-align: left;">Created By :</label>
              <div class="col-sm-5" id="createdBy" name="CreatedBy" #CreatedBy>
                {{username}}
              </div>
            </div>
            <div class="modal-footer" id="saveOnboardingFormData" [hidden]="formDisabled">
              <div>
                <div class="float-right">
                  <button id="btnOrSaveIssue" type="submit" class="btn btn-secondary" (click)="SaveFormRecord();">Save changes</button>
                  <button id="formclosebtn" type="button" class="btn btn-primary" data-dismiss="modal" (click)="modal.dismiss('Close click'); formReset();">Close</button>
                  <input type="hidden" id="hdnAppId" />
                </div>
              </div>
            </div>

          </form>
          <!--</div>--><!--modal dialog closing-->
        </ng-template>
        <div class="noRequest" *ngIf="pendingRequest == null"><p>You have not registered a request yet. Please click on "Add New" button to register a request.</p></div>
        <div id="divSuccess" class="row" ng-show="show">

          <div class="card-group" style="width:100%" id="accordion">

            <div class="card-group" style="width:100%" id="accordion">

              <div class="card" style="border:none">
                <mat-accordion>
                  <ng-container *ngFor="let data of pendingRequest">
                    <mat-expansion-panel (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false" *ngIf="data.Status=='Requested'" style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;" (click)="FetchOnboardingDetailsForApplication(data);">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <h4 class="card-title">
                            <strong>{{data.ApplicationName}}</strong>
                          </h4>
                        </mat-panel-title>
                        <div class="col-md-4">
                          <div style="float:left"><strong>Over All Status :</strong></div>
                          <div id="light" style="padding-left: 120px">
                            <span id="OverAllStatus" [class]="data.Status"></span> <!--need to add variable fetched from database instead of Red-->
                          </div>
                        </div>



                      </mat-expansion-panel-header>
                      <div class="shiftTurntableWrap">
                        <table class="table table-bordered display-table" style="width:100%">
                          <thead>
                            <tr style="background-color: #f5f5f5;">
                            <tr style="background-color: #f5f5f5;">
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Air Id</strong></td>
                              <td style="border:solid; border-width:1px; text-align: left"><strong>Application Name</strong></td>
                              <td style="border:solid; border-width:1px; text-align: left"><strong>SO Lead</strong></td>
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Application Lead</strong></td>
                              <td style="border: solid; border-width: 1px; text-align: center"><strong>Status</strong></td>
                              <td style="border: solid; border-width: 1px; text-align: center"><strong>Request Date</strong></td>
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Edit/View</strong></td>

                            </tr>
                          </thead>
                          <tbody>
                            <tr class="custom-column" *ngFor="let data of pendingRequestForApplication">
                              <td style="border:solid; border-width:1px; text-align: left">{{data.AirId}}</td>
                              <td style="border:solid; border-width:1px; text-align: left">{{data.ApplicationName}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.ServiceLead}}</td>
                              <td style="border:solid; border-width:1px; text-align: center;word-break:break-word;width:12%">{{data.ApplicationPointofContact}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.Status}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.CreateDateTime|date:'dd/MM/yyyy'}}</td>
                              <td style="border:solid; border-width:1px; text-align: center">
                                <button type="button" class="btn btn-primary" [disabled]="enableAfterResponse" (click)="openEdit(modalForm, data);"><span class="fa fa-edit"></span>Edit</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </mat-expansion-panel>
                  </ng-container>
                  <ng-container *ngFor="let data of pendingRequest">
                    <mat-expansion-panel (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false" *ngIf="data.Status=='Approved'" style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;" (click)="FetchOnboardingDetailsForApplication(data);">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <h4 class="card-title">
                            <strong>{{data.ApplicationName}}</strong>
                          </h4>
                        </mat-panel-title>
                        <div class="col-md-4">
                          <div style="float:left"><strong>Over All Status :</strong></div>
                          <div id="light" style="padding-left: 120px">
                            <span id="OverAllStatus" [class]="data.Status"></span> <!--need to add variable fetched from database instead of Red-->
                          </div>
                        </div>



                      </mat-expansion-panel-header>
                      <div class="shiftTurntableWrap">
                        <table class="table table-bordered display-table" style="width:100%">
                          <thead>
                            <tr style="background-color: #f5f5f5;">
                            <tr style="background-color: #f5f5f5;">
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Air Id</strong></td>
                              <td style="border:solid; border-width:1px; text-align: left"><strong>Application Name</strong></td>
                              <td style="border:solid; border-width:1px; text-align: left"><strong>SO Lead</strong></td>
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Application Lead</strong></td>
                              <td style="border: solid; border-width: 1px; text-align: center"><strong>Status</strong></td>
                              <td style="border: solid; border-width: 1px; text-align: center"><strong>Request Date</strong></td>
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Edit/View</strong></td>

                            </tr>
                          </thead>
                          <tbody>
                            <tr class="custom-column" *ngFor="let data of pendingRequestForApplication">
                              <td style="border:solid; border-width:1px; text-align: left">{{data.AirId}}</td>
                              <td style="border:solid; border-width:1px; text-align: left">{{data.ApplicationName}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.ServiceLead}}</td>
                              <td style="border:solid; border-width:1px; text-align: center;word-break:break-word;width:12%">{{data.ApplicationPointofContact}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.Status}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.CreateDateTime|date:'dd/MM/yyyy'}}</td>
                              <td style="border:solid; border-width:1px; text-align: center">
                                <button type="button" class="btn btn-primary" [disabled]="enableAfterResponse" (click)="openEdit(modalForm, data);"><span class="fa fa-edit"></span>Edit</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </mat-expansion-panel>
                  </ng-container>
                  <ng-container *ngFor="let data of pendingRequest">
                    <mat-expansion-panel (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false" *ngIf="data.Status=='Review'" style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;" (click)="FetchOnboardingDetailsForApplication(data);">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <h4 class="card-title">
                            <strong>{{data.ApplicationName}}</strong>
                          </h4>
                        </mat-panel-title>
                        <div class="col-md-4">
                          <div style="float:left"><strong>Over All Status :</strong></div>
                          <div id="light" style="padding-left: 120px">
                            <span id="OverAllStatus" [class]="data.Status"></span> <!--need to add variable fetched from database instead of Red-->
                          </div>
                        </div>



                      </mat-expansion-panel-header>
                      <div class="shiftTurntableWrap">
                        <table class="table table-bordered display-table" style="width:100%">
                          <thead>
                            <tr style="background-color: #f5f5f5;">
                            <tr style="background-color: #f5f5f5;">
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Air Id</strong></td>
                              <td style="border:solid; border-width:1px; text-align: left"><strong>Application Name</strong></td>
                              <td style="border:solid; border-width:1px; text-align: left"><strong>SO Lead</strong></td>
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Application Lead</strong></td>
                              <td style="border: solid; border-width: 1px; text-align: center"><strong>Status</strong></td>
                              <td style="border: solid; border-width: 1px; text-align: center"><strong>Request Date</strong></td>
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Edit/View</strong></td>

                            </tr>
                          </thead>
                          <tbody>
                            <tr class="custom-column" *ngFor="let data of pendingRequestForApplication">
                              <td style="border:solid; border-width:1px; text-align: left">{{data.AirId}}</td>
                              <td style="border:solid; border-width:1px; text-align: left">{{data.ApplicationName}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.ServiceLead}}</td>
                              <td style="border:solid; border-width:1px; text-align: center;word-break:break-word;width:12%">{{data.ApplicationPointofContact}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.Status}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.CreateDateTime|date:'dd/MM/yyyy'}}</td>
                              <td style="border:solid; border-width:1px; text-align: center">
                                <button type="button" class="btn btn-primary" [disabled]="enableAfterResponse" (click)="openEdit(modalForm, data);"><span class="fa fa-edit"></span>Edit</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </mat-expansion-panel>
                  </ng-container>
                  <ng-container *ngFor="let data of pendingRequest">
                    <mat-expansion-panel (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false" *ngIf="data.Status=='Onboarded'" style=" border: thick; border: solid; border-color: lightgrey; margin-bottom: 2%;" (click)="FetchOnboardingDetailsForApplication(data);">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <h4 class="card-title">
                            <strong>{{data.ApplicationName}}</strong>
                          </h4>
                        </mat-panel-title>
                        <div class="col-md-4">
                          <div style="float:left"><strong>Over All Status :</strong></div>
                          <div id="light" style="padding-left: 120px">
                            <span id="OverAllStatus" [class]="data.Status"></span> <!--need to add variable fetched from database instead of Red-->
                          </div>
                        </div>



                      </mat-expansion-panel-header>
                      <div class="shiftTurntableWrap">
                        <table class="table table-bordered display-table" style="width:100%">
                          <thead>
                            <tr style="background-color: #f5f5f5;">
                            <tr style="background-color: #f5f5f5;">
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Air Id</strong></td>
                              <td style="border:solid; border-width:1px; text-align: left"><strong>Application Name</strong></td>
                              <td style="border:solid; border-width:1px; text-align: left"><strong>SO Lead</strong></td>
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Application Lead</strong></td>
                              <td style="border: solid; border-width: 1px; text-align: center"><strong>Status</strong></td>
                              <td style="border: solid; border-width: 1px; text-align: center"><strong>Request Date</strong></td>
                              <td style="border:solid; border-width:1px; text-align: center"><strong>Edit/View</strong></td>

                            </tr>
                          </thead>
                          <tbody>
                            <tr class="custom-column" *ngFor="let data of pendingRequestForApplication">
                              <td style="border:solid; border-width:1px; text-align: left">{{data.AirId}}</td>
                              <td style="border:solid; border-width:1px; text-align: left">{{data.ApplicationName}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.ServiceLead}}</td>
                              <td style="border:solid; border-width:1px; text-align: center;word-break:break-word;width:12%">{{data.ApplicationPointofContact}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.Status}}</td>
                              <td style="border:solid; border-width:1px; text-align: left;word-break:break-word">{{data.CreateDateTime|date:'dd/MM/yyyy'}}</td>
                              <td style="border:solid; border-width:1px; text-align: center">
                                <button type="button" class="btn btn-primary" [disabled]="enableAfterResponse" (click)="openEdit(modalForm, data); makeDisable();"><span class="fa fa-edit"></span>View</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </mat-expansion-panel>
                  </ng-container>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
        <!--template form selection block starts-->
        <!--<div class="card template-form">
    <button type="button" class="btn btn-default app-panel" data-toggle="collapse" data-target="#app3"><strong><a class="templateDownload" href="assets/Template.xlsx">Download Playbook Template</a></strong></button>

    <button type="button" class="btn btn-default app-panel" data-toggle="collapse" data-target="#app4"><strong><a class="templateDownload" href="assets/Template.xlsx">Download xMatters Template</a></strong></button>

    <button type="button" class="btn btn-default app-panel" data-toggle="collapse" data-target="#app1"><strong><a class="templateDownload" href="assets/Template.xlsx">Download Mock Drills Template</a></strong></button>

    <button type="button" class="btn btn-default app-panel" data-toggle="collapse" data-target="#app2"><strong><a class="templateDownload" href="assets/Template.xlsx">Download Monitoring SOP Template</a></strong></button>

  </div>-->
        <div id="divSuccess" class="row" ng-show="show" *ngIf="pendingRequest != null">
          <div class="card-group" style="width:100%" id="accordion">

            <div class="card" style="border:none" [hidden]="dependsOnStatus">
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false" style=" border: thick; border: solid; border-color: lightgrey;">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h4 class="card-title">
                        <strong>Playbook Template</strong>
                      </h4>
                    </mat-panel-title>

                  </mat-expansion-panel-header>
                  <mat-panel-description>
                    File to enter a step by step walk through of a P1/P2 incident, information such as affected apps (primary or secondary), resolved timelines, teams engaged to solve the issue, etc should be included and shared to use in future scenarios.
                  </mat-panel-description>
                  <br>
                  <div class="shiftTurntableWrap">
                    <table class="table table-bordered display-table" style="width:100%">
                      <tbody>
                        <tr class="custom-column">
                          <td style="border:solid; border-width:1px; text-align: left">ApplicationName Playbook Sample</td>
                          <td style="border:solid; border-width:1px; text-align: center">
                            <!--<fa-icon [icon]="['fas', 'download']"></fa-icon><a class="templateDownload" href="assets/Playbook_Template/ApplicationName_Playbook_Sample_20220310.xlsm"> Download</a>-->
                            <fa-icon [icon]="['fas', 'download']"></fa-icon><button class="templateDownload" type="button" [disabled]="clickedsampleFileplaybook" name="ApplicationName_Playbook_Sample_20220310.xlsm" style="cursor: pointer;" (click)="sampleDownload(sampleFileplaybook); clickedsampleFileplaybook = true;" #sampleFileplaybook> Download</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false" style=" border: thick; border: solid; border-color: lightgrey;">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h4 class="card-title">
                        <strong>xMatters Template</strong>
                      </h4>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-panel-description>
                    File to create a distribution list (as per format) that GSM will use for Communications in case of a P1/P2 issue. Keep in mind that the communications are the emails sent by GSM including all P1/P2 related information such as, business impact, incident updates, etc
                  </mat-panel-description>
                  <br>
                  <div class="shiftTurntableWrap">
                    <table class="table table-bordered display-table" style="width:100%">
                      <tbody>
                        <tr class="custom-column">
                          <td style="border:solid; border-width:1px; text-align: left">ApplicationName xMatters Sample</td>
                          <td style="border:solid; border-width:1px; text-align: center">
                            <!--<fa-icon [icon]="['fas', 'download']"></fa-icon><a class="templateDownload" href="assets/xMatters_Template/ApplicationName_xMatters.xlsx"> Download</a>-->
                            <fa-icon [icon]="['fas', 'download']"></fa-icon><button class="templateDownload" type="button" [disabled]="clickedsampleFileXmatters" name="ApplicationName_xMatters.xlsx" style="cursor: pointer;" (click)="sampleDownload(sampleFileXmatters); clickedsampleFileXmatters = true;" #sampleFileXmatters> Download</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false" style=" border: thick; border: solid; border-color: lightgrey;">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h4 class="card-title">
                        <strong>Mock Drills Template</strong>
                      </h4>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-panel-description>
                    File required to be filled with application information to simulate a P1/P2 scenario that will test your team’s response time and any additional team needed to be gathered depending on the issue scenario you choose to re-create.
                  </mat-panel-description>
                  <br>
                  <div class="shiftTurntableWrap">
                    <table class="table table-bordered display-table" style="width:100%">
                      <tbody>
                        <tr class="custom-column">
                          <td style="border:solid; border-width:1px; text-align: left">GPC Mock drill Rehearsal - Scenario 1</td>
                          <td style="border:solid; border-width:1px; text-align: center">
                            <!--<fa-icon [icon]="['fas', 'download']"></fa-icon><a class="templateDownload" href="assets/Mock_Drills_Template/GPC MockDrillRehersal_1 - Disk space issue.xlsx"> Download</a>-->
                            <fa-icon [icon]="['fas', 'download']"></fa-icon><button class="templateDownload" type="button" [disabled]="clickedsampleFileMock1" name="GPC MockDrillRehersal_1 - Disk space issue.xlsx" style="cursor: pointer;" (click)="sampleDownload(sampleFileMock1); clickedsampleFileMock1 = true;" #sampleFileMock1> Download</button>
                          </td>
                        </tr>
                        <tr class="custom-column">
                          <td style="border:solid; border-width:1px; text-align: left">GPC Mock drill Rehearsal - Scenario 2</td>
                          <td style="border:solid; border-width:1px; text-align: center">
                            <!--<fa-icon [icon]="['fas', 'download']"></fa-icon><a class="templateDownload" href="assets/Mock_Drills_Template/GPC MockDrillRehersal_2 - Scenario2.xlsx"> Download</a>-->
                            <fa-icon [icon]="['fas', 'download']"></fa-icon><button class="templateDownload" type="button" [disabled]="clickedsampleFileMock2" name="GPC MockDrillRehersal_2 - Scenario2.xlsx" style="cursor: pointer;" (click)="sampleDownload(sampleFileMock2); clickedsampleFileMock2 = true;" #sampleFileMock2> Download</button>
                          </td>
                        </tr>
                        <tr class="custom-column">
                          <td style="border:solid; border-width:1px; text-align: left">GPC Mock drill Rehearsal - Scenario 3</td>
                          <td style="border:solid; border-width:1px; text-align: center">
                            <!--<fa-icon [icon]="['fas', 'download']"></fa-icon><a class="templateDownload" href="assets/Mock_Drills_Template/GPC MockDrillRehersal_3 - Scenario3.xlsx"> Download</a>-->
                            <fa-icon [icon]="['fas', 'download']"></fa-icon><button class="templateDownload" type="button" [disabled]="clickedsampleFileMock3" name="GPC MockDrillRehersal_3 - Scenario3.xlsx" style="cursor: pointer;" (click)="sampleDownload(sampleFileMock3); clickedsampleFileMock3 = true;" #sampleFileMock3> Download</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false" style=" border: thick; border: solid; border-color: lightgrey;">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h4 class="card-title">
                        <strong>Monitoring SOP Template</strong>
                      </h4>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-panel-description>
                    File to create a step by step document, with the action items you need GPC Monitoring Team to take in order to assess your alerts and handled them under a Standard Operation Procedure.
                  </mat-panel-description>
                  <br>
                  <div class="shiftTurntableWrap">
                    <table class="table table-bordered display-table" style="width:100%">
                      <tbody>
                        <tr class="custom-column">
                          <td style="border:solid; border-width:1px; text-align: left">Monitoring SOP Template</td>
                          <td style="border:solid; border-width:1px; text-align: center">
                            <!--<fa-icon [icon]="['fas', 'download']"></fa-icon><a class="templateDownload" href="assets/Monitoring_SOP_Template/ApplicationName_Monitoring_SOP.xlsx"> Download</a>-->
                            <fa-icon [icon]="['fas', 'download']"></fa-icon><button class="templateDownload" type="button" [disabled]="clickedsampleFileMonitorSOP" name="ApplicationName_Monitoring_SOP.xlsx" style="cursor: pointer;" (click)="sampleDownload(sampleFileMonitorSOP); clickedsampleFileMonitorSOP = true;" #sampleFileMonitorSOP> Download</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false" style=" border: thick; border: solid; border-color: lightgrey;">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h4 class="card-title">
                        <strong>Brown Bag session</strong>
                      </h4>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-panel-description>
                    GSM IM Best Practices for GPC Onboarded Applications.
                  </mat-panel-description>
                  <br>
                  <div class="shiftTurntableWrap">
                    <table class="table table-bordered display-table" style="width:100%">
                      <tbody>
                        <tr class="custom-column">
                          <td style="border:solid; border-width:1px; text-align: left">GSM Brown Bag session</td>
                          <td style="border:solid; border-width:1px; text-align: center">
                            <fa-icon [icon]="['fas', 'external-link']"></fa-icon><a class="templateDownload" target="_blank" style="cursor: pointer;" href="https://mediaexchange.accenture.com/media/t/1_26jp6nds"> Redirect</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <!--template form selection block ends-->
        <br />
        <br />
        <br />


        <br>
        <br />
        <br />


      </div>
        <div id="divOnboardingStatus">
          <h2 style="text-align:center">GPC Onboarding Status</h2>
          <br>
          <br>
          <table style="width:100%">
            <tr>
              <th style="text-align:center">Requested Number</th>
              <th style="text-align:center">Application Air ID</th>
              <th style="text-align:center">Application Name</th>
              <th style="text-align:center">Status</th>
              <th style="text-align:center">Latest Updates</th>
              <th style="text-align:center">Edit/View</th>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td><button type="button" id="btnEdit" class="btn btn-primary" onclick="alert('Edit your form')"><span class="fa fa-edit"></span>Edit</button></td>
            </tr>
          </table>
          <br>
          <div class="float-right">
            <button id="downloadbtn" type="button" class="btn btn-primary" ng-click="Downloadtemp();">Download</button>
            <button id="uploadbtn" type="button" class="btn btn-primary" ng-click="Uploadtemp;">Upload</button>
          </div>
        </div>
      </div>


</div>



<!--</body>

</html>-->
