import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { ShiftturnoverModel } from '../models/shiftturnover-model';
import { ShiftturnoverResponse } from '../models/shiftturnover-response';
import { map, catchError, delay } from 'rxjs/operators';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import * as $ from 'jquery';
import { environment } from '../../environments/environment';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestOnboardingModel } from '../request-onboarding-model';
import { RequestOnboardingService } from '../services/request-onboarding.service';
import { OnboardingInterface } from '../models/onboarding-interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-onboarding-request-approver',
  templateUrl: './onboarding-request-approver.component.html',
  styleUrls: ['./onboarding-request-approver.component.css']
})
export class OnboardingRequestApproverComponent implements OnInit {
  baseURL: string = environment.baseUrl;
  username!: string;
  control = new UntypedFormControl('');
  errorMessage: string = '';
  errorMessage1: string = '';
  successMessage = "";
  pendingRequest: RequestOnboardingModel[] = [];
  holdsPendingRequest: RequestOnboardingModel[] = [];
  pendingRequestForApplication: RequestOnboardingModel[] = [];
  files?: FileList;
  sendImagesFile: any[] = [];
  filename!: string;
  sendImagesDB: string[] = [];
  public ImageAttachmentPath: any[] = [];
  closeResult: string = '';
  status: any[] = [];
  SelectedFiles: any[] = [];
  asTier: any[] = [];
  asStatus: any[] = [];
  request: any;
  imageArray: string[] = [];
  ifApproved: boolean = false;
  hiddenform = new UntypedFormGroup({
    LoggedInUser: new UntypedFormControl()
  });
  buttonName: string = "Approve";
  formDisabled: boolean = true;
  approvedOnce: boolean = false;
  fetchTurnOverD = {
    Id: 0, TurnOverDate: '', LoggedInUser: ''
  }
  loading: boolean | undefined;

  deldata: any = {
    Id: 0, AirId: '',ApplicationName: '',
    ServiceLead: '', Status: '', 
    ApplicationServiceTier: '', LoggedInUser: sessionStorage.getItem('LoggedInUser')
  }

  successNotification(reason: string) {
    Swal.fire({
      title: 'Success',
      text: reason,
      icon: 'success',
      confirmButtonText: 'OK'
    });
  }

  panelOpenState = false;
  nonApproverMessage: boolean = false;
  approverPageContent: boolean = true;

  requestForm = new FormGroup({
    Id: new FormControl(), AirId: new FormControl(), ApplicationName: new FormControl(), ServiceOperationMD: new FormControl(), ServiceLead: new FormControl(), ServiceOperationsManager: new FormControl(), ApplicationPointofContact: new FormControl(),
    ApplicationServiceTier: new FormControl(), GPCOnboardingType: new FormControl(), MonitoringAlertSource: new FormControl(), TotalAmountP1P2IncidentsFiscalYear: new FormControl(), ApplicationDistributionList: new FormControl(),
    HighAvailabilityEnabled: new FormControl(), NumberofApplicationUsers: new FormControl(), FilesUpload: new FormControl(), CreatedBy: new FormControl(), Status: new FormControl(), ImagesPath: new FormControl(this.imageArray), LoggedInUser: new FormControl(sessionStorage.getItem('LoggedInUser'))
  });
  searchForm = new FormGroup({
    status: new FormControl(), service: new FormControl()
  });
  message: string = "";
  approveMessage: string = "";
  constructor(private http: HttpClient, private modalService: NgbModal, private datePipe: DatePipe, private Approvingservice: RequestOnboardingService ) {
    
    this.asStatus = this.getAsStatus();
    this.asTier = this.getAsTier();
    this.Approvingservice.delData = this.deldata;
  }

  ngOnInit() {
    this.checkIfApprover();
    this.FetchOnboardingDetails();
  }
  getAsStatus() {
    return [{ Id: "1", value: "Requested" },
    { Id: "2", value: "Approved" },
    { Id: "3", value: "Review" },
    { Id: "4", value: "Onboarded" }]
  }
  getAsTier() {
    return [{ Id: "1", value: "Always On" },
    { Id: "2", value: "Nearly Always On" }]
  }
  checkIfApprover() {
    let data: any = {
      LoggedInUser: sessionStorage.getItem('LoggedInUser')
    }
    console.log("nav", data);
    return this.http.post<OnboardingInterface>(this.baseURL + 'api/Approver/IsApprover', data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      if (response.Result == true) {
        this.nonApproverMessage = true;
        this.approverPageContent = false;
      }
    });
  }
  FilterRequests() {
    this.pendingRequest = this.holdsPendingRequest;
    if (this.searchForm.value.status != null && this.searchForm.value.service == null) {
      this.pendingRequest = this.pendingRequest.filter(
        request => request.Status === this.searchForm.value.status);
    } else if (this.searchForm.value.service != null && this.searchForm.value.status == null) {
      this.pendingRequest = this.pendingRequest.filter(
        request => request.ApplicationServiceTier === this.searchForm.value.service);
    } else if (this.searchForm.value.service == null && this.searchForm.value.status == null) {
      this.FetchOnboardingDetails();
    } else {
      let pendingRequestService: RequestOnboardingModel[] = [];
      pendingRequestService = this.pendingRequest.filter(
        request => request.Status === this.searchForm.value.status);
      this.pendingRequest = pendingRequestService.filter(
        request => request.ApplicationServiceTier === this.searchForm.value.service);
    }
    if (this.pendingRequest.length == 0) {
      var message = "No Data found for the selected search filters";

      this.errorMessage1 = message;
    }
    else {
      this.errorMessage1 = '';
    }
  
  }
  viewRequest(content: any, data: any) {
    this.imageArray = [];
    var fetchData = { Id: data.Id, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    return this.http.post<RequestOnboardingModel>(this.baseURL + 'api/Approver/FetchOnboardApprovingDetails', fetchData).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {

      if (response.ErrorMessage != '') {

        this.errorMessage = response.ErrorMessage;
      } else {
        this.requestForm.patchValue({
          Id: response.Result[0].Id, AirId: response.Result[0].AirId, ApplicationName: response.Result[0].ApplicationName,
          ServiceOperationMD: response.Result[0].ServiceOperationMD, ServiceLead: response.Result[0].ServiceLead, ServiceOperationsManager: response.Result[0].ServiceOperationsManager,
          ApplicationPointofContact: response.Result[0].ApplicationPointofContact,
          ApplicationServiceTier: response.Result[0].ApplicationServiceTier, GPCOnboardingType: response.Result[0].GPCOnboardingType, MonitoringAlertSource: response.Result[0].MonitoringAlertSource,
          TotalAmountP1P2IncidentsFiscalYear: response.Result[0].TotalAmountP1P2IncidentsFiscalYear,
          ApplicationDistributionList: response.Result[0].ApplicationDistributionList, Status: response.Result[0].Status, ImagesPath: this.imageArray,
          HighAvailabilityEnabled: response.Result[0].HighAvailabilityEnabled, NumberofApplicationUsers: response.Result[0].NumberofApplicationUsers,
           FilesUpload: response.Result[0].FilesUpload, CreatedBy: response.Result[0].CreatedBy
        });
        this.FetchAttachmentImages(response.Result[0].Id);
        if (response.Result[0].Status == 'Review') {
          this.buttonName = "Onboard";
        } else {
          this.buttonName = "Approve";
        }
        if (response.Result[0].Status == "Onboarded") {
          this.approvedOnce = true;
          this.approveMessage = "This request has already been approved";
        } else {
          this.approvedOnce = false;
          this.approveMessage = "";
        }
        if (this.requestForm.value.Status == 'Requested') {
          this.ifApproved = true;
        }
        else {
          this.ifApproved = false;
        }
      }
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result: any) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason: any) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });
  }
  // Creating the panels
  FetchOnboardingDetails() {
    var log = { LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<OnboardingInterface>(this.baseURL + 'api/Approver/FetchOnboardApprovingDetails', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.pendingRequest = response.Result;
      this.holdsPendingRequest = response.Result;
    });

  }
  FetchOnboardingDetailsTable(data: any) {
    var log = { Id: data.Id, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.http.post<RequestOnboardingModel>(this.baseURL + 'api/Approver/FetchOnboardApprovingDetails', log).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      //console.log(response);
      this.pendingRequestForApplication = response.Result;

    });

  }

  getFileDetails(e: any) {
    this.files = e.target.files;
  }

  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  uploadFiles(): Observable<any> {
    this.loading = true;
    this.sendImagesFile = [];
    if (this.files != undefined) {
      this.loading = false;
      for (let k = 0; k < this.files.length; k++) {
        let filename = this.files[k].name;
        this.filename = filename;
        let index = filename.lastIndexOf(".");
        let strsubstring = filename.substring(index, filename.length);
        if (strsubstring.toUpperCase() == '.JPG' || strsubstring.toUpperCase() == '.JPEG' || strsubstring.toUpperCase() == '.PNG' || strsubstring.toUpperCase() == '.GIF' || strsubstring.toUpperCase() == '.MSG' || strsubstring.toUpperCase() == '.XLSX' || strsubstring.toUpperCase() == '.XLSM' || strsubstring.toUpperCase() == '.DOCX' || strsubstring.toUpperCase() == '.ZIP' || strsubstring.toUpperCase() == '.TXT' || strsubstring.toUpperCase() == '.PDF' || strsubstring.toUpperCase() == '.PPTX') {
          this.sendImagesFile.push(this.files[k]);
          // if (!this.sendImagesFile.includes(this.files[k].name)) this.sendImagesFile.push(this.files[k])

        }
        else {
          alert("File Extension Is InValid - Please Upload valid Extension Files");

        }
      }

      if (this.sendImagesFile) {
        if (this.sendImagesFile.length > 0) {
          let fd = new FormData();
          for (let k = 0; k < this.sendImagesFile.length; k++) {
            fd.append("uploadedFile", this.sendImagesFile[k]);
            this.S4();
            // then to call it, plus stitch in '4' in the third group
            let guid = (this.S4() + this.S4() + "-" + this.S4() + "-4" + this.S4().substr(0, 3) + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4()).toLowerCase();
            let uploadedFile = this.sendImagesFile[k].name;
            let index = uploadedFile.lastIndexOf(".");
            let ext = uploadedFile.substring(index, this.filename.length);
            let imgN = uploadedFile.replace(ext, "");
            let imageName = imgN + "_" + guid.toString() + ext;
            // if (!this.sendImagesDB.includes(imageName)) this.sendImagesDB.push(imageName)
           // this.sendImagesDB.push(imageName);
            this.imageArray.push(imageName);

            this.SelectedFiles.push(({ name: imageName, fName: this.sendImagesFile[k].name }));
            fd.append("UniqVal", guid.toString());

          }

          this.http.post(this.baseURL + "api/OnboardingForm/UploadOnboardingTemplate", fd).pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
          ).subscribe(response => {
            this.loading = false;
            if (response.ErrorMessage != '') {

              // this.errorNotification(response.ErrorMessage);
              this.errorMessage = response.ErrorMessage;
            } else {
              this.ImageAttachmentPath = response.Result;
              console.log(this.sendImagesDB);
            }
          });
        }

      }

      $('#file').val('');

    }
    else {
      this.loading = false;
      alert("Please select at least one valid file.");
    }
    return <any>this.SelectedFiles;
  }

  removeFile(file: any) {
    let data = { Id: file.Id, FileName: file.fName, ImagesName: file.name, LoggedInUser: sessionStorage.getItem("LoggedInUser") };

    return this.http.post<RequestOnboardingModel>(this.baseURL + "api/OnboardingForm/RemoveOnboardingTemplate", data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      this.SelectedFiles.splice(0, 1);
      //this.FetchAttachmentImages(file.SId);
      if (response.ErrorMessage != '') {

        // this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      } else {
        var message = "Removed";
        this.successNotification(message);

      }
    });

  }

  dateReset() {
    
    this.sendImagesDB = [];
    this.imageArray = [];
    this.status = []; 


  }

  saveChanges() {
    this.requestForm.get('LoggedInUser')?.setValue(sessionStorage.getItem('LoggedInUser'));
    this.requestForm.get('CreatedBy')?.setValue(sessionStorage.getItem('LoggedInUser'));
    //this.requestForm.get('Status')?.setValue("Completed");
    if (this.requestForm.value.Id != 0 && this.requestForm.value.Status == 'Requested' ) {
      this.requestForm.get('Status')?.setValue("Approved");
    }
    //if (this.requestForm.value.Id != 0 && this.requestForm.value.Status == 'Approved' && this.SelectedFiles.length < 1) { //approver edit the request when status: Approved
    //  this.requestForm.get('Status')?.setValue("Approved");
    //}
    if (this.requestForm.value.Id != 0 && this.requestForm.value.Status == 'Approved' && this.SelectedFiles.length < 1) { //requester edit the request when status: Approved
      this.requestForm.get('Status')?.setValue("Approved");
    }
    else if (this.requestForm.value.Id != 0 && this.requestForm.value.Status == 'Approved' && this.SelectedFiles.length > 0) { //requester uploads files when status: Approved
      this.requestForm.get('Status')?.setValue("Review");
    }
    else if (this.requestForm.value.Id != 0 && this.requestForm.value.Status == 'Review' && this.SelectedFiles.length > 0) {
      this.requestForm.get('Status')?.setValue("Onboarded");
    }
    // Rest of the add functinality goes here
    //console.log(this.requestForm.value);
    this.Approvingservice.approveForms(this.requestForm.value)
      .subscribe(response => {
        if (response.ErrorMessage == '') {
          $("#btnApForForm").prop("disabled", false);
          var saveMessage = "Saved successfully!!";
          this.successNotification(saveMessage);
          $('#Closebtn').trigger('click');
          this.FetchOnboardingDetails();
          this.requestForm.reset();
          this.dateReset();
          this.searchForm.reset();
          // this.ServiceTierrequestForm = [];

         
        } else {
          this.errorMessage = response.ErrorMessage;
          $("#btnApForForm").prop("disabled", false);
          this.sendImagesFile = [];
          this.SelectedFiles = [];
        }

      });
  }


  delete(content: any, data: OnboardingInterface) {
   
    this.loading = true;
    this.deldata.Id = data.Id;

    this.deldata.AirId = data.AirId;
    this.deldata.ApplicationName = data.ApplicationName;
    this.deldata.ServiceLead = data.ServiceLead;
    this.deldata.Status = data.Status;
    this.deldata.ApplicationServiceTier = data.ApplicationServiceTier;
   
    this.deldata.LoggedInUser = sessionStorage.getItem('LoggedInUser');
    this.Approvingservice.deleteForms(this.deldata)
      .subscribe(response => {
        this.loading = false;
        this.FetchOnboardingDetails();
        if (response.ErrorMessage != '') {

          this.errorMessage = response.ErrorMessage;
        } else {
          var message = "Deleted Successfully!!";
          this.successNotification(message);
         
        }
      });
  }


  previewAttachment(file: any) {
    let previewAttachmentData = { ImagesName: file.name, FileName: file.fName, LoggedInUser: sessionStorage.getItem('LoggedInUser') };

    const headers = { Accept: "application/octet-stream" };
    return this.http.post(this.baseURL + "api/Approver/PreviewOnboardingTemplate", previewAttachmentData, { responseType: 'arraybuffer', headers: headers })
      .subscribe(response => {

        var blob = new Blob([response], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = previewAttachmentData.FileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url); //to release memory when not in use

      });
  }

  FetchAttachmentImages(IssueId: any) {
    this.errorMessage = '';

    this.SelectedFiles = [];
    var data = { Id: IssueId, LoggedInUser: sessionStorage.getItem('LoggedInUser') };
    this.loading = true;
    return this.http.post<RequestOnboardingModel>(this.baseURL + "api/Approver/FetchOnboardingTemplate", data).pipe(
      map(this.extractData),
      catchError(this.handleErrorObservable)
    ).subscribe(response => {
      this.loading = false;
      for (var k = 0; k < response.Result.length; k++) {
        var resultSplit = response.Result[k].ImagesName;
        this.SelectedFiles.push({ name: resultSplit, Id: response.Result[k].ImageId, SId: IssueId, ImageName: response.Result[k].ImagesName, fName: response.Result[k].FileName });
        this.imageArray.push(response.Result[k].FileName);
      }
      if (response.ErrorMessage != '') {

        //this.errorNotification(response.ErrorMessage);
        this.errorMessage = response.ErrorMessage;
      }
      return <any>this.SelectedFiles;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  }
}


